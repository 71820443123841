import React from 'react'
import Icon from 'components/elements/Icon'

import { AddCardFormOptions } from '../types'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'

import styles from './formOptions.module.scss'

type Props = {
  currentView: 'selecting' | 'reconciling' | 'finalizing'
  options: AddCardFormOptions
  onOptionsChange: (options: AddCardFormOptions) => void
}

const FormOptions = ({ currentView, options, onOptionsChange }: Props) => {
  return (
    <ArchidektDropdown
      triggerClassName={styles.triggerWrapper}
      menuClassName={styles.menu}
      options={[
        { type: 'header', label: 'On selecting a card' },
        {
          type: 'checkbox',
          label: 'Instantly create new card (skip steps 2 & 3)',
          checked: options.addCardOnSelect,
          disabled: currentView !== 'selecting',
          onChange: () => onOptionsChange({ ...options, addCardOnSelect: !options.addCardOnSelect }),
        },
        {
          type: 'checkbox',
          label: 'Skip "Existing records" tab (skip step 2)',
          checked: options.skipExistingTab,
          disabled: currentView !== 'selecting',
          onChange: () => onOptionsChange({ ...options, skipExistingTab: !options.skipExistingTab }),
        },

        { type: 'spacer' },
        {
          type: 'checkbox',
          label: '+1 quantity if exactly one match is found',
          checked: options.updateIfOneExactExists,
          disabled: currentView !== 'selecting' || options.addCardOnSelect,
          onChange: () => onOptionsChange({ ...options, updateIfOneExactExists: !options.updateIfOneExactExists }),
        },

        {
          type: 'checkbox',
          label: 'Persistent extra search fields',
          checked: options.showAdvancedOptions,
          disabled: currentView !== 'selecting',
          onChange: () => onOptionsChange({ ...options, showAdvancedOptions: !options.showAdvancedOptions }),
        },
        { type: 'spacer' },
        { type: 'header', label: "Don't reset these fields after adding a card" },
        {
          type: 'checkbox',
          label: 'Quantity',
          checked: options.keepQuantity,
          onChange: () => onOptionsChange({ ...options, keepQuantity: !options.keepQuantity }),
        },
        {
          type: 'checkbox',
          label: 'Non / foil',
          checked: options.keepModifier,
          onChange: () => onOptionsChange({ ...options, keepModifier: !options.keepModifier }),
        },
        {
          type: 'checkbox',
          label: 'Collection labels',
          checked: options.keepTags,
          onChange: () => onOptionsChange({ ...options, keepTags: !options.keepTags }),
        },
        {
          type: 'checkbox',
          label: 'Language',
          checked: options.keepLanguage,
          onChange: () => onOptionsChange({ ...options, keepLanguage: !options.keepLanguage }),
        },
        {
          type: 'checkbox',
          label: 'Condition',
          checked: options.keepCondition,
          onChange: () => onOptionsChange({ ...options, keepCondition: !options.keepCondition }),
        },
        {
          type: 'checkbox',
          label: 'Purchase price',
          checked: options.keepPurchasePrice,
          onChange: () => onOptionsChange({ ...options, keepPurchasePrice: !options.keepPurchasePrice }),
        },
        { type: 'spacer' },
        { type: 'header', label: 'After adding / updating a card' },
        {
          type: 'checkbox',
          label: 'Remain on card details (stay on step 3)',
          checked: options.stayOnCardDetails,
          onChange: () => onOptionsChange({ ...options, stayOnCardDetails: !options.stayOnCardDetails }),
        },
      ]}>
      <button className={styles.trigger}>
        <Icon name="flag checkered" /> Speed up collection adding
      </button>
    </ArchidektDropdown>
  )
}

export default FormOptions
