import React, { useState } from 'react'
import { useAppSelector } from 'redux/hooks'

import { FormatType, HUMAN_FORMAT } from 'types/deck'

import PhatDropdown from 'components/formElements/PhatDropdown'

import styles from './formatDropdown.module.scss'

type Props = {
  value?: number
  onChange: (values: FormatType) => void
  header?: string
  className?: string
  disabled?: boolean
  placeholder?: string
}

const FormatDropdown = ({ value, onChange, header, className, disabled = false, placeholder }: Props) => {
  const format = useAppSelector(state => state.deck.format)

  const [selectedValue, setSelectedValue] = useState(value ?? format ?? undefined)

  const options = Object.keys(HUMAN_FORMAT).map(formatName => ({
    label: formatName === 'Custom' ? 'All Formats' : formatName, // Overriding here for better search options
    id: `${HUMAN_FORMAT[formatName]}`,
    onClick: () => handleChange(HUMAN_FORMAT[formatName]),
  }))

  const handleChange = (selectedValue: FormatType) => {
    setSelectedValue(selectedValue)
    onChange(selectedValue)
  }

  return (
    <PhatDropdown
      placeholder={placeholder || 'Format'}
      options={options}
      value={value ?? selectedValue}
      header={header}
      className={`${styles.fullWidth} ${className}`}
      disabled={disabled}
    />
  )
}

export default FormatDropdown
