import { RefObject } from 'react'

import { CardType } from 'types/deck'

import { PlaytesterCardData, Zone } from 'redux/playtesterV2/types'

export type DeckCardDragType = {
  card: CardType
  metaData?: PlaytesterCardData
  index?: number
  insertAtIndex?: boolean
  currentZone?: Zone
  isCtrlPressedRef?: RefObject<boolean>
  stackName?: string

  // We should convert the deckbuilder to use the `isMultiSelected` since passing around the entire object seems to be slower
  multiSelectedIds?: Record<string, boolean> // deckbuilder
  isMultiSelected?: boolean // playtester
}

export const DropItemTypes = {
  CARD: 'card',
  FOLDER: 'folder',
}
