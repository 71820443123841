import type { DefaultTheme } from 'styled-components'

export const LIGHT: DefaultTheme = {
  id: 0,
  backgroundColor: '#f9fafb',
  rgbBackground: '249, 250, 251',
  lightBackground: '#fff',
  lightBackground2: '#e8e8e8',
  lightBackground3: '#f9f9f9',
  color: '#383838',
  secondaryTextColor: '#727272',
  lightColor: '#808080',
  lightColor2: '#d4d4d5',
  lightColor3: '#fafafa',
  rgbColor: '56,56,56',
  tableBorderColor: 'rgba(34, 36, 38, 0.15)',
  tableAlternateColor: 'rgba(0,0,50,.01)',
  linkColor: '#4183c4',
  framingColor: 'rgb(48, 48, 48)',
  rgbFramingColor: '48, 48, 48',
  cardBorder: '#5b5b5b',
  inputBorderColor: 'rgba(34,36,38,.15)',

  // Landing Banner
  secondaryColor: 'rgb(255, 202, 132)',
  tertiaryColor: 'rgba(207, 207, 207, 1)',

  // Folders
  tableSelected: '#eff4fd',
  tableDrop: '#c2d8ff',

  buttonGreen: '#21ba45',
}

export const DARK: DefaultTheme = {
  id: 1,
  backgroundColor: '#181818',
  rgbBackground: '36, 34, 33',
  lightBackground: '#232323',
  lightBackground2: '#383838',
  lightBackground3: '#4B4B4B',
  color: '#e3e3e3',
  secondaryTextColor: '#a8a8a8',
  lightColor: '#8b8b8b',
  lightColor2: '#5f5f5f',
  lightColor3: '#323232',
  rgbColor: '227,227,227',
  tableBorderColor: 'rgb(76,76,76)',
  tableAlternateColor: 'rgba(177,177,194,0.09)',
  linkColor: '#73a8dc',
  framingColor: 'rgb(14,14,14)',
  rgbFramingColor: '14,14,14',
  cardBorder: '#525252',
  inputBorderColor: 'rgba(219, 230, 241,.07)',

  // Landing Banner
  secondaryColor: '#422d00',
  tertiaryColor: '#444444',

  // Folders
  tableSelected: '#333438',
  tableDrop: '#4e586a',

  buttonGreen: '#21ba45',
}
