import React, { useState, useEffect } from 'react'

import Input from 'components/formElements/SemanticInput'

import styles from './numericElements.module.scss'

type Flags = [gte: boolean | undefined, lte: boolean | undefined]

type Props = {
  id: string
  onValueChange: (value: number | string) => void
  onOperationChange: ([gte, lte]: Flags) => void
  placeholder?: string
  value?: number | string
  operations?: Flags
}

const GreatherLessEqualToInput = ({
  id,
  placeholder,
  onValueChange,
  onOperationChange,
  value,
  operations = [false, false],
}: Props) => {
  const [localValue, setLocalValue] = useState<number | string>(value ?? '')
  const [gteValue, setGteValue] = useState<boolean>(operations[0] ?? false)
  const [lteValue, setLteValue] = useState<boolean>(operations[1] ?? false)

  const [equal] = useState(`${id}-logical-equal`)
  const [greater] = useState(`${id}-logical-greater`)
  const [less] = useState(`${id}-logical-less`)

  const handleChange = (value: string) => {
    setLocalValue(value)
    onValueChange(value)
  }

  const handleOperationsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === equal) {
      setGteValue(false)
      setLteValue(false)

      onOperationChange([false, false])
    }

    if (e.target.value === greater) {
      setGteValue(true)
      setLteValue(false)

      onOperationChange([true, false])
    }

    if (e.target.value === less) {
      setGteValue(false)
      setLteValue(true)

      onOperationChange([false, true])
    }
  }

  useEffect(() => {
    if (value === undefined || value === '') setLocalValue('')
    if (operations === undefined || (operations[0] === undefined && operations[1] === undefined)) {
      setGteValue(false)
      setLteValue(false)
    }
  }, [value, operations])

  return (
    <div className={styles.container}>
      <label className={styles.label}>{id}:</label>
      <div className={styles.radio}>
        <input
          onChange={handleOperationsChange}
          name={equal}
          value={equal || ''}
          type="radio"
          id={equal}
          checked={!gteValue && !lteValue}
        />
        <label htmlFor={equal}>=</label>
        <input
          onChange={handleOperationsChange}
          name={greater}
          value={greater || ''}
          type="radio"
          id={greater}
          checked={gteValue}
        />
        <label htmlFor={greater}>{'>='}</label>
        <input
          onChange={handleOperationsChange}
          name={less}
          value={less || ''}
          type="radio"
          id={less}
          checked={lteValue}
        />
        <label htmlFor={less}>{'<='}</label>
      </div>
      <Input className={styles.input} placeholder={placeholder} value={localValue} onChange={handleChange} />
    </div>
  )
}

export default GreatherLessEqualToInput
