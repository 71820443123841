import { store } from 'redux/store'

import { STACK, StackOptionType, getCategoryName } from 'types/deck'

export const makePrimary = (
  categories: Array<string>,
  stackName: string,
  leaveExistingPrimaryAsSecondary?: boolean,
): Array<string> => {
  const deckCategoryInfo = store.getState().deck.categories

  // Special case for making the primary category, if it's "outside of the deck" keep existing categories in place
  if (stackName === 'Sideboard' || !deckCategoryInfo[stackName]?.includedInDeck || leaveExistingPrimaryAsSecondary)
    return [stackName, ...categories.filter(c => c !== stackName)]

  const newCategories = categories.slice()
  if (newCategories.includes(stackName)) newCategories.splice(newCategories.indexOf(stackName), 1)

  newCategories[0] = stackName

  return newCategories
}

const replaceCategory: (categories: Array<string>, oldStackName: string, newStackName: string) => Array<string> = (
  categories,
  oldStackName,
  newStackName,
) => {
  const newCategories = categories.slice()

  newCategories[newCategories.indexOf(oldStackName)] = newStackName

  // Uniqueify the categories so that we never end up with duplicate categories
  return [...new Set(newCategories)]
}

export const getNewCategories = (
  categories: string[],
  stack: StackOptionType,
  currentStack: string | undefined,
  newCategory: string,
  addAsSecondary = false,
): string[] => {
  const primaryCategory = categories[0] || ''

  if (addAsSecondary) {
    if (!categories.includes(newCategory)) return [...categories, newCategory]

    return categories
  }

  if (stack === STACK.CUSTOM || currentStack !== primaryCategory) return makePrimary(categories, newCategory)
  else return replaceCategory(categories, currentStack, newCategory)
}

export const getMultiSelectedUpdates = (
  multiSelectedIds: Record<string, boolean>,
  currentCategory: string | undefined,
  newCategory: string | null,
  stackType = STACK.CUSTOM,
  addAsSecondary = false,
) => {
  const selectedIds = Object.keys(multiSelectedIds)
  const fullDeck = Object.values(store.getState().deck.cardMap)
  const selectedCards = fullDeck.filter(card => selectedIds.includes(card.id))
  const updatedCards = selectedCards.map(card => {
    const updatedCategory = newCategory || getCategoryName({ ...card, categories: [] }) || '😎'
    const updatedCategories = getNewCategories(
      card.categories,
      stackType,
      currentCategory,
      updatedCategory,
      addAsSecondary,
    )

    return { ...card, qty: card.qty || 1, categories: updatedCategories }
  })

  return updatedCards
}
