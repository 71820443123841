import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useCookies } from 'react-cookie'

import { ROLES } from 'types/user'

import { CustomAdContainer, StyledAd, StyledAdLink } from './style'

type Props = {
  className?: string
}

const IMG_URLS: Record<CustomAdType, string> = {
  tcg: 'https://storage.googleapis.com/topdekt-user/ads/tcg_long.jpg',

  tcgHoliday1: 'https://storage.googleapis.com/topdekt-user/ads/tcg_holiday_v1.jpg',
  tcgHoliday2: 'https://storage.googleapis.com/topdekt-user/ads/tcg_holiday_v2.jpg',
  tcgHoliday3: 'https://storage.googleapis.com/topdekt-user/ads/tcg_holiday_v3.jpg',
  tcgHoliday4: 'https://storage.googleapis.com/topdekt-user/ads/tcg_holiday_v4.jpg',
  tcgHoliday5: 'https://storage.googleapis.com/topdekt-user/ads/tcg_holiday_v5.jpg',

  tcgBlackFriday24: 'https://storage.googleapis.com/topdekt-user/ads/tcg_black_friday_24.webp',

  codex: 'https://storage.googleapis.com/topdekt-user/ads/cc.png',
  ch: 'https://storage.googleapis.com/topdekt-user/ads/ch.png',
  chHoliday: 'https://storage.googleapis.com/topdekt-user/ads/ch_holiday.png',
  spellbook: 'https://storage.googleapis.com/topdekt-user/ads/csb.png',

  edhrec: 'https://storage.googleapis.com/topdekt-user/ads/edhrec_ad.png',
  fabrec: 'https://storage.googleapis.com/topdekt-user/ads/archidekt.png',
  spellvoid: 'https://storage.googleapis.com/topdekt-user/ads/archidekt.png',

  archidekt: 'https://storage.googleapis.com/topdekt-user/ads/archidekt.png', // Fallback

  cardsphere1: 'https://storage.googleapis.com/topdekt-user/ads/cardsphere_ad.png',
  cardsphere2: 'https://storage.googleapis.com/topdekt-user/ads/cardsphere_ad_2.png',
  cardsphere3: 'https://storage.googleapis.com/topdekt-user/ads/cardsphere_ad_3.png',

  bolcast: 'https://storage.googleapis.com/topdekt-user/ads/bolcast_ad.png',
  archidektPatreon: 'https://storage.googleapis.com/topdekt-user/ads/archidekt_patreon_ad.png',
  edhrecCast: 'https://storage.googleapis.com/topdekt-user/ads/edhrec_cast_ad.png',
  spellify: 'https://storage.googleapis.com/topdekt-user/ads/spellify_ad.png',

  ckHolidayAd: 'https://storage.googleapis.com/topdekt-user/ads/ck_holiday_ad.jpg',
}

const MOBILE_IMG_URLS: Record<CustomAdType, string> = {
  tcg: 'https://storage.googleapis.com/topdekt-user/ads/tcg_mobile.jpg',

  tcgHoliday1: 'https://storage.googleapis.com/topdekt-user/ads/tcg_holiday_v1.jpg',
  tcgHoliday2: 'https://storage.googleapis.com/topdekt-user/ads/tcg_holiday_v2.jpg',
  tcgHoliday3: 'https://storage.googleapis.com/topdekt-user/ads/tcg_holiday_v3.jpg',
  tcgHoliday4: 'https://storage.googleapis.com/topdekt-user/ads/tcg_holiday_v4.jpg',
  tcgHoliday5: 'https://storage.googleapis.com/topdekt-user/ads/tcg_holiday_v5.jpg',

  tcgBlackFriday24: 'https://storage.googleapis.com/topdekt-user/ads/tcg_black_friday_24.webp',

  codex: 'https://storage.googleapis.com/topdekt-user/ads/cc.png',
  ch: 'https://storage.googleapis.com/topdekt-user/ads/ch.png',
  chHoliday: 'https://storage.googleapis.com/topdekt-user/ads/ch_holiday.png',
  spellbook: 'https://storage.googleapis.com/topdekt-user/ads/csb.png',

  edhrec: 'https://storage.googleapis.com/topdekt-user/ads/edhrec_ad.png',
  fabrec: 'https://storage.googleapis.com/topdekt-user/ads/archidekt.png',
  spellvoid: 'https://storage.googleapis.com/topdekt-user/ads/archidekt.png',

  archidekt: 'https://storage.googleapis.com/topdekt-user/ads/archidekt.png', // Fallback

  cardsphere1: 'https://storage.googleapis.com/topdekt-user/ads/cardsphere_ad.png',
  cardsphere2: 'https://storage.googleapis.com/topdekt-user/ads/cardsphere_ad_2.png',
  cardsphere3: 'https://storage.googleapis.com/topdekt-user/ads/cardsphere_ad_3.png',

  bolcast: 'https://storage.googleapis.com/topdekt-user/ads/bolcast_ad.png',
  archidektPatreon: 'https://storage.googleapis.com/topdekt-user/ads/archidekt_patreon_ad.png',
  edhrecCast: 'https://storage.googleapis.com/topdekt-user/ads/edhrec_cast_ad.png',
  spellify: 'https://storage.googleapis.com/topdekt-user/ads/spellify_ad.png',

  ckHolidayAd: 'https://storage.googleapis.com/topdekt-user/ads/ck_holiday_ad.jpg',
}

const AD_URLS: Record<CustomAdType, string> = {
  tcg: 'http://tcgads.archidecked.com',

  tcgHoliday1: 'http://tcgplayer.pxf.io/c/4913290/1897075/21018',
  tcgHoliday2: 'http://tcgplayer.pxf.io/c/4913290/1897076/21018',
  tcgHoliday3: 'http://tcgplayer.pxf.io/c/4913290/1897077/21018',
  tcgHoliday4: 'http://tcgplayer.pxf.io/c/4913290/1897078/21018',
  tcgHoliday5: 'http://tcgplayer.pxf.io/c/4913290/1897079/21018',

  tcgBlackFriday24: 'https://tcgplayer.pxf.io/09Z2RL',

  codex: 'https://commandercodex.com/',
  ch: 'https://commandersherald.com/',
  chHoliday: 'https://commandersherald.com/tag/mtg-2023-gift-guide/',
  spellbook: 'https://commanderspellbook.com/',

  edhrec: 'https://edhrec.com/',
  fabrec: 'https://fabrec.com/',
  spellvoid: 'https://spellvoid.com/',

  archidekt: 'https://archidekt.com/', // Fallback

  cardsphere1: 'https://www.cardsphere.com/',
  cardsphere2: 'https://commandersherald.com/selling-your-magic-cards-with-cardsphere/',
  cardsphere3: 'https://www.cardsphere.com/',

  bolcast: 'https://www.youtube.com/@AmITheBolcast',
  archidektPatreon: 'https://www.patreon.com/archidekt',
  edhrecCast: 'https://youtube.com/@EDHRECast',
  spellify: 'https://commandersherald.com/games/spellify/',

  ckHolidayAd:
    'http://www.cardkingdom.com/landing/mtg-dual-lands?partner=archidekt&utm_source=archidekt&utm_medium=affiliate&utm_campaign=archidekt&partner_args=holiday-2024',
}

export const getAdInfo = (skippableAds: Array<CustomAdType> = []): CustomAdInfo => {
  const adPool: CustomAdType[] = []

  const now = new Date()
  const forceOverrideAdStartTime = new Date('2024-11-29T14:00:00Z')
  const forceOverrideAdEndTime = new Date('2024-12-03T01:00:00Z')

  const isForceOverrideAdTime = now >= forceOverrideAdStartTime && now <= forceOverrideAdEndTime

  if (isForceOverrideAdTime)
    return {
      adType: 'tcgBlackFriday24',
      adHref: AD_URLS['tcgBlackFriday24'],
      adUrl: IMG_URLS['tcgBlackFriday24'],
      adMobileUrl: MOBILE_IMG_URLS['tcgBlackFriday24'],
    }

  if (!skippableAds.includes('cardsphere3')) adPool.push('cardsphere3')
  if (!skippableAds.includes('spellify')) adPool.push('spellify')
  if (!skippableAds.includes('edhrec')) adPool.push('edhrec')
  if (!skippableAds.includes('ch')) adPool.push('ch')
  if (!skippableAds.includes('edhrecCast')) adPool.push('edhrecCast')
  if (!skippableAds.includes('bolcast')) adPool.push('bolcast')
  if (!skippableAds.includes('spellbook')) adPool.push('spellbook')
  if (!skippableAds.includes('archidektPatreon')) adPool.push(...Array(3).fill('archidektPatreon'))

  if (!skippableAds.includes('tcg')) adPool.push(...Array(adPool.length).fill('tcg')) // Fills the array with tcg to account for 50% of the ads (aka the same amount as all other ads combined)

  const chosenAd = adPool[Math.floor(Math.random() * adPool.length)]

  return {
    adType: chosenAd,
    adHref: AD_URLS[chosenAd],
    adUrl: IMG_URLS[chosenAd],
    adMobileUrl: MOBILE_IMG_URLS[chosenAd],
  }
}

type CustomAdType =
  | 'tcg'
  | 'edhrec'
  | 'ch'
  | 'fabrec'
  | 'spellvoid'
  | 'codex'
  | 'spellbook'
  | 'archidekt'
  | 'chHoliday'
  | 'tcgHoliday1'
  | 'tcgHoliday2'
  | 'tcgHoliday3'
  | 'tcgHoliday4'
  | 'tcgHoliday5'
  | 'tcgBlackFriday24'
  | 'cardsphere1'
  | 'cardsphere2'
  | 'cardsphere3'
  | 'bolcast'
  | 'archidektPatreon'
  | 'edhrecCast'
  | 'spellify'
  | 'ckHolidayAd'

export type CustomAdInfo = {
  adType: CustomAdType
  adHref: string
  adUrl: string
  adMobileUrl: string
}

const CustomAd = ({ className = '' }: Props) => {
  const [{ tbTier: tier, tbR: roles = [] }] = useCookies(['tbTier', 'tbR'])

  const [chosenAd, setChosenAd] = useState<CustomAdInfo | null>(null)

  useEffect(() => {
    const ad = getAdInfo()

    setChosenAd(ad)
  }, [])

  const handleLogAdClick = () => {
    ReactGA.event({ category: 'Ad', action: `Clicked ${chosenAd?.adType} ad` })
  }

  const noAds = Number(tier) || roles.includes(ROLES.AD_FREE)

  if (noAds || !chosenAd) return null

  return (
    <CustomAdContainer className={className}>
      <StyledAdLink href={chosenAd.adHref} target="_blank" rel="noopener noreferrer" onClick={handleLogAdClick}>
        <StyledAd url={chosenAd.adUrl} mobileUrl={chosenAd.adMobileUrl} />
      </StyledAdLink>
    </CustomAdContainer>
  )
}

export default CustomAd
