import React, { useEffect, useRef } from 'react'
import { useCookies } from 'react-cookie'

import { CardType } from 'types/deck'
import { AddCardView } from 'components/collectionV2/addCards/formParts/types'
import { SET_COLLECTION_V2_STATE } from 'redux/collectionV2/actions/types'
import { OWNED } from 'types/deck'

import { useActionless, useAppSelector } from 'redux/hooks'

import CardAutocomplete from 'components/formElements/CardAutocomplete'
import SetDropdown from 'components/cardSearchPanel/searchFormElements/setDropdown'
import Input from 'components/formElements/SemanticInput'

import styles from './selectCard.module.scss'

type Props = {
  onSelectCard: (card: CardType) => void
  onViewChange: (view: AddCardView) => void

  noChangeView?: boolean
  showSecondaryOption?: boolean
  skipExistingTab?: boolean
}

export const COLLECTION_FORM_COOKIE = 'collectionAddCardForm'

type FormType = 'archidekt' | 'scryfall'

const SelectCard = ({ onSelectCard, onViewChange, noChangeView, showSecondaryOption, skipExistingTab }: Props) => {
  const archidektInputRef = useRef<HTMLInputElement>(null)
  const syntaxInputRef = useRef<HTMLInputElement>(null)

  const [{ collectionAddCardForm: addCardForm = 'archidekt' }, setCookies] = useCookies([COLLECTION_FORM_COOKIE])

  const selectCardExtraSearchOptions = useAppSelector(state => state.collectionV2.selectCardExtraSearchOptions)

  const [setCollectionV2State] = useActionless(SET_COLLECTION_V2_STATE)

  const handleSetFromType = (type: FormType) => setCookies(COLLECTION_FORM_COOKIE, type, { path: '/' })

  const handleSelectCard = (card: CardType) => {
    onSelectCard(card)

    if (noChangeView) return handleFocus()
    if (skipExistingTab) return onViewChange('finalizing')

    if (!card.owned) onViewChange('finalizing')
    else onViewChange('reconciling')
  }

  const handleFocus = () => {
    setTimeout(() => {
      if (addCardForm === 'archidekt') archidektInputRef.current?.focus()
      if (addCardForm === 'scryfall') syntaxInputRef.current?.focus()
    }, 200)
  }

  useEffect(() => {
    handleFocus()
  }, [])

  useEffect(() => {
    const updatedOptions = {
      archidekt: addCardForm === 'syntax' ? [] : selectCardExtraSearchOptions?.archidekt,
      scryfall: addCardForm === 'archidekt' ? '' : selectCardExtraSearchOptions?.scryfall,
    }

    setCollectionV2State({ selectCardExtraSearchOptions: updatedOptions })
  }, [addCardForm])

  return (
    <div className={styles.container}>
      <div className={styles.radio}>
        <label>Card search type:</label>
        <span>
          <input
            id="archidekt-radio"
            type="radio"
            checked={addCardForm === 'archidekt'}
            onClick={() => handleSetFromType('archidekt')}
          />
          <label htmlFor="archidekt-radio">Archidekt</label>
        </span>

        <span>
          <input
            id="syntax-radio"
            type="radio"
            checked={addCardForm === 'scryfall'}
            onClick={() => handleSetFromType('scryfall')}
          />
          <label htmlFor="syntax-radio">Syntax</label>
        </span>
      </div>

      <div className={styles.primaryInput}>
        <label>Search for card to add</label>

        {addCardForm === 'archidekt' && (
          <CardAutocomplete
            ref={archidektInputRef}
            fetchCard
            placeholder="Sol Ring"
            onSelect={handleSelectCard}
            limitedSearchOptions={{
              editions: selectCardExtraSearchOptions.archidekt,
            }}
          />
        )}

        {addCardForm === 'scryfall' && (
          <CardAutocomplete
            ref={syntaxInputRef}
            scryfallSearch
            fetchCard
            placeholder="set:wot name:island"
            onSelect={handleSelectCard}
            limitedSyntaxSearchOptions={selectCardExtraSearchOptions.scryfall}
          />
        )}
      </div>

      {showSecondaryOption && (
        <>
          <div className={styles.horizontalRule} />

          <div className={styles.secondaryOptions}>
            <div className={styles.editionLocks}>
              {addCardForm === 'archidekt' && (
                <>
                  <label>Limit search to editions</label>{' '}
                  <SetDropdown
                    single={false}
                    value={selectCardExtraSearchOptions.archidekt}
                    onChange={archidekt =>
                      setCollectionV2State({ selectCardExtraSearchOptions: { archidekt, scryfall: '' } })
                    }
                  />
                </>
              )}
              {addCardForm === 'scryfall' && (
                <>
                  <label>Always appended syntax</label>{' '}
                  <Input
                    placeholder="set:one"
                    onChange={scryfall =>
                      setCollectionV2State({ selectCardExtraSearchOptions: { archidekt: [], scryfall } })
                    }
                    value={selectCardExtraSearchOptions.scryfall}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default SelectCard
