import React from 'react'

import styles from './manaFont.module.scss'

// THESE FONTS ARE INSTALLED VIA A CDN IN index.html
// Due to our using scss modules, installing outside of npm and using the cdn was the easiest way to get these installed
// Be aware that they're installed via the index.html

// https://mana.andrewgioia.com/icons.html - add as needed
export type ManaFontIcons =
  | 'w'
  | 'u'
  | 'b'
  | 'r'
  | 'g'
  | 'c'
  | 'e' // energy
  | 'p' // phyrexian
  | 'tap'
  | 'untap'
  | 'power'
  | 'toughness'
  | 'loyalty-up'
  | 'loyalty-down'
  | 'loyalty-start'
  | 'counter-loyalty'
  | 'ability-d20'
  | 'ability-deathtouch'
  | 'ability-first-strike'
  | 'ability-double-strike'
  | 'ability-flying'
  | 'ability-haste'
  | 'ability-hexproof'
  | 'ability-indestructible'
  | 'ability-transform'
  | 'ability-lifelink'
  | 'ability-menace'
  | 'ability-trample'
  | 'ability-reach'
  | 'ability-vigilance'
  | 'token'
  | 'rarity'
  | 'saga'
  | 'ability-party'
  | 'counter-pin'
  | 'counter-charge'
  | 'counter-time'
  | 'artifact'
  | 'creature'
  | 'enchantment'
  | 'instant'
  | 'land'
  | 'planeswalker'
  | 'sorcery'
  | 'counter-lore'
  | 'counter-shield'

type Props = {
  icon: ManaFontIcons
  className?: string
  style?: React.CSSProperties
}

const ManaFrontIcon = ({ icon, className = '', style }: Props) => (
  <i className={`${styles.icon} ms ms-${icon} ${className}`} style={style} />
)

export default ManaFrontIcon
