import React from 'react'
import { Select as SemanticSelect } from 'semantic-ui-react'
import type { SelectProps } from 'semantic-ui-react'

import styles from './semanticSelect.module.scss'

export type Options = { text: React.ReactNode; value: any; key?: string }

type Props = Omit<SelectProps, 'onChange' | 'multiple'> &
  (
    | { onChange: (value: any[]) => void; multiple: true }
    | { onChange: (value: any) => void; multiple?: never | false }
  ) & {
    label?: string
    containerClassName?: string
    searchable?: boolean
  }

const Select = (props: Props) => {
  const handleChange = (_e: any, { value }: any) => {
    props.onChange(value)
  }

  const semanticProps = { ...props }

  delete semanticProps.containerClassName

  return (
    <span className={`${styles.container} ${props.disabled ? styles.disabled : ''} ${props.containerClassName}`}>
      {props.label && <label>{props.label}</label>}
      <SemanticSelect
        {...semanticProps}
        fluid
        search={props.searchable}
        className={`${styles.dropdown} ${props.className || ''}`}
        options={props.options}
        onChange={handleChange}
        forceSelection={false}
        selectOnBlur={false}
      />
    </span>
  )
}

export default Select
