import { Finishes } from 'types/deck'

import { codeAndHumanToDropdown } from 'utils/DropdownConversions'

import variables from 'assets/scss/variables.module.scss'
import { invert } from 'lodash'

export type EditionType = {
  editioncode: string
  editionname: string
  id: number
}

export type EditionCardType = {
  edition: EditionType
  id: number
  rarity: string
}

export type CollectionCardType = {
  id: number
  card: {
    name: string
    options: Array<string>
    edition: EditionType
    uid: string
    rarity: string
    id: number
  }
  editions: Array<EditionCardType>
  quantity: number
  modifier: Finishes
  language: number
  condition: number
  tags: Array<any>
  createdAt: string
}

export type CollectionTagType = {
  id: number
  tagname: string
  tagcolor: string
}

export const LANGUAGE_HUMAN = {
  English: 1,
  'Chinese Traditional': 2,
  German: 3,
  French: 4,
  Italian: 5,
  Japanese: 6,
  Korean: 7,
  Portuguese: 8,
  Russian: 9,
  'Chinese Simplified': 10,
  Spanish: 11,
}

export const LANGUAGE_FOREIGN: Record<string, number> = {
  English: 1,
  繁體中文: 2,
  Deutsch: 3,
  Français: 4,
  Italiano: 5,
  日本語: 6,
  한국어: 7,
  Português: 8,
  'русский язык': 9,
  简体中文: 10,
  Español: 11,
}

export const LANGUAGE_CODE: Record<string, number> = {
  EN: 1,
  CT: 2,
  DE: 3,
  FR: 4,
  IT: 5,
  JP: 6,
  KR: 7,
  PT: 8,
  RU: 9,
  CS: 10,
  SP: 11,
}

export const CONDITION_HUMAN: Record<string, number> = {
  'Near Mint': 1,
  'Lightly Played': 2,
  'Moderately Played': 3,
  'Heavily Played': 4,
  Damaged: 5,
}

export const CONDITION_CODE: Record<string, number> = {
  NM: 1,
  LP: 2,
  MP: 3,
  HP: 4,
  D: 5,
}

export const LANG_ID_TO_CODE: Record<number | string, string> = invert(LANGUAGE_CODE)
export const LANG_ID_TO_NAME: Record<number | string, string> = invert(LANGUAGE_FOREIGN)
export const COND_ID_COND_ABR: Record<number | string, string> = invert(CONDITION_CODE)
export const COND_ID_COND_NAME: Record<number | string, string> = invert(CONDITION_HUMAN)

export const defaultCollectionCard: CollectionCardType = {
  id: 0,
  card: {
    name: '',
    options: [],
    edition: {
      editioncode: '',
      editionname: '',
      id: 0,
    },
    uid: '',
    id: 0,
    rarity: '',
  },
  quantity: 0,
  modifier: 'Normal',
  language: LANGUAGE_HUMAN.English,
  condition: CONDITION_CODE.NM,
  tags: [],
  createdAt: '',
  editions: [],
}

export type UploadErrorsType = {
  columns: Array<string>
  condition: Array<string>
  language: Array<string>
  notFound: Array<string>
  notSure: Array<string>
  quantity: Array<string>
  badIds: Array<string>
  badLines: Array<string>
}

export const defaultUploadErrors = {
  columns: [],
  condition: [],
  language: [],
  notFound: [],
  notSure: [],
  quantity: [],

  // .dek
  badIds: [],
  badLines: [],
}

export type TextErrorsType = {
  notFound: Array<string>
  notSure: Array<string>
}

export const defaultTextErrors = {
  notFound: [],
  notSure: [],
}

export const CONDITION_DROPDOWN = codeAndHumanToDropdown(CONDITION_CODE, CONDITION_HUMAN)
export const LANGUAGE_DROPDOWN = codeAndHumanToDropdown(LANGUAGE_CODE, LANGUAGE_FOREIGN)

export const OWNERSHIP_COLORS = {
  EXACT: variables.quantityGreen,
  NOT_EXACT: variables.quantityBlue,
}

export type CollectionFiltersType = {
  tags?: string[]
  collectionLanguage?: string[]
  quantity?: number | string
  quantityLogic?: '=' | '>=' | '<='
  treatment?: 'foil' | 'nonfoil' | 'etched'
  condition?: string[]
}

export const DEFAULT_COLLECTION_FILTERS: CollectionFiltersType = {
  tags: [],
  collectionLanguage: [],
  condition: [],
  quantity: '',
  quantityLogic: undefined,
  treatment: undefined,
}
