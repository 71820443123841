import React from 'react'

import config from 'environment'

import ContextMenu from 'components/elements/ContextMenu'
import { createKeybindLabel } from 'components/elements/ArchidektDropdown'
import { Option } from 'components/elements/ArchidektDropdown/types'
import { DeckCardWrapperChildProps } from './deckCardWrapper'

import { creatEdhrecCardName } from 'utils/edhrec.utils'

import styles from './deckCardContextMenu.module.scss'
import { getPriceStringAndUrl } from 'utils/Price'
import { PRICE_SOURCE } from 'types/active'

type Props = {
  children?: React.ReactNode
  checkSkipRightClick: () => boolean
  wrapperData: DeckCardWrapperChildProps
}

const DeckCardContextMenu = ({ children, wrapperData, checkSkipRightClick }: Props) => (
  <ContextMenu
    checkSkipRightClick={checkSkipRightClick}
    options={generateContextOptions(wrapperData, true)}
    menuClassName={styles.contextMenu}>
    {children}
  </ContextMenu>
)

export default DeckCardContextMenu

export const generateContextOptions = (
  { card, actions, localCardData, deckData }: DeckCardWrapperChildProps,
  asContextMenu?: boolean,
): Option[] => {
  const { url: tcgUrl } = getPriceStringAndUrl(card, PRICE_SOURCE.TCG, card.modifier !== 'Normal')
  const { url: ckUrl } = getPriceStringAndUrl(card, PRICE_SOURCE.CK, card.modifier !== 'Normal')

  const writeAccessActions: Option[] = [
    {
      label: createKeybindLabel('Increase quantity', 'add', '+'),
      onClick: actions.incrementCard,
      noCloseOnClick: true,
    },
    {
      label: createKeybindLabel('Decrease quantity', 'minus', '-'),
      onClick: actions.decrementCard,
      noCloseOnClick: true,
    },
    {
      icon: 'add circle',
      label: 'Add as new card',
      onClick: actions.addAsNewCard,
    },
    { type: 'spacer' },
    { label: 'Switch card printing', onClick: () => actions.openCardModal(true), icon: 'grid layout', keybind: 'P' },
    {
      label: createKeybindLabel('Switch to nonfoil', 'star outline', 'N'),
      onClick: actions.setNormal,
      hidden: !localCardData.hasFinishOptions || localCardData.isNormal,
    },
    {
      label: createKeybindLabel('Switch to foil', 'star', 'F', 'blue'),
      onClick: actions.setFoil,
      hidden: !localCardData.hasFinishOptions || localCardData.isFoil,
    },
    {
      label: createKeybindLabel('Switch to etched', 'star', 'E', 'orange'),
      onClick: actions.setEtched,
      hidden: !localCardData.hasEtchedOption || !localCardData.hasFinishOptions || localCardData.isEtched,
    },
    {
      label: 'Set as commander',
      icon: 'chess queen',
      hidden: deckData.categories[card.categories[0]]?.isPremier,
      // Matching the other set commander buttons, remove all other categories and just set 'Commander'
      onClick: () => actions.onCardUpdate({ ...card, categories: ['Commander'] }),
    },
    {
      label: 'Unset commander',
      icon: 'ban',
      hidden: !deckData.categories[card.categories[0]]?.isPremier,
      onClick: () =>
        actions.onCardUpdate({
          ...card,
          categories:
            card.categories.length > 1 ? [...card.categories.filter((_card, index) => index > 0)] : [card.typeCategory],
        }),
    },
    { type: 'spacer' },
    {
      type: 'extras',
      label: 'Move to category',
      icon: 'arrow right',
      id: 'move-to-category',
      className: styles.categoryMenu,
      options: [
        {
          label: createKeybindLabel('Maybeboard', undefined, 'M'),
          onClick: () => actions.changeCategory('Maybeboard'),
          hidden: localCardData.isMaybeboardPrimary,
        },
        {
          label: createKeybindLabel('Sideboard', undefined, 'S'),
          onClick: () => actions.changeCategory('Sideboard'),
          hidden: localCardData.isSideboardPrimary,
        },
        {
          label: createKeybindLabel('Automatic category', undefined, 'A'),
          onClick: () => actions.changeCategory(card.categories[1] || card.types[0] || card.front?.types[0] || '😎'),
          hidden: !localCardData.isSideboardPrimary && !localCardData.isMaybeboardPrimary,
        },
        { type: 'spacer' },
        ...localCardData.categoryNames
          .filter(categoryName => !['Sideboard', 'Maybeboard', 'Commander'].includes(categoryName))
          .map(categoryName => ({
            label: categoryName,
            onClick: () => actions.changeCategory(categoryName),
          })),
      ],
    },

    { type: 'spacer' },
  ]

  return [
    { type: 'header', label: card.name },
    { type: 'spacer' },
    {
      label: createKeybindLabel('Flip card', 'refresh', 'L'),
      onClick: () => actions.setFlipped(!localCardData.flipped),
      hidden: !localCardData.isFlipCard,
      noCloseOnClick: true,
    },
    { type: 'spacer', hidden: !localCardData.isFlipCard },

    ...(localCardData.ownsDeck ? writeAccessActions : []),

    { label: 'View card details', onClick: actions.openCardModal, icon: 'arrow right', keybind: 'O' },
    {
      label: createKeybindLabel('Remove card', 'trash alternate', 'R'),
      onClick: actions.removeCard,
      hidden: !localCardData.ownsDeck,
    },
    {
      label: createKeybindLabel(
        'Multi-select',
        'hand point up outline',
        config.getOnMac() ? 'Cmd+Click' : 'Ctrl + Click',
      ),
      onClick: actions.multiSelect,
    },
    {
      label: 'View card page',
      icon: 'external alternate',
      to: `/card?uid=${card.uid}`,
      hidden: localCardData.ownsDeck,
    },
    { label: 'Copy card name', icon: 'copy', onClick: actions.copyCardName, hidden: localCardData.ownsDeck },
    { type: 'spacer', hidden: !localCardData.ownsDeck },
    {
      type: 'extras',
      id: 'extras-menu',
      icon: 'arrow right',
      label: 'Card extras',
      hidden: !localCardData.ownsDeck,
      options: [
        { label: 'View card page', icon: 'external alternate', to: `/card?uid=${card.uid}` },
        { label: 'Copy card name', icon: 'copy', onClick: actions.copyCardName, keybind: 'C' },
        { type: 'spacer' },
        { type: 'header', label: 'External links' },
        {
          label: 'EDHREC',
          target: '_blank',
          to: `https://edhrec.com/cards/${creatEdhrecCardName(card)}`,
          className: styles.externalLinks,
        },
        {
          label: 'Scryfall',
          target: '_blank',
          to: `https://scryfall.com/card/${card.uid}`,
          className: styles.externalLinks,
        },
        {
          label: 'TCGPlayer',
          target: '_blank',
          to: tcgUrl,
          className: styles.externalLinks,
        },
        {
          label: 'Card Kingdom',
          target: '_blank',
          to: ckUrl,
          className: styles.externalLinks,
        },
      ],
    },
    { type: 'message', label: 'Ctrl + Right Click for standard menu', hidden: !asContextMenu },
  ]
}
