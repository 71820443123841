import React from 'react'
import Icon from 'components/elements/Icon'

import styles from './checkboxFancybox.module.scss'

type Props = {
  onClick: () => void
  checked: boolean
  header: string
  description?: string
  disabled?: boolean
  className?: string
}

const CheckboxFancybox = ({ onClick, checked, header, description, disabled, className = '' }: Props) => (
  <button type="button" className={`${styles.toggleBox} ${className}`} disabled={disabled} onClick={onClick}>
    <Icon name={checked ? 'check square' : 'square outline'} className={checked ? styles.checked : undefined} />
    <div>
      <h4>{header}</h4>
      <label>{description}</label>
    </div>
  </button>
)

export default CheckboxFancybox
