import React from 'react'

import Link from 'components/elements/Link'

import styles from './internalLinks.module.scss'

const InternalLinks = () => (
  <div className={styles.container}>
    <Link href="/app">
      <button>APPS</button>
    </Link>
    <Link href="/faq">
      <button>FAQ</button>
    </Link>
    <Link href="/contact">
      <button>CONTACT</button>
    </Link>
    <Link href="/supportus">
      <button>SUPPORT US</button>
    </Link>
  </div>
)

export default InternalLinks
