import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import { Icon, SemanticICONS } from 'semantic-ui-react'

import { useActionless } from 'redux/hooks'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import AnimatedHamburger from 'components/misc/AnimatedHamburger'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import Link from 'components/elements/Link'
import ManaFrontIcon, { ManaFontIcons } from 'components/misc/customIcons/ManaFont'

import { getDeckSearchOrderingQueryParameter } from 'services/accountSettings.service'

import styles from './mobileNavbarOptions.module.scss'

type Props = {
  noSearch?: boolean
  className?: string
  overflowItemsClassName?: string
}

const MobileNavbarOptions = ({ noSearch, className, overflowItemsClassName }: Props) => {
  const [{ tbId: userId, tbUser: username, tbRootFolder: rootFolder }] = useCookies(['tbId', 'tbUser', 'tbRootFolder'])
  const [setActiveState] = useActionless(SET_ACTIVE_STATE)

  const handleOpen = () => setActiveState({ searchOpen: true })

  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  const allDecks = `/search/decks?${getDeckSearchOrderingQueryParameter()}`
  const myDecks = `/search/decks?ownerUsername=${username}&${getDeckSearchOrderingQueryParameter()}`
  const myFolder = `/folders/${rootFolder}`
  const sharedWithMe = `/search/decks?shared=true&${getDeckSearchOrderingQueryParameter()}`
  const bookmarkedDecks = `/search/decks?bookmarks=true&${getDeckSearchOrderingQueryParameter()}`

  return (
    <ArchidektDropdown
      tabIndex={-1}
      onFocus={() => setHamburgerOpen(true)}
      onBlur={() => setHamburgerOpen(false)}
      triggerClassName={`${styles.hamburger} ${className}`}
      menuClassName={styles.menu}
      customOptions={
        <>
          {!noSearch && (
            <>
              <Button
                className={overflowItemsClassName}
                label="Card Search"
                icon="search"
                onClick={handleOpen}
                aria-label="Card Search"
              />
              <div className={`${styles.spacer} ${overflowItemsClassName}`} />
            </>
          )}

          <CustomLink className={overflowItemsClassName} label="My decks" to={myDecks} icon="user" hidden={!username} />
          <CustomLink
            className={overflowItemsClassName}
            label="My folders"
            to={myFolder}
            icon="folder outline"
            hidden={!username}
          />

          {username && <div className={`${styles.spacer} ${overflowItemsClassName}`} />}

          <CustomLink className={overflowItemsClassName} label="Search all decks" to={allDecks} icon="globe" />

          <CustomLink to="/commander-precons" label="Commander precons" icon="gift" ariaLabel="Commander precons" />

          {username && <div className={`${styles.spacer} ${overflowItemsClassName}`} />}

          <CustomLink
            label="Shared with me"
            className={overflowItemsClassName}
            hidden={!username}
            to={sharedWithMe}
            icon="group"
            ariaLabel="Decks shared with me"
          />

          <CustomLink
            label="Bookmarked decks"
            className={overflowItemsClassName}
            hidden={!username}
            to={bookmarkedDecks}
            icon="bookmark outline"
          />

          <div className={`${styles.spacer} ${overflowItemsClassName}`} />

          <CustomLink
            className={overflowItemsClassName}
            label="New deck"
            icon="add"
            to="/new-deck"
            hidden={!username}
          />
          <CustomLink className={overflowItemsClassName} label="Deck sandbox" to="/sandbox" icon="flask" />

          <CustomLink className={overflowItemsClassName} label="Compare decks" to="/compare" icon="exchange" />

          <div className={`${styles.spacer} ${overflowItemsClassName}`} />

          <CustomLink
            to="https://discord.gg/GyAAANU8uJ"
            label="Archidekt Discord"
            icon="discord"
            ariaLabel="Archidekt discord"
            rel="noopener noreferrer"
            target="_blank"
          />

          <CustomLink className={overflowItemsClassName} label="News" to="/news" icon="newspaper" />

          <CustomLink to="/forum" label="Forums" icon="comment outline" ariaLabel="Archidekt forum" />

          {/* <CustomLink
            to="/forum/General/Development"
            label="Development forum"
            icon="bug"
            ariaLabel="Development forums"
          />
          <CustomLink
            to="/forum/General/General"
            label="General MTG forum"
            manaFontIcon="ability-d20"
            ariaLabel="General forums"
          />
          <CustomLink to="/forum/General/Archidecture" label="Deck help forum" icon="wrench" ariaLabel="Deck forums" /> */}

          {/* <CustomLink to="/features" label="Feature voting" icon="balance scale" ariaLabel="Feature voting forums" /> */}

          {username && <div className={styles.spacer} />}
          <CustomLink
            to={`/collection/v2/${userId}`}
            label="Collection"
            icon="address book outline"
            hidden={!username}
          />
        </>
      }>
      <button>
        <AnimatedHamburger open={hamburgerOpen} className={styles.hamburgerColor} />
      </button>
    </ArchidektDropdown>
  )
}

export default MobileNavbarOptions

type ButtonProps = {
  label: string
  onClick: () => void
  hidden?: boolean
  icon?: SemanticICONS
  ariaLabel?: string
  className?: string
}
type LinkProps = {
  label: string
  to: string
  hidden?: boolean
  icon?: SemanticICONS
  ariaLabel?: string
  manaFontIcon?: ManaFontIcons
  rel?: string
  target?: string
  className?: string
}

const Button = ({ label, onClick, hidden, icon, ariaLabel, className }: ButtonProps) => {
  if (hidden) return null

  return (
    <button onClick={onClick} aria-label={ariaLabel} className={className}>
      {icon && <Icon name={icon} />}
      {label}
    </button>
  )
}

const CustomLink = ({ label, to, hidden, icon, manaFontIcon, ariaLabel, rel, target, className }: LinkProps) => {
  if (hidden) return null

  return (
    <Link href={to} aria-label={ariaLabel} rel={rel} target={target} className={className}>
      {icon && <Icon name={icon} />}
      {manaFontIcon && <ManaFrontIcon icon={manaFontIcon} />}
      {label}
    </Link>
  )
}
