import React from 'react'
import { Dropdown } from 'semantic-ui-react'

const options = [
  { text: 'Ascending', value: false },
  { text: 'Descending', value: true },
]

type Props = {
  value?: boolean
  onChange: (value: boolean) => void
}

const SortingDropdown = ({ value = options[0].value, onChange }: Props) => {
  const handleChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: any) => onChange(value)

  return (
    <div>
      <label>Sort Direction:</label>
      <Dropdown selection fluid options={options} value={value} onChange={handleChange} />
    </div>
  )
}

export default SortingDropdown
