import React, { useState, useEffect, useRef } from 'react'
import { Input as SemanticInput } from 'semantic-ui-react'
import type { InputProps } from 'semantic-ui-react'

type Props = Omit<InputProps, 'onChange'> & {
  onChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (value: string) => void
  focusOnMount?: boolean
}

const Input = (props: Props) => {
  const inputRef = useRef<any>(null)
  const [value, setValue] = useState<string>(props.defaultValue || '')

  useEffect(() => {
    if (props.focusOnMount) inputRef.current.focus()
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, { value }: any) => {
    setValue(value)

    if (props.onChange) props.onChange(value, e)
  }

  const handleBlur = () => {
    if (props.onBlur) props.onBlur(value || '')
  }

  // Allows us to set the value of the input to undefined to clear it out
  useEffect(() => setValue(props.defaultValue || props.value || ''), [props.value])

  const semanticProps = { ...props }

  // Remove custom props here to semantic doesn't apply them
  delete semanticProps.focusOnMount
  delete semanticProps.defaultValue

  return <SemanticInput ref={inputRef} {...semanticProps} onChange={handleChange} onBlur={handleBlur} value={value} />
}

export default Input
