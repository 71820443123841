import React, { useEffect } from 'react'

import { SET_ACTIVE_STATE } from 'redux/active/actions/types'
import { generateIsLarge, generateIsMidsized, generateIsMobile } from 'redux/active/initialState'
import { store } from 'redux/store'

import ToastContainer from 'components/toasts/ToastContainer'

import GlobalToolbar from 'components/misc/GlobalToolbar'
import Headers from 'components/misc/Headers'

import styles from './simplePageWrapper.module.scss'

type Props = {
  children: React.ReactNode
  title?: string
  robots?: string
  description?: string
  className?: string
  containerClassName?: string
  noLinks?: boolean
  noAccount?: boolean
}

const SimplePageWrapper = ({
  children,
  title,
  robots,
  description,
  className,
  containerClassName,
  noLinks,
  noAccount,
}: Props) => {
  const updateDimensions = () => {
    const isMobile = generateIsMobile()
    const isMidsize = generateIsMidsized()
    const isLarge = generateIsLarge()

    store.dispatch({ type: SET_ACTIVE_STATE, payload: { isMobile, isMidsize, isLarge } })
  }

  useEffect(() => {
    updateDimensions()

    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  return (
    <>
      <div className={`${styles.container} ${containerClassName || ''}`}>
        <GlobalToolbar noSearch noLinks={noLinks} noAccount={noAccount} />

        <img className={styles.sketch} alt="sketch1" src="/images/sketch1.svg" style={{ top: '-20%', left: '-5%' }} />
        <img className={styles.sketch} alt="sketch6" src="/images/sketch3.svg" style={{ top: '60%', left: '70%' }} />
        <img className={styles.sketch} alt="sketch7" src="/images/sketch4.svg" style={{ top: '3%', left: '65%' }} />
        <img className={styles.sketch} alt="sketch8" src="/images/sketch6.svg" style={{ top: '60%', left: '-10%' }} />

        <div className={styles.topGradientMask} />
        <div className={styles.bottomGradientMask} />

        <div className={`${styles.content} ${className || ''}`}>{children}</div>

        <Headers description={description} title={title} robots={robots} />
      </div>

      <ToastContainer />
    </>
  )
}

export default SimplePageWrapper
