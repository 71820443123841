import React from 'react'

import LineLoader from 'components/elements/LineLoader'
import SearchedCard from 'components/cardSearchPanel/cardLayouts/searchedCard'

import { CardType } from 'types/deck'

import styles from './cardResultsGrid.module.scss'

export const gridClass = styles.container

type Props = {
  cards: CardType[]
  className?: string
  onCardClick: (card: CardType, forceAllLayoutsView?: boolean) => void
  onCardUpdated?: (currentId: string, updatedCard: CardType) => void
  onDragStart?: () => void
  onDragStop?: () => void
  loading?: boolean
  allowDragging?: boolean
  editionControls?: boolean
}

const CardResultsGrid = ({
  cards,
  onCardClick,
  className = '',
  onCardUpdated,
  loading,
  allowDragging,
  onDragStart,
  onDragStop,
  editionControls = false,
}: Props) => (
  <div className={styles.queryableContainer}>
    {loading && <LineLoader />}

    <div className={`${styles.container} ${className}`}>
      {cards.map(card => (
        <SearchedCard
          key={card.id}
          card={card}
          onCardClick={onCardClick}
          onCardUpdated={onCardUpdated}
          allowDragging={allowDragging}
          onDragStart={onDragStart}
          onDragStop={onDragStop}
          editionControls={editionControls}
        />
      ))}
    </div>

    {/* 8 is arbitary, really just trying to keep from showing both the top and bottom bar at the same time */}
    {/* The bottom bar is nice for when you're clicking "load more", so you're at the bottom of the continer */}
    {loading && cards.length > 8 && <LineLoader className={styles.lowerLoader} />}
  </div>
)

export default CardResultsGrid
