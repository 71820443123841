import React from 'react'
import Icon from 'components/elements/Icon'

import ToastService from 'services/toast.service'

import CardBanner from 'components/misc/CardBanner'
import PageWrapper from 'components/misc/PageWrapper'
import Link from 'components/elements/Link'

import styles from './errorBoundary.module.scss'

type Props = {
  children: React.ReactNode
}

type State = {
  hasError: boolean

  message: string | null
  error: any
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, error: null, message: null }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error }
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ message: error.message, error: errorInfo })
  }

  getStackTrace = () => this.state.error?.componentStack?.trim() || ''

  handleCopyStackTrace = () => {
    const message = this.state.message || ''
    const stackTrace = this.getStackTrace()

    navigator.clipboard.writeText(`Error: ${message}\n ${stackTrace}`)

    ToastService.create('Stack trace copied', 'Error page', 'success')
  }

  render() {
    if (!this.state.hasError) return this.props.children

    const stackTrace = this.getStackTrace()

    return (
      <PageWrapper title="Unknown client error - Archidekt" robots="noindex">
        <CardBanner
          height="300px"
          artist
          src={'https://cards.scryfall.io/art_crop/front/4/a/4a4764da-c508-4a7e-aabc-771f22a32c23.jpg?1673914627'}
          title="Unknown browser error"
        />
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.blurb}>
              An unrecoverable error has occured. Feel free to report this via the links below if you feel like it,
              otherwise refresh and you should be able to continue as normal. If this problem keeps happening, feel free
              to reach out via the links below.
            </div>

            <div className={styles.codeBlock}>
              {this.state.message && <div className={styles.errorMessage}>Error: {this.state.message}</div>}

              {stackTrace}
              <button className={styles.copyButton} onClick={this.handleCopyStackTrace}>
                <Icon name="copy" />
              </button>
            </div>

            <div className={styles.helpLinks}>
              <a
                className={styles.discordLink}
                rel="noopener noreferrer"
                target="_blank"
                href="https://discord.gg/GyAAANU8uJ">
                <Icon name="discord" />
                Ask on discord
              </a>
              <Link className={styles.forumLink} href="/forum/thread/new?section=Development">
                <Icon name="chat" />
                Ask on forums
              </Link>
            </div>
          </div>
        </div>
      </PageWrapper>
    )
  }
}

export default ErrorBoundary
