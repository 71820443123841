export type StoredControlledStackOptionsType = Record<string, ControlledStackOptionsType>

export type ControlledStackOptionsType = {
  columnCount: number
  columns: string[][]
  controlled: boolean
  showEmptyColumns: boolean
}
export const DEFAULT_CONTROLLED_STACK_OPTIONS: ControlledStackOptionsType = {
  columnCount: 0,
  columns: [],
  controlled: false,
  showEmptyColumns: false,
}
