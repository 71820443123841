import { selectCachables } from '../reducers'
import { createSelector } from 'reselect'
import { COLUMN_WIDTHS } from 'types/textColumns'
import { TextColumns } from './initialState'

export const selectTextColumnWidth = createSelector(selectCachables, cacheableState => {
  let width = COLUMN_WIDTHS.name

  for (const key in cacheableState.textColumns) {
    if (cacheableState.textColumns[key as keyof TextColumns]) width += COLUMN_WIDTHS[key as keyof TextColumns]
  }

  return width
})

export const selectShowEmptyCardStacks = createSelector(
  selectCachables,
  cachableState => cachableState.showEmptyCardStacks,
)

export const selectHideDefaultTypeCategories = createSelector(
  selectCachables,
  cachableState => cachableState.hideDefaultTypeCategories,
)

export const selectHideOutOfDeckCategories = createSelector(
  selectCachables,
  cachableState => cachableState.hideOutOfDeckCategories,
)
