import React from 'react'

import NewDeckForm from 'components/misc/NewDeckModal/NewDeckForm'
import Modal from 'components/elements/Modal'

import { toggleModal as toggleModalAction } from 'redux/active/actions'
import { useActions, useAppSelector } from 'redux/hooks'

type Props = {}

/**
 * This component is rendered by the PageWrapper, so the NewDeckModal does not have to reside in any other component,
 * fixing the space bar issue when this is opened from the AccountDropdown. The modal is opened conditionally on the
 * redux state and the redux state is set to false when the modal is closed.
 */
const NewDeckModal: React.FC<Props> = () => {
  const newDeckModalActive = useAppSelector(state => state.active.modals.newDeck)
  const [toggleModal] = useActions(toggleModalAction)

  return (
    <Modal closeButton open={newDeckModalActive} onClose={() => toggleModal('newDeck', false)}>
      <h3>New Deck</h3>

      <NewDeckForm onCreate={() => toggleModal('newDeck', false)} />
    </Modal>
  )
}

export default NewDeckModal
