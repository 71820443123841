import { useAppSelector } from 'redux/hooks'
import { useWarnIfUnsavedChanges } from 'hooks/useWarnIfUnsavedChanges'

import Icon from 'components/elements/Icon'
import SimpleSpinner from 'components/elements/SimpleSpinner'

import styles from './saveWatcher.module.scss'

type Props = { className?: string; includeLabel?: boolean }

const SaveWatcher = ({ className, includeLabel }: Props) => {
  const saving = useAppSelector(state => state.deck.saving)
  const wsConnectionOpen = useAppSelector(state => state.deck.wsConnectionOpen)

  useWarnIfUnsavedChanges(saving === 'saving')

  let title = `Deck saved (Server connection ${wsConnectionOpen ? 'open' : 'closed'})`

  if (saving === 'saving') title = 'Saving deck...'
  if (saving === 'error') title = 'Error saving deck'

  return (
    <button
      className={`
        ${className}
        ${saving === 'error' ? styles.errorButton : ''}
    `}
      title={title}>
      {saving === 'saving' && <SimpleSpinner size="xSmall" />}
      {saving === 'saved' && <Icon className={styles.saved} name="check circle" />}
      {saving === 'error' && <Icon className={styles.error} name="exclamation triangle" />}

      {includeLabel && <label>{saving ? 'Saving' : 'Saved'}</label>}
    </button>
  )
}

export default SaveWatcher
