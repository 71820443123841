import { CategoryType } from 'types/deck'

import { RequestService } from 'services/request.service'

import { store } from 'redux/store'

export class CategoryService extends RequestService {
  update = async (patch: CategoryType): Promise<CategoryType> => {
    const deckId = store.getState().deck.id
    const response = deckId
      ? await super.patch(`/api/decks/category/${patch.id}/`, patch)
      : await Promise.resolve(patch)

    return response
  }

  create = async (category: CategoryType, overrideDeckId?: string | number | null): Promise<CategoryType> => {
    const deckId = overrideDeckId || store.getState().deck.id
    const response = deckId
      ? await super.post('/api/decks/createCategory/', { ...category, deck: deckId })
      : await Promise.resolve(category)

    return response
  }

  remove = async (id: number | null) => {
    const response = id ? await super.delete(`/api/decks/category/${id}/`, {}, true) : await Promise.resolve()

    return response
  }

  listTemplates = (userId: number | string | undefined): Promise<CategoryType[]> => {
    if (!userId) return Promise.resolve([])

    return super.get(`/api/users/category/`)
  }

  createTemplate = (category: CategoryType): Promise<CategoryType> => {
    const userId = this.cookies.get('tbId')

    if (!userId) return Promise.reject()
    return super.post(`/api/users/category/`, category)
  }

  patchTemplate = (category: CategoryType): Promise<CategoryType> => {
    const userId = this.cookies.get('tbId')

    if (!userId) return Promise.reject()
    return super.patch(`/api/users/category/${category.id}/`, category)
  }

  save = (category: CategoryType): Promise<CategoryType> => {
    if (category.id) return this.update(category)
    else return this.create(category)
  }

  saveTemplate = (category: CategoryType): Promise<CategoryType> => {
    if (category.id) return this.patchTemplate(category)
    else return this.createTemplate(category)
  }

  deleteTemplate = (id: string | number | undefined): Promise<void> => {
    if (!id) return Promise.reject()

    return super.delete(`/api/users/category/${id}/`, {}, true)
  }
}

const categoryService = new CategoryService()

export default categoryService
