import { CollectionColumnOptions, CollectionSpecificFilters, CollectionV2State } from 'redux/collectionV2/types'

import { PRICE_SOURCE } from 'types/active'
import { getCachedState } from './helpers/cachedValues'

export const generateInitialState = (serverCookies?: Record<string, any> | string): CollectionV2State => ({
  ownsCollection: false,
  hasFetchedCollectionTags: false,

  preferredPageSize: 100,

  recentlyAdded: [],
  serverCollectionData: [],

  collectionCards: {},

  tags: {},

  numberOfResults: 0,
  game: 1,
  isPublic: false,

  // TODO - These values need to be pulled from cache at some point
  view: 'text',
  viewingLabels: false,

  priceSource: PRICE_SOURCE.CK,
  priceSource2: PRICE_SOURCE.TCG,

  activeCardTab: 'details',
  activeCardId: null,
  openToAllEditionsMenu: false,

  selectCardExtraSearchOptions: {
    archidekt: [],
    scryfall: '',
  },

  multiSelectedIds: {},

  filteringModalOpen: false,

  autoColumns: true,
  columnData: defaultColumnData,

  imageSize: 'normal',

  filterType: 'scryfall',

  ...getCachedState(serverCookies),
})

export default generateInitialState

// This is the order the columns will appear in the table
export const defaultColumnData: CollectionColumnOptions[] = [
  { label: 'Quantity', key: 'quantity', enabled: true, width: 40 },
  { label: 'Name', key: 'name', enabled: true, width: 200 },
  { label: 'Finish', key: 'modifier', enabled: true, width: 80 },
  { label: 'Rarity', key: 'rarity', enabled: false, width: 65 },
  { label: 'Edition', key: 'set', enabled: true, width: 375 },
  { label: 'Edition code', key: 'setCode', enabled: false, width: 100 },
  { label: 'Edition date', key: 'editionDate', enabled: false, width: 150 },
  { label: 'Collector number', key: 'collectorNumber', enabled: false, width: 85 },
  { label: 'Language', key: 'language', enabled: true, width: 65 },
  { label: 'Condition', key: 'condition', enabled: true, width: 65 },
  { label: 'Collection labels', key: 'colorLabels', enabled: false, width: 350 },
  { label: 'Price 1', key: 'priceOne', enabled: true, width: 100 },
  { label: 'Price 2', key: 'priceTwo', enabled: false, width: 100 },
  { label: 'Purchase price', key: 'purchasePrice', enabled: false, width: 125 },
  { label: 'Artist', key: 'artist', enabled: false, width: 150 },
  { label: 'Date added', key: 'createdAt', enabled: false, width: 150 },
  { label: 'Date updated', key: 'updatedAt', enabled: false, width: 150 },
  { label: 'Casting cost', key: 'casingCost', enabled: false, width: 185 },
  { label: 'Mana value', key: 'manaValue', enabled: false, width: 85 },
  { label: 'Types', key: 'types', enabled: false, width: 200 },
  { label: 'Power', key: 'power', enabled: false, width: 65 },
  { label: 'Toughness', key: 'toughness', enabled: false, width: 65 },
  { label: 'Loyalty', key: 'loyalty', enabled: false, width: 65 },
  { label: 'Scryfall ID', key: 'uid', enabled: false, width: 350 },
  { label: 'Row options', key: 'actions', enabled: true, width: 25 },
]

export const collectionSpecificFiltersDefault: CollectionSpecificFilters = {
  andTags: [],
  orTags: [],
  notTags: [],
  collectionLanguage: [],
  condition: [],
  quantity: '',
  quantityLogic: undefined,
  treatment: undefined,
}
