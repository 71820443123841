import React from 'react'

import styles from './labelDisplay.module.scss'

type Props = {
  color: string
  name: string
  htmlFor?: string
  className?: string
  noName?: boolean
}

const LabelDisplay = ({ color, name, className = '', noName = false, htmlFor }: Props) => {
  if (!name || !color) return null

  return (
    <label className={`${styles.container} ${className}`} htmlFor={htmlFor}>
      <LabelColorSplotch color={color} />
      {!noName && name}
    </label>
  )
}

export default LabelDisplay

export const LabelColorSplotch = ({ color, className = '' }: { color: string; className?: string }) => (
  <span className={`${styles.colorSquare} ${className}`} style={{ backgroundColor: color }} />
)
