import { useEffect, useState } from 'react'
import { Checkbox } from 'semantic-ui-react'

import CookieService from 'services/cookie.service'
import LocalStorageService from 'services/localStorage.service'

import styles from './localSettingToggle.module.scss'

type Props = {
  header: React.ReactNode
  label: string
  cacheKey: string
  cacheStyle?: 'cookie' | 'localStorage'
  className?: string
  onToggle?: (enabled: boolean) => void
}

const LocalSettingToggle = ({ header, label, cacheKey, cacheStyle = 'cookie', className = '' }: Props) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const cachedValue = cacheStyle === 'cookie' ? CookieService.get(cacheKey) : LocalStorageService.get(cacheKey)
    if (cachedValue !== null) setEnabled(`${cachedValue}` === 'true')
  }, [])

  const handleChange = (enabled: boolean) => {
    if (cacheStyle === 'cookie') CookieService.set(cacheKey, enabled, 'year')
    if (cacheStyle === 'localStorage') LocalStorageService.set(cacheKey, `${enabled}`)

    setEnabled(enabled)
  }

  return (
    <span className={`${styles.container} ${className}`}>
      <label className={styles.header}>{header}</label>
      <Checkbox toggle onChange={(_, { checked }: any) => handleChange(checked)} label={label} checked={!!enabled} />
    </span>
  )
}

export default LocalSettingToggle
