import { RequestService } from 'services/request.service'

import { GlobalColorTag } from 'services/apiTypes/colorTag.types'

export class ColorTagService extends RequestService {
  public list = () => super.get<GlobalColorTag[]>(`/api/decks/colorTags/`)

  public deleteItem = (id: string | number) => super.delete(`/api/decks/colorTags/${id}/`, undefined, true)

  public updateItems = (colorTag: GlobalColorTag) =>
    super.patch<GlobalColorTag>(`/api/decks/colorTags/${colorTag.id}/`, colorTag)

  public create = (colorTag: GlobalColorTag) => super.post<GlobalColorTag>('/api/decks/colorTags/', colorTag)
}

const colorTagService = new ColorTagService()

export default colorTagService
