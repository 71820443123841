import { CachablesState } from './initialState'
import { CacheableActions } from './actions'

import * as Actions from './actions/types'
import generateCacheablesState from './initialState'
import { writeChangesToCookies } from './helpers/cacheStateChanges'

export default (state: CachablesState = generateCacheablesState(), action: CacheableActions): CachablesState => {
  switch (action.type) {
    case Actions.SET_CACHEABLES_STATE:
      writeChangesToCookies({ ...state, ...action.payload })

      return { ...state, ...action.payload }

    default:
      return state
  }
}
