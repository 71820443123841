import { useState } from 'react'

import { CardType } from 'types/deck'

import GlobalCategoryService from 'services/globalCategoryService.service'

import PhatButton from 'components/formElements/PhatButton'
import Icon from 'components/elements/Icon'
import SimpleSpinner from 'components/elements/SimpleSpinner'
import ToastService from 'services/toast.service'

type Props = {
  card: CardType
  onCardUpdated: (card: CardType) => void
  className?: string
}

const LockPersonalCategoriesButton = ({ card, onCardUpdated, className }: Props) => {
  const [saving, setSaving] = useState(false)

  const handleSetPersonalCategories = () => {
    setSaving(true)

    return GlobalCategoryService.updateByOracleCardId(card.oracleCardId, card.categories)
      .then(() => {
        onCardUpdated({ ...card, globalCategories: card.categories })

        ToastService.create('Categories saved', 'Personal Category Service', 'success')
      })
      .catch(err => ToastService.create(err, 'Personal Category Service', 'error'))
      .finally(() => setSaving(false))
  }

  const personalMatchesExistingCategories = JSON.stringify(card.globalCategories) === JSON.stringify(card.categories)
  const disabled = saving || personalMatchesExistingCategories

  return (
    <PhatButton className={className} onClick={handleSetPersonalCategories} disabled={disabled}>
      {personalMatchesExistingCategories && !saving && <Icon name="lock" />}
      {!personalMatchesExistingCategories && !saving && <Icon name="unlock" />}
      {saving && <SimpleSpinner size="xSmall" />}
      Lock Personal Categories
    </PhatButton>
  )
}

export default LockPersonalCategoriesButton
