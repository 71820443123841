import React from 'react'
import { invert } from 'lodash'

import { setCodeToElement } from 'components/misc/customIcons/SetSymbols'

type DropdownDataType = Array<{
  key: string | number
  text: React.ReactNode
  value: string | boolean
  label?: Record<string, any>
  content?: React.ReactNode | string
}>

export const stringListToDropdown = (list: Array<string>): DropdownDataType => {
  let output: DropdownDataType = []
  list.forEach(str => {
    output.push({ key: str, text: str, value: str })
  })
  return output
}

export const humanReadableObjectToDropdown = (obj: Record<string, any>): DropdownDataType => {
  let output = []
  for (let key in obj) {
    output.push({ key: obj[key], text: key, value: obj[key] })
  }
  return output
}

export const labelListToDropdown = (list: Array<Record<string, any>>): DropdownDataType => {
  return list.map((label, i) => ({
    text: label.name,
    value: !label.name ? '' : `${label.name},${label.color}`,
    label: {
      style: { backgroundColor: label.color },
    },
    key: i,
  }))
}

export const editionToDropdown = (editions: Array<Record<string, any>>): DropdownDataType => {
  let output: DropdownDataType = []
  editions.forEach(printing => {
    output.push({
      key: printing.id,
      text: setCodeToElement(printing.edition.editioncode, printing.rarity ? printing.rarity : 'common'),
      value: printing.id,
      content: (
        <span>
          {setCodeToElement(printing.edition.editioncode, printing.rarity ? printing.rarity : 'common')}{' '}
          {printing.edition.editionname}
        </span>
      ),
    })
  })
  return output
}

export const optionsToDropdown = (options: Array<string>): DropdownDataType => {
  let output = []
  if (options.includes('Normal')) {
    output.push({
      key: 'normal',
      text: '',
      value: 'Normal',
      content: 'Nonfoil',
    })
  }
  if (options.includes('Foil')) {
    output.push({
      key: 'foil',
      text: 'foil',
      value: 'Foil',
      content: 'Foil',
    })
  }

  if (options.includes('Etched')) {
    output.push({
      key: 'etched',
      text: 'etch',
      value: 'Etched',
      content: 'Etched',
    })
  }

  return output
}

export const codeAndHumanToDropdown = (code: Record<string, any>, human: Record<string, any>): DropdownDataType => {
  let output = []
  for (let key in code) {
    output.push({
      key: key,
      text: key,
      value: code[key],
      content: `${invert(human)[code[key]]} (${key})`,
    })
  }
  return output
}

/**
 * This function takes in a list of semantic options and a value within them and returns the associated text.
 */
export const getTextFromValue = (options: Array<Record<string, any>>, value: string): string => {
  for (let i = 0; i < options.length; i++) {
    if (options[i].value === value) {
      return options[i].text
    }
  }
  return ''
}
