import { DeckState } from 'redux/deck/initialState'
import { DeckResponse } from 'services/apiTypes/deck.types'

import { CardType, CategoryType, defaultCategories, EDH_FORMATS, generateDefaultCategory } from 'types/deck'
import { emptyTextArea } from 'types/editor'

import { responseToDeckCards } from './ResponseToResults'

export function deckResponseToDeckState(
  currentState: DeckState,
  response: DeckResponse,
  unlinkFromDeck = false,
): DeckState {
  const categories: Record<string, CategoryType> = { ...defaultCategories }

  if (EDH_FORMATS.includes(response.deckFormat)) categories['Commander'] = generateDefaultCategory('Commander')

  response.categories.forEach((category: CategoryType) => (categories[category.name] = category))

  const fetchedCards = responseToDeckCards(response.cards, unlinkFromDeck)
  const cardMap: Record<string, CardType> = {}
  const colorLabels = [...currentState.colorLabels]

  for (const card of fetchedCards) {
    const primaryCategory = card.categories[0]

    if (!categories[primaryCategory]) {
      // This card's default category isn't in the categories fetched from the server so we're adding it.
      categories[primaryCategory] = generateDefaultCategory(primaryCategory)
    }

    if (!colorLabels.filter(label => label.name === card.colorLabel.name).length) {
      colorLabels.push(card.colorLabel)
    }

    cardMap[card.id] = card
  }

  return {
    ...currentState,
    colorLabels,
    name: response.name,
    description: response.description || emptyTextArea,
    format: response.deckFormat,
    game: response.game || 0,
    id: response.id,
    private: response.private || false,
    unlisted: response.unlisted || false,
    theorycrafted: response.theorycrafted || false,
    points: response.points || 0,
    categories: categories,
    owner: response.owner.username,
    ownerid: response.owner.id,
    editors: response.editors || [],
    ownerAvatar: response.owner.avatar,
    ownerFrame: response.owner.frame || '',
    ownerReferrerEnum: response.owner.referrerEnum || '',
    ckAffiliate: response.owner.ckAffiliate || '',
    tcgAffiliate: response.owner.tcgAffiliate || '',
    updatedAt: response.updatedAt,
    createdAt: response.createdAt,
    commentid: response.commentRoot || 0,
    voteInput: response.userInput || 0,
    viewCount: response.viewCount || 0,
    parentFolder: response.parentFolder || 0,
    cardMap: cardMap,
    featured: response.featured || '',
    customFeatured: response.customFeatured,
    snapshotMeta: response.snapshotMeta || null,
    bookmarked: response.bookmarked || false,
    deckTags: response.deckTags || [],
    cardPackage: response.cardPackage || null,
    playgroupDeckUrl: response.playgroupDeckUrl || null,
  }
}
