import React from 'react'

import { CardType } from 'types/deck'

import { CardLoaderContainer, TextBox, TitleBox, PTBox, Logo } from './style'

type Props = {
  loading?: boolean
  card?: CardType
  className?: string
  style?: React.CSSProperties
  includeName?: boolean
  includeText?: boolean
}

const CardLoader = React.forwardRef((props: Props, ref: any) => {
  const { loading = false, card, className, style, includeName = true, includeText } = props

  if (!loading) return null

  const types = !card ? [] : card.front ? [...card.front.types] : [...card.types]
  const name = !card ? '' : card.front ? card.front.name : card.name

  return (
    <CardLoaderContainer ref={ref} className={className} style={style}>
      <TitleBox style={{ opacity: includeName ? 1 : 0 }}>{name}</TitleBox>
      <TextBox>
        <p style={{ opacity: includeText ? 1 : 0 }}>{card?.text}</p>
      </TextBox>
      <TitleBox $typebox style={{ opacity: includeText ? 1 : 0 }}>
        {types.join(' ')}
      </TitleBox>
      {includeText && card?.types?.includes('Creature') && (
        <PTBox>
          {card?.power || 0} / {card?.toughness || 0}
        </PTBox>
      )}
      <Logo />
    </CardLoaderContainer>
  )
})

export default CardLoader
