import { useEffect, useState } from 'react'

// Taken from stack overflow - https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
// @ts-ignore - globalThis acts as window on client side, and does nothing on server side - https://stackoverflow.com/questions/66412291/nextjs-how-to-use-window-in-custom-hook
export function useWindowSize(customWindow: Window = globalThis) {
  const [size, setSize] = useState([0, 0])

  useEffect(() => {
    function updateSize() {
      setSize([customWindow.innerWidth, customWindow.innerHeight])
    }

    customWindow.addEventListener('resize', updateSize)

    updateSize()

    return () => customWindow.removeEventListener('resize', updateSize)
  }, [])

  return size
}
