import React from 'react'

import { CardType } from 'types/deck'
import { GlobalColorTag } from 'services/apiTypes/colorTag.types'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'

import useDeckColorTagOptions from 'hooks/useDeckColorTagOptions'

import styles from './colorTagCssDropdown.module.scss'

type Props = {
  card: CardType
  onCardChange: (updatedCard: CardType) => void
  includeLabel?: boolean
  disabled?: boolean
}

const ColorTagCssDropdown = ({ card, onCardChange, includeLabel, disabled }: Props) => {
  const handleChange = (selectedTag: GlobalColorTag) => onCardChange({ ...card, colorLabel: selectedTag })

  const [options, onFocus] = useDeckColorTagOptions(handleChange)

  return (
    <ArchidektDropdown onFocus={onFocus} options={options} menuClassName={styles.menu}>
      <button disabled={disabled} className={styles.trigger}>
        <span className={styles.splotch} style={{ backgroundColor: card.colorLabel.color }} />
        {includeLabel ? card.colorLabel.name || 'Default Tag' : ''}
      </button>
    </ArchidektDropdown>
  )
}

export default ColorTagCssDropdown
