import { useState } from 'react'

import Icon from 'components/elements/Icon'
import GlobalOverlayStack from 'components/elements/Overlay/GlobalOverlayStack'
import Link from 'components/elements/Link'

import styles from './cardModalCategoiesInfo.module.scss'

const CardModalCategoriesInfo = () => {
  const [openInformation, setOpenInformation] = useState(false)

  return (
    <>
      <button onClick={() => setOpenInformation(true)} className={styles.button}>
        Categories: <Icon name="question circle" />
      </button>

      <GlobalOverlayStack open={openInformation} onClose={() => setOpenInformation(false)} className={styles.container}>
        <h2>Category Information</h2>

        <div className={styles.explanationBlock}>
          <h3>How categories work</h3>
          <ul>
            <li>You can add multiple categories for a card</li>
            <li>Only the first category for a card will be used for legality checking</li>
            <li>
              You can view all categories visually by going under views and changing the stack sorting to Categories
              (Multiple)
            </li>
          </ul>

          <p>You can edit category information for a deck via the deck settings.</p>
        </div>

        <div className={styles.explanationBlock}>
          <h3>Default category names</h3>

          <p>
            By default cards are provided a category name. This default is either going to be a Community Category (eg:
            Ramp, Draw, Removal) or a card type (eg: Creature, Artifact, Enchantment). The default behavior is dependent
            on whether there's enough community data provided to put a card into a Community Category, or whether or not
            you have the Community Categories setting disabled.
          </p>

          <p>
            Users can disabled/ enable Community Categories via their{' '}
            <Link href="/accountSettings/site-settings">account settings</Link>. If you disable Community Categories
            they will not be used at all, cards will then always default to the card type as the card's category name.
          </p>
        </div>

        <div className={styles.explanationBlock}>
          <h3>Personal Categories</h3>
          <p>
            Personal Categoires are categories that can be applied to cards irrespective of whether they're in a deck,
            or the specifc printing of a card. The Personal Categories override any defaults of a card when they're
            added to a deck regardless of whether or not you have Community Categories disabled.
          </p>

          <p>
            You can configure Personal Categories using the{' '}
            <Link href="/personal-categories">Personal Categories page</Link>, or by Locking the categories of a card to
            your Personal Categories. Locking a card to Personal Categories will set the existing card categories as
            your new Personal Categories. This way, the next time you add a card to your deck, the Personal Categories
            will be used as the default .
          </p>
        </div>
      </GlobalOverlayStack>
    </>
  )
}

export default CardModalCategoriesInfo
