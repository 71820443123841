import React from 'react'

import styles from './simpleSpinner.module.scss'

const Sizes: Record<string, { height: string; width: string; borderWidth: string }> = {
  xSmall: { height: '15px', width: '15px', borderWidth: '2px' },
  medXSmall: { height: '20px', width: '20px', borderWidth: '2px' },
  tiny: { height: '30px', width: '30px', borderWidth: '3px' },
  small: { height: '50px', width: '50px', borderWidth: '4px' },
  large: { height: '100px', width: '100px', borderWidth: '12px' },
}

const Colors: Record<string, { borderColor: string; borderTopColor: string }> = {
  default: { borderColor: '#cecece', borderTopColor: '#4e4e4e' },
  dark: { borderColor: '#b6b6b6', borderTopColor: '#313131' },
}

const Speed: Record<string, string> = {
  slow: '2s',
  default: '1s',
  fast: '.5s',
  zippy: '.25s',
}

type Props = {
  className?: string
  size?: keyof typeof Sizes
  color?: keyof typeof Colors
  speed?: keyof typeof Speed
}

const SimpleSpinner = ({ className = '', size = 'small', color = 'default', speed = 'default' }: Props) => {
  const { width, height, borderWidth } = Sizes[size]
  const { borderColor, borderTopColor } = Colors[color]
  const animationDuration = Speed[speed]

  return (
    <div
      className={`${styles.loader} ${className}`}
      style={{ width, height, borderWidth, borderColor, borderTopColor, animationDuration }}
    />
  )
}

export default SimpleSpinner

export const SimpleCenterSpinner = (
  props: Props & {
    style?: React.CSSProperties
    label?: string
    containerClassName?: string
  },
) => (
  <div className={`${styles.center} ${props.containerClassName || ''}`} style={props.style}>
    <SimpleSpinner {...props} />
    {props.label && <label>{props.label}</label>}
  </div>
)
