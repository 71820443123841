import React, { useState } from 'react'

import styles from './universeBeyondCheckbox.module.scss'

type Props = {
  value?: boolean
  onChange: (flag: boolean) => void
}

const UniverseBeyondCheckbox = ({ value = false, onChange }: Props) => {
  const [hideUniverseBeyond, setHideUniverseBeyond] = useState<boolean>(value)

  const handleChange = (_updates: React.ChangeEvent) => {
    const updatedValue = !hideUniverseBeyond

    setHideUniverseBeyond(updatedValue)
    onChange(updatedValue)
  }

  return (
    <div className={styles.container}>
      <label htmlFor="universe-beyond-checkbox">Exclude Universes Beyond cards:</label>
      <input id="universe-beyond-checkbox" type="checkbox" checked={hideUniverseBeyond} onChange={handleChange} />
    </div>
  )
}

export default UniverseBeyondCheckbox
