import React from 'react'

// Style
import { SaltMeterContainer, GaugeContainer, MaskDiv, Hash, ScoreContainer } from './style'
import Icon from 'components/elements/Icon'

type Props = {
  salt: number
}

const SaltMeter: React.FC<Props> = ({ salt }: Props) => {
  const percent = (salt / 4) * 100
  return (
    <SaltMeterContainer>
      <GaugeContainer leftPercent={percent}>
        <Icon name="angle double up" />
        <ScoreContainer>{salt}</ScoreContainer>
      </GaugeContainer>
      <MaskDiv widthPercent={100 - percent} />
      {Array.from(Array(15).keys()).map(i => (
        <Hash key={i} leftPercent={((i + 1) / 16) * 100} height={(i + 1) % 4 === 0 ? 9 : 4} />
      ))}
    </SaltMeterContainer>
  )
}

export default SaltMeter
