import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import ToastService from 'services/toast.service'

import { useAppSelector } from 'redux/hooks'
import { useCanEditDeck } from 'redux/deck/selectors'

import { STACK } from 'types/deck'
import { DeckCardDragType, DropItemTypes } from 'types/draggables'

import BasicCard, { Props as BasicCardProps } from 'components/card/BasicCard'

import { getBooleanFlagFromLocalStorage } from 'services/accountSettings.service'
import { enableTouchDragDeckPageKey } from 'components/accountSettingsPage/BrowserSpecificSiteSettings'

export type DragProps = {
  checkCanDrag?: () => boolean
  onDragStart?: () => void
  onDragEnd?: () => void
  onClick?: (e: React.MouseEvent) => void
  onContextMenu?: (e: React.MouseEvent) => void
  onTouchStart?: (e: React.TouchEvent) => void
  onTouchEnd?: (e: React.TouchEvent) => void
  dragItem?: DeckCardDragType
  children?: React.ReactNode
}

export const DraggableBasicCard = (props: BasicCardProps & DragProps) => {
  const canEditDeck = useCanEditDeck()
  const selectedStackSortingOption = useAppSelector(state => state.deck.stack)

  // isCtrlDown is used as a state variable for a dependency for changing the cursor, while the ref is used to pass
  // to the useDrag hook so the boolean value doesn't get stale.
  const [isCtrlDown, setIsControlDown] = useState(false)

  const ctrlRef = useRef<boolean>()
  const touchRef = useRef<boolean>(false)

  const handleKey = useCallback((e: KeyboardEvent) => setIsControlDown(e.ctrlKey), [setIsControlDown])

  const handleCheckCanDrag = (isMonitor = false) => {
    // Allows the parent to overwrite if they wish
    if (props.checkCanDrag) return props.checkCanDrag()

    if (touchRef.current && !getBooleanFlagFromLocalStorage(enableTouchDragDeckPageKey)) return false
    if (!canEditDeck) return false
    if (selectedStackSortingOption !== STACK.CUSTOM && selectedStackSortingOption !== STACK.MULTIPLE) {
      if (isMonitor)
        ToastService.create(
          'Drag not supported in this Group By. You may only drag while using Categories or Categories (multiple) group by viewing modes. Change your group by settings at the top of the deck to support drag and drop.',
          'Cannot Drag',
          'warning',
        )
      return false
    }

    return true
  }

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DropItemTypes.CARD,
      item: props.dragItem
        ? { isCtrlPressedRef: ctrlRef, ...props.dragItem }
        : { card: props.card, isCtrlPressedRef: ctrlRef },
      end: props.onDragEnd,
      canDrag: () => handleCheckCanDrag(true),
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [props.card, selectedStackSortingOption, canEditDeck, props.dragItem],
  )

  useEffect(() => {
    if (isDragging && props.onDragStart) props.onDragStart()

    if (isDragging) {
      document.addEventListener('keydown', handleKey)
      document.addEventListener('keyup', handleKey)
    } else {
      document.removeEventListener('keydown', handleKey)
      document.removeEventListener('keydown', handleKey)
    }
  }, [isDragging])

  useEffect(() => {
    ctrlRef.current = isCtrlDown

    if (isDragging) document.body.style.cursor = isCtrlDown ? 'copy' : 'grabbing'
    else document.body.style.cursor = 'unset'
  }, [isDragging, isCtrlDown])

  if (typeof window !== 'undefined') preview(getEmptyImage(), { captureDraggingState: true })

  const style: React.CSSProperties = {}

  if (handleCheckCanDrag()) style.cursor = 'grab'

  return drag(
    <div
      onMouseDown={e => setIsControlDown(e.ctrlKey)}
      onTouchStart={() => (touchRef.current = true)}
      onTouchEnd={() => (touchRef.current = false)}
      style={style}>
      {props.children ? props.children : <BasicCard {...props} />}
    </div>,
  )
}

export default DraggableBasicCard
