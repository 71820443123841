import React, { useState } from 'react'
import Icon from 'components/elements/Icon'
import { useCookies } from 'react-cookie'

import { Option } from 'components/elements/ArchidektDropdown/types'

import { store } from 'redux/store'
import { useActionless, useAppSelector } from 'redux/hooks'
import { SET_CACHEABLES_STATE } from 'redux/cacheables/actions/types'

import { SET_ACTIVE_STATE, TOGGLE_MODAL } from 'redux/active/actions/types'
import { StickiableControls } from 'redux/cacheables/initialState'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import SaveWatcher from 'components/deckPage/DeckContainerV2/SaveWatcher'
import saveService from 'services/save.service'

import { useRightPanel } from 'hooks/useRightPanel'
import { useCanEditDeck } from 'redux/deck/selectors'

import styles from './stickiedDeckControls.module.scss'

type Props = {
  className?: string
  liteControls?: boolean
}

const StickiedDeckControls = ({ className = '', liteControls }: Props) => {
  const [{ tbId: userId }] = useCookies(['tbId'])

  const canEditDeck = useCanEditDeck()

  const undoStack = useAppSelector(state => state.deck.actionStack)
  const redoStack = useAppSelector(state => state.deck.undoneActionStack)
  const staticCategoy = useAppSelector(state => state.cacheables.pinnedCategory)

  const viewableDeckControls = useAppSelector(state => state.cacheables.viewableDeckControls)

  const [toggleModal, setCacheableState, setActiveState] = useActionless(
    TOGGLE_MODAL,
    SET_CACHEABLES_STATE,
    SET_ACTIVE_STATE,
  )

  const [rightPanelStatus, setRightPanelStatus] = useRightPanel()

  const [localViewableContorls, setLocalViewableContorls] = useState(viewableDeckControls)

  const handleToggleViewableDeckControls = (control: StickiableControls) =>
    setLocalViewableContorls({ ...localViewableContorls, [control]: !localViewableContorls[control] })

  const handleApplyControlChanges = () => {
    setCacheableState({ viewableDeckControls: localViewableContorls })
  }

  const handleSetAllLocalViewableToggles = (flag: boolean) => {
    const newToggles = Object.keys(localViewableContorls).reduce(
      (acc, key) => ({ ...acc, [key]: flag }),
      {} as typeof localViewableContorls,
    )

    setLocalViewableContorls(newToggles)
  }

  const options: (Option & { id?: StickiableControls })[] = [
    {
      label: 'Card search',
      id: 'cardSearch',
      icon: 'search',
      onClick: () => setActiveState({ searchOpen: 'search' }),
      keybind: 'Ctrl + Enter',
    },
    { type: 'spacer' },
    {
      label: 'Import cards',
      id: 'import',
      icon: 'edit outline',
      onClick: () => toggleModal({ modalName: 'massEditModal', flag: true }),
      hidden: !canEditDeck,
    },
    {
      label: 'Export deck',
      id: 'export',
      onClick: () => toggleModal({ modalName: 'export', flag: true }),
      icon: 'download',
    },
    { type: 'spacer' },
    {
      label: 'Clone deck',
      id: 'clone',
      onClick: () => toggleModal({ modalName: 'cloneModal', flag: true }),
      icon: 'clone',
      hidden: !userId,
    },
    {
      label: 'Card packages',
      id: 'packages',
      icon: 'cube',
      onClick: () => toggleModal({ modalName: 'cardPackageModal', flag: true }),
    },
    {
      label: 'Add deck to collection',
      id: 'addToCollection',
      onClick: () => toggleModal({ modalName: 'addToCollection', flag: true }),
      icon: 'plus circle',
      hidden: !userId,
    },
    { type: 'spacer' },
    {
      label: 'Select all',
      id: 'selectAll',
      keybind: 'Ctrl + A',
      onClick: () => {
        const { cardMap } = store.getState().deck
        const multiSelectedIds = Object.keys(cardMap).reduce((a, id) => ({ ...a, [id]: true }), {})

        setActiveState({ multiSelectedIds })
      },
      icon: 'hand pointer outline',
    },
    {
      label: 'Optimize printings',
      id: 'optimize',
      onClick: () => {
        const { cardMap } = store.getState().deck
        const multiSelectedIds = Object.keys(cardMap).reduce((a, id) => ({ ...a, [id]: true }), {})

        setActiveState({ multiSelectedIds, optimizeCardOverlay: true })
      },
      icon: 'money bill alternate outline',
    },
    { type: 'spacer' },
    {
      label: 'Deck stats',
      id: 'stats',
      icon: 'pie graph',
      disabled: rightPanelStatus.locked,
      keybind: 'Ctrl + M',
      onClick: () => setRightPanelStatus({ ...rightPanelStatus, open: true }),
    },
    {
      label: 'Deck history',
      id: 'history',
      onClick: () => toggleModal({ modalName: 'addRemovedCards', flag: true }),
      icon: 'history',
    },
    {
      label: 'Deck settings',
      id: 'settings',
      onClick: () => toggleModal({ modalName: 'deckSettings', flag: true }),
      icon: 'cog',
      hidden: !canEditDeck,
    },
  ]

  return (
    <div className={`${styles.controls} ${staticCategoy ? styles.pinnedCategoryEnabled : ''} ${className}`}>
      <SaveWatcher className={styles.save} />
      <button className={styles.button} title="Undo" onClick={() => saveService.undo()} disabled={!undoStack.length}>
        <Icon name="undo" />
      </button>
      <button className={styles.button} title="Redo" onClick={() => saveService.redo()} disabled={!redoStack.length}>
        <Icon name="redo" />
      </button>

      {!liteControls && (
        <>
          {options.map(option => {
            if (option.type === 'spacer') return null
            if (!option.id || option.hidden) return null
            if (!viewableDeckControls[option.id]) return null

            return (
              // @ts-ignore
              <button className={styles.button} title={option.label || ''} onClick={option.onClick} key={option.id}>
                <Icon name={option.icon} />
              </button>
            )
          })}

          <ArchidektDropdown
            menuClassName={styles.extrasMenu}
            options={[
              ...options,
              { type: 'spacer' },
              {
                label: 'Deck controls',
                type: 'extras',
                id: 'stickied-buttons',
                icon: 'eye slash outline',
                options: [
                  {
                    label: 'Select all',
                    onClick: () => handleSetAllLocalViewableToggles(true),
                    noCloseOnClick: true,
                    icon: 'eye',
                  },
                  {
                    label: 'Select none',
                    onClick: () => handleSetAllLocalViewableToggles(false),
                    noCloseOnClick: true,
                    icon: 'eye slash outline',
                  },
                  { type: 'spacer' },
                  ...options.reduce((acc, option) => {
                    if (option.type === 'spacer') return acc
                    if (!option.id || option.hidden) return acc

                    acc.push({
                      type: 'checkbox',
                      // @ts-ignore
                      label: option.label || '',
                      // @ts-ignore
                      onChange: () => handleToggleViewableDeckControls(option.id),
                      checked: localViewableContorls[option.id],
                    })

                    return acc
                  }, [] as Option[]),

                  { type: 'spacer' },
                  { label: 'Apply view settings', onClick: handleApplyControlChanges, icon: 'save outline' },
                ],
              },
            ]}>
            <button className={styles.button} title="Extras">
              <Icon name="ellipsis horizontal" />
            </button>
          </ArchidektDropdown>
        </>
      )}
    </div>
  )
}

export default StickiedDeckControls
