import React, { useState } from 'react'
import Icon from 'components/elements/Icon'

import { CREATE_CATEGORY_TEXT, CardType, generateDefaultCategory } from 'types/deck'

import { useAppSelector } from 'redux/hooks'

import CreatingView from 'components/card/cardForm/categories/creatingView'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import PhatInput from 'components/formElements/PhatInput'
import PhatButton from 'components/formElements/PhatButton'

import styles from './categoryDropdown.module.scss'

type Props = {
  card: CardType
  category: string
  onCardChange: (updatedCard: CardType) => void
  onClose?: () => void
}

// In category row. Allows the user to change the category from one to another via a dropdown
const CategoryDropdown = ({ card, category, onCardChange, onClose }: Props) => {
  const [isCreating, setIsCreating] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const categories = useAppSelector(state => state.deck.categories)
  const isMobile = useAppSelector(state => state.active.isMobile)

  const handleCategoryChange = (categoryName: string) => {
    setIsCreating(false)

    if (!categoryName) return onClose && onClose()
    if (categoryName === CREATE_CATEGORY_TEXT) return setIsCreating(true)

    const categories = card.categories.slice()

    if (category) categories[categories.indexOf(category)] = categoryName
    else categories.push(categoryName)

    onCardChange({ ...card, categories })
  }

  const filteredCategories = Object.values(categories)
    .sort((a, b) => (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1))
    .filter(category => category.name.toLowerCase().includes(searchValue.toLowerCase()))
    .map(category => ({
      label: category.name,
      onClick: () => handleCategoryChange(category.name),
    }))

  return (
    <>
      <ArchidektDropdown
        triggerClassName={styles.container}
        menuClassName={styles.menu}
        options={[
          {
            icon: 'plus',
            label: CREATE_CATEGORY_TEXT,
            onClick: () => setIsCreating(true),
          },
          { type: 'spacer', hidden: !filteredCategories.length },
          ...filteredCategories,
        ]}>
        {!isMobile && (
          <PhatInput
            focusOnMount={!category}
            className={styles.input}
            value={category}
            onChange={setSearchValue}
            selectOnFocus
          />
        )}
        {isMobile && (
          <PhatButton className={styles.input}>
            {category || 'Choose a category'} <Icon name="chevron down" />{' '}
          </PhatButton>
        )}
      </ArchidektDropdown>

      <CreatingView
        open={isCreating}
        card={card}
        defaultValue={generateDefaultCategory(searchValue)}
        onCanceled={() => handleCategoryChange('')}
        onCreated={category => handleCategoryChange(category.name)}
      />
    </>
  )
}

export default CategoryDropdown
