import React from 'react'
import Icon from 'components/elements/Icon'

import PhatButton from 'components/formElements/PhatButton'

import { CardType } from 'types/deck'

type Props = {
  card: CardType
  onCardUpdated: (card: CardType) => void
}

const MakeCompanionButton = ({ card, onCardUpdated }: Props) => {
  if (!card.text.includes('Companion —')) return null

  const handleToggleCompanion = () => {
    const categories = card.categories.slice()

    if (!card.companion && !categories.includes('Sideboard')) categories.unshift('Sideboard')

    onCardUpdated({
      ...card,
      qty: card.qty || 1,
      companion: !card.companion,
      categories: categories,
    })
  }

  return (
    <PhatButton onClick={handleToggleCompanion}>
      <Icon name="paw" color={card.companion ? 'purple' : undefined} />{' '}
      {card.companion ? 'Remove Companion' : 'Set Companion'}
    </PhatButton>
  )
}

export default MakeCompanionButton
