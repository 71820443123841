import shortid from 'shortid'

import LocalStorageService from 'services/localStorage.service'

import { SearchOptions } from 'services/apiTypes/card.types'
import { Tab } from 'types/searchV2'
import { isEqual } from 'lodash'

const HISTORY_KEY = 'archidekt_card_search_history'
const MAX_RECORDS = 20

type HistoryRecord = Omit<Tab, 'scrollTop'> & {
  id: string
  time: string
}

function writeScryfallSearch(query: string) {
  const currentHistory = LocalStorageService.getObject<HistoryRecord[]>(HISTORY_KEY) || []

  const newSearch: HistoryRecord = {
    id: shortid.generate(),
    type: 'scrySearch',
    meta: { query },
    noAutoSearch: false,
    time: new Date().toString(),
    scrollHeight: 0,
  }

  // @ts-expect-error
  const modifedHistory = currentHistory.filter(oldHistory => oldHistory.meta.query !== query)
  const newHistory = [newSearch, ...modifedHistory].slice(0, MAX_RECORDS)

  LocalStorageService.setObject(HISTORY_KEY, newHistory)
}

function writeArchidektSearch(options: SearchOptions) {
  const currentHistory = LocalStorageService.getObject<HistoryRecord[]>(HISTORY_KEY) || []

  const newSearch: HistoryRecord = {
    id: shortid.generate(),
    type: 'archidektSearch',
    meta: options,
    noAutoSearch: false,
    time: new Date().toString(),
    scrollHeight: 0,
  }

  const modifedHistory = currentHistory.filter(oldHistory => !isEqual(oldHistory.meta, options))
  const newHistory = [newSearch, ...modifedHistory].slice(0, MAX_RECORDS)

  LocalStorageService.setObject(HISTORY_KEY, newHistory)
}

function getHistory() {
  return LocalStorageService.getObject<HistoryRecord[]>(HISTORY_KEY) || []
}

function removeFromHistory(id: string) {
  const currentHistory = LocalStorageService.getObject<HistoryRecord[]>(HISTORY_KEY) || []
  const newHistory = currentHistory.filter(record => record.id !== id)

  LocalStorageService.setObject(HISTORY_KEY, newHistory)

  return newHistory
}

function clearHistory(): HistoryRecord[] {
  LocalStorageService.remove(HISTORY_KEY)

  return []
}

export default {
  writeScryfallSearch,
  writeArchidektSearch,
  getHistory,
  removeFromHistory,
  clearHistory,
}
