import React from 'react'
import { useAppSelector } from 'redux/hooks'

import { ToastType } from 'types/toasts'

import Toast from 'components/toasts/Toast'

import styles from './toastContainer.module.scss'

type Props = {}

const ToastContainer: React.FC<Props> = () => {
  const toasts = useAppSelector(state => state.active.toasts)

  const checkIfMatchingToastExists = (
    currentToast: ToastType,
    currentIndex: number,
    otherToast: ToastType,
    otherIndex: number,
  ) => {
    if (currentIndex === otherIndex) return false
    if (currentIndex > otherIndex) return false // Only mark older toasts as repeates

    if (
      currentToast.title === otherToast.title &&
      currentToast.text === otherToast.text &&
      currentToast.type === otherToast.type
    )
      return true

    return false
  }

  return (
    <div className={styles.toastListContainer}>
      {toasts.map((toast, currentIndex) => (
        <Toast
          toast={toast}
          key={toast.id}
          matchesExistingToast={toasts.some((otherToast, otherIndex) =>
            checkIfMatchingToastExists(toast, currentIndex, otherToast, otherIndex),
          )}
        />
      ))}
    </div>
  )
}

export default ToastContainer
