import { nodeEnv } from 'environment'

export function timeInSecondsToEpoch(): number {
  return Math.round(Date.now() / 1000)
}

export function timeSince(date: string) {
  const suppliedDatetime = new Date(date).getTime()
  const seconds = Math.floor((Date.now() - suppliedDatetime) / 1000)

  let interval = seconds / 31536000

  if (interval > 2) return Math.floor(interval) + ' years'

  interval = seconds / 2592000

  if (interval > 2) return Math.floor(interval) + ' months'

  interval = seconds / 86400

  if (interval > 2) return Math.floor(interval) + ' days'

  interval = seconds / 3600

  if (interval > 2) return Math.floor(interval) + ' hrs'

  interval = seconds / 60

  if (interval > 2) return Math.floor(interval) + ' mins'

  if (nodeEnv !== 'production') return 'moments ago'
  if (seconds < 0) return 'today'

  return Math.floor(seconds) + ' secs'
}
