import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useActionless, useActions, useAppSelector } from 'redux/hooks'
import { SET_COLLECTION_V2_STATE } from 'redux/collectionV2/actions/types'
import { modifyCollectionCardMapAction } from 'redux/collectionV2/actions'

import { CollectionV2Card, defaultCollectionV2Card } from 'redux/collectionV2/types'
import { CardType } from 'types/deck'
import {
  AddCardFormOptions,
  getDefaultAddCardFormOptions,
  setCachedAddCardFormOptions,
  AddCardView,
} from 'components/collectionV2/addCards/formParts/types'

import CardDetailsOverlay from 'components/elements/Overlay/CardDetailsOverlay'
import SelectCard from 'components/collectionV2/addCards/formParts/SelectCard'
import ReconcileExistingData from 'components/collectionV2/addCards/formParts/ReconcileExistingData'
import FinalizeData from 'components/collectionV2/addCards/formParts/FinalizeData'
import FormOptions from 'components/collectionV2/addCards/formParts/FormOptions'
import BasicCard from 'components/card/BasicCard'

import CollectionService from 'services/collection.service'
import ToastService from 'services/toast.service'

import styles from './modal.module.scss'

type Props = {
  open: boolean
  onClose: () => void
  cardToAdd?: CardType
  onCardCreatedOrUpdated?: (updatedCard: CollectionV2Card) => void
  closeOnCreateOrUpdate?: boolean
  resetOnClose?: boolean
  includeGameTypeControls?: boolean
}

const AddCardsModal = ({
  open,
  onClose,
  cardToAdd,
  onCardCreatedOrUpdated,
  resetOnClose,
  closeOnCreateOrUpdate,
  includeGameTypeControls = false,
}: Props) => {
  const router = useRouter()
  const gameId = `${router.query.game || 1}` // TODO - need a form element to choose this not on the collection page

  const ownsCollection = useAppSelector(state => state.collectionV2.ownsCollection)

  const recentlyAdded = useAppSelector(state => state.collectionV2.recentlyAdded)
  const serverCollectionData = useAppSelector(state => state.collectionV2.serverCollectionData)
  const collectionCards = useAppSelector(state => state.collectionV2.collectionCards)

  const [setCollectionV2State] = useActionless(SET_COLLECTION_V2_STATE)
  const [modifyCollectionCardMap] = useActions(modifyCollectionCardMapAction)

  const [collectionCard, setCollectionCard] = useState({
    ...defaultCollectionV2Card,
    card: cardToAdd || defaultCollectionV2Card.card,
  })

  const [view, setView] = useState<AddCardView>('selecting')
  const [addCardOptions, setAddCardOptions] = useState(getDefaultAddCardFormOptions)

  useEffect(() => {
    if (view === 'selecting') resetCard()
  }, [open, view])

  useEffect(() => {
    if (!cardToAdd) return

    setCollectionCard({ ...collectionCard, modifier: cardToAdd.modifier, card: cardToAdd })

    if (!cardToAdd.owned) setView('finalizing')
    else setView('reconciling')
  }, [cardToAdd])

  const handleSelectCard = (card: CardType) => {
    const updatedCard = { ...collectionCard, modifier: card.modifier, card }

    setCollectionCard(updatedCard)

    if (addCardOptions.addCardOnSelect) return handleAddCollectionCard(updatedCard)
  }

  const handleChangeOptions = (newOptions: AddCardFormOptions) => {
    setAddCardOptions(newOptions)
    setCachedAddCardFormOptions(newOptions)

    if (!newOptions.showAdvancedOptions)
      setCollectionV2State({ selectCardExtraSearchOptions: { archidekt: [], scryfall: '' } })
  }

  const handleAddCollectionCard = (newOrUpdatedCard = collectionCard, overrideGameType?: number): Promise<any> => {
    const isNewCard = !newOrUpdatedCard.id

    return CollectionService.upsertV2(newOrUpdatedCard, overrideGameType || gameId)
      .then(responseCard => {
        const updatedCard = {
          ...newOrUpdatedCard,
          modifiedAt: responseCard.modifiedAt,
          createdAt: responseCard.createdAt,
          id: isNewCard ? responseCard.id : newOrUpdatedCard.id,
        }

        modifyCollectionCardMap(updatedCard)

        if (onCardCreatedOrUpdated) onCardCreatedOrUpdated(updatedCard) // Optional callback to parent component
        if (closeOnCreateOrUpdate) handleClose() // Close early and let the rest of this function run in the background (not visable)

        // Why the timeout? When we're closing the overlay using closeOnCreateOrUpdate, adding the card to recently added causes a bit of a flash/flicker
        // Timeout to allow the close animation to finish before updating the recently added list
        setTimeout(
          () => {
            // Any modified cards get pulled to the front of the list
            setCollectionV2State({
              recentlyAdded: [responseCard.id, ...recentlyAdded.filter(id => id !== responseCard.id)],
              serverCollectionData: serverCollectionData.filter(id => id !== responseCard.id),
            })

            if (!addCardOptions.stayOnCardDetails) setView('selecting')

            resetCard(newOrUpdatedCard, addCardOptions.stayOnCardDetails)

            ToastService.create(`Successfully ${isNewCard ? 'added' : 'updated'} card`, 'Collection Service', 'success')
          },
          closeOnCreateOrUpdate ? 500 : 0,
        )
      })
      .catch(err => ToastService.create(err, 'Collection Service'))
  }

  const resetCard = (currentCard = collectionCard, dontResetCard?: boolean) => {
    const updatedCollectionCard = { ...defaultCollectionV2Card }

    if (dontResetCard) updatedCollectionCard.card = currentCard.card

    if (addCardOptions.keepQuantity) updatedCollectionCard.quantity = currentCard.quantity
    if (addCardOptions.keepModifier) updatedCollectionCard.modifier = currentCard.modifier
    if (addCardOptions.keepTags) updatedCollectionCard.tags = [...currentCard.tags]
    if (addCardOptions.keepLanguage) updatedCollectionCard.language = currentCard.language
    if (addCardOptions.keepCondition) updatedCollectionCard.condition = currentCard.condition
    if (addCardOptions.keepPurchasePrice) updatedCollectionCard.purchasePrice = currentCard.purchasePrice

    setCollectionCard(updatedCollectionCard)
  }

  const handleClose = () => {
    onClose()

    if (!resetOnClose) return

    resetCard()
    setView('selecting')
  }

  return (
    <CardDetailsOverlay
      open={open}
      card={{ ...collectionCard.card, modifier: collectionCard.modifier }}
      onClose={handleClose}
      rightControls={<FormOptions currentView={view} options={addCardOptions} onOptionsChange={handleChangeOptions} />}
      activeTabId={view}
      onTabChange={setView}
      tabs={[
        {
          label: '1. Select a card',
          id: 'selecting',
          disabled: !!cardToAdd && closeOnCreateOrUpdate, // If these are present, there's no reason to be selecting a card, as we're providing one from the parent
          body: (
            <SelectCard
              onSelectCard={handleSelectCard}
              onViewChange={setView}
              noChangeView={addCardOptions.addCardOnSelect}
              showSecondaryOption={addCardOptions.showAdvancedOptions}
              skipExistingTab={addCardOptions.skipExistingTab}
            />
          ),
        },
        {
          label: '2. Existing records',
          id: 'reconciling',
          body: (
            <ReconcileExistingData
              collectionCard={collectionCard}
              onCardChange={setCollectionCard}
              onViewChange={setView}
              onCardSave={handleAddCollectionCard}
              updateIfOneExactExists={addCardOptions.updateIfOneExactExists}
            />
          ),
          disabled: !collectionCard?.card.cardId,
        },
        {
          label: '3. Card details',
          id: 'finalizing',
          disabled: !collectionCard?.card.cardId,
          body: (
            <FinalizeData
              // Two editting scenarios 1) We're on the collection page (then we can use redux)
              // 2) If we're adding a card from a parent, assume the user is the owner and can add cards
              ownsCollection={ownsCollection || (!!cardToAdd && !!onCardCreatedOrUpdated)}
              collectionCard={collectionCard}
              onCardChange={setCollectionCard}
              onCardSave={overrideGameType => handleAddCollectionCard(collectionCard, overrideGameType)}
              onChangeView={setView}
              gameTypeSelector={includeGameTypeControls}
            />
          ),
        },
      ]}>
      <div className={styles.recentlyAdded}>
        <h3>Recently added / updated</h3>
        {!recentlyAdded.length && <div className={styles.noRecentCards}>No recently added / updated cards</div>}

        <div className={styles.recentlyAddedGrid}>
          {recentlyAdded.map((recentCollectionCardId, index) => {
            const collectionCard = collectionCards[recentCollectionCardId]

            return (
              <BasicCard
                key={index}
                card={{ ...collectionCard.card, modifier: collectionCard.modifier }}
                className={styles.basicCard}
                imageClassName={styles.cardImage}
              />
            )
          })}
        </div>
      </div>
    </CardDetailsOverlay>
  )
}

export default AddCardsModal
