import CookieService from 'services/cookie.service'

export const checkCanEdit = (ownsDeck: boolean, editors: number[]) => {
  const userId = CookieService.get('tbId') // TODO - NEXT - does this rerender correctly?

  if (ownsDeck) return true
  if (editors.map(id => `${id}`).includes(userId)) return true

  return false
}
