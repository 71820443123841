import { uniqueId } from 'lodash'

import { store } from 'redux/store'

import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import { ToastOptionType, ToastComponentType } from 'types/toasts'

const create = (content: any, title = 'Network Error', type: ToastOptionType = 'error', duration = 3000) => {
  if (typeof window === 'undefined') return

  let contentString = ''

  if (typeof content === 'string') contentString = content
  else if (content instanceof Error && content.message) contentString = content.message
  else if (content instanceof Error) contentString = 'Uncaught error.'
  else if (typeof content === 'object' && Array.isArray(content.nonFieldErrors))
    contentString = content.nonFieldErrors.join(' / ')
  else contentString = JSON.stringify(content)

  const currentToasts = store.getState().active.toasts

  store.dispatch({
    type: SET_ACTIVE_STATE,
    payload: {
      toasts: [
        ...currentToasts,
        {
          text: contentString,
          title: title,
          body: null,
          type: type,
          id: uniqueId('toast_'),
          duration,
        },
      ],
    },
  })
}

const createCustomToast = (
  component: ToastComponentType,
  type: ToastOptionType = 'error',
  extraProps: Record<string, any> = {},
) => {
  const currentToasts = store.getState().active.toasts

  store.dispatch({
    type: SET_ACTIVE_STATE,
    payload: {
      toasts: [
        ...currentToasts,
        {
          type: type,
          body: component,
          id: uniqueId('toast_'),
          text: null,
          title: null,
          extraProps,
        },
      ],
    },
  })
}

const deleteToast = (id: string) => {
  const currentToasts = store.getState().active.toasts
  store.dispatch({
    type: SET_ACTIVE_STATE,
    payload: { toasts: currentToasts.filter(toast => toast.id !== id) },
  })
}

const ToastService = {
  create,
  createCustomToast,
  delete: deleteToast,
}

export default ToastService
