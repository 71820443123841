// React
import React, { useState } from 'react'
import Icon from 'components/elements/Icon'

// Utils
import { getArtUrl } from 'utils/ImageUrl'

// Models
import type { CardType } from 'types/deck'
import DeckService from 'services/deck.service'
import { useActionless, useAppSelector } from 'redux/hooks'
import { DECK_PATCH_SUCCEEDED } from 'redux/deck/actions/types'
import RequestService from 'services/request.service'
import PhatButton from 'components/formElements/PhatButton'
import SimpleSpinner from 'components/elements/SimpleSpinner'

type Props = {
  card: CardType
  flipped?: boolean
}

/**
 * This component renders a menu button that when clicked sets the custom deck image of the current deck to be the card
 * passed to this component in props.
 */
const SetDeckImageButton = ({ card, flipped }: Props) => {
  const deckId = useAppSelector(state => state.deck.id)
  const customFeatured = useAppSelector(state => state.deck.customFeatured)

  const [deckPatchSucceeded] = useActionless(DECK_PATCH_SUCCEEDED)
  const [loading, setLoading] = useState(false)

  const setCustomDeckImage = () => {
    const customFeatured = getArtUrl(card.setCode, card.uid, flipped)

    const body = { customFeatured }

    setLoading(true)

    DeckService.update(deckId, body)
      .then(_data => deckPatchSucceeded(body))
      .catch(RequestService.createToast)
      .finally(() => {
        // without the timeout, the loader is removed too fast otherwise most of the time
        setTimeout(() => setLoading(false), 1000)
      })
  }

  const alreadySet = getArtUrl(card.setCode, card.uid, flipped) === customFeatured

  return (
    <PhatButton disabled={alreadySet || loading} onClick={setCustomDeckImage}>
      {!loading && (
        <>
          <Icon name="image" /> Set Deck Image
        </>
      )}

      {loading && (
        <>
          <SimpleSpinner size="medXSmall" /> Set Deck Image
        </>
      )}
    </PhatButton>
  )
}

export default SetDeckImageButton
