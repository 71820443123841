import React, { useEffect, useRef, useState } from 'react'

type Props = {
  type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url'
  placeholder?: string
  initialValue?: string | number | null
  value?: string | number | null
  recycleInitialValueOn?: string | number | null // Useful for onBlur chenanigans
  onChange?: (value: any, e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (value: any, e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
  className?: string
  id?: string
  autoFocus?: boolean
  highlightOnFocus?: boolean
}

const Input = ({
  type = 'text',
  placeholder,
  initialValue,
  value: parentValue,
  recycleInitialValueOn,

  onChange,
  onBlur,
  onFocus,

  disabled,
  className,
  id,
  autoFocus,

  highlightOnFocus,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null)

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (value !== parentValue) setValue(parentValue)
  }, [parentValue])

  useEffect(() => {
    if (initialValue !== value) setValue(initialValue)
  }, [recycleInitialValueOn])

  const getValue = (val: string): string | number => (type === 'number' ? Number(val) : val)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = getValue(e.target.value)

    setValue(updatedValue)

    if (onChange) onChange(updatedValue, e)
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (highlightOnFocus) e.target.select()

    if (onFocus) onFocus(e)
  }

  return (
    <input
      ref={ref}
      type={type}
      placeholder={placeholder}
      defaultValue={initialValue ?? undefined}
      value={value ?? undefined}
      onChange={handleChange}
      onBlur={e => onBlur && onBlur(getValue(e.target.value), e)}
      onFocus={handleFocus}
      disabled={disabled}
      className={className}
      id={id}
      autoFocus={autoFocus}
    />
  )
}

export default Input
