import { PRICE_SOURCE, PRICE_COLORS } from 'types/active'

import { invert } from 'lodash'
import { store } from 'redux/store'
import environment from 'environment'
import { CardType } from 'types/deck'

export const getPrice = (
  card: Record<string, any>,
  source: number,
  foil?: boolean,
  takeQuantityIntoAccount?: boolean,
): { price: string; priceNumber: number } => {
  const priceSource = invert(PRICE_SOURCE)[source].toLowerCase()
  const foilString = card.ids ? 'Foil' : 'foil'
  const isFoil = card.foil || (!!card.modifier && card.modifier !== 'Normal') || foil

  let price = '0.00'

  if (isFoil && card.prices[priceSource + foilString] !== undefined)
    price = card.prices[priceSource + foilString].toFixed(2)
  else if (card.prices[priceSource] !== undefined) price = card.prices[priceSource].toFixed(2)

  const quantity = takeQuantityIntoAccount ? card.qty ?? card.quantity : 1
  const priceNumber = Number(price) * quantity

  if (takeQuantityIntoAccount) price = (priceNumber * quantity).toFixed(2)
  if (price === '0.00') price = '----'

  return {
    price,
    priceNumber,
  }
}

export const getPriceStringAndUrl = (
  card: Record<string, any>,
  source: number,
  foil?: boolean,
): { price: string; url: string; background: string; affiliateName: string } => {
  const isFoil = card.foil || (!!card.modifier && card.modifier !== 'Normal') || foil

  let { price } = getPrice(card, source, foil)
  let url = '#'
  let background = PRICE_COLORS[PRICE_SOURCE.TCG]
  let searchName = card.name.split('//')[0].trim()

  const affilateParams = getAffiliateParams('single')

  if (source === PRICE_SOURCE.CK) {
    const CK_BROKEN = true // TODO This is only set while we are waiting for a fix on CK links
    background = PRICE_COLORS[PRICE_SOURCE.CK]
    price = `$${price}`
    let ckid = 0
    if (card.ids) {
      ckid = isFoil ? card.ids.ckFoilId : card.ids.ckNormalId
    } else {
      ckid = isFoil ? card.ckFoilId : card.ckNormalId
    }
    url = 'https://www.cardkingdom.com/catalog/search?search=header&filter[name]=' + searchName
    url += (ckid && !CK_BROKEN ? '?' : '&') + affilateParams.ck
  } else if (source === PRICE_SOURCE.TCG) {
    const tcgid = card.ids ? card.ids.tcgId : card.tcgProductId

    let baseTcgUrl = `https://tcgplayer.pxf.io/c/4913290/1830156/21018?partnerpropertyid=${environment.tcgPropertyId}`
    let tcgRedirectUrl = `https://www.tcgplayer.com/search/magic/product?productLineName=magic&q=${searchName}`

    if (tcgid) tcgRedirectUrl = `https://www.tcgplayer.com/product/${tcgid}`

    price = `$${price}`
    url = `${baseTcgUrl}&${affilateParams.tcg}&u=${encodeURIComponent(tcgRedirectUrl)}`
  } else if (source === PRICE_SOURCE.MTGO) {
    background = PRICE_COLORS[PRICE_SOURCE.MTGO]
    price += ' tix'
    let mtgoid = 0
    if (card.ids) {
      mtgoid = isFoil ? card.ids.mtgoFoilId : card.ids.mtgoNormalId
    } else {
      mtgoid = isFoil ? card.mtgoFoilId : card.mtgoNormalId
    }
    url = mtgoid
      ? `https://www.cardhoarder.com/cards/${mtgoid}?${affilateParams.mtgo}`
      : `https://www.cardhoarder.com/cards?data[search]=${searchName.replace(' ', '+')}?${affilateParams.mtgo}`
  } else if (source === PRICE_SOURCE.CM) {
    background = PRICE_COLORS[PRICE_SOURCE.CM]
    price = `${price}€`
    let cmEd = ''
    if (card.ids) {
      cmEd = card.ids.cmEd
    } else {
      cmEd = card.cmEd
    }
    if (!cmEd) cmEd = ''
    cmEd = cmEd.replace(/ /g, '-').replace(/[^0-9a-z-]/gi, '')
    searchName = card.name.replace('// ', '')
    searchName = searchName
      .replace(/ /g, '-')
      .replace(/'/g, '-')
      .replace(/[^0-9a-z-]/gi, '')
    url = cmEd
      ? `https://www.cardmarket.com/en/Magic/Products/Singles/${cmEd}/${searchName}?${affilateParams.cm}`
      : `https://www.cardmarket.com/en/Magic/Products/Search?searchString=${card.name}&${affilateParams.cm}`
  } else if (source === PRICE_SOURCE.SCG) {
    background = PRICE_COLORS[PRICE_SOURCE.SCG]
    price = `$${price}`

    let scgSku = isFoil ? card.ids.scgFoil : card.ids.scg || card.ids.scgFoilSku

    if (scgSku) {
      scgSku = scgSku[scgSku.length - 1] === '1' ? scgSku.substring(0, scgSku.length - 1) : scgSku // Remove the last character if it's a 1
      url = `https://starcitygames.com/${card.name
        .replace(/ /g, '-')
        .replace(/[^0-9a-z-]/gi, '')
        .toLowerCase()}-${scgSku.toLowerCase()}/?aff=${environment.scgAffiliateId}`
    } else {
      url = `https://starcitygames.com/search/?search_query=${encodeURIComponent(card.name)}&sort=score?aff=${
        environment.scgAffiliateId
      }`
    }
  } else if (source === PRICE_SOURCE.MP) {
    background = PRICE_COLORS[PRICE_SOURCE.MP]
    price = `$${price}`
    url = `https://manapool.com/card/${card.setCode}/${card.collectorNumber}/${card.name
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/ /g, '-')
      .toLowerCase()}?${environment.manapoolAffiliateUrlParams}&tm_arc_single=1`
  } else if (source === PRICE_SOURCE.TCGLAND) {
    const cleanup = (s: string) =>
      s
        .replace(/\/\/ /g, '')
        .replace(/ /g, '-')
        .replace(/[^a-zA-Z0-9-]/g, '')
        .toLowerCase()

    background = PRICE_COLORS[PRICE_SOURCE.TCGLAND]
    price = `$${price}`
    url = `https://www.tcg.land/es/magic/productos/single/en/${card.setCode}/${cleanup(card.set)}/${
      card.collectorNumber
    }/${cleanup(card.name)}?&utm_medium=affiliate&utm_source=${environment.tcgLandAffiliateUrlParam}&via=single-card`
  }

  return {
    price: price,
    url: url,
    background: background,
    affiliateName: affilateParams.affiliteName,
  }
}

export const getPriceStringFromFloat: (price: number, source: number) => string = (price, source) => {
  if (source === PRICE_SOURCE.MTGO) return `${price.toFixed(2)} tix`
  if (source === PRICE_SOURCE.CM) return `${price.toFixed(2)} €`

  return `$${price.toFixed(2)}`
}

export const getAffiliateParams = (linkedFromArg: string = 'misc') => {
  const { ckAffiliate, tcgAffiliate } = store.getState().deck

  const regex = (ckAffiliate || tcgAffiliate || 'partner=archidekt').match(/partner=([^&]*)/)
  const affiliteName = regex ? regex[1] : 'archidekt'

  // prettier-ignore
  return {
    ck: ckAffiliate || `partner=archidekt&utm_source=archidekt&utm_medium=affiliate&utm_campaign=archidekt&partner_args=${linkedFromArg}`,
    tcg: tcgAffiliate || `subId1=archidekt-${linkedFromArg}`,
    mtgo: `affiliate_id=archidekt&utm_source=archidekt&utm_campaign=affiliate&utm_medium=${linkedFromArg}`,
    cm: 'referrer=archidekt',
    affiliteName,
  }
}

export const getPricesForCards = (cards: CardType[]) => {
  const ck = cards.map(card => getPrice(card, PRICE_SOURCE.CK, card.modifier !== 'Normal', true))
  const tcg = cards.map(card => getPrice(card, PRICE_SOURCE.TCG, card.modifier !== 'Normal', true))
  const mtgo = cards.map(card => getPrice(card, PRICE_SOURCE.MTGO, card.modifier !== 'Normal', true))
  const cm = cards.map(card => getPrice(card, PRICE_SOURCE.CM, card.modifier !== 'Normal', true))
  const scg = cards.map(card => getPrice(card, PRICE_SOURCE.SCG, card.modifier !== 'Normal', true))
  const mp = cards.map(card => getPrice(card, PRICE_SOURCE.MP, card.modifier !== 'Normal', true))
  const tcgLand = cards.map(card => getPrice(card, PRICE_SOURCE.TCGLAND, card.modifier !== 'Normal', true))

  const ckTotalPriceNumber = ck.reduce((acc, price) => price.priceNumber + acc, 0)
  const tcgTotalPriceNumber = tcg.reduce((acc, price) => price.priceNumber + acc, 0)
  const mtgoTotalPriceNumber = mtgo.reduce((acc, price) => price.priceNumber + acc, 0)
  const cmTotalPriceNumber = cm.reduce((acc, price) => price.priceNumber + acc, 0)
  const scgTotalPriceNumber = scg.reduce((acc, price) => price.priceNumber + acc, 0)
  const mpTotalPriceNumber = mp.reduce((acc, price) => price.priceNumber + acc, 0)
  const tcgLandTotalPriceNumber = tcgLand.reduce((acc, price) => price.priceNumber + acc, 0)

  const ckPrice = getPriceStringFromFloat(ckTotalPriceNumber, PRICE_SOURCE.CK)
  const tcgPrice = getPriceStringFromFloat(tcgTotalPriceNumber, PRICE_SOURCE.TCG)
  const mtgoPrice = getPriceStringFromFloat(mtgoTotalPriceNumber, PRICE_SOURCE.MTGO)
  const cmPrice = getPriceStringFromFloat(cmTotalPriceNumber, PRICE_SOURCE.CM)
  const scgPrice = getPriceStringFromFloat(scgTotalPriceNumber, PRICE_SOURCE.SCG)
  const mpPrice = getPriceStringFromFloat(mpTotalPriceNumber, PRICE_SOURCE.MP)
  const tcgLandPrice = getPriceStringFromFloat(tcgLandTotalPriceNumber, PRICE_SOURCE.TCGLAND)

  return {
    ck: {
      prices: ck,
      totalPrice: ckPrice,
      totalPriceNumber: ckTotalPriceNumber,
    },
    tcg: {
      prices: tcg,
      totalPrice: tcgPrice,
      totalPriceNumber: tcgTotalPriceNumber,
    },
    mtgo: {
      prices: mtgo,
      totalPrice: mtgoPrice,
      totalPriceNumber: mtgoTotalPriceNumber,
    },
    cm: {
      prices: cm,
      totalPrice: cmPrice,
      totalPriceNumber: cmTotalPriceNumber,
    },
    scg: {
      prices: scg,
      totalPrice: scgPrice,
      totalPriceNumber: scgTotalPriceNumber,
    },
    mp: {
      prices: mp,
      totalPrice: mpPrice,
      totalPriceNumber: mpTotalPriceNumber,
    },
    tcgLand: {
      prices: tcgLand,
      totalPrice: tcgLandPrice,
      totalPriceNumber: tcgLandTotalPriceNumber,
    },
  }
}
