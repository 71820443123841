import * as Folder from './actions/types'
import { initialFolderState } from './initialState'
import { FoldersState } from './initialState'
import { FoldersActions } from './actions'
import update from 'utils/ImmutableCommands'

export default (state: FoldersState = initialFolderState, action: FoldersActions) => {
  switch (action.type) {
    case Folder.ADD_SELECTED_FOLDER_ITEM:
      return update(state, {
        selectedItems: {
          $push: [action.payload],
        },
      })
    case Folder.REMOVE_SELECTED_FOLDER_ITEM:
      const type = action.payload.type
      const id = action.payload.id
      const removeIndex = state.selectedItems.findIndex(item => item.type === type && item.id === id)
      if (removeIndex < 0) return state
      return update(state, {
        selectedItems: {
          $splice: [[removeIndex, 1]],
        },
      })

    case Folder.SET_FOLDER_STATE:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
