import config from 'environment'

import { PlaytesterV2State } from 'redux/playtesterV2/types'

export function getDeviceSpecificDefaults(): Partial<PlaytesterV2State> {
  const defaults: Partial<PlaytesterV2State> = {}

  if (typeof document === 'undefined') return defaults

  const screenWidth = document.body.clientWidth

  if (config.getIOSSafari()) defaults.defaultActionOnClick = false
  if (screenWidth < 1400) {
    defaults.handSize = 'small'
    defaults.cardSize = 'small'
    defaults.handRotation = false
  }

  return defaults
}
