import React, { useState, useEffect, useRef } from 'react'
import Icon from 'components/elements/Icon'

import environment from 'environment'

import { Tab as TabType, generateTabDOMId, generateTabState } from 'types/searchV2'

import { store } from 'redux/store'
import { useActionless, useAppSelector } from 'redux/hooks'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import LocalStorageService from 'services/localStorage.service'
import { getLeftPanelOpenDefault, getBooleanFlagFromLocalStorage } from 'services/accountSettings.service'
import { leftHandControlsKey, stickyDeckSearchKey } from 'components/accountSettingsPage/BrowserSpecificSiteSettings'
import { basicKeybindSkips } from 'components/deckPage/modals/KeybindsOverlay'

import Tab from 'components/cardSearchPanel/tabs/tab'
import TabSelection from 'components/cardSearchPanel/tabs/tabSelection'
import SearchHistory from 'components/cardSearchPanel/tabs/searchHistory'
import GlobalOverlayStack from 'components/elements/Overlay/GlobalOverlayStack'
import TabTypeSelector from 'components/cardSearchPanel/tabs/tabTypeSelector'
import PhatButton from 'components/formElements/PhatButton'

import { LOCKED_SEARCH } from 'assets/globalClasses'

import styles from './searchV2.module.scss'

type Props = {
  lockable?: boolean
}

// Search container (opens from the left or locks open)
const SearchV2 = ({ lockable }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  const searchOpen = useAppSelector(state => state.active.searchOpen)

  const [setActiveState] = useActionless(SET_ACTIVE_STATE)

  const [tabs, setTabs] = useState(new Array<TabType>())
  const [locked, setLocked] = useState(false)
  const [lockedWidth, setLockedWidth] = useState(500)
  const [openedOnce, setOpenedOnce] = useState(false)

  const [viewingHistory, setViewingHistory] = useState(false)
  const [viewingTabs, setViewingTabs] = useState(false)

  const [activeTabId, setActiveTabId] = useState<string | null>(null)
  const activeTabRef = useRef(activeTabId)

  // We need the ref so we can access up to date activeTabId in the event listener
  const handleSetActiveTabId = (newTabId: string | null) => {
    setActiveTabId(newTabId)
    activeTabRef.current = newTabId
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    const state = store.getState()
    const controlHeld = environment.getOnMac() ? e.metaKey : e.ctrlKey

    if (basicKeybindSkips(e)) return

    if (controlHeld && e.key === 'Enter' && !state.active.searchOpen) {
      setActiveState({ searchOpen: 'search' })

      if (!activeTabRef.current) return

      const focusInput = document.getElementById(generateTabDOMId(activeTabRef.current))

      focusInput?.focus()
    }

    if (e.key === 'Escape' && state.active.searchOpen && !state.deck.menuCardId) setActiveState({ searchOpen: false })
  }

  useEffect(() => {
    setLocked(getLeftPanelOpenDefault())
    if (getBooleanFlagFromLocalStorage(leftHandControlsKey)) document.body.classList.add(leftHandControlsKey)

    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  useEffect(() => {
    if (getBooleanFlagFromLocalStorage(stickyDeckSearchKey) && activeTabRef.current) {
      const focusInput = document.getElementById(generateTabDOMId(activeTabRef.current))

      focusInput?.focus()
    }
  }, [searchOpen])

  useEffect(() => {
    if (locked) document.body.classList.add(LOCKED_SEARCH)
    if (!locked || !searchOpen) document.body.classList.remove(LOCKED_SEARCH)

    // Everything below this point deals with tab creation if none exist, and or gerating a new tab via an event (akaL: sending a new tab via searchOpen state)
    if (!searchOpen) return

    setOpenedOnce(true)

    const activeTab = tabs.find(t => t.id === activeTabId)

    if (searchOpen === 'search' && activeTab?.type !== 'archidektSearch' && activeTab?.type !== 'scrySearch') {
      const foundTab = tabs.find(t => t.type === 'archidektSearch' || t.type === 'scrySearch')

      if (foundTab) handleSetActiveTabId(foundTab.id)
      else {
        const cachedSearch = LocalStorageService.get('cachedSearchType')
        const newTab =
          cachedSearch === 'scrySearch' ? generateTabState.scrySearch() : generateTabState.archidektSearch()

        setTabs([...tabs, newTab])
        handleSetActiveTabId(newTab.id)
      }
    }

    if (searchOpen === 'recs' && activeTab?.type !== 'edhRecs' && activeTab?.type !== 'archidektRecs') {
      const foundTab = tabs.find(t => t.type === 'edhRecs' || t.type === 'archidektRecs')

      if (foundTab) handleSetActiveTabId(foundTab.id)
      else {
        const newTab = generateTabState.edhRecs()

        setTabs([...tabs, newTab])
        handleSetActiveTabId(newTab.id)
      }
    }

    if (searchOpen === 'manabaseGenerator' && activeTab?.type !== 'manabaseGenerator') {
      const foundTab = tabs.find(t => t.type === 'manabaseGenerator')

      if (foundTab) handleSetActiveTabId(foundTab.id)
      else {
        const newTab = generateTabState.manabaseGenerator()

        setTabs([...tabs, newTab])
        handleSetActiveTabId(newTab.id)
      }
    }

    if (searchOpen === 'spellbookCombo' && activeTab?.type !== 'spellbookCombo') {
      const foundTab = tabs.find(t => t.type === 'spellbookCombo')

      if (foundTab) handleSetActiveTabId(foundTab.id)
      else {
        const newTab = generateTabState.spellbookCombo()

        setTabs([...tabs, newTab])
        handleSetActiveTabId(newTab.id)
      }
    }

    if (typeof searchOpen === 'object') {
      setTabs([...tabs, searchOpen])
      handleSetActiveTabId(searchOpen.id)
    }

    // Creates a default tab if none exist
    if (typeof searchOpen == 'boolean' && !tabs.length && activeTabId === null) {
      const cachedSearch = LocalStorageService.get('cachedSearchType')
      const newTab = cachedSearch === 'scrySearch' ? generateTabState.scrySearch() : generateTabState.archidektSearch()

      setTabs([...tabs, newTab])
      handleSetActiveTabId(newTab.id)
    }

    return () => {
      document.body.classList.remove(LOCKED_SEARCH)
    }
  }, [searchOpen, locked])

  // Can edit a tab, or add a new tab if the tab id doesn't match any existing tabs
  const handleTabChange = (selectedTab: TabType) => {
    const exitingTabIndex = tabs.findIndex(t => t.id === selectedTab.id)

    // AKA - they've chosen to switch to an existing tab rather than
    if (exitingTabIndex > -1) {
      const updatedTabs = [...tabs]

      updatedTabs[exitingTabIndex] = selectedTab

      handleSetActiveTabId(selectedTab.id)
      setTabs(updatedTabs)

      setViewingHistory(false)
      setViewingTabs(false)

      return
    }

    setTabs([...tabs, selectedTab])
    handleSetActiveTabId(selectedTab.id)

    setViewingHistory(false)
    setViewingTabs(false)
  }

  const handleBackToTop = () => {
    scrollRef?.current?.scrollTo({ top: 0, behavior: 'smooth' })

    // const focusInput = document.getElementById(generateTabDOMId(tab.id))

    // if (focusInput && !isMobile) setTimeout(() => focusInput.focus(), 1000) // Timeout allows the scroll animation to finish before focusing
  }

  const handleKillTab = (tab: TabType) => {
    const updatedTabs = tabs.filter(t => t.id !== tab.id)

    if (tab.id === activeTabId) setActiveTabId(null)

    setTabs(updatedTabs)
  }

  const handleLockedResize = (mouseDownEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const startWidth = lockedWidth
    const startPosition = mouseDownEvent.pageX

    const onMouseUp = () => document.body.removeEventListener('mousemove', onMouseMove)
    const onMouseMove = (mouseMoveEvent: MouseEvent) =>
      handleSetLockedWidth(startWidth - startPosition + mouseMoveEvent.pageX)

    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  const handleSetLockedWidth = (requestedWidth: number) => {
    const MAX_WIDTH = window.innerWidth * 0.8
    const MIN_WIDTH = 400

    if (requestedWidth > MAX_WIDTH) return setLockedWidth(MAX_WIDTH)
    if (requestedWidth < MIN_WIDTH) return setLockedWidth(MIN_WIDTH)

    setLockedWidth(requestedWidth)
  }

  const transitionStyles: React.CSSProperties = {}

  if (searchOpen && !locked) transitionStyles.transform = 'unset'
  if (searchOpen && locked) transitionStyles.width = lockedWidth
  if (!searchOpen && locked) {
    transitionStyles.width = 0
    transitionStyles.borderRight = 'none'
  }

  const activeTab = tabs.find(t => t.id === activeTabId)
  const content = (
    <>
      <div className={styles.tabLine}>
        <TabTypeSelector
          currentTabId={activeTabId}
          selected={activeTab}
          onSelect={handleTabChange}
          totalTabs={tabs.length}
          onViewAllTabs={() => setViewingTabs(true)}
        />

        <div className={styles.rightControls}>
          <div className={styles.overflowControls}>
            <button
              title="Add new search tab"
              onClick={() => handleTabChange({ ...generateTabState.archidektSearch() })}
              className={styles.newTabButton}
              disabled={tabs.length >= 10}>
              <Icon name="add" />
            </button>

            <button onClick={() => setViewingTabs(true)} className={styles.allTabsButton} title="View all search tabs">
              {tabs.length}
            </button>
          </div>

          {locked && (
            <>
              <PhatButton onClick={() => setLocked(false)} className={styles.extrasSimpleButton}>
                <Icon name="columns" /> <span>Unlock</span>
              </PhatButton>
            </>
          )}

          <PhatButton onClick={() => setViewingHistory(true)} className={styles.extrasSimpleButton}>
            <Icon name="history" /> History
          </PhatButton>
        </div>
      </div>

      {tabs.map(tab => (
        <Tab key={tab.id} tab={tab} onTabUpdate={handleTabChange} active={tab.id === activeTabId} />
      ))}

      {!locked && (
        <PhatButton className={styles.toTop} onClick={handleBackToTop}>
          <Icon name="arrow up" />
          <span>Scroll to top</span>
        </PhatButton>
      )}

      {!locked && lockable && (
        <button
          className={styles.lockButton}
          onClick={() => {
            setLocked(true)
            handleSetLockedWidth(window.innerWidth * 0.4)
          }}>
          <Icon name="columns" />
          <span>Lock</span>
        </button>
      )}
    </>
  )

  return (
    <>
      <GlobalOverlayStack
        remainMounted={openedOnce}
        id="global-search-panel"
        ref={scrollRef}
        open={!!searchOpen && !locked}
        onClose={() => setActiveState({ searchOpen: false })}
        className={styles.container}
        mobileControlsClassName={styles.mobileControls}
        mobileControls={
          <>
            <button onClick={() => setViewingHistory(true)}>
              <Icon name="history" />
              <span>History</span>
            </button>

            <button onClick={() => setViewingTabs(true)}>
              <div className={styles.viewAllTabsMobile}>{tabs.length}</div>
              <span>Tabs</span>
            </button>

            <button onClick={handleBackToTop}>
              <Icon name="arrow circle up" />
              <span>Scroll up</span>
            </button>
          </>
        }>
        {content}
      </GlobalOverlayStack>

      {locked && (
        <div className={styles.lockWrapper}>
          <div className={styles.lockedContainer} style={transitionStyles}>
            <div className={styles.lockedContent}>{content}</div>
          </div>

          <button className={styles.draggable} onMouseDown={handleLockedResize}></button>
        </div>
      )}

      {/* Sub overlays */}
      <SearchHistory onTabSelected={handleTabChange} open={viewingHistory} onClose={() => setViewingHistory(false)} />
      <TabSelection
        tabs={tabs}
        open={viewingTabs}
        onTabSelected={handleTabChange}
        onClose={() => setViewingTabs(false)}
        onKillTab={handleKillTab}
      />
    </>
  )
}

export default SearchV2
