import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useCookies } from 'react-cookie'
import queryString from 'query-string'

import { useAppSelector } from 'redux/hooks'

import DeckService from 'services/deck.service'
import RequestService from 'services/request.service'
import ToastService from 'services/toast.service'

import { CardType, defaultCard } from 'types/deck'
import { IN_DECK } from 'types/search'
import { DecksWithCardResponseType, DeckWithCardType } from 'services/apiTypes/deck.types'

import PhatButton from 'components/formElements/PhatButton'
import CardDetailsOverlay from 'components/elements/Overlay/CardDetailsOverlay'
import SimpleSpinner from 'components/elements/SimpleSpinner'
import AsyncDropdown, { Option } from 'components/elements/AsyncDropdown'
import PhatDropdown from 'components/formElements/PhatDropdown'
import Icon from 'components/elements/Icon'

import { getCardDefaultCategoryFromCard } from 'utils/CategoryGenerator'

import styles from './addToOtherDeck.module.scss'

type Props = {
  label?: string
  onAdded?: (deckName: string) => void
} & ({ card: CardType; cards?: never } | { card?: never; cards: CardType[] }) &
  (
    | { parentControlled: true; open: boolean; onClose: () => void }
    | { parentControlled?: false; open?: never; onClose?: never }
  )

const AddToOtherDeck = ({ card, cards, label, parentControlled = false, onAdded, open: parentOpen }: Props) => {
  const selectedCards = card ? [card] : cards

  const [{ tbId: userId }] = useCookies(['tbId'])

  const currentDeckId = useAppSelector(state => state.deck.id)

  // prettier-ignore
  const [selectedCategory, setSelectedCategory] = useState(selectedCards.length > 1 ? null : getCardDefaultCategoryFromCard(selectedCards[0]))
  const [selectedDeckId, setSelectedDeckId] = useState<number | null>(null)
  const [searchedDeckName, setSearchedDeckName] = useState('')
  const [dropdownLoading, setDropdownLoading] = useState(false)
  const [decksWithCard, setDecksWithCard] = useState<Array<DeckWithCardType>>([])
  const [addLoading, setAddLoading] = useState(false)
  const [open, setOpen] = useState(parentOpen || false)

  useEffect(() => {
    if (!parentControlled) return

    setOpen(parentOpen || false)
  }, [parentOpen, parentControlled])

  const fetchDecks = async (deckName?: string) => {
    const params: Record<string, any> = {}

    if (card) params.id = card.cardId
    if (deckName) params.deckName = deckName

    const requestUrl = `/api/users/${userId}/decksWithCard/?${queryString.stringify(params)}`

    RequestService.get<DecksWithCardResponseType>(requestUrl)
      .then(data => setDecksWithCard(data.decks))
      .finally(() => setDropdownLoading(false))
  }

  const debouncedFetchDecks = useRef(debounce(fetchDecks, 500)).current
  const deckSearchInputRef = useRef<any>(null)

  const handleSearchForDecks = (deckName?: string) => {
    setSearchedDeckName(deckName || '')
    setDropdownLoading(true)

    debouncedFetchDecks(deckName)
  }

  const handleAddToOtherDeck = async () => {
    const deck = decksWithCard.find(obj => obj.id === selectedDeckId)

    if (deck && deck.hasCard === IN_DECK.NOT_IN_DECK) {
      setAddLoading(true)

      DeckService.add(
        deck.id,
        selectedCards.map((card, index) => {
          const defaultCategory = getCardDefaultCategoryFromCard(card)
          const categories = selectedCategory !== 'Default' ? [selectedCategory || defaultCategory] : [defaultCategory]

          return {
            ...card,
            qty: card.qty || 1,
            modifier: card.modifier || card.options[0],
            categories: categories,
          }
        }),
      )
        .then(() => {
          if (!!card) ToastService.create(`${card?.name} added to ${deck.name}`, 'Deck service', 'success')
          if (selectedCards.length > 1)
            ToastService.create(`${selectedCards.length} cards added to ${deck.name}`, 'Deck service', 'success')

          setOpen(false)
          setSelectedCategory(null)
          setDecksWithCard([])
          setSelectedDeckId(null)
          setSearchedDeckName('')
        })
        .catch(err => RequestService.createToast(err))
        .finally(() => {
          setAddLoading(false)

          if (onAdded) onAdded(deck.name)
        })
    }
  }

  const isAddingMultipleCards = !card
  const defaultCategory = getCardDefaultCategoryFromCard(selectedCards[0])
  const currentlySelectedDeck = decksWithCard.find(o => `${o.id}` === `${selectedDeckId}`)
  const options: Option[] = decksWithCard.map(deck => {
    let label = deck.name

    if (`${deck.id}` === `${currentDeckId}`) `${deck.name} (Currently viewed deck)`
    else if (deck.hasCard === IN_DECK.NAME_IN_DECK) label = `${deck.name} (Different Edition)`
    else if (deck.hasCard === IN_DECK.EXACT_IN_DECK) label = `${deck.name} (In Deck)`

    return {
      label,
      id: `${deck.id}`,
      value: deck.id,
      disabled: deck.id === currentDeckId,
    }
  })

  return (
    <>
      {!parentControlled && (
        <PhatButton onClick={() => setOpen(true)}>
          <Icon name="plus" icon="folder" /> {label ? label : 'Add to other deck'}
        </PhatButton>
      )}

      <CardDetailsOverlay
        rightControls={null}
        tabs={[
          {
            id: 'add-to-other-deck',
            label: 'Add to other deck',
            body: (
              <div className={styles.container}>
                {card?.name && <h3>Add {card?.name} to other deck</h3>}
                {selectedCards.length > 1 && <h3>Add {selectedCards.length} card(s) to other deck</h3>}

                <AsyncDropdown
                  ref={deckSearchInputRef}
                  placeholder="Select a deck..."
                  loading={dropdownLoading}
                  options={options}
                  onChange={id => {
                    const selectedDeck = options.find(o => `${o.id}` === `${id}`)

                    if (!selectedDeck) return

                    setSelectedDeckId(id)

                    // @ts-ignore
                    setSearchedDeckName(selectedDeck.label || '')
                  }}
                  onSearchChange={handleSearchForDecks}
                  onFocus={() => {
                    deckSearchInputRef?.current?.select()

                    if (!options.length) handleSearchForDecks()
                  }}
                  value={searchedDeckName}
                  triggerClassName={styles.deckSearchDropdown}
                />

                <PhatDropdown
                  options={[
                    {
                      label: isAddingMultipleCards ? 'Default Category' : defaultCategory,
                      id: isAddingMultipleCards ? 'Default' : defaultCategory,
                      onClick: () => setSelectedCategory(isAddingMultipleCards ? 'Default' : defaultCategory),
                    },
                    { label: 'Sideboard', id: 'Sideboard', onClick: () => setSelectedCategory('Sideboard') },
                    { label: 'Maybeboard', id: 'Maybeboard', onClick: () => setSelectedCategory('Maybeboard') },
                  ]}
                  value={selectedCategory}
                  disabled={!currentlySelectedDeck || currentlySelectedDeck.hasCard !== IN_DECK.NOT_IN_DECK}
                  className={styles.categoryDropdown}
                />

                <div className={styles.controls}>
                  <button
                    disabled={
                      !currentlySelectedDeck || currentlySelectedDeck.hasCard !== IN_DECK.NOT_IN_DECK || addLoading
                    }
                    onClick={handleAddToOtherDeck}>
                    {!addLoading && 'Add Card(s)'}
                    {addLoading && (
                      <>
                        <SimpleSpinner size="medXSmall" /> Adding card(s)...
                      </>
                    )}
                  </button>
                </div>
              </div>
            ),
          },
        ]}
        onTabChange={() => null}
        activeTabId="add-to-other-deck"
        card={card || defaultCard}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default AddToOtherDeck
