import React, { useEffect, useState } from 'react'

import Modal from 'components/elements/Modal'
import SimpleSpinner from 'components/elements/SimpleSpinner'

import styles from './confirmDeleteModal.module.scss'

type Props = {
  open: boolean
  onClose: () => void
  header?: string
  message?: React.ReactNode
  buttonText?: string
  loadingMessage?: string
  delayEnabledTime?: number // ms to wait before enabling the button
  usedInOverlay?: boolean
} & ({ onDelete: () => Promise<any>; onConfirm?: never } | { onDelete?: never; onConfirm: () => void })

const ConfirmDeleteModal = ({
  open,
  onClose,
  onDelete,
  onConfirm,
  header = 'Delete thing',
  message = 'Are you sure you want to delete that thing?',
  buttonText = 'Confirm delete',
  loadingMessage = 'Deleting that thing...',
  delayEnabledTime = 0,
  usedInOverlay,
}: Props) => {
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleConfirmDelete = () => {
    if (onConfirm) return onConfirm()

    setLoading(true)

    onDelete()
      .then(() => setButtonEnabled(false))
      .then(onClose)
      // .catch handled by the parent
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (open) setTimeout(() => setButtonEnabled(true), delayEnabledTime)
    else setButtonEnabled(false)
  }, [open])

  return (
    <Modal open={open} onClose={onClose} closeButton modalClassName={styles.modal} usedInOverlay={usedInOverlay}>
      <h2>{header}</h2>

      <div className={styles.info}>{message}</div>

      <button className={styles.deleteButton} disabled={!buttonEnabled || loading} onClick={handleConfirmDelete}>
        {!loading && <>{buttonText}</>}
        {loading && (
          <>
            <SimpleSpinner size="xSmall" /> {loadingMessage}
          </>
        )}
      </button>
    </Modal>
  )
}

export default ConfirmDeleteModal
