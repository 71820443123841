import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import { CollectionV2Card, defaultCollectionV2Card } from 'redux/collectionV2/types'

import { useAppSelector } from 'redux/hooks'

import CollectionService from 'services/collection.service'

import SimpleSpinner from 'components/elements/SimpleSpinner'
import AddCardsModal from 'components/collectionV2/addCards/Modal'
import PhatButton from 'components/formElements/PhatButton'
import { CardDetailsModalStandalone } from '../Modal'
import { ExistingCardRecord } from 'components/collectionV2/addCards/formParts/ReconcileExistingData'

import { responseToCard } from 'utils/ResponseToResults'

import styles from './relatedCards.module.scss'

type Props = {
  collectionCard: CollectionV2Card
}

const RelatedCards = ({ collectionCard }: Props) => {
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState(new Array<CollectionV2Card>())
  const [selectedCard, setSelectedCard] = useState<CollectionV2Card | null>(null)
  const [addNewCard, setAddNewCard] = useState(false)

  const ownsCollection = useAppSelector(state => state.collectionV2.ownsCollection)

  const [{ tbId: userId }] = useCookies(['tbId'])

  useEffect(() => {
    if (selectedCard) return
    if (!collectionCard.card.oracleCardId) return setLoading(false) // Default empty card object
    if (!userId) return setLoading(false)

    setLoading(true)
    setResults(new Array<CollectionV2Card>())

    CollectionService.listV2(
      `${userId}`,
      `oracleCardIds=${collectionCard.card.oracleCardId}&collectionOrderBy=editionDate&orderDirection=descending`,
    )
      .then(res => {
        const serverCollectionData = res.results.map(collectionCard => ({
          ...collectionCard,
          card: responseToCard(collectionCard.card),
        }))

        // @ts-ignore - TODO - come back and fix this - Move the serialization logic to the service please
        setResults(serverCollectionData)
      })
      .finally(() => setTimeout(() => setLoading(false), 750))
  }, [collectionCard.card?.id, selectedCard])

  const { sumOfAllMatches, sumOfExactMatches } = results.reduce(
    (acc, relatedCard) => {
      acc.sumOfAllMatches += relatedCard.quantity

      if (collectionCard.card.cardId === relatedCard.card.cardId) acc.sumOfExactMatches += relatedCard.quantity

      return acc
    },
    { sumOfAllMatches: 0, sumOfExactMatches: 0 },
  )

  return (
    <>
      <div className={styles.container}>
        <div className={styles.group}>
          <div className={styles.header}>Existing collection records by name</div>

          <div className={styles.stripes}>
            {!loading && (
              <>
                {results.map((card, index) => (
                  <ExistingCardRecord
                    key={index}
                    collectionCard={card}
                    onSelect={() => setSelectedCard(card)}
                    disabled={card.id === collectionCard.id}
                    selectLabel={card.id === collectionCard.id ? 'Active record' : 'View this card'}
                  />
                ))}
              </>
            )}
          </div>

          {loading && (
            <div className={styles.loader}>
              <SimpleSpinner size="xSmall" />
              Fetching matching cards...
            </div>
          )}

          {!loading && results.length === 0 && (
            <div className={styles.noResults}>No matching collection records found</div>
          )}
        </div>
      </div>

      {!loading && results.length > 0 && (
        <div className={styles.sumOfCards}>
          <div>
            Sum of {collectionCard.card.name}: {sumOfAllMatches}
          </div>
          <div>
            Sum of {collectionCard.card.name} ({collectionCard.card.setCode}) {collectionCard.card.collectorNumber}:{' '}
            {sumOfExactMatches}
          </div>
        </div>
      )}

      {!loading && (
        <div>
          <div className={styles.addNewCard}>
            <PhatButton color="green" onClick={() => setAddNewCard(true)}>
              Add new collection record
            </PhatButton>
          </div>
        </div>
      )}

      <CardDetailsModalStandalone
        ownsCollection
        open={!!selectedCard}
        collectionCard={selectedCard || defaultCollectionV2Card}
        onCardUpdated={setSelectedCard}
        onClose={() => setSelectedCard(null)}
      />

      <AddCardsModal
        open={addNewCard}
        cardToAdd={collectionCard.card}
        onClose={() => setAddNewCard(false)}
        includeGameTypeControls={!ownsCollection}
        closeOnCreateOrUpdate
        onCardCreatedOrUpdated={() => null}
      />
    </>
  )
}

export default RelatedCards
