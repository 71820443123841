import React, { useState, useRef, useEffect } from 'react'
import debounce from 'lodash.debounce'

import { Color, SearchOptions } from 'services/apiTypes/card.types'
import { CardType, EDH_FORMATS, FORMAT_IDS_SCRYFALL, GAME_IDS, HUMAN_GAME, NONSCRYFALL_FORMATS } from 'types/deck'

import { store } from 'redux/store'
import { selectCommanderCards } from 'redux/deck/selectors'
import { useAppSelector } from 'redux/hooks'

import CardService from 'services/card.service'

import AsyncDropdown, { Option } from 'components/elements/AsyncDropdown'

import { colorsToColorIdentityName } from 'utils/colorUtils'
import { getPriceStringAndUrl } from 'utils/Price'
import { getDeckColorIdent } from 'utils/mtgColor.utils'
import { COLOR_MAP } from 'components/deckPage/charts/charts.const'

import styles from './cardAutocomplete.module.scss'

type Props = {
  id?: string
  className?: string
  triggerClassName?: string
  value?: string
  icon?: string | null
  placeholder?: string
  onChange?: (e: any, value: any) => void
  onFocus?: () => void
  focusOnMount?: boolean
  scryfallSearch?: boolean
  keepOpenOnSelect?: boolean
  smartFilter?: boolean
  limitedSearchOptions?: SearchOptions
  limitedSyntaxSearchOptions?: string
  noIcon?: boolean
  header?: string
  absoluteHeader?: boolean
  headerClassName?: string
  disabled?: boolean
} & ({ fetchCard?: never; onSelect?: (c: string) => void } | { fetchCard: true; onSelect?: (c: CardType) => void })

/**
 * This is an extractable CardAutocomplete component. It is passed down an onChange function and a value which controls
 * the input state. It also takes a onSelect function for when the dropdown component is selected. The
 * onSelect function is passed the selected string by default or can fetch the card and pass that instead if
 * the fetchCard prop is set to true.
 */
const CardAutocomplete = React.forwardRef((props: Props, ref: any) => {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Array<Option>>([])

  const handleSearch = async (search: string, extraSyntax = '', extraFilter: SearchOptions = {}) => {
    setLoading(true)

    const state = store.getState()

    const game = state.deck.game

    let scryfallQuery = search

    if (extraSyntax) scryfallQuery += ` and ${extraSyntax}`

    let searchParameters: SearchOptions = {
      search,
      allEditions: false,
      includeDigital: game !== HUMAN_GAME.Paper,
      includeEmblems: true,
      includeTokens: true,
      includeArtCards: true,
      ...extraFilter,
    }

    if (props.smartFilter) {
      const { archidektSmartSearch, scryfallSmartSearch } = appendSmartFilters(searchParameters, scryfallQuery)

      searchParameters = archidektSmartSearch
      scryfallQuery = scryfallSmartSearch
    }

    const request = props.scryfallSearch
      ? CardService.scryfallCardsOnly(scryfallQuery, undefined, false, 1, true)
      : CardService.listCardsOnly(searchParameters)

    return request
      .then(cards => {
        const options = cards.map(card => ({
          label: <SearchOption card={card} cardNameOnly={!props.fetchCard} />,
          value: card,
        }))

        setOptions(options)
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  const debouncedSeach = useRef(debounce(handleSearch, 500)).current

  const handleSearchChange = (query: string) => {
    props.onChange && props.onChange({}, { value: query })

    if (query.length <= 2) {
      setOptions([])
      setLoading(false)

      return
    }

    setLoading(true)
    debouncedSeach(query, props.limitedSyntaxSearchOptions, props.limitedSearchOptions)
  }

  const handleResultSelect = (card: CardType) => {
    if (!props.keepOpenOnSelect) setOptions([])

    if (props.fetchCard && props.onSelect) return props.onSelect(card)
    if (!props.fetchCard && props.onSelect) return props.onSelect(card.name)
  }

  useEffect(() => {
    if (props.value) handleSearch(props.value)
  }, [props.scryfallSearch])

  return (
    <div className={props.absoluteHeader ? styles.absoluteHeaderContainer : undefined}>
      {props.header && (
        <label
          className={`
            ${styles.header} 
            ${props.headerClassName || ''} 
            ${props.absoluteHeader ? styles.absoluteHeader : ''}
            ${props.disabled ? styles.disabled : ''}
          `}>
          {props.header}
        </label>
      )}
      <AsyncDropdown
        skipCloseOnClick
        noShowWithoutOptions
        ref={ref}
        onFocus={props.onFocus}
        triggerClassName={`${styles.trigger} ${props.triggerClassName || ''}`}
        placeholder={props.placeholder}
        loading={loading}
        options={options.map(option => ({ ...option, noCloseOnClick: props.keepOpenOnSelect }))}
        onChange={handleResultSelect}
        onSearchChange={handleSearchChange}
        containerClassName={props.className}
        noClearOnClick={props.keepOpenOnSelect}
        value={props.value}
        noIcon={props.noIcon}
        disabled={props.disabled}
      />
    </div>
  )
})

export default CardAutocomplete

const SearchOption = ({ card, cardNameOnly }: { card: CardType; cardNameOnly?: boolean }) => {
  const priceSource = useAppSelector(state => state.active.priceSource)
  const { price } = getPriceStringAndUrl(card, priceSource[0], card.modifier !== 'Normal')

  return (
    <div className={styles.option}>
      <div className={styles.words}>
        <div className={styles.setName}>
          {card.name}
          {card.displayName ? ` (${card.displayName})` : ''}
          {card.layout === 'token' && <span className={styles.metaInfo}>(Token)</span>}
        </div>
        {!cardNameOnly && (
          <div className={styles.metaInfo}>
            <span>
              ({card.setCode.toLocaleUpperCase()}) ({card.collectorNumber})
            </span>
            <span>{price}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export const appendSmartFilters = (
  archidektSearch: SearchOptions,
  scryfallSearch: string,
): { archidektSmartSearch: SearchOptions; scryfallSmartSearch: string } => {
  const state = store.getState()

  const commanders = selectCommanderCards(state)
  const deck = Object.values(state.deck.cardMap)
  const categories = state.deck.categories
  const isCommanderFormat = EDH_FORMATS.includes(state.deck.format)
  const format = state.deck.format
  const game = state.deck.game

  let scryfallSmartSearch = ''
  let archidektSmartSearch = { ...archidektSearch }

  // Game type (paper, mtgo, arena)
  scryfallSmartSearch += ` game:${GAME_IDS[game || 1]}`
  archidektSmartSearch.game = game || undefined

  // Game format
  if (!NONSCRYFALL_FORMATS.includes(format)) {
    scryfallSmartSearch += ` format:${FORMAT_IDS_SCRYFALL[format]}`
    archidektSmartSearch.formatLegality = format
  }

  // Color identity
  if (isCommanderFormat && commanders.length) {
    const colorAbr = getDeckColorIdent(deck, categories, isCommanderFormat)
    const colors = colorAbr.map(colorAbr => COLOR_MAP[colorAbr])
    const identityName = colorsToColorIdentityName(colors)

    if (identityName !== 'WUBRG') scryfallSmartSearch += ` identity<=${identityName} `
    archidektSmartSearch.colorIdentity = true
    archidektSmartSearch.colors = colors.reduce((acc, color) => ({ ...acc, [color]: true }), {})
  }

  return {
    archidektSmartSearch,
    scryfallSmartSearch: `${scryfallSearch} and (${scryfallSmartSearch})`,
  }
}
