import styled from 'styled-components'

import { ColorAbr } from 'services/apiTypes/card.types'

const scryfallUrl = (filename: string | number) => `https://svgs.scryfall.io/card-symbols/${filename}.svg`
const getWidth = (symbol: string | number, width?: string) => {
  if (width) return width
  if (`${symbol}` === '1000000') return '4.9em'
  if (`${symbol}` === '100') return '1.8em'
  return '1em'
}

export const ManaSymbol = styled.div<{
  symbol: string | number | ColorAbr
  height?: string
  width?: string
  opacity?: number
}>`
  background-image: url(${props => scryfallUrl(props.symbol)});
  background-repeat: no-repeat;
  display: inline-block;
  height: ${props => (props.height ? props.height : '1em')};
  width: ${props => getWidth(props.symbol, props.width)};
  transition: all 0.2s ease-in-out;
  opacity: ${props => (props.opacity ? props.opacity : 1)};
`

export const Slash = styled.div`
  display: inline-block;
  height: 1em;
  width: 1em;
  text-align: center;
`

export default ManaSymbol
