import environment from 'environment'

export const ctrlConverter = (s: string): string =>
  // prettier-ignore
  environment.getOnMac() ? s
        .replace('ctrl', 'cmd')
        .replace('Ctrl', 'Cmd')
        .replace('delete', 'fn + delete')
        .replace('Delete', 'Fn + Delete')
    : s
