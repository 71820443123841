import styled from 'styled-components'
import { FoilDiv } from 'components/card/controlsAndInfo/cardOverlays'

const CARD_PAGE_IMAGE_DIMENSIONS = {
  HEIGHT: 418,
  WIDTH: 300,
  RADIUS: 16,
}

export const OuterCardInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ToolsContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.lightBackground2};
  padding: 25px;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.lightColor};
`

export const DoubleWide = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

export const CardInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
`

export const ImageContainer = styled.div<{ src?: string; sidebar?: boolean }>`
  position: relative;
  border-radius: ${CARD_PAGE_IMAGE_DIMENSIONS.RADIUS}px;
  width: ${CARD_PAGE_IMAGE_DIMENSIONS.WIDTH}px;
  height: ${CARD_PAGE_IMAGE_DIMENSIONS.HEIGHT}px;
  background-image: url(${props => props.src});
  background-size: cover;
  box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, 0.75);
  transition: background-image 0.25s ease-in-out;
  ${props => (!props.sidebar ? `top: -45px;` : ``)}
`
export const CardPageFoilDiv = styled(FoilDiv)`
  border-radius: ${CARD_PAGE_IMAGE_DIMENSIONS.RADIUS}px;
  width: ${CARD_PAGE_IMAGE_DIMENSIONS.WIDTH}px;
  height: ${CARD_PAGE_IMAGE_DIMENSIONS.HEIGHT}px;
`

export const ImagePlaceHolder = styled.div<{ sidebar?: boolean }>`
  width: 300px;
  height: ${props => (props.sidebar ? 425 : 380)}px;
  position: relative;
`

export const InfoContainer = styled.div`
  margin: 10px;
  width: 300px;
`
export const Info = styled.div`
  color: ${props => props.theme.lightColor};
`
export const SubInfo = styled.div`
  font-size: 14px;
  width: 100%;
  padding: 10px;
`
export const Flavor = styled(SubInfo)`
  font-style: italic;
`

export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 115%;
  margin: 10px 0 10px 0;
  color: ${props => props.theme.color};
`

export const NameCell = styled.div`
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`

export const PriceCell = styled.div`
  width: 75px;
  color: ${props => (props.color ? props.color : '#6697ff')};
  text-align: right;
`
