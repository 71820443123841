import { SET_DECK_STATE } from './types'
import { DeckState } from '../initialState'

export type SetDeckStateActionType = {
  type: typeof SET_DECK_STATE
  payload: Partial<DeckState>
}

export const setDeckStateAction = (partial: Partial<DeckState>): SetDeckStateActionType => ({
  type: SET_DECK_STATE,
  payload: partial,
})
