import type { CollectionTagType, TextErrorsType, UploadErrorsType } from 'types/collection'
import { defaultTextErrors, defaultUploadErrors } from 'types/collection'
import { CollectionCardResponseType } from 'services/apiTypes/collection.types'

import { getCollectionGame, getCollectionPrice } from 'services/accountSettings.service'

export type CollectionState = {
  collection: Array<CollectionCardResponseType>
  resultCount: number
  nextPage: string | null
  loading: boolean
  addingCard: boolean
  deletingCard: boolean
  modifyingId: number
  clearingCollection: boolean
  importing: boolean
  query: {
    cardName: string
    orderBy: string
    game: number
  }
  priceSource: number
  tagMap: Record<string, CollectionTagType>
  creatingTag: boolean
  deletingTag: boolean
  uploadErrors: UploadErrorsType
  textErrors: TextErrorsType
  currentChunk: number
  maxChunks: number
  owner?: {
    username: string
    avatar: string
    frame: string
    id: number
  }
}

export const initialState: CollectionState = {
  collection: [],
  resultCount: 0,
  loading: false,
  nextPage: null,
  addingCard: false,
  deletingCard: false,
  modifyingId: 0,
  clearingCollection: false,
  importing: false,
  query: {
    cardName: '',
    orderBy: '-id',
    game: getCollectionGame(),
  },
  priceSource: getCollectionPrice(),
  tagMap: {},
  creatingTag: false,
  deletingTag: false,
  uploadErrors: defaultUploadErrors,
  textErrors: defaultTextErrors,
  maxChunks: 0,
  currentChunk: 0,
}
