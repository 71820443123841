import React, { useEffect, useState } from 'react'

type Status = 'unmounted' | 'exited' | 'entering' | 'entered' | 'exiting'
type Props = {
  children: React.ReactNode
  show: boolean
  duration?: number
  inDuration?: number
  outDuration?: number
  unmountOnClosed?: boolean
  cssRemovedFromDOMOnClosed?: boolean
  className?: string
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const UNMOUNTED: Status = 'unmounted'
const EXITED: Status = 'exited'
const ENTERING: Status = 'entering'
const ENTERED: Status = 'entered'
const EXITING: Status = 'exiting'

const transitionStyles: Record<Status, React.CSSProperties> = {
  unmounted: { opacity: 0 },
  entering: { opacity: 0, overflow: 'hidden' },
  entered: { opacity: 1 },
  exiting: { opacity: 0, overflow: 'hidden' },
  exited: { opacity: 0 },
}

const FadeInOut = ({
  children,
  show,
  duration = 300,
  outDuration,
  inDuration,
  className,
  style,
  onClick,
  unmountOnClosed,
  cssRemovedFromDOMOnClosed,
}: Props) => {
  const [status, setStatus] = useState<Status>(UNMOUNTED)

  useEffect(() => {
    if (show) performEnter()
    else performExit()
  }, [show])

  const performEnter = () => {
    setStatus(ENTERING)
    setTimeout(() => setStatus(ENTERED), inDuration ?? duration)
  }

  const performExit = () => {
    setStatus(EXITING)
    setTimeout(() => setStatus(EXITED), outDuration ?? duration)
  }

  let extraStyles: React.CSSProperties = {}

  if (unmountOnClosed && (status === UNMOUNTED || status === EXITED)) return null
  if (cssRemovedFromDOMOnClosed && (status === UNMOUNTED || status === EXITED)) extraStyles.display = 'none'

  return (
    <div
      onClick={onClick}
      className={className}
      style={{
        ...style,
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0.1,
        ...transitionStyles[status],
        ...extraStyles,
      }}>
      {children}
    </div>
  )
}

export default FadeInOut
