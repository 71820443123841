import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

import { useAppSelector } from 'redux/hooks'
import { useCanEditDeck } from 'redux/deck/selectors'

import { CardType, EDH_FORMATS } from 'types/deck'

import MakeCompanionButton from 'components/card/cardForm/makeCompanionButton'
import SetDeckImageButton from 'components/card/cardForm/setDeckImageButton'
import AddToCollectionButton from 'views_DEPRICATED/Collection/AddToCollectionButton/AddToCollectionButton'
import AddToOtherDeck from 'components/card/cardForm/addToOtherDeck'
import Categories from 'components/card/cardForm/categories/categories'
import CardModalCategoriesInfo from 'components/tooltips/CardModalCategoriesInfo'
import ToggleFoilButton from 'components/card/cardForm/toggleFoilButton'
import CustomCmcDropdown from 'components/card/cardForm/customCmcDropdown'
import QuantityInput from 'components/card/cardForm/quantityInput'
import EditionOptions from 'components/card/cardForm/editionOptions'
import CardEditionsGridOverlay from '../CardEditionsGridOverlay'
import LockPersonalCategoriesButton from './lockPersonalCategoriesButton'
import ColorLabelDropdown from './colorLabelDropdown'
import { ToCategoryButton, ToCommanderButton } from 'components/card/cardForm/toCategoryButtons'

import { NextPrevOptions } from 'components/elements/Overlay/CardDetailsOverlay'

import styles from './cardForm.module.scss'

type Props = {
  card: CardType
  onCardUpdated: (card: CardType) => void
  multiEdit?: boolean
  flipped?: boolean
  nextPrevControls?: {
    previous: NextPrevOptions
    next: NextPrevOptions
  }
}

const CardForm = ({ card, onCardUpdated, multiEdit, flipped = false, nextPrevControls }: Props) => {
  const starttingRef = React.useRef<HTMLButtonElement>(null)

  const [{ tbId: userId }] = useCookies(['tbId'])

  const canEditDeck = useCanEditDeck()

  const format = useAppSelector(state => state.deck.format)

  const [viewingAllPrintings, setViewingAllPrintings] = useState(false)

  return (
    <div className={styles.container}>
      <button className={styles.focusFirstButton} ref={starttingRef} />

      {canEditDeck && (
        <div className={styles.quantityCommanderRow}>
          <QuantityInput
            className={`${styles.quantityRow} ${multiEdit ? styles.removeExtraMargins : ''}`}
            quantity={card.qty}
            onQuantityUpdate={qty => onCardUpdated({ ...card, qty })}
          />

          <div className={styles.commanderCompanion}>
            {EDH_FORMATS.includes(format) && <ToCommanderButton card={card} onCardUpdate={onCardUpdated} />}
            <MakeCompanionButton card={card} onCardUpdated={onCardUpdated} />
          </div>
        </div>
      )}

      <div className={styles.row}>
        {!multiEdit && (
          <EditionOptions
            label="Printing"
            card={card}
            className={styles.editionSelection}
            onEditionChanged={onCardUpdated}
            onAllPrintingsClicked={() => setViewingAllPrintings(true)}
          />
        )}

        <ToggleFoilButton
          className={multiEdit ? styles.fullWidth : ''}
          triggerClassName={multiEdit ? styles.fullWidth : ''}
          card={card}
          onCardUpdate={onCardUpdated}
        />
      </div>

      <div className={styles.row}>
        {canEditDeck && (
          <div className={styles.left}>
            <div className={styles.prettyBox}>
              <CardModalCategoriesInfo />
              <Categories card={card} onCardChange={onCardUpdated} />
              {/* <div className={styles.divider} /> */}
              <label>Quick category options:</label>
              <div className={styles.sideMaybeRow}>
                {!multiEdit && (
                  <ToCategoryButton
                    icon="backward"
                    card={card}
                    categoryName={card.globalCategories[0] || card.defaultCategory || card.typeCategory}
                    onCardUpdate={onCardUpdated}
                  />
                )}
                <ToCategoryButton card={card} categoryName="Sideboard" onCardUpdate={onCardUpdated} />
                <ToCategoryButton
                  card={card}
                  pointing="top right"
                  categoryName="Maybeboard"
                  onCardUpdate={onCardUpdated}
                />
              </div>
            </div>

            <div>
              <LockPersonalCategoriesButton
                card={card}
                onCardUpdated={onCardUpdated}
                className={styles.lockPersonalCategories}
              />
            </div>
          </div>
        )}

        <div className={styles.right}>
          <label>Other options:</label>

          {canEditDeck && <ColorLabelDropdown card={card} onCardUpdate={onCardUpdated} />}
          {canEditDeck && <CustomCmcDropdown card={card} onCardUpdate={onCardUpdated} />}

          {!multiEdit && (
            <>
              {canEditDeck && <SetDeckImageButton card={card} flipped={flipped} />}

              {!!userId && <AddToCollectionButton card={card} onCardUpdated={onCardUpdated} />}
              {!!userId && <AddToOtherDeck card={card} />}
            </>
          )}
        </div>
      </div>

      <CardEditionsGridOverlay
        open={viewingAllPrintings}
        card={card}
        onClose={() => setViewingAllPrintings(false)}
        onCardSelected={onCardUpdated}
        nextPrevControls={nextPrevControls}
      />
    </div>
  )
}

export default CardForm
