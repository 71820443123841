import React, { useEffect, useState } from 'react'

import { CollectionV2Card } from 'redux/collectionV2/types'
import { CONDITION_OPTIONS, LANGUAGE_OPTIONS } from 'components/collection/imports/CSVV2Form/csvForm.constant'

import QuantityInput from 'components/card/cardForm/quantityInput'
import ToggleFoilButton from 'components/card/cardForm/toggleFoilButton'
import EditionOptions from 'components/card/cardForm/editionOptions'
import LabelOptions from 'components/collectionV2/cardDetails/Form/LabelOptions'
import PhatDropdown from 'components/formElements/PhatDropdown'
import PhatInput from 'components/formElements/PhatInput'
import CardEditionsGridOverlay from 'components/card/CardEditionsGridOverlay'

import { NextPrevOptions } from 'components/elements/Overlay/CardDetailsOverlay'

import styles from './form.module.scss'
import AddToOtherDeck from 'components/card/cardForm/addToOtherDeck'

type FormOnlyProps = {
  collectionCard: CollectionV2Card
  onChange: (updatedCollectionCard: CollectionV2Card) => void
  ownsCollection?: boolean
  dontAllowZero?: boolean
  nextPrevControls?: {
    previous: NextPrevOptions
    next: NextPrevOptions
  }
}

export const CardDetailsFormOnly = ({
  collectionCard,
  onChange,
  ownsCollection = false,
  dontAllowZero = false,
  nextPrevControls,
}: FormOnlyProps) => {
  const starttingRef = React.useRef<HTMLButtonElement>(null)

  const [viewingAllPrintings, setViewingAllPrintings] = useState(false)

  useEffect(() => {
    setTimeout(() => starttingRef.current?.focus(), 100)
  }, [collectionCard.card])

  return (
    <div className={styles.container}>
      <button className={styles.focusFirstButton} ref={starttingRef} />

      {ownsCollection && (
        <QuantityInput
          dontAllowZero={dontAllowZero}
          className={styles.quantityRow}
          quantity={collectionCard.quantity}
          onQuantityUpdate={quantity => onChange({ ...collectionCard, quantity })}
        />
      )}

      <div className={styles.row}>
        <EditionOptions
          label="Printing"
          card={collectionCard.card}
          onEditionChanged={card => onChange({ ...collectionCard, modifier: card.modifier, card })}
          onAllPrintingsClicked={() => setViewingAllPrintings(true)}
          className={styles.editionSelection}
        />

        <ToggleFoilButton
          card={{ ...collectionCard.card, modifier: collectionCard.modifier }}
          onCardUpdate={card =>
            onChange({
              ...collectionCard,
              modifier: card.modifier,
              card: { ...collectionCard.card, modifier: card.modifier },
            })
          }
        />
      </div>

      {ownsCollection && (
        <div className={styles.row}>
          <div className={styles.left}>
            <LabelOptions collectionCard={collectionCard} onChange={onChange} />
          </div>
          <div className={styles.right}>
            <label>Other options</label>

            <PhatDropdown
              className={styles.otherOptions}
              value={collectionCard.condition}
              options={CONDITION_OPTIONS.map(option => ({
                id: `${option.value}`,
                label: option.label,
                onClick: () => onChange({ ...collectionCard, condition: option.value }),
              }))}
            />
            <PhatDropdown
              className={styles.otherOptions}
              value={collectionCard.language}
              options={LANGUAGE_OPTIONS.map(option => ({
                id: `${option.value}`,
                label: option.label,
                onClick: () => onChange({ ...collectionCard, language: option.value }),
              }))}
            />

            <AddToOtherDeck label="Add to deck" card={collectionCard.card} />

            <label>Purchase price</label>

            <PhatInput
              type="number"
              placeholder="$1.99"
              value={collectionCard.purchasePrice || ''}
              className={styles.otherOptions}
              onBlur={purchasePrice => onChange({ ...collectionCard, purchasePrice: Number(purchasePrice) || null })}
            />
          </div>
        </div>
      )}

      <CardEditionsGridOverlay
        open={viewingAllPrintings}
        onClose={() => setViewingAllPrintings(false)}
        card={collectionCard.card}
        onCardSelected={card => onChange({ ...collectionCard, modifier: card.modifier, card })}
        nextPrevControls={nextPrevControls}
      />
    </div>
  )
}
