import React from 'react'

import styles from './lineLoader.module.scss'

type Props = {
  className?: string
}

const LineLoader = ({ className = '' }: Props) => (
  <div className={`${styles.container} ${className}`}>
    <div className={styles.bar} />
  </div>
)

export default LineLoader
