import styled from 'styled-components'

export const LargeGrid = styled.div<{ lineColor?: string }>`
  height: 100%;
  width: 100%;
  background-size: 50px 50px;
  background-image: linear-gradient(
      to right,
      ${props => (props.lineColor ? props.lineColor : '#fff')} 2px,
      transparent 1px
    ),
    linear-gradient(to bottom, ${props => (props.lineColor ? props.lineColor : '#fff')} 2px, transparent 1px);
  position: absolute;
  pointer-events: none;
`

export const SmallGrid = styled(LargeGrid)`
  background-size: 5px 5px;
  background-image: linear-gradient(
      to right,
      ${props => (props.lineColor ? props.lineColor : '#fff')} 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, ${props => (props.lineColor ? props.lineColor : '#fff')} 1px, transparent 1px);
`

export const DeckGrid = styled.div`
  height: 100%;
  width: 100%;
  opacity: 0.1;
  left: 0;
  bottom: 0;
  //mask-image: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 24%, rgba(255,255,255,0) 66%, rgba(0,0,0,1) 100%);
  mask-image: radial-gradient(rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 1) 100%);
  position: absolute;
  pointer-events: none;
`

export const PageGrid = styled(DeckGrid)`
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 24%, rgba(255, 255, 255, 0) 66%);
`

export const DiagonalGrid = styled(DeckGrid)`
  mask-image: linear-gradient(
    145deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 26%,
    rgba(255, 255, 255, 0) 70%,
    rgba(0, 0, 0, 1) 100%
  );
  pointer-events: none;
`

export const SingleDirection = styled.div<{
  height: string
  deg: string
  lineColor?: string
  position?: string
  width: string
  left?: string
  top?: string
  bottom?: string
  right?: string
}>`
  height: ${props => props.height};
  background: rgb(161, 161, 161);
  background: linear-gradient(
    ${props => props.deg}deg,
    ${props => (props.lineColor ? props.lineColor : 'rgba(161,161,161,1)')} 0%,
    rgba(255, 255, 255, 0) 100%
  );
  position: ${props => (props.position ? props.position : 'absolute')};
  margin: 0;
  width: ${props => props.width};
  transition: all 0.25s ease-in-out;
  left: ${props => props.left};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  right: ${props => props.right};
`

export const DoubleDirection = styled(SingleDirection)`
  background: linear-gradient(
    ${props => props.deg}deg,
    rgba(255, 255, 255, 0) 0%,
    ${props => (props.lineColor ? props.lineColor : 'rgba(161,161,161,1)')} 25%,
    ${props => (props.lineColor ? props.lineColor : 'rgba(161,161,161,1)')} 75%,
    rgba(255, 255, 255, 0) 100%
  );
`
