import React, { useEffect, useState } from 'react'
import Icon from 'components/elements/Icon'
import { useCookies } from 'react-cookie'

import { useAppSelector } from 'redux/hooks'

import { DeckListColors } from 'types/search'
import { CardType, FormatType, HUMAN_READABLE_FORMAT } from 'types/deck'

import RequestService from 'services/request.service'
import ToastService from 'services/toast.service'

import Link from 'components/elements/Link'
import SimpleSpinner from 'components/elements/SimpleSpinner'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import { colorsToManaString, manaStringToElement } from 'components/misc/customIcons/ManaSymbol/helpers'

import { generateDeckUrl } from 'utils/deckSeo'

import styles from './inDecks.module.scss'

type Props = {
  card: CardType
}

type CardInfo = {
  quantity: number
  cardId: string
  oracleCardId: string
  includedInDeck: boolean
}

type CardsInDecks = {
  id: number
  name: string
  deckFormat: FormatType

  colors: DeckListColors

  cards: CardInfo[]
}

type CardsInDecksResponse = {
  results: {
    id: number
    name: string
    deckFormat: FormatType

    white: number
    blue: number
    black: number
    red: number
    green: number

    quantity: number
    cardId: string
    oracleCardId: string
    includedInDeck: boolean
  }[]
}

const InDecks = ({ card }: Props) => {
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState(new Array<CardsInDecks>())

  const [exactlyMatchingCardsOnly, setExactlyMatchingCardsOnly] = useState(false)
  const [includeAllDecks, setIncludeAllDecks] = useState(false)
  const [includePackages, setIncludePackages] = useState(false)
  const [excludeMaybeboard, setExcludeMaybeboard] = useState(false)

  const [{ tbId: userId }] = useCookies(['tbId'])

  useEffect(() => {
    if (!card.cardId) return

    setResults(new Array<CardsInDecks>())
    setLoading(true)

    if (!userId) {
      setLoading(false)

      return ToastService.create(
        'You must be logged in to view decks you own with this card',
        'Collection Page',
        'warning',
      )
    }

    let url = `/api/cards/inDecks/`

    url += exactlyMatchingCardsOnly ? `?cardId=${card.cardId}` : `?oracleCardId=${card.oracleCardId}`

    if (includeAllDecks) url += `&allDecks=true`
    if (includePackages) url += `&includePackages=true`
    if (excludeMaybeboard) url += `&excludeOutOfDeckCards=true`

    RequestService.get<CardsInDecksResponse>(url)
      .then(res => {
        const cardInfoMap: Record<number, CardInfo[]> = {} // deckId -> cardInfo
        const cardsInDecks: CardsInDecks[] = []

        for (const result of res.results) {
          // AKA - if we haven't seen this deck yet
          if (!cardInfoMap[result.id])
            cardsInDecks.push({
              id: result.id,
              name: result.name,
              deckFormat: result.deckFormat,

              colors: {
                W: result.white,
                U: result.blue,
                B: result.black,
                R: result.red,
                G: result.green,
              },

              cards: [],
            })

          if (!cardInfoMap[result.id]) cardInfoMap[result.id] = []

          cardInfoMap[result.id].push({
            quantity: result.quantity,
            cardId: `${result.cardId}`,
            oracleCardId: `${result.oracleCardId}`,
            includedInDeck: result.includedInDeck,
          })
        }

        for (const cardInDecks of cardsInDecks) {
          cardInDecks.cards = cardInfoMap[cardInDecks.id]
        }

        setResults(cardsInDecks)
      })
      .catch(err => ToastService.create(err, 'Card details', 'error'))
      .finally(() => setLoading(false))
  }, [card.id, exactlyMatchingCardsOnly, includeAllDecks, includePackages, excludeMaybeboard])

  const { sumOfAllMatches, sumOfExactMatches } = results.reduce(
    (acc, cardsInDeck) => {
      for (const cardInDeck of cardsInDeck.cards) {
        acc.sumOfAllMatches += cardInDeck.quantity

        if (cardInDeck.cardId === card.cardId) acc.sumOfExactMatches += cardInDeck.quantity
      }

      return acc
    },
    { sumOfAllMatches: 0, sumOfExactMatches: 0, sumOfIncludedInDeck: 0, sumOfIncludedInDeckExact: 0 },
  )

  return (
    <>
      <div className={styles.container}>
        <div className={styles.group}>
          <div className={styles.controls}>
            <div className={styles.header}>Your decks that include {card.name}</div>
            <ArchidektDropdown
              menuClassName={styles.optionsMenu}
              options={[
                {
                  type: 'checkbox',
                  label: 'Exactly matching cards only',
                  checked: exactlyMatchingCardsOnly,
                  onChange: () => setExactlyMatchingCardsOnly(!exactlyMatchingCardsOnly),
                },
                {
                  type: 'checkbox',
                  label: 'Include theorycrafted decks',
                  checked: includeAllDecks,
                  onChange: () => setIncludeAllDecks(!includeAllDecks),
                },
                {
                  type: 'checkbox',
                  label: 'Include card packages',
                  checked: includePackages,
                  onChange: () => setIncludePackages(!includePackages),
                },
                {
                  type: 'checkbox',
                  label: 'Exclude maybeboards',
                  checked: excludeMaybeboard,
                  onChange: () => setExcludeMaybeboard(!excludeMaybeboard),
                },
              ]}
              triggerIcon="cog"
            />
          </div>

          <div className={styles.stripes}>
            {!loading &&
              results.map((cardInDecks, index) => (
                <CardInDeck key={index} cardsInDecks={cardInDecks} matchingCardId={card.cardId} />
              ))}
          </div>

          {loading && (
            <div className={styles.loader}>
              <SimpleSpinner size="xSmall" />
              Fetching containing decks...
            </div>
          )}

          {!loading && results.length === 0 && <div className={styles.noResults}>No decks found for this card</div>}
        </div>
      </div>

      {!loading && results.length > 0 && (
        <div className={styles.sumOfCards}>
          <div>
            Sum of {card.name}: {sumOfAllMatches}
          </div>
          <div>
            Sum of {card.name} ({card.setCode}) {card.collectorNumber}: {sumOfExactMatches}
          </div>
        </div>
      )}
    </>
  )
}

export default InDecks

const CardInDeck = ({ cardsInDecks, matchingCardId }: { cardsInDecks: CardsInDecks; matchingCardId: string }) => {
  const [detailsOpen, setDetailsOpen] = useState(false)

  const deckId = useAppSelector(state => state.deck.id)

  return (
    <div>
      <div className={styles.deckRow}>
        <div className={styles.leftContent}>
          <button className={styles.deckName} onClick={() => setDetailsOpen(!detailsOpen)}>
            {cardsInDecks.name}{' '}
            <Icon
              name="chevron right"
              className={`${styles.chevron} ${detailsOpen ? styles.detailsOpen : undefined}`}
            />{' '}
          </button>
          <div className={styles.deckFormat}>{HUMAN_READABLE_FORMAT[cardsInDecks.deckFormat]}</div>
        </div>

        <div className={styles.rightRight}>
          <div className={styles.colors}>{manaStringToElement(colorsToManaString(cardsInDecks.colors))}</div>
          {cardsInDecks.id !== deckId ? (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={generateDeckUrl(cardsInDecks?.id || 0, cardsInDecks?.name)}
              className={styles.deckLink}>
              View deck <Icon name="arrow right" />
            </Link>
          ) : (
            <label className={styles.currentDeck}>Current deck</label>
          )}
        </div>
      </div>

      {detailsOpen && (
        <div className={styles.details}>
          <div className={styles.matchingHeader}>Matching cards in deck</div>
          <div className={styles.cardInfo}>
            {cardsInDecks.cards.map((cardInfo, index) => (
              <div key={index} className={styles.cardInfoRow}>
                <div>Quantity: {cardInfo.quantity}</div>
                <div>Included in deck: {cardInfo.includedInDeck ? 'Yes' : 'No'}</div>
                <div>Exact edition match: {cardInfo.cardId === matchingCardId ? 'Yes' : 'No'}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
