import { PriceSources } from 'services/apiTypes/card.types'
import type { CardType } from './deck'

export type ActiveStack = string
export type ActiveCard = CardType

export type PriceSourceType = Array<number>

export const PRICE_CODES: Record<number, Partial<PriceSources>> = {
  1: 'ck',
  2: 'tcg',
  3: 'mtgo',
  4: 'cm',
  5: 'scg',
  6: 'mp',
  7: 'tcgland',
}

export const PRICE_SOURCE = {
  CK: 1,
  TCG: 2,
  MTGO: 3,
  CM: 4,
  SCG: 5,
  MP: 6,
  TCGLAND: 7,
}

export const HUMAN_PRICE_SOURCE: Record<string, number> = {
  'Card Kingdom': 1,
  TCG: 2,
  'Star City Games': 5,
  'Mana Pool': 6,
  Cardhoarder: 3,
  Cardmarket: 4,
  'TCG Land': 7,
}

export const PRICE_ICONS: Record<string, string> = {
  'Card Kingdom': 'cardKingdom',
  TCG: 'tcg',
  Cardhoarder: 'cardhoarder',
  Cardmarket: 'cardmarket',
  'Star City Games': 'scg',
  'Mana Pool': 'manapool',
  'TCG Land': 'tcgLand',
}

export const PRICE_ICON_NAMES: Record<number, string> = {
  '1': 'cardKingdom',
  '2': 'tcg',
  '3': 'cardhoarder',
  '4': 'cardmarket',
  '5': 'scg-simple',
  '6': 'manapool',
  '7': 'tcgland',
}

export const PRICE_COLORS: Record<number, string> = {
  '1': 'rgb(66, 134, 244)',
  '2': '#3ac692',
  '3': 'rgb(168, 127, 80)',
  '4': '#6262de',
  '5': '#DC181E',
  '6': '#3061ff',
  '7': '#536de6',
}

export const PRICE_NAMES: Record<string, string> = {
  '1': 'Card Kingdom ($)',
  '2': 'TCGplayer ($)',
  '3': 'Cardhoarder (tix)',
  '4': 'Cardmarket (€)',
  '5': 'Star City Games ($)',
  '6': 'Mana Pool ($)',
  '7': 'TCG Land (MXN)',
}

export type DeckResultType = {
  results: Array<any>
  next: string
  loading: boolean
}

export const MIN_LEFT_PANEL_WIDTH = 320
export const CARD_WIDTH = 270
export const MIN_DIMMER_WIDTH = 125

export const DRAG_SCROLL_PAGES_REGEX = /^\/decks\/\S+|\/folders\/\d+/g

export const THEME = {
  LIGHT: `0`,
  DARK: `1`,
}

export const AGE = {
  DAY: 86400,
  WEEK: 604800,
  MONTH: 2592000,
}
