import { createSelector } from 'reselect'

import { RootState } from 'redux/reducers'

export const selectCollectionCards = createSelector(
  (state: RootState) => state.collectionV2.recentlyAdded,
  (state: RootState) => state.collectionV2.serverCollectionData,
  (recentlyAdded, serverCollectionData) => {
    return [...recentlyAdded, ...serverCollectionData]
  },
)

export const selectActiveCard = createSelector(
  (state: RootState) => state.collectionV2.activeCardId,
  (state: RootState) => state.collectionV2.collectionCards,
  (activeCardId, collectionCards) => {
    if (!activeCardId) return null

    return collectionCards[activeCardId] || null
  },
)

export const selectNextPreviousCardIds = createSelector(
  (state: RootState) => state.collectionV2.activeCardId,
  (state: RootState) => state.collectionV2.collectionCards,
  (state: RootState) => state.collectionV2.recentlyAdded,
  (state: RootState) => state.collectionV2.serverCollectionData,
  (
    activeCardId,
    collectionCards,
    recentlyAdded,
    serverCollectionData,
  ): [null | number, null | number, string, string] => {
    if (!activeCardId) return [null, null, '', '']

    const mergedIds = [...recentlyAdded, ...serverCollectionData]
    const activeCardIndex = mergedIds.findIndex(index => index === activeCardId)

    if (activeCardIndex === -1) return [null, null, '', '']

    const nextCardId = mergedIds[activeCardIndex + 1] || null
    const previousCardId = mergedIds[activeCardIndex - 1] || null

    const nextCardName = nextCardId ? collectionCards[nextCardId]?.card.name : ''
    const previousCardName = previousCardId ? collectionCards[previousCardId]?.card.name : ''

    return [nextCardId, previousCardId, nextCardName, previousCardName]
  },
)
