import React from 'react'
import Icon from 'components/elements/Icon'
import type { SemanticICONS } from 'semantic-ui-react'

import { useWindowSize } from 'hooks/resize'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'

import styles from './tabButtons.module.scss'

type Option = {
  label: React.ReactNode
  icon?: SemanticICONS
  selected?: boolean
  onClick: (e?: React.MouseEvent) => void
  hidden?: boolean
  disabled?: boolean
  className?: string
  title?: string
}

type Props = {
  className?: string
  optionsClassName?: string
  noHideOverflow?: boolean
} & ({ children: React.ReactNode; options?: never } | { options?: Option[]; children?: never })

const TabContainer = ({
  className = '',
  optionsClassName = '',
  children = null,
  noHideOverflow = false,
  options = [],
}: Props) => {
  const [width] = useWindowSize()

  let visualOptions = options
  let overflowOptions = new Array<Option>()

  if (options.length > 5 && !noHideOverflow) {
    visualOptions = options.filter((_o, i) => i < 5)
    overflowOptions = options.filter((_o, i) => i >= 5)
  }

  if (options.length > 3 && width < 1000 && !noHideOverflow) {
    const activeOption = options.find(o => o.selected) || options[0]

    const firstOption = options[0]
    const secondOption = activeOption?.label === firstOption.label ? options[1] : activeOption

    visualOptions = [firstOption, secondOption]

    const seenIds = visualOptions.map(o => o.label)

    overflowOptions = options.filter(o => !seenIds.includes(o.label))
  }

  return (
    <div className={`${styles.container} ${className}`}>
      {children}
      {visualOptions.map((option, index) => (
        <TabButton
          disabled={option.disabled}
          key={index}
          selected={option.selected}
          onClick={option.onClick}
          icon={option.icon}
          hidden={option.hidden}
          title={option.title}
          className={`${option.className} ${optionsClassName}`}>
          {option.label}
        </TabButton>
      ))}
      {overflowOptions.length > 0 && (
        <ArchidektDropdown options={overflowOptions} menuClassName={styles.overflowOptionsMenu}>
          <TabButton onClick={() => null} className={`${styles.overflowOptionsTrigger} ${optionsClassName}`}>
            More options <Icon name="chevron circle down" />
          </TabButton>
        </ArchidektDropdown>
      )}
    </div>
  )
}

export default TabContainer

type ButtonProps = {
  selected?: boolean
  children?: React.ReactNode
  onClick: (e?: React.MouseEvent) => void
  className?: string
  icon?: SemanticICONS
  disabled?: boolean
  hidden?: boolean
  title?: string
}

export const TabButton = ({
  className = '',
  selected = false,
  children = null,
  onClick,
  icon,
  disabled,
  hidden,
  title,
}: ButtonProps) => {
  if (hidden) return null

  return (
    <button
      title={title}
      disabled={disabled}
      className={`${styles.button} ${selected ? styles.selected : ''} ${className}`}
      onClick={onClick}>
      {icon && <Icon name={icon} />}
      {children}
    </button>
  )
}
