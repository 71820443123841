import React from 'react'
import Icon from 'components/elements/Icon'

import { EDH_FORMATS } from 'types/deck'
import { generateTabState, Tab, TabType } from 'types/searchV2'

import { useAppSelector } from 'redux/hooks'

import TabContainer from 'components/elements/TabButtons'
import ManaFrontIcon from 'components/misc/customIcons/ManaFont'

import LocalStorageService from 'services/localStorage.service'

import styles from './tabTypeSelector.module.scss'

type Props = {
  currentTabId?: string | null // Update tab if Id provied; otherwise makes a new tab
  selected?: Tab
  className?: string
  onSelect: (updatedTab: Tab) => void
  totalTabs: number
  onViewAllTabs: () => void
}

const TabTypeSelector = ({ currentTabId, onSelect, selected, totalTabs, onViewAllTabs, className = '' }: Props) => {
  const format = useAppSelector(state => state.deck.format)

  const handleTabTypeChange = (type: TabType) => {
    if (!currentTabId) return

    if (type === 'archidektSearch' || type === 'scrySearch') LocalStorageService.set('cachedSearchType', type)
    if (type === 'archidektRecs') onSelect({ ...generateTabState.archidektRecs(currentTabId) })
    if (type === 'edhRecs') onSelect({ ...generateTabState.edhRecs(currentTabId) })
    if (type === 'manabaseGenerator') onSelect({ ...generateTabState.manabaseGenerator(currentTabId) })
    if (type === 'spellbookCombo') onSelect({ ...generateTabState.spellbookCombo(currentTabId) })

    // Fancy code to copy over primary input between tabs
    if (type === 'scrySearch') {
      const newTab = { ...generateTabState.scrySearch(currentTabId) }

      // @ts-expect-error - If we're going from archidekt search to scry search, copy primary input to new tab
      if (selected?.type === 'archidektSearch') newTab.meta.query = selected.meta.names

      onSelect(newTab)
    }

    if (type === 'archidektSearch') {
      const newTab = { ...generateTabState.archidektSearch(currentTabId) }

      // @ts-expect-error - If we're going from scry search to archidekt search, copy primary input to new tab
      if (selected?.type === 'scrySearch') newTab.meta.names = selected.meta.query

      onSelect(newTab)
    }
  }

  const isEdh = EDH_FORMATS.includes(format)

  return (
    <TabContainer
      className={styles.tabContainer}
      optionsClassName={styles.tabButton}
      options={[
        {
          label: (
            <>
              <Icon name="search" />
              Archidekt <span className={styles.hideOnMobile}>search</span>
            </>
          ),
          selected: selected?.type === 'archidektSearch',
          onClick: () => handleTabTypeChange('archidektSearch'),
          className: selected?.type === 'archidektSearch' ? styles.activeTab : '',
        },
        {
          label: (
            <>
              <Icon name="code" />
              Syntax <span className={styles.hideOnMobile}>search</span>
            </>
          ),
          selected: selected?.type === 'scrySearch',
          onClick: () => handleTabTypeChange('scrySearch'),
          className: selected?.type === 'scrySearch' ? styles.activeTab : '',
        },
        {
          label: (
            <>
              <img alt="EDHREC" src="/images/edhrec.svg" height={15} className={styles.edhrecLogo} />
              EDH Recs
            </>
          ),
          hidden: !isEdh,
          selected: selected?.type === 'edhRecs',
          onClick: () => handleTabTypeChange('edhRecs'),
          className: selected?.type === 'edhRecs' ? styles.activeTab : '',
        },
        {
          label: (
            <>
              <ManaFrontIcon icon="c" />
              Landbase
            </>
          ),
          hidden: !isEdh,
          selected: selected?.type === 'manabaseGenerator',
          onClick: () => handleTabTypeChange('manabaseGenerator'),
          className: selected?.type === 'manabaseGenerator' ? styles.activeTab : '',
        },
        {
          label: (
            <label className={styles.customIconContainer}>
              <img
                alt="Commander Spellbook"
                src="/images/spellbook_simple.svg"
                height={15}
                className={styles.spellbookLogo}
              />
              Combos
            </label>
          ),
          hidden: !isEdh,
          selected: selected?.type === 'spellbookCombo',
          onClick: () => handleTabTypeChange('spellbookCombo'),
          className: selected?.type === 'spellbookCombo' ? styles.activeTab : '',
        },
      ]}
    />
  )
}

export default TabTypeSelector
