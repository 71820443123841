import { HUMAN_GAME } from 'types/deck'
import { LANGUAGE_FOREIGN, CONDITION_HUMAN, CONDITION_CODE, LANGUAGE_CODE } from 'types/collection'
import { invert } from 'lodash'

export type ColumnType =
  | 'quantity'
  | 'oracleCard__name'
  | 'edition__editionname'
  | 'edition__editioncode'
  | 'modifier'
  | 'condition'
  | 'language'
  | 'uid'
  | 'multiversid'
  | 'collectorNumber'
  | 'ignore'
  | 'tags'
  | 'purchasePrice'

// This seemingly random order has been chosen to match that of the export options in the archidekt CSV collection export. This way, archidekt users can just spam the (+) button and remove the columns the didn't export. Works pretty well
export const columnOptions: { value: ColumnType; label: React.ReactNode }[] = [
  { value: 'quantity', label: 'Quantity' },
  { value: 'oracleCard__name', label: 'Card Name' },
  { value: 'modifier', label: 'Foil/Variant' },
  { value: 'condition', label: 'Condition' },
  { value: 'ignore', label: 'Ignore' },
  { value: 'language', label: 'Language' },
  { value: 'purchasePrice', label: 'Purchase Price' },
  { value: 'tags', label: 'Labels (CSV)' },
  { value: 'edition__editionname', label: 'Set Name' },
  { value: 'edition__editioncode', label: 'Set Code' },
  { value: 'multiversid', label: 'Multiverse Id' },
  { value: 'uid', label: 'Scryfall Id' },
  { value: 'collectorNumber', label: 'Collector Number' },
]

export const HEADER_TO_EXAMPLE: Record<ColumnType, string> = {
  'quantity': '2',
  'oracleCard__name': 'Sol Ring',
  'edition__editionname': 'Commander 2013',
  'edition__editioncode': 'C13',
  'modifier': 'Normal',
  'condition': 'NM',
  'language': 'EN',
  'uid': '1b59533a-3e38-495d-873e-2f89fbd08494',
  'multiversid': '376508',
  'collectorNumber': '120s',
  'ignore': 'Skip me',
  'tags': '"Tag1, Tag2, Tag3"',
  'purchasePrice': '5.24',
}

const INVERTED_LANG: Record<number, string> = invert(LANGUAGE_CODE)
const INVERTED_CONDITION: Record<number, string> = invert(CONDITION_CODE)

export const LANGUAGE_OPTIONS = Object.keys(LANGUAGE_FOREIGN).map(key => ({
  label: `${key} (${INVERTED_LANG[LANGUAGE_FOREIGN[key]]})`,
  value: LANGUAGE_FOREIGN[key],
}))

export const CONDITION_OPTIONS = Object.keys(CONDITION_HUMAN).map(key => ({
  label: `${key} (${INVERTED_CONDITION[CONDITION_HUMAN[key]]})`,
  value: CONDITION_HUMAN[key],
}))

export const AMBIGUOUS_OPTIONS = [
  { label: 'Skip import', value: true },
  { label: 'Import with "Unsure" tag', value: false },
]

export const GAME_OPTIONS = Object.keys(HUMAN_GAME).map(key => ({ label: key, value: HUMAN_GAME[key] }))

export type PredefinedColumn =
  | 'moxfield'
  | 'cardsphere'
  | 'helavault'
  | 'deckbox'
  | 'delverLense'
  | 'manaBox'
  | 'manaBoxThicc'
  | 'dragonshield'
  | 'lionsEye'
// | 'archidekt'

export const PREDEFINED_COLUMNS: Record<PredefinedColumn, ColumnType[]> = {
  // prettier-ignore
  // archidekt: ['quantity', 'oracleCard__name', 'modifier', 'condition', 'language', 'ignore', 'tags', 'edition__editionname', 'edition__editioncode', 'multiversid', 'collectorNumber', 'uid', 'ignore'],

  // prettier-ignore
  moxfield: ['quantity', 'ignore', 'oracleCard__name', 'edition__editioncode', 'condition', 'language', 'modifier', 'ignore', 'ignore', 'collectorNumber', 'ignore', 'ignore', 'ignore'],

  // prettier-ignore
  cardsphere: ['quantity', 'ignore', 'oracleCard__name', 'edition__editionname', 'condition', 'language', 'modifier', 'ignore', 'ignore'],

  // prettier-ignore
  helavault: ['collectorNumber','ignore','ignore','language','oracleCard__name','ignore','quantity','ignore','uid','edition__editioncode','edition__editionname'],

  // prettier-ignore
  deckbox: ['quantity','ignore','oracleCard__name','edition__editionname','edition__editioncode','collectorNumber','condition','language','modifier','ignore','ignore','ignore','ignore','ignore','ignore','ignore','ignore','tags','ignore'],

  // prettier-ignore
  delverLense: ['quantity', 'oracleCard__name', 'edition__editionname','modifier','collectorNumber'],

  // prettier-ignore
  manaBox: ['oracleCard__name','edition__editioncode','edition__editionname','collectorNumber','modifier','ignore','quantity','ignore','uid','ignore','ignore','ignore','condition','language','ignore'],

  // prettier-ignore
  manaBoxThicc: ['oracleCard__name', 'ignore', 'ignore', 'edition__editioncode','edition__editionname','collectorNumber','modifier','ignore','quantity','ignore','uid','ignore','ignore','ignore','condition','language','ignore'],

  // prettier-ignore
  dragonshield: ['quantity','oracleCard__name','collectorNumber','edition__editioncode','edition__editionname','ignore','modifier','condition','language','ignore','ignore','ignore'],

  // prettier-ignore
  lionsEye: ['oracleCard__name', 'edition__editioncode', 'collectorNumber', 'language', 'quantity', 'ignore', 'ignore', 'uid', 'ignore', 'ignore', 'ignore', 'ignore', 'ignore', 'ignore', 'ignore', 'ignore', 'ignore', 'ignore', 'condition', 'ignore', 'ignore', 'ignore', 'tags'],
}

export const PREDEFINED_COLUMN_OPTIONS: { label: string; value: PredefinedColumn }[] = [
  // { label: 'Archidekt (all)', value: 'archidekt' },
  { label: 'Cardsphere', value: 'cardsphere' },
  { label: 'Deckbox', value: 'deckbox' },
  { label: 'Delver Lens', value: 'delverLense' },
  { label: 'Dragonshield', value: 'dragonshield' },
  { label: 'Helvault', value: 'helavault' },
  // { label: "Lion's Eye", value: 'lionsEye' },
  { label: 'ManaBox - Single folder', value: 'manaBox' },
  { label: 'ManaBox - Whole collection', value: 'manaBoxThicc' },
  { label: 'Moxfield', value: 'moxfield' },
]

export const PREDEFINED_DESCRIPTIONS: Record<any, string> = {
  'manaBox':
    'This predefined import is only for exporting a single folder from ManaBox. If you want to export your whole collection, use the "ManaBox - Whole collection" option.',
  'manaBoxThicc':
    'This predefined import is only for exporting your whole collection from ManaBox. If you want to export a single folder, use the "ManaBox - Single folder" option.',
}

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)

  result.splice(endIndex, 0, removed)

  return result
}

export const CHUNK_SIZE = 2000
