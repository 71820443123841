import React from 'react'
import { useCookies } from 'react-cookie'

import { getDeckSearchOrderingQueryParameter } from 'services/accountSettings.service'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'

import styles from './deckButton.module.scss'

type Props = {
  className?: string
}

const DecksButton = ({ className }: Props) => {
  const [{ tbUser: username, tbRootFolder: rootFolder }] = useCookies(['tbUser', 'tbRootFolder'])

  const allDecks = `/search/decks?${getDeckSearchOrderingQueryParameter()}`
  const myDecks = `/search/decks?ownerUsername=${username}&${getDeckSearchOrderingQueryParameter()}`
  const myFolder = `/folders/${rootFolder}`
  const sharedWithMe = `/search/decks?shared=true&${getDeckSearchOrderingQueryParameter()}`
  const bookmarkedDecks = `/search/decks?bookmarks=true&${getDeckSearchOrderingQueryParameter()}`

  return (
    <ArchidektDropdown
      menuClassName={styles.menu}
      options={[
        { hidden: !username, label: 'My decks', icon: 'user', to: myDecks },
        { hidden: !username, label: 'My folders', icon: 'folder outline', to: myFolder },
        { hidden: !username, type: 'spacer' },
        { label: 'Search all decks', icon: 'search', to: allDecks },
        { label: 'All card packages', icon: 'cube', to: `/card-packages` },
        { label: 'Commander precons', icon: 'gift', to: '/commander-precons' },
        { hidden: !username, type: 'spacer' },
        {
          hidden: !username,
          label: 'Shared with me',
          icon: 'group',
          to: sharedWithMe,
          ariaLabel: 'Decks shared with me',
        },
        {
          hidden: !username,
          label: 'Bookmarked decks',
          icon: 'bookmark outline',
          to: bookmarkedDecks,
        },
        { type: 'spacer' },
        { label: 'New deck', icon: 'add', to: '/new-deck', hidden: !username },
        { label: 'Deck sandbox', icon: 'flask', to: '/sandbox' },
        { label: 'Compare decks', icon: 'exchange', to: '/compare' },
      ]}>
      <button className={`${styles.trigger} ${className}`}>Decks</button>
    </ArchidektDropdown>
  )
}

export default DecksButton
