import defaultUpdate, { extend } from 'immutability-helper'
import { CustomCommands, Spec } from 'immutability-helper'
import { CollectionCardResponseType } from 'services/apiTypes/collection.types'

type ExtendedCommands = {
  $removeCardWithId: number
}

/**
 * Typescript does include the extended commands in the library's update function, so this is an update function with
 * the extended commands' types built in.
 */
export default function update<T>(object: T, spec: Spec<T, CustomCommands<ExtendedCommands>>) {
  return defaultUpdate(object, spec)
}

export const createAdditionalImmutableCommands = () => {
  /**
   * This custom immmutability-helper command is currently used by the Collection reducer
   */
  extend(
    '$removeCardWithId',
    (id: number, original: Array<CollectionCardResponseType>): Array<CollectionCardResponseType> => {
      let foundIndex = -1
      for (let i = 0; i < original.length; i++) {
        if (original[i].id === id) {
          foundIndex = i
          break
        }
      }

      if (foundIndex > -1) return update(original, { $splice: [[foundIndex, 1]] })

      return original
    },
  )
}
