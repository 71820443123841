import { CardType } from './deck'

export const DOUBLE_FACED_LAYOUTS = [
  'transform',
  'modal_dfc',
  'double_faced_token',
  'double_sided',
  'art_series',
  'battle',
]

export type RarityOptionType = 'Common' | 'Uncommon' | 'Rare' | 'Mythic' | 'Special'
export const RARITY_WEIGHTS: Record<RarityOptionType, number> = {
  Common: 1,
  Uncommon: 2,
  Rare: 3,
  Mythic: 4,
  Special: 5,
}

export const isCardRotateable = (card: CardType): boolean => {
  if (card.back?.text.includes('Aftermath')) return false // Excption for aftermath cards since they'd need to rotate the other way

  if (card.types.includes('Battle')) return true
  if (card.types.includes('Plane')) return true
  if (card.types.includes('Phenomenon')) return true
  if (card.layout === 'split') return true

  return false
}
