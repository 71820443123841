import React from 'react'

import { CardType, CategoryType, defaultCard } from 'types/deck'

import CardDetailsOverlay from 'components/elements/Overlay/CardDetailsOverlay'

import CategoryForm from 'components/deckPage/categories/Form'

import styles from './creatingView.module.scss'

type Props = {
  open: boolean
  onCanceled: () => void
  onCreated: (name: CategoryType) => void
  defaultValue?: CategoryType
  templateForm?: boolean
  card?: CardType
}

const CreatingView: React.FC<Props> = ({ open, defaultValue, onCreated, onCanceled, templateForm, card }: Props) => {
  return (
    <CardDetailsOverlay
      rightControls={null}
      activeTabId="category-edit"
      card={card || defaultCard}
      open={open}
      onClose={onCanceled}
      tabs={[
        {
          id: 'category-edit',
          label: `${defaultValue?.id ? 'Edit' : 'Create'} Category`,
          body: (
            <div className={styles.content}>
              <CategoryForm
                category={defaultValue}
                onChange={onCreated}
                isTemplate={templateForm}
                optionsContainerClassName={styles.options}
              />
            </div>
          ),
        },
      ]}
      onTabChange={() => null}
    />
  )
}

export default CreatingView
