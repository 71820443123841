import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import Icon from 'components/elements/Icon'
import shortid from 'shortid'

import FadeInOut from 'components/transitions/FadeInOut'
import DropIn from 'components/transitions/DropIn'

import { basicKeybindSkips } from 'components/deckPage/modals/KeybindsOverlay'

import styles from './modal.module.scss'

type Props = {
  trigger?: React.ReactNode
  open?: boolean
  onOpen?: () => void
  onClose?: () => void
  children?: React.ReactNode
  modalClassName?: string
  triggerClassName?: string
  closeButton?: boolean
  usedInOverlay?: boolean
}

const Modal = ({
  trigger,
  open = false,
  onOpen,
  onClose,
  children = null,
  modalClassName = '',
  triggerClassName = '',
  closeButton = false,
  usedInOverlay = false,
}: Props) => {
  const [internalOpen, setInternalOpen] = useState(open)

  const [onClient, setOnClient] = useState(false)
  const modalRef = useRef<HTMLDivElement>(typeof document === 'undefined' ? null : document.createElement('div'))
  const openRef = useRef(internalOpen)
  const keylistener = useRef<(e: KeyboardEvent) => void>(() => null)

  useEffect(() => {
    setOnClient(true)
    if (!modalRef.current) return

    modalRef.current.id = `archidekt-modal-${shortid.generate()}`

    document.body.appendChild(modalRef.current)

    return () => {
      modalRef.current && document.body.removeChild(modalRef.current)
    }
  }, [])

  useEffect(() => {
    setInternalOpen(open)
  }, [open])

  useEffect(() => {
    if (internalOpen && onOpen) onOpen()

    window.removeEventListener('keydown', keylistener.current)

    keylistener.current = (e: KeyboardEvent) => {
      if (basicKeybindSkips(e)) return

      if (e.key === 'Escape') handleClose(false)
    }

    window.addEventListener('keydown', keylistener.current)

    if (internalOpen) document.body.classList.add('no-scrolling')
    else if (!usedInOverlay) document.body.classList.remove('no-scrolling')

    openRef.current = internalOpen

    setTimeout(() => setInternalOpen(internalOpen), 200)

    return () => {
      if (!usedInOverlay) document.body.classList.remove('no-scrolling')

      window.removeEventListener('keydown', keylistener.current)
    }
  }, [internalOpen])

  const handleClose = (openState = false) => {
    if (onClose) onClose()

    setInternalOpen(openState)
  }

  const handleWeirdClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target !== e.currentTarget) return

    handleClose(false)
  }

  return (
    <>
      {trigger && (
        <button className={triggerClassName} onClick={() => setInternalOpen(true)}>
          {trigger}
        </button>
      )}
      {onClient &&
        internalOpen &&
        ReactDOM.createPortal(
          <FadeInOut unmountOnClosed show={internalOpen} className={styles.container} onClick={handleWeirdClose}>
            <DropIn show className={`${styles.modal} ${modalClassName}`}>
              {closeButton && (
                <button className={styles.closeButton} onClick={() => handleClose(false)}>
                  <Icon name="close" />
                </button>
              )}
              {children}
            </DropIn>
          </FadeInOut>,
          modalRef.current,
        )}
    </>
  )
}
export default Modal
