import React from 'react'

import { useCanEditDeck } from 'redux/deck/selectors'

import { CardType, defaultColorLabel } from 'types/deck'
import { GlobalColorTag } from 'services/apiTypes/colorTag.types'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import SimpleSpinner from 'components/elements/SimpleSpinner'

import useDeckColorTagOptions from 'hooks/useDeckColorTagOptions'
import { getContrastingColor } from 'utils/calculateContrast'

import styles from './cornerQuantity.module.scss'

type Props = {
  card: CardType
  onCardUpdate: (updatedCard: CardType) => void
  loader?: boolean
}

const CornerQuantity = ({ card, onCardUpdate, loader = false }: Props) => {
  const canEditDeck = useCanEditDeck()

  const handleUpdateCard = (selectedTag: GlobalColorTag) => onCardUpdate({ ...card, colorLabel: selectedTag })
  const [options, onFocus] = useDeckColorTagOptions(handleUpdateCard)

  const calculatedTextColor = getContrastingColor(card.colorLabel.color)

  return (
    <ArchidektDropdown
      triggerClassName={styles.container}
      menuClassName={styles.menu}
      disabled={!canEditDeck}
      onFocus={onFocus}
      options={options}>
      <button
        disabled={!canEditDeck}
        className={styles.cornerQuantity}
        style={{ color: calculatedTextColor, backgroundColor: card.colorLabel.color || defaultColorLabel.color }}>
        {!loader && card.qty}
        {loader && <SimpleSpinner size="xSmall" />}
      </button>
    </ArchidektDropdown>
  )
}

export default CornerQuantity
