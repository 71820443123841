import { store } from 'redux/store'
import ToastService from 'services/toast.service'
import { CardType } from 'types/deck'
import { deckToString } from './DeckToString'

export function copyCardNamesToClipboard(cards: CardType[], withQuantity?: boolean) {
  let copyString = ''

  for (const card of cards) {
    if (withQuantity) copyString += `${card.qty} `
    copyString += `${card.name}\n`
  }

  navigator.clipboard.writeText(copyString)

  if (withQuantity) ToastService.create(`Copied cards to clipboard (with quantities)`, 'Clipboard Helper', 'success')
  else ToastService.create(`Copied cards to clipboard`, 'Clipboard Helper', 'success')
}

export function copyArchidektSyntaxToClipboard(cards: CardType[]) {
  const categories = store.getState().deck.categories
  const copyString = deckToString(cards, categories)

  navigator.clipboard.writeText(copyString)

  ToastService.create(`Copied cards to clipboard (Archidekt Syntax)`, 'Clipboard Helper', 'success')
}
