import React from 'react'
import { Icon as SemanticIcon, IconProps, SemanticICONS } from 'semantic-ui-react'

export type IconTypes = SemanticICONS

const Icon = (props: IconProps) => {
  return <SemanticIcon {...props} />
}

export default Icon
