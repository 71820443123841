import React, { useState, useEffect } from 'react'

import { useAppSelector } from 'redux/hooks'

import Select, { Options } from 'components/formElements/SemanticSelect'

const options: Options[] = [
  { value: 'Artifact', text: 'Artifact' },
  { value: 'Conspiracy', text: 'Conspiracy' },
  { value: 'Creature', text: 'Creature' },
  { value: 'Enchantment', text: 'Enchantment' },
  { value: 'Instant', text: 'Instant' },
  { value: 'Land', text: 'Land' },
  { value: 'Phenomenon', text: 'Phenomenon' },
  { value: 'Plane', text: 'Plane' },
  { value: 'Planeswalker', text: 'Planeswalker' },
  { value: 'Scheme', text: 'Scheme' },
  { value: 'Sorcery', text: 'Sorcery' },
  { value: 'Vanguard', text: 'Vanguard' },
  { value: 'Tribal', text: 'Tribal' },
  { value: 'Battle', text: 'Battle' },
]

type Props = {
  onChange: (values: string[]) => void
  value?: string | string[]
}

const TypeDropdown = ({ value, onChange }: Props) => {
  const isMobile = useAppSelector(state => state.active.isMobile)

  const [selectedOptions, setSelectedOptions] = useState(value ?? new Array<string>())

  // Used to reset the form
  useEffect(() => {
    if (!value || !value?.length) setSelectedOptions([])
  }, [value])

  const handleChange = (selectedOptions: string[]) => {
    setSelectedOptions(selectedOptions)
    onChange(selectedOptions)
  }

  return (
    <Select
      search={!isMobile}
      placeholder="Type (eg: Creature)"
      onChange={handleChange}
      options={options}
      value={selectedOptions}
      multiple
    />
  )
}

export default TypeDropdown
