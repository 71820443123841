import React, { useEffect, useState } from 'react'

import CardService from 'services/card.service'
import ToastService from 'services/toast.service'

import { CardType } from 'types/deck'
import { Ruling } from 'services/apiTypes/card.types'

import LineLoader from 'components/elements/LineLoader'

import styles from './rulings.module.scss'

type Props = {
  card: CardType
}

const Rulings = ({ card }: Props) => {
  const [loading, setLoading] = useState(true)
  const [rulings, setRulings] = useState(new Array<Ruling>())

  useEffect(() => {
    if (!card.uid) return

    setLoading(true)
    setRulings(new Array<Ruling>())

    CardService.rulings(card.uid)
      .then(setRulings)
      .catch(() => ToastService.create('Unable to fetch rulings', 'Card Service', 'error'))
      .finally(() => setTimeout(() => setLoading(false), 750)) // Make the request take a little longer for the loader to show
  }, [card])

  return (
    <div className={styles.content}>
      {loading && <LineLoader />}

      {!loading &&
        rulings.map((ruling, index) => (
          <div className={styles.ruling}>
            <div className={styles.comment} key={index}>
              {ruling.comment}
            </div>
            <div className={styles.date}>{ruling.published_at}</div>
          </div>
        ))}

      {!loading && rulings.length === 0 && <div className={styles.noRulings}>No rulings found</div>}
    </div>
  )
}

export default Rulings
