import { useEffect, useState } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'

import { allowLiveStreamAd } from 'environment'

import Icon from 'components/elements/Icon'

import styles from './streamAd.module.scss'

type Props = {}

const StreamAd: React.FC<Props> = () => {
  const [isClient, setIsClient] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [closeVisible, setCloseVisible] = useState(false)
  const [liveStreamTimeBlock] = useState(allowLiveStreamAd())

  const router = useRouter()

  const unique_id = new Date().getTime()

  useEffect(() => {
    setIsClient(true)
    setTimeout(() => setCloseVisible(true), 3000)
  }, [])

  if (!liveStreamTimeBlock) return null
  if (!isClient || !isOpen) return null

  return (
    <div key={router.asPath} className={styles.container}>
      <div className={styles.innerContainer}>
        <div
          // @ts-ignore
          asterilivestream_height={window.innerWidth <= 600 ? 90 : 250}
          asterilivestream_profile="106_86"
          asterilivestream_width={window.innerWidth <= 600 ? 160 : 300}
        />
        <Script
          id={`${unique_id}`}
          async
          src={`https://asteriresearch.com/livestream-latest.min.js?v=${unique_id}`}
          type="text/javascript"
        />
        {closeVisible && (
          <button className={styles.closeButton} onClick={() => setIsOpen(false)}>
            <Icon name="close" />
          </button>
        )}
      </div>
    </div>
  )
}

export default StreamAd
