import * as Active from 'redux/active/actions/types'
import { SetActiveStateAction } from 'redux/active/actions/actionless'

import { setLocalPriceSources } from 'services/accountSettings.service'

import { CardType } from 'types/deck'

export const setActiveCard = (payload: CardType): SetActiveCard => ({
  type: Active.SET_ACTIVE_CARD,
  payload,
})

export const toggleModalOn = (): ToggleModalOn => ({
  type: Active.TOGGLE_MODAL_ON,
  payload: true,
})

export const toggleModalOff = (): ToggleModalOff => ({
  type: Active.TOGGLE_MODAL_OFF,
  payload: false,
})

export const toggleTextOn = (): ToggleTextOn => ({
  type: Active.TOGGLE_TEXT_ON,
  payload: true,
})

export const toggleTextOff = (): ToggleTextOff => ({
  type: Active.TOGGLE_TEXT_OFF,
  payload: false,
})

export const setRightPanelView = (payload: boolean): SetRightPanelView => ({
  type: Active.SET_RIGHT_PANEL_VIEW,
  payload,
})

export const setLeftPanelView = (payload: boolean): SetLeftPanelView => ({
  type: Active.SET_LEFT_PANEL_VIEW,
  payload,
})

export const setDetailsTab = (position: string, index: number): SetDetailsTab => ({
  type: Active.SET_DETAILS_TAB,
  payload: { position, index },
})

export const toggleModal = (modalName: string, flag: any): ToggleModal => ({
  type: Active.TOGGLE_MODAL,
  payload: { modalName, flag },
})

export const pageChange = (payload: string): PageChangeAction => ({
  type: Active.PAGE_CHANGE,
  payload,
})

export const setPriceSourceAction = (priceSource: Active.PriceSourceType): SetActiveStateAction => {
  setLocalPriceSources(priceSource)

  return {
    type: Active.SET_ACTIVE_STATE,
    payload: { priceSource },
  }
}

export const loadHomeWheels = (): LoadHomeWheels => ({
  type: Active.LOAD_HOME_WHEELS,
  payload: undefined as never,
})

export const setHighlightCards = (payload: string[]): SetHighlightCards => ({
  type: Active.SET_HIGHLIGHT_CARDS,
  payload,
})

export const setActivePreferences = (
  priceSource: number,
  keybinds: number,
  width: number,
  locking: number,
  theme: number,
): SetActivePreferences => ({
  type: Active.SET_ACTIVE_PREFERENCES,
  payload: { priceSource, keybinds, width, locking, theme },
})

export const setTheme = (payload: number): SetTheme => ({
  type: Active.SET_THEME,
  payload,
})

export const setModalOverlay = (type: string | null, props?: any): SetModalOverlay => ({
  type: Active.SET_MODAL_OVERLAY,
  payload: { type, props },
})

export interface SetActiveCard {
  type: typeof Active.SET_ACTIVE_CARD
  payload: CardType
}

export interface ToggleModalOn {
  type: typeof Active.TOGGLE_MODAL_ON
  payload: true
}

export interface ToggleModalOff {
  type: typeof Active.TOGGLE_MODAL_OFF
  payload: false
}

export interface ToggleTextOn {
  type: typeof Active.TOGGLE_TEXT_ON
  payload: true
}

export interface ToggleTextOff {
  type: typeof Active.TOGGLE_TEXT_OFF
  payload: false
}

export interface SetRightPanelView {
  type: typeof Active.SET_RIGHT_PANEL_VIEW
  payload: boolean
}

export interface SetLeftPanelView {
  type: typeof Active.SET_LEFT_PANEL_VIEW
  payload: boolean
}

export interface SetDetailsTab {
  type: typeof Active.SET_DETAILS_TAB
  payload: {
    position: string
    index: number
  }
}

export interface SetMobileAction {
  type: typeof Active.SET_MOBILE
  payload: {
    target: string
    flag: boolean
  }
}

export interface ToggleNoFocus {
  type: typeof Active.TOGGLE_NO_FOCUS
  payload: boolean
}

export interface ToggleModal {
  type: typeof Active.TOGGLE_MODAL
  payload: {
    modalName: string
    flag: any
  }
}

export interface PageChangeAction {
  type: typeof Active.PAGE_CHANGE
  payload: string
}

export type LoadHomeWheels = {
  type: typeof Active.LOAD_HOME_WHEELS
  payload: never
}

export interface SetHighlightCards {
  type: typeof Active.SET_HIGHLIGHT_CARDS
  payload: string[]
}

export interface SetActivePreferences {
  type: typeof Active.SET_ACTIVE_PREFERENCES
  payload: {
    priceSource: number
    keybinds: number
    width: number
    locking: number
    theme: number
  }
}

export interface SetTheme {
  type: typeof Active.SET_THEME
  payload: number
}

export interface SetModalOverlay {
  type: typeof Active.SET_MODAL_OVERLAY
  payload: {
    type: string | null
    props: any
  }
}

export type GenericActions =
  | SetActiveCard
  | ToggleModalOn
  | ToggleModalOff
  | ToggleTextOn
  | ToggleTextOff
  | SetRightPanelView
  | SetLeftPanelView
  | SetDetailsTab
  | SetMobileAction
  | ToggleNoFocus
  | ToggleModal
  | PageChangeAction
  | LoadHomeWheels
  | SetHighlightCards
  | SetActivePreferences
  | SetTheme
  | SetModalOverlay
