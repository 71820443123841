// React
import React, { useEffect, useState } from 'react'

// Style
import { SocialMediaContainer } from './style'

type Props = {}

const SocialMediaInteractions: React.FC<Props> = () => {
  // The iframe must use window because we're getting a hydration warning from FB seemingly as a race condition. Just let the page render before loading it
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    try {
      // @ts-ignore
      window.twttr.widgets.load()

      setHidden(false)
    } catch (e) {
      console.log("Didn't load twitter widget.")
    }
  }, [])

  if (hidden) return null

  return (
    <SocialMediaContainer>
      <a className="twitter-follow-button" href="https://twitter.com/archidekt">
        Follow
      </a>
      <iframe
        title="facebook"
        src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Farchidekt%2F&width=112&layout=button_count&action=like&size=small&show_faces=true&share=true&height=46&appId"
        width="150"
        height="20"
        style={{ marginLeft: 15, marginRight: 15, border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allow="encrypted-media"
      />
    </SocialMediaContainer>
  )
}

export default SocialMediaInteractions
