import React from 'react'
import Icon from 'components/elements/Icon'

import { CardType } from 'types/deck'

import PhatButton from 'components/formElements/PhatButton'

type Props = {
  category: string
  card: CardType
  onCardChange: (card: CardType) => void
  onDismissNewCategory?: () => void
  disabled?: boolean
}

// Removes a category from a card
const RemoveButton = ({ category, card, onCardChange, disabled, onDismissNewCategory }: Props) => {
  const handleRemove = () => {
    if (!category && onDismissNewCategory) return onDismissNewCategory()
    if (!category) return

    const categories = card.categories.slice()

    categories.splice(categories.indexOf(category), 1)

    onCardChange({ ...card, categories })
  }

  return (
    <PhatButton
      onClick={handleRemove}
      title={disabled ? 'A card must have at least one category.' : ''}
      disabled={disabled}>
      <Icon name="remove" />
    </PhatButton>
  )
}

export default RemoveButton
