import React from 'react'
import type { SemanticICONS } from 'semantic-ui-react'
import Icon from 'components/elements/Icon'

import config from 'environment'

import ManaFrontIcon, { ManaFontIcons } from 'components/misc/customIcons/ManaFont'

import styles from './button.module.scss'

type ButtonProps = {
  id?: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  icon?: SemanticICONS
  manaFontIcon?: ManaFontIcons
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
  labelClass?: string
  disabled?: boolean
  keybind?: string
}

// This button is the default and has transitions and media queries built into it
const Button = (props: ButtonProps) => <SimpleButton {...props} className={`${props.className} ${styles.transition}`} />

// This button will remove the label on smaller screens
export const ShrinkButton = (props: ButtonProps) => (
  <SimpleButton {...props} className={`${props.className} ${styles.transition}`} labelClass={styles.buttonContent} />
)

// This button is simple, and doesn't do anything fancy (also used as the basis to build all other buttons)
export const SimpleButton = (props: ButtonProps) => {
  const {
    id,
    onClick,
    icon,
    manaFontIcon,
    keybind = '',
    children = null,
    style = {},
    className = '',
    labelClass = '',
    disabled = false,
  } = props

  const displayKeybind = config.getOnMac() ? keybind.replace('ctrl', 'cmd').replace('Ctrl', 'Cmd') : keybind

  return (
    <button
      type="button"
      id={id}
      onClick={onClick}
      style={style}
      className={`
        ${styles.button}
        ${keybind ? styles.keybindButton : ''}
        ${className}
      `}
      disabled={disabled}>
      <span className={styles.primaryContent}>
        {icon && <Icon name={icon} />}
        {manaFontIcon && <ManaFrontIcon icon={manaFontIcon} />}
        {children && <span className={labelClass}>{children}</span>}
      </span>
      {displayKeybind && <span className={styles.keybind}>{displayKeybind}</span>}
    </button>
  )
}

export default Button
