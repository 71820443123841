import { NotificationType } from 'types/notifications'

import { generateDeckUrl } from 'utils/deckSeo'

const NOTIFICATION_TYPE = {
  FORUM_THREAD: 'ForumThread',
  DECK_THREAD: 'DeckThread',
  GLOBAL: 'Global',
  STICKY: 'Sticky',
  NEWS: 'News',
  DECK: 'Deck',
  USER: 'Profile',
  FEATURE: 'Features',
  DECK_SHARED: 'DeckUserEditorLink',
}

/**
 * Takes a notification object from the api and generates a title string for a notification along with its link path.
 */
const buildNotificationRoute = (n: NotificationType): string => {
  if (n.notificationType === NOTIFICATION_TYPE.FORUM_THREAD) return `/forum/thread/${n.selfId ?? n.linkId}`
  else if (n.notificationType === NOTIFICATION_TYPE.GLOBAL) return `/forum/thread/${n.selfId ?? n.linkId}`
  else if (n.notificationType === NOTIFICATION_TYPE.STICKY) return `/forum/thread/${n.selfId ?? n.linkId}`
  else if (n.notificationType === NOTIFICATION_TYPE.FEATURE) return `/forum/thread/${n.selfId ?? n.linkId}`
  else if (n.notificationType === NOTIFICATION_TYPE.DECK_THREAD) return generateDeckUrl(n.linkId)
  else if (n.notificationType === NOTIFICATION_TYPE.DECK) return generateDeckUrl(n.linkId)
  else if (n.notificationType === NOTIFICATION_TYPE.NEWS) return `/news/${n.selfId ?? n.linkId}`
  else if (n.notificationType === NOTIFICATION_TYPE.USER) return `/user/${n.linkId}`
  else if (n.notificationType === NOTIFICATION_TYPE.DECK_SHARED) return generateDeckUrl(n.linkId)

  return '/404' // Shouldn't happen
}

export default buildNotificationRoute
