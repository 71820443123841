import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'
import shortid from 'shortid'

import config from 'environment'

import SaveService from 'services/save.service'
import ToastService from 'services/toast.service'

import { CardType, CategoryType, defaultCard, SaveStatus, STACK } from 'types/deck'
import { DOUBLE_FACED_LAYOUTS } from 'types/card'

import { setDeckStateAction } from 'redux/deck/actions'
import { useActionless, useActions, useAppSelector } from 'redux/hooks'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'
import { useCanEditDeck } from 'redux/deck/selectors'

import DeckCardContextMenu from 'components/card/deckCards/deckCardContextMenu'

import { basicKeybindSkips } from 'components/deckPage/modals/KeybindsOverlay'

import styles from './deckCardWrapper.module.scss'

type CardActions = {
  multiSelect: () => void
  cardClick: (e: React.MouseEvent, skipOpenOnClick?: boolean) => void
  rightClick: (e: React.MouseEvent) => void
  openCardModal: (opensToEditions?: boolean) => void
  incrementCard: () => void
  decrementCard: () => void
  changeCategory: (zone: string) => void
  copyCardName: () => void
  setNormal: () => void
  setFoil: () => void
  setEtched: () => void
  removeCard: () => void
  setFlipped: (flipped: boolean) => void
  addAsNewCard: () => void
  setLocalQuantity: (newQuantity: number) => void
  onCardUpdate: (updatedCard: CardType | CardType[], removedId?: string) => void
}

type LocalCardData = {
  saving: SaveStatus
  quantity: number
  flipped: boolean
  anyMultiSelected: boolean
  isMultiSelected: boolean
  hasFinishOptions: boolean
  isNormal: boolean
  isFoil: boolean
  isEtched: boolean
  hasFoilOption: boolean
  hasEtchedOption: boolean
  isMaybeboardPrimary: boolean
  isSideboardPrimary: boolean
  cardError: boolean
  inSecondaryCategory: boolean
  isFlipCard: boolean
  ownsDeck: boolean
  canBeCompanion: boolean
  categoryNames: string[]
  antiHighlight: boolean
}

type LocalDeckData = {
  categories: Record<string, CategoryType>
}

export type DeckCardWrapperChildProps = {
  card: CardType
  stackName?: string
  localCardData: LocalCardData
  deckData: LocalDeckData
  actions: CardActions
  extraData: Record<string, any>
}

type Props = {
  cardId: string
  stackName?: string
  component: React.FC<DeckCardWrapperChildProps>
  extraData?: Record<string, any> // used to pass class names to wrapped component
}

const DeckCardWrapper = memo(({ cardId, stackName, component, extraData = {} }: Props) => {
  const card = useAppSelector(state => state.deck.cardMap[cardId]) || defaultCard
  const multiSelectedIds = useAppSelector(state => state.active.multiSelectedIds)
  const cardError = useAppSelector(state => state.deck.warningCards[card.name])
  const selectedStackSortingOption = useAppSelector(state => state.deck.stack)
  const categories = useAppSelector(state => state.deck.categories)
  const canEditDeck = useCanEditDeck()
  const highlightedCards = useAppSelector(state => state.active.highlightedCards)

  const touched = useRef<boolean>(false)
  const keylistener = useRef<(e: KeyboardEvent) => void>(() => null)
  const hovered = useRef<boolean>(false)

  const [setDeckState] = useActions(setDeckStateAction)
  const [setActiveState] = useActionless(SET_ACTIVE_STATE)

  const [saving, setSaving] = useState<SaveStatus>('saved')
  const [flipped, setFlipped] = useState(card.flippedDefault)
  const [localQuantity, setLocalQuantity] = useState(card.qty) // used while debouncing fast quantity changes

  const anyMultiSelected = !!Object.keys(multiSelectedIds).length
  const isMultiSelected = !!multiSelectedIds[card.id]

  const hasFinishOptions = card.options.length > 1
  const isNormal = card.modifier === 'Normal'
  const isFoil = card.modifier === 'Foil'
  const isEtched = card.modifier === 'Etched'
  const hasFoilOption = card.options.includes('Foil')
  const hasEtchedOption = card.options.includes('Etched')

  const isMaybeboardPrimary = card.categories[0] === 'Maybeboard'
  const isSideboardPrimary = card.categories[0] === 'Sideboard'

  const inSecondaryCategory = selectedStackSortingOption === STACK.MULTIPLE && card.categories[0] !== stackName
  const isFlipCard = DOUBLE_FACED_LAYOUTS.includes(card.layout)

  const handleMultiSelect = () => {
    const updatedMultiSelectedIds = { ...multiSelectedIds }

    if (isMultiSelected) delete updatedMultiSelectedIds[card.id]
    else updatedMultiSelectedIds[card.id] = true

    setActiveState({ multiSelectedIds: updatedMultiSelectedIds })
  }

  const handleCardClick = (e: React.MouseEvent, skipOpenOnClick?: boolean) => {
    const modKeyHeld = config.getOnMac() ? e.metaKey : e.ctrlKey

    if (!modKeyHeld && !anyMultiSelected && skipOpenOnClick) return
    if (modKeyHeld || anyMultiSelected) return handleMultiSelect()

    setDeckState({ menuCardId: card.id })
  }

  const handleRightClick = (e: React.MouseEvent) => {
    // Only supported on mobile
    if (!touched.current) return

    e.preventDefault()
    handleMultiSelect()
  }

  const handleSetDebouncedQty = (qty: number) => {
    const removeId = qty < 1 ? card.id : undefined
    const updatedCard = qty < 1 ? [] : { ...card, qty }

    handleUpdateCard(updatedCard, removeId)

    if (qty < 1 && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }

  // Reset local state if the cards quantity changes someway that isn't here
  // Reset the debounce method to make sure it has correct card info (since it's only updated when lodash.debounce is called)
  const debouncedQtyChange = useMemo(() => {
    if (card.qty !== localQuantity) setLocalQuantity(card.qty)

    return debounce(handleSetDebouncedQty, 660)
  }, [card])

  const handleIncrementCard = () => {
    setLocalQuantity(localQuantity + 1)
    debouncedQtyChange(localQuantity + 1)
  }

  const handleDecrementCard = () => {
    setLocalQuantity(localQuantity - 1 > 0 ? localQuantity - 1 : 0)
    debouncedQtyChange(localQuantity - 1)
  }

  const handleMoveToZone = (zone: string) => {
    if (card.categories[0] === zone) return

    const primaryCategoryName = card.categories[0]
    const primaryIncludedInDeck = categories[primaryCategoryName]?.includedInDeck

    const updatedCardCategories = [
      zone,
      ...card.categories.filter(existingCategoryName => {
        if (existingCategoryName === zone) return false

        // Don't remove a cards existing categories when it moves from that category _unless_ it's moving from side/maybeboard
        if (!primaryIncludedInDeck && existingCategoryName === primaryCategoryName) return false
        if (primaryCategoryName === 'Sideboard' && existingCategoryName === 'Sideboard') return false

        return true
      }),
    ]

    handleUpdateCard({ ...card, qty: card.qty || 1, categories: updatedCardCategories })
  }

  const handleCopyCardName = () => {
    navigator.clipboard.writeText(card.name)
    ToastService.create(`Copied ${card.name} to clipboard`, 'Deck Page', 'success')
  }

  const handleAddAsNewCard = () => handleUpdateCard({ ...card, deckRelationId: '', id: shortid.generate(), qty: 1 })
  const handleSetNormal = () => handleUpdateCard({ ...card, modifier: 'Normal' })
  const handleSetFoil = () => handleUpdateCard({ ...card, modifier: 'Foil' })
  const handleSetEtched = () => handleUpdateCard({ ...card, modifier: 'Etched' })
  const handleRemoveCard = () => handleUpdateCard([], card.id)

  useEffect(() => {
    // Cleanup old listeners (we need to remove the old ones and create new ones when the state changes)
    window.removeEventListener('keydown', keylistener.current)

    // Update current listener (this way the listener has the updated redux/ local state)
    keylistener.current = (e: KeyboardEvent) => {
      if (!hovered.current) return

      const key = e.key.toLocaleUpperCase()

      // prettier-ignore
      const listenedForKeys = ['-', '_', '=', '+', 'R', 'M', 'S', 'F', 'N', 'E', 'L', 'A', 'O', 'P', 'C']

      if (e.ctrlKey || e.metaKey) return
      if (basicKeybindSkips(e)) return
      if (!listenedForKeys.includes(key)) return
      if (anyMultiSelected)
        return ToastService.create('Keybinds are disabled when multiselecting', 'Deck Page', 'warning')

      if (isFlipCard && key === 'L') setFlipped(!flipped)
      if (key === 'O') setDeckState({ menuCardId: card.id, openMenuCardToEditions: false })
      if (key === 'P') setDeckState({ menuCardId: card.id, openMenuCardToEditions: true })

      if (key === 'C') handleCopyCardName()

      if (!canEditDeck) return // the rest of the actions deal with editing the deck, if they don't have write access, we can ignore the rest

      if (key === '-' || key === '_') handleDecrementCard()
      if (key === '=' || key === '+') handleIncrementCard()
      if (key === 'R') handleUpdateCard([], card.id)

      // prettier-ignore
      if ((isMaybeboardPrimary || isSideboardPrimary) && key === 'A') handleMoveToZone(card.categories[1] || card.types[0] || card.front?.types[0] || '😎')
      if (!isMaybeboardPrimary && key === 'M') handleMoveToZone('Maybeboard')
      if (!isSideboardPrimary && key === 'S') handleMoveToZone('Sideboard')

      if (!isFoil && hasFoilOption && key === 'F') handleSetFoil()
      if (!isNormal && hasFinishOptions && key === 'N') handleSetNormal()
      if (!isEtched && hasEtchedOption && key === 'E') handleSetEtched()
    }

    window.addEventListener('keydown', keylistener.current)

    return () => window.removeEventListener('keydown', keylistener.current)
  }, [localQuantity, card, flipped, canEditDeck, anyMultiSelected])

  useEffect(() => {
    setFlipped(card.flippedDefault)
  }, [card.flippedDefault])

  const handleUpdateCard = (updatedCard: CardType | CardType[], removedId?: string) => {
    setSaving('saving')

    SaveService.save(updatedCard, removedId)
      .then(() => setSaving('saved'))
      .catch(() => setSaving('error'))
  }

  const actions: CardActions = {
    multiSelect: handleMultiSelect,
    cardClick: handleCardClick,
    rightClick: handleRightClick,
    incrementCard: handleIncrementCard,
    decrementCard: handleDecrementCard,
    changeCategory: handleMoveToZone,
    copyCardName: handleCopyCardName,
    openCardModal: (openMenuCardToEditions = false) => setDeckState({ menuCardId: card.id, openMenuCardToEditions }),
    setNormal: handleSetNormal,
    setFoil: handleSetFoil,
    setEtched: handleSetEtched,
    removeCard: handleRemoveCard,
    addAsNewCard: handleAddAsNewCard,
    setFlipped,
    setLocalQuantity,
    onCardUpdate: handleUpdateCard,
  }

  const cardData: LocalCardData = {
    quantity: localQuantity,
    flipped,
    anyMultiSelected,
    isMultiSelected,
    hasFinishOptions,
    isNormal,
    isFoil,
    isEtched,
    hasFoilOption,
    hasEtchedOption,
    isMaybeboardPrimary,
    isSideboardPrimary,
    cardError,
    inSecondaryCategory,
    isFlipCard,
    ownsDeck: canEditDeck,
    canBeCompanion: card.text.includes('Companion —'),
    categoryNames: Object.keys(categories).sort(),
    saving,
    antiHighlight: !!Object.keys(highlightedCards).length && !highlightedCards[card.id],
  }

  const deckData = { categories }

  const Component = component

  return (
    <div
      className={`${styles.container} ${extraData.wrapperClassName}`}
      onMouseEnter={() => (hovered.current = true)}
      onMouseLeave={() => (hovered.current = false)}
      onTouchStart={() => (touched.current = true)}
      onTouchEnd={() => (touched.current = false)}>
      <DeckCardContextMenu
        checkSkipRightClick={() => !touched.current && !anyMultiSelected}
        wrapperData={{ card, localCardData: cardData, deckData, stackName, actions, extraData }}>
        <Component
          card={card}
          localCardData={cardData}
          stackName={stackName}
          actions={actions}
          extraData={extraData}
          deckData={deckData}
        />
      </DeckCardContextMenu>
    </div>
  )
})

export default DeckCardWrapper
