import React, { useState, useEffect } from 'react'

import RequestService from 'services/request.service'

import Select, { Options } from 'components/formElements/SemanticSelect'

type SubtypeResponse = {
  subtypename: string
}

type Props = {
  onChange: (values: string[]) => void
  value?: string | string[]
}

const SubTypeDropdown = ({ value, onChange }: Props) => {
  const [selectedOptions, setSelectedOptions] = useState(value ?? new Array<string>())
  const [options, setOptions] = useState(new Array<Options>())

  useEffect(() => {
    RequestService.get<SubtypeResponse[]>(`/api/cards/subtypes/`)
      .then(editions => {
        const options = editions.map(res => ({ text: res.subtypename, value: res.subtypename }))

        setOptions(options)
      })
      .catch(err => console.error(err))
  }, [])

  // Used to reset the form
  useEffect(() => {
    if (!value || !value?.length) setSelectedOptions([])
  }, [value])

  const handleChange = (selectedOptions: string[]) => {
    setSelectedOptions(selectedOptions)
    onChange(selectedOptions)
  }

  return (
    <Select
      searchable
      placeholder="Subtype (eg: Elf)"
      onChange={handleChange}
      options={options}
      value={selectedOptions}
      multiple
    />
  )
}

export default SubTypeDropdown
