import React from 'react'
import { invert } from 'lodash'

import LegalityKey from './LegalityKey/LegalityKey'

import { SectionHeader } from '../style'
import { LegalityBlock, LegalityLabel } from './style'

import { INTERNAL_FORMAT_NAME, HUMAN_FORMAT } from 'types/deck'
import { Legality as LegalityType } from 'services/apiTypes/card.types'

type Props = {
  legalities: Record<string, LegalityType>
}

/**
 * This component displays all of the formats for a card in a color coted fashion
 * to display the formats a card is
 * - legal
 * - restricted
 * - not legal
 * - banned
 *
 * It also displays The @see LegalityKey so users know what the colors mean
 *
 */
const Legality = ({ legalities }: Props) => (
  <>
    <SectionHeader key="SectionHeader">
      Legalities: <LegalityKey />
    </SectionHeader>

    <LegalityBlock key="LegalityBlock">
      {Object.keys(INTERNAL_FORMAT_NAME).map((format, i) => {
        if (format === 'custom') return null

        return (
          <LegalityLabel key={i} legality={legalities[format]}>
            {invert(HUMAN_FORMAT)[i + 1]}
          </LegalityLabel>
        )
      })}
    </LegalityBlock>
  </>
)

export default Legality
