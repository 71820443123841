import React from 'react'
import Icon from 'components/elements/Icon'

import { CardType } from 'types/deck'

import Legality from 'components/cardDetailsPage/Legality/Legality'
import FromFace from 'components/cardDetailsPage/FromFace/FromFace'
import SaltMeter from 'components/cardDetailsPage/SaltMeter/SaltMeter'
import SaltScoreInfo from 'components/tooltips/SaltScoreInfo'

import styles from './cardInfo.module.scss'

type Props = {
  card: CardType
  className?: string
  flipped?: boolean
}

const CardInfo = ({ card, className = '', flipped }: Props) => {
  const isFlipCard = !!card.front && !!card.back

  return (
    <div className={`${styles.container} ${className}`}>
      {/* @ts-ignore */}
      {!isFlipCard && <FromFace card={card} />}
      {card.front && card.back && (
        <>
          <FromFace card={card.front} />
          <FromFace card={card.back} />
        </>
      )}
      <div>
        <div className={styles.row}>
          <span>Collector Number: {card.collectorNumber}</span>
          <span>
            <Icon name="paint brush" />
            {card.artist}
          </span>
        </div>

        <div>
          <span>
            <b>EDH Salt Score</b> <SaltScoreInfo />
          </span>
          <SaltMeter salt={card.salt} />
        </div>

        <Legality legalities={card.legalities} />
      </div>
    </div>
  )
}

export default CardInfo
