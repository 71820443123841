import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd'

import TextViewCard from 'components/card/deckCards/textViewCard'
import StackWrapper from 'components/deckPage/dragAndDrop/StackWrapper'
import StackHeader from 'components/deckPage/DeckContainerV2/StackHeader'

import styles from './textStack.module.scss'

type Props = {
  cardIds: string[]
  name: string
  price: number
  quantity: number
  dragHandleProps?: DraggableProvidedDragHandleProps | null
  onDragTriggerMouseDown?: () => void
  onDragTriggerMouseUp?: () => void
  isPremier: boolean
  includeInDeck: boolean
  includeInPrice: boolean
}

const TextStack = ({
  cardIds,
  name,
  price,
  quantity,
  dragHandleProps,
  onDragTriggerMouseDown,
  onDragTriggerMouseUp,
  isPremier = false,
  includeInDeck = true,
  includeInPrice = true,
}: Props) => {
  return (
    <div className={styles.container}>
      <StackWrapper name={name}>
        <StackHeader
          name={name}
          price={price}
          quantity={quantity}
          cardIds={cardIds}
          dragHandleProps={dragHandleProps}
          onDragTriggerMouseDown={onDragTriggerMouseDown}
          onDragTriggerMouseUp={onDragTriggerMouseUp}
          includeInDeck={includeInDeck}
          includeInPrice={includeInPrice}
          isPremier={isPremier}
        />

        {cardIds.map((cardId, index) => (
          <TextViewCard cardId={cardId} key={index} stackName={name} />
        ))}

        {!cardIds.length && <div className={styles.emptyStack}>Empty stack</div>}
      </StackWrapper>
    </div>
  )
}

export default TextStack
