import { Store } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { ActionTypes } from './actions'

import reducer, { RootState } from './reducers'

import { DeepPartial } from 'types/deepPartial'

let store: ReturnType<typeof configStore>

const configStore = (preloadedState?: DeepPartial<RootState>): Store<RootState, ActionTypes> =>
  configureStore({
    reducer,
    preloadedState: preloadedState as any,
    devTools: process.env.NODE_ENV !== 'production',
  })

export const makeStore = (preloadedState?: DeepPartial<RootState>) => {
  store = configStore(preloadedState)

  return store
}

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>
export type ReduxServerProps = { redux?: DeepPartial<RootState> }
// export const wrapper = createWrapper<AppStore>(makeStore, { debug: false })

export { store }
