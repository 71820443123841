import React, { useEffect, useRef, useState } from 'react'

import { CardType } from 'types/deck'
import { DOUBLE_FACED_LAYOUTS } from 'types/card'

import CardLoader from 'components/card/CardLoader'
import CornerOwned from 'components/card/controlsAndInfo/cornerOwned'
import { ErrorCard, SecondaryCategory, FoilDiv } from 'components/card/controlsAndInfo/cardOverlays'

import { getImageSizePreference } from 'services/accountSettings.service'

import { ImageSize, getScryfallImageUrl } from 'utils/ImageUrl'

import styles from './basicCard.module.scss'

export type Props = {
  card: CardType
  flipped?: boolean
  style?: React.CSSProperties
  className?: string
  imageClassName?: string
  cardLoaderClassName?: string
  error?: boolean
  faded?: boolean
  onClick?: (e: React.MouseEvent) => void
  onDoubleClick?: (e: React.MouseEvent) => void
  onContextMenu?: (e: React.MouseEvent) => void
  onTouchStart?: (e: React.TouchEvent) => void
  onTouchEnd?: (e: React.TouchEvent) => void
  onMouseEnter?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
  onMouseDown?: (e: React.MouseEvent) => void
  onMouseUp?: (e: React.MouseEvent) => void
  nameOnPlaceholder?: boolean
  textOnPlaceholder?: boolean
  overrideImageUrl?: string
  overrideFoil?: boolean
  skipPlaceholder?: boolean
  forceOnlyShowFaceUpSide?: boolean
  cardDataPending?: boolean
  includeCorderOwned?: boolean
  disableTooltip?: boolean
  overrideCardResolutionPreference?: ImageSize
}

const BasicCard = React.forwardRef((props: Props, ref: any) => {
  const {
    card,
    flipped,
    error,
    faded,
    style,
    className = '',
    imageClassName = '',
    cardLoaderClassName = '',
    onTouchStart,
    onTouchEnd,
    onDoubleClick,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
    onMouseUp,
    onContextMenu,
    nameOnPlaceholder,
    textOnPlaceholder,
    overrideImageUrl,
    overrideFoil,
    skipPlaceholder,
    forceOnlyShowFaceUpSide,
    cardDataPending,
    includeCorderOwned,
    disableTooltip = false,
    overrideCardResolutionPreference,
  } = props

  // NextJS doesn't fire onLoaded for images that are loaded from cache. We use this ref and a useEffect to setImageLoaded forcefully if the image has completely loading pre-render (AKA from cache)
  // Can't use next's <Image /> here because it requires a defined width
  const image = useRef<HTMLImageElement>(null)

  const [imageLoaded, setImageLoaded] = useState(skipPlaceholder || false)

  const isFlipCard = DOUBLE_FACED_LAYOUTS.includes(card.layout)

  const size = overrideCardResolutionPreference || getImageSizePreference()

  const imageUrl =
    overrideImageUrl || (card.uid ? getScryfallImageUrl(card.uid, false, size, card.scryfallImageHash) : '')
  const imageUrlBack =
    overrideImageUrl || (card.uid ? getScryfallImageUrl(card.uid, true, size, card.scryfallImageHash) : '')

  useEffect(() => {
    if (image.current?.complete) setImageLoaded(true)
  }, [])

  if (cardDataPending)
    return (
      <div ref={ref} className={`${styles.container} ${className} ${imageLoaded ? styles.border : ''}`} style={style}>
        <CardLoader loading className={styles.loadingCard} />
      </div>
    )

  const metaName = (!flipped ? card.name : `${card.name} back`) + ` (${card.setCode}) ${card.collectorNumber}`

  return (
    <div
      ref={ref}
      className={`${styles.container} ${className} ${imageLoaded ? styles.border : ''}`}
      style={style}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onContextMenu={onContextMenu}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onClick={onClick}>
      <CardLoader
        loading
        className={`${styles.loadingCard} ${cardLoaderClassName}`}
        card={card}
        style={{ opacity: imageLoaded && imageUrl ? '0' : '1', animation: 'none' }}
        includeName={nameOnPlaceholder}
        includeText={!imageUrl || textOnPlaceholder}
      />
      {/* You get strange behavior sometimes when trying to keep both front and back renderred at once, this option allows you to disable that */}
      {forceOnlyShowFaceUpSide && imageUrl ? (
        <img
          ref={image}
          src={!flipped ? imageUrl : imageUrlBack}
          alt={metaName}
          title={disableTooltip ? undefined : metaName}
          className={`${styles.image} ${imageClassName}`}
          style={{ opacity: imageLoaded ? 1 : 0 }}
          onLoad={() => setImageLoaded(true)}
          onError={() => setImageLoaded(false)}
        />
      ) : (
        <>
          {imageUrl && (
            <img
              id="basicCardImage"
              ref={image}
              src={imageUrl}
              alt={metaName}
              title={disableTooltip ? undefined : metaName}
              className={`${styles.image} ${imageClassName}`}
              style={{ opacity: !flipped && imageLoaded ? 1 : 0 }}
              onLoad={() => setImageLoaded(true)}
              onError={() => setImageLoaded(false)}
            />
          )}
          {(isFlipCard || flipped) && (
            <img
              src={imageUrlBack}
              alt={metaName}
              title={disableTooltip ? undefined : metaName}
              className={styles.image}
              onError={() => setImageLoaded(false)}
              style={{ opacity: flipped ? 1 : 0, pointerEvents: flipped ? 'auto' : 'none' }}
            />
          )}
        </>
      )}
      {imageLoaded && ((card.modifier !== 'Normal' && overrideFoil === undefined) || overrideFoil) && <FoilDiv />}
      {error && <ErrorCard />}
      {faded && <SecondaryCategory />}
      {includeCorderOwned && <CornerOwned owned={card.owned} />}
    </div>
  )
})

export default BasicCard
