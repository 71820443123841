// React
import React from 'react'

// Styles
import Icon from 'components/elements/Icon'

/**
 * Icon links to Archidekt's social media for the footer.
 */
const ExternalLinks = () => {
  return (
    <p>
      <a href="https://discord.gg/GyAAANU8uJ" rel="noopener noreferrer" target="_blank">
        <Icon name="discord" />
      </a>
      <a href="https://www.facebook.com/archidekt/" rel="noopener noreferrer" target="_blank">
        <Icon name="facebook" />
      </a>
      <a href="https://twitter.com/archidekt" rel="noopener noreferrer" target="_blank">
        <Icon name="twitter" />
      </a>
      <a href="https://www.twitch.tv/archidekt" rel="noopener noreferrer" target="_blank">
        <Icon name="twitch" />
      </a>
      <a href="https://www.youtube.com/channel/UC__St4xQR9vUipooUZFeNNw" rel="noopener noreferrer" target="_blank">
        <Icon name="youtube" />
      </a>
      <a href="https://www.patreon.com/archidekt" rel="noopener noreferrer" target="_blank">
        <Icon name="patreon" />
      </a>
    </p>
  )
}

export default ExternalLinks
