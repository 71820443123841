import React from 'react'

import { useActionless } from 'redux/hooks'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import { CardType, defaultCard } from 'types/deck'
import { Combo } from 'services/apiTypes/spellbook.types'

import PhatButton from 'components/formElements/PhatButton'
import SearchedCard from 'components/cardSearchPanel/cardLayouts/searchedCard'
import CardModalStandalone from 'components/card/CardModal'
import CardEditionsGridOverlay from 'components/card/CardEditionsGridOverlay'
import Link from 'components/elements/Link'
import Icon from 'components/elements/Icon'

import { fullTextToElement } from 'components/misc/customIcons/ManaSymbol/helpers'

import styles from './spellbookCombo.module.scss'
import { generateTabState } from 'types/searchV2'

type Props = {
  combo: Combo
  cards: (CardType | undefined)[]

  onCardUpdated: (currentId: string, updatedCard: CardType) => void
  onCardDragStart?: () => void
  onCardDragStop?: () => void
}

const SpellbookCombo = ({ combo, cards, onCardUpdated, onCardDragStart, onCardDragStop }: Props) => {
  const [selectedCard, setSelectedCard] = React.useState<CardType>(defaultCard)
  const [editionsAsDefault, setEditionsAsDefault] = React.useState(false)

  const [setActiveState] = useActionless(SET_ACTIVE_STATE)

  const handleCardClicked = (card: CardType, forceAllPrintingsView?: boolean) => {
    setSelectedCard(card)
    setEditionsAsDefault(!!forceAllPrintingsView)
  }

  const handleDismissSelectedCard = () => {
    setSelectedCard(defaultCard)
    setEditionsAsDefault(false)
  }

  const handleExtrasClick = (query: string) => {
    const newSearchTab = generateTabState.scrySearch()

    // @ts-ignore
    newSearchTab.meta.query = query

    setActiveState({ searchOpen: newSearchTab })
  }

  const comboComplete = cards.every(card => card?.qty || card?.otherPrintingQty)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.controls}>
          <span className={styles.status}>
            {comboComplete ? (
              <>
                <Icon name="check circle" className={styles.green} />
                Complete
              </>
            ) : (
              <>
                <Icon name="times circle" className={styles.red} />
                Incomplete
              </>
            )}
          </span>

          <Link href={`https://commanderspellbook.com/combo/${combo.id}/`} target="_blank" rel="noopener noreferrer">
            <PhatButton color="blue">
              <img src="/images/spellbook_simple.svg" alt="Commander Spellbook logo" className={styles.spellbookLogo} />
              Combo on Commander Spellbook
            </PhatButton>
          </Link>
        </div>

        <div className={styles.cards}>
          {cards.map(card => (
            <>
              {card && (
                <SearchedCard
                  allowDragging
                  key={card.id}
                  card={card}
                  className={styles.card}
                  onCardClick={handleCardClicked}
                  onCardUpdated={onCardUpdated}
                  onDragStart={onCardDragStop}
                  onDragStop={onCardDragStart}
                />
              )}
            </>
          ))}
          {combo.extraRequirements.length > 0 && (
            <div className={styles.extraRequirements}>
              <h3>Extra Requirements</h3>

              <div className={styles.extraRequirementsList}>
                {combo.extraRequirements.map((req, index) => (
                  <button key={index} onClick={() => handleExtrasClick(req.syntaxQuery)}>
                    <span>•</span>
                    <span> {req.name}</span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className={styles.comboInfo}>
          <div className={styles.infoBlock}>
            <h3>Steps</h3>

            <ol>
              {combo.steps.map((step, index) => (
                <li key={`${combo.id}-step-${index}`}> {fullTextToElement(step)}</li>
              ))}
            </ol>
          </div>

          <div className={styles.infoBlock}>
            <h3>Effects</h3>

            <ul>
              {combo.comboEffects.map((effect, index) => (
                <li key={`${combo.id}-effect-${index}`}>{effect}</li>
              ))}
            </ul>
          </div>

          <div className={styles.infoBlock}>
            <h3>Prerequisites</h3>

            <ul>
              {combo.prerequisites.length === 0 && <li>None</li>}

              {combo.prerequisites.map((prerequisite, index) => (
                <li key={`${combo.id}-prerequisite-${index}`}>{prerequisite}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <CardModalStandalone
        open={!!selectedCard.id && !editionsAsDefault}
        card={selectedCard}
        onCardUpdated={updatedCard => onCardUpdated(selectedCard.id, updatedCard)}
        onClose={handleDismissSelectedCard}
      />

      <CardEditionsGridOverlay
        open={!!selectedCard.id && editionsAsDefault}
        card={selectedCard}
        onCardSelected={updatedCard => onCardUpdated(selectedCard.id, updatedCard)}
        onClose={handleDismissSelectedCard}
      />
    </>
  )
}

export default SpellbookCombo
