import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'

import { ActiveCollectionTab, CollectionV2Card, defaultCollectionV2Card } from 'redux/collectionV2/types'

import CollectionService from 'services/collection.service'
import ToastService from 'services/toast.service'

import { useActionless, useActions, useAppSelector } from 'redux/hooks'
import { selectActiveCard, selectNextPreviousCardIds } from 'redux/collectionV2/selectors'
import { modifyCollectionCardMapAction } from 'redux/collectionV2/actions'
import { SET_COLLECTION_V2_STATE } from 'redux/collectionV2/actions/types'

import CardEditionsGridOverlay from 'components/card/CardEditionsGridOverlay'
import CardDetailsOverlay from 'components/elements/Overlay/CardDetailsOverlay'
import RelatedCards from 'components/collectionV2/cardDetails/RelatedCards'
import InDecks from 'components/collectionV2/cardDetails/InDecks'
import CardInfo from 'components/card/CardInfo/cardInfo'
import { CardDetailsFormOnly } from 'components/collectionV2/cardDetails/Form'

type Props = {
  open: boolean
  ownsCollection: boolean
  openToAllEditionsMenu?: boolean
  collectionCard: CollectionV2Card
  onCardUpdated: (updatedCollectionCard: CollectionV2Card, removedCollectionCardIds?: number[] | number) => void
  currentTab?: ActiveCollectionTab
  writeActiveTabChangesToRedux?: boolean
  onClose: () => void
}

export const CardDetailsModalStandalone = ({
  open,
  openToAllEditionsMenu = false,
  collectionCard: parentCollectionCard,
  currentTab: parentCurrentTab,
  writeActiveTabChangesToRedux = false,
  ownsCollection = false,
  onCardUpdated,
  onClose,
}: Props) => {
  const [currentTab, setCurrentTab] = useState<ActiveCollectionTab>(parentCurrentTab || 'details')
  const [flipped, setFlipped] = useState(false)

  const game = useAppSelector(state => state.collectionV2.game)

  const [nextCardId, previousCardId, nextCardName, previousCardName] = useAppSelector(selectNextPreviousCardIds)

  const [setCollectionV2State] = useActionless(SET_COLLECTION_V2_STATE)

  const [_loading, setLoading] = useState(false) // Might use at some point
  const [collectionCard, setCollectionCard] = useState(parentCollectionCard || defaultCollectionV2Card)

  useEffect(() => {
    if (parentCollectionCard) return setCollectionCard(parentCollectionCard)

    setTimeout(() => setCollectionCard(defaultCollectionV2Card), 250)
  }, [parentCollectionCard])

  useEffect(() => {
    if (!open) return
    if (currentTab === parentCurrentTab) return

    setCurrentTab(parentCurrentTab || 'details')
  }, [open, parentCurrentTab])

  const handleUpdateCard = (updatedCollectionCard: CollectionV2Card) => {
    if (!collectionCard) return
    if (isEqual(updatedCollectionCard, collectionCard)) return

    if (!ownsCollection) return onCardUpdated(updatedCollectionCard)

    setLoading(true)

    if (updatedCollectionCard.quantity < 1) {
      return CollectionService.deleteCard(updatedCollectionCard.id)
        .then(() => {
          onCardUpdated(updatedCollectionCard, updatedCollectionCard.id)
          onClose()

          ToastService.create(`${collectionCard.card.name} deleted`, 'Collection page', 'success')
        })
        .catch(() => ToastService.create(`${collectionCard.card.name} delete failed`, 'Collection page', 'error'))
    }

    CollectionService.upsertV2(updatedCollectionCard, game)
      .then(responseCard => {
        onCardUpdated({
          ...updatedCollectionCard,
          modifiedAt: responseCard.modifiedAt,
          createdAt: responseCard.createdAt,
        })

        ToastService.create(`${collectionCard.card.name} saved`, 'Collection page', 'success')
      })
      .catch(() => ToastService.create(`${collectionCard.card.name} save failed`, 'Collection page', 'error'))
      .finally(() => setLoading(false))
  }

  const handleChangeTabs = (tab: ActiveCollectionTab) => {
    setCurrentTab(tab)

    if (writeActiveTabChangesToRedux) setCollectionV2State({ activeCardTab: tab })
  }

  const handleGotoPrevious = () => setCollectionV2State({ activeCardId: previousCardId })
  const handleGotoNext = () => setCollectionV2State({ activeCardId: nextCardId })

  return (
    <>
      <CardDetailsOverlay
        open={open && !openToAllEditionsMenu}
        onClose={onClose}
        onTabChange={handleChangeTabs}
        card={{ ...collectionCard.card, modifier: collectionCard.modifier }}
        activeTabId={currentTab}
        onCardFlipped={setFlipped}
        tabs={[
          {
            id: 'details',
            label: 'Card options',
            body: (
              <CardDetailsFormOnly
                collectionCard={collectionCard}
                onChange={handleUpdateCard}
                ownsCollection={ownsCollection}
                nextPrevControls={{
                  previous: {
                    disabled: !previousCardId,
                    label: previousCardName || 'None',
                    onClick: handleGotoPrevious,
                  },
                  next: {
                    disabled: !nextCardId,
                    label: nextCardName || 'None',
                    onClick: handleGotoNext,
                  },
                }}
              />
            ),
          },
          {
            id: 'relatedCards',
            label: 'Other records',
            body: <RelatedCards collectionCard={collectionCard} />,
          },
          {
            id: 'decks',
            label: 'In decks',
            body: <InDecks card={{ ...collectionCard.card, modifier: collectionCard.modifier }} />,
          },
          {
            label: 'Card info',
            id: 'info',
            body: <CardInfo card={collectionCard.card} flipped={flipped} />,
          },
        ]}
        nextPrevControls={{
          previous: {
            disabled: !previousCardId,
            label: previousCardName || 'None',
            onClick: handleGotoPrevious,
          },
          next: {
            disabled: !nextCardId,
            label: nextCardName || 'None',
            onClick: handleGotoNext,
          },
        }}
      />

      <CardEditionsGridOverlay
        open={open && openToAllEditionsMenu}
        onClose={onClose}
        card={collectionCard.card}
        onCardSelected={card => handleUpdateCard({ ...collectionCard, card })}
      />
    </>
  )
}

export const CardDetailsModal = () => {
  const [setCollectionV2State] = useActionless(SET_COLLECTION_V2_STATE)
  const [modifyCollectionCardMap] = useActions(modifyCollectionCardMapAction)

  const selectedReduxCard = useAppSelector(selectActiveCard)
  const activeCardId = useAppSelector(state => state.collectionV2.activeCardId)
  const openToAllEditionsMenu = useAppSelector(state => state.collectionV2.openToAllEditionsMenu)
  const activeCardTab = useAppSelector(state => state.collectionV2.activeCardTab)
  const ownsCollection = useAppSelector(state => state.collectionV2.ownsCollection)

  const handleClose = () => setCollectionV2State({ activeCardId: null, openToAllEditionsMenu: false })

  return (
    <CardDetailsModalStandalone
      writeActiveTabChangesToRedux
      ownsCollection={ownsCollection}
      collectionCard={selectedReduxCard || defaultCollectionV2Card}
      onCardUpdated={modifyCollectionCardMap}
      open={!!activeCardId}
      currentTab={activeCardTab}
      openToAllEditionsMenu={openToAllEditionsMenu}
      onClose={handleClose}
    />
  )
}

export default CardDetailsModal
