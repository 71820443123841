import React, { useEffect, useRef, useState } from 'react'

import styles from './phatInput.module.scss'

type Props = {
  type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url'
  className?: string
  containerClassName?: string
  placeholder?: string
  inputMode?: 'numeric' | 'decimal' | 'email' | 'search' | 'tel' | 'text' | 'url'
  min?: number
  max?: number
  value?: string | number
  defaultValue?: string | number
  onChange?: (value: any, event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (value: string | number, event: React.FocusEvent<HTMLInputElement>) => void
  selectOnFocus?: boolean
  focusOnMount?: boolean
  header?: string
  disabled?: boolean
  style?: React.CSSProperties
  inlineHeader?: boolean
  name?: string
  autocomplete?: string
  uncontrolledInput?: boolean
}

const PhatInput = ({
  type,
  className = '',
  containerClassName = '',
  value,
  defaultValue: parentDefaultValue,
  placeholder,
  onChange,
  onBlur,
  min,
  max,
  inputMode,
  selectOnFocus,
  focusOnMount,
  header,
  inlineHeader,
  disabled,
  style,
  name,
  autocomplete,
  uncontrolledInput = false,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null)

  const [localValue, setLocalValue] = useState(value ?? parentDefaultValue)
  const [defaultValue] = useState(value ?? parentDefaultValue)

  const handleChange = (value: string | number, event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(value)

    if (onChange) onChange(value, event)
  }

  const handleBlur = (value: string | number, event: React.FocusEvent<HTMLInputElement>) => {
    setLocalValue(value)

    if (onBlur) onBlur(value, event)
  }

  // IF the parent quantity changes, update the local quantity
  useEffect(() => {
    if (value !== localValue) setLocalValue(value)
  }, [value])

  useEffect(() => {
    if (ref.current && focusOnMount) ref.current.focus()
  }, [])

  return (
    <div className={`${styles.container} ${containerClassName}`}>
      {header && <label className={`${styles.header} ${inlineHeader ? styles.inlineHeader : ''}`}>{header}</label>}

      <input
        name={name}
        ref={ref}
        type={type}
        className={`${styles.input} ${className}`}
        style={style}
        value={uncontrolledInput ? undefined : localValue}
        placeholder={placeholder}
        onChange={e => handleChange(e.target.value, e)}
        onBlur={e => handleBlur(e.target.value, e)}
        onFocus={() => selectOnFocus && ref.current?.select()}
        min={min}
        max={max}
        inputMode={inputMode}
        disabled={disabled}
        autoComplete={autocomplete}
        defaultValue={uncontrolledInput ? defaultValue : undefined} // Only set defaultValue if uncontrolledInput is true
      />
    </div>
  )
}

export default PhatInput
