import { cloneDeep } from 'lodash'

import { CollectionV2Card, CollectionV2State } from 'redux/collectionV2/types'
import { SET_COLLECTION_V2_STATE } from 'redux/collectionV2/actions/types'

import { store } from 'redux/store'

export type SetCollectionV2StateActionType = {
  type: typeof SET_COLLECTION_V2_STATE
  payload: Partial<CollectionV2State>
}

export const genericAction = (updates: Partial<CollectionV2State> = {}): SetCollectionV2StateActionType => ({
  type: SET_COLLECTION_V2_STATE,
  payload: updates,
})

export const modifyCollectionCardMapAction = (
  updatedCard: CollectionV2Card | CollectionV2Card[],
  removedId?: number | number[],
): SetCollectionV2StateActionType => {
  const collectionV2 = store.getState().collectionV2

  const updatedCollectionCards = cloneDeep(collectionV2.collectionCards)
  const { serverCollectionData, recentlyAdded } = collectionV2

  const updatedCards = Array.isArray(updatedCard) ? updatedCard : [updatedCard]
  const removedIds = new Array<number>()

  if (removedId) {
    if (Array.isArray(removedId)) removedIds.push(...removedId)
    else removedIds.push(removedId)
  }

  for (const card of updatedCards) {
    updatedCollectionCards[card.id] = card
  }

  for (const id of removedIds) {
    delete updatedCollectionCards[id]
  }

  const updatedServerCollectionData = serverCollectionData.filter(id => !removedIds.includes(id))
  const updatedRecentlyAdded = recentlyAdded.filter(id => !removedIds.includes(id))

  return genericAction({
    collectionCards: updatedCollectionCards,
    serverCollectionData: updatedServerCollectionData,
    recentlyAdded: updatedRecentlyAdded,
  })
}

export type CollectionV2Actions = SetCollectionV2StateActionType
