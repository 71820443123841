import React from 'react'
import shortid from 'shortid'
import type { SemanticICONS, StrictDropdownProps } from 'semantic-ui-react'

import { useAppSelector } from 'redux/hooks'

import { CardType } from 'types/deck'

import SaveService from 'services/save.service'

import Icon from 'components/elements/Icon'
import PhatButton from 'components/formElements/PhatButton'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'

import styles from './toCategoryButtons.module.scss'

type Props = {
  card: CardType
  onCardUpdate: (updatedCard: CardType) => void
  categoryName: string
  pointing?: StrictDropdownProps['pointing']
  icon?: SemanticICONS
}

export const ToCategoryButton = ({ card, onCardUpdate, categoryName, pointing = 'top', icon }: Props) => {
  const isInCategory = card.categories.includes(categoryName)

  const handleUpdateCard = (action: 'set' | 'primary' | 'secondary') => {
    if (card.categories.includes(categoryName)) return

    let categories = [...card.categories]

    if (action === 'set') categories = [categoryName]
    if (action === 'primary') categories = [categoryName, ...card.categories]
    if (action === 'secondary') categories = [...card.categories, categoryName]

    onCardUpdate({ ...card, qty: card.qty || 1, categories })
  }

  const hanleAddNewCopy = () => {
    const categories = card.categories.includes(categoryName)
      ? [...card.categories]
      : [categoryName, ...card.categories]

    // New internal id here to force it being added as a new card
    SaveService.save({ ...card, id: shortid.generate(), deckRelationId: '', categories, qty: 1 })
  }

  return (
    <ArchidektDropdown
      disabled={isInCategory}
      triggerClassName={styles.triggerButton}
      menuClassName={styles.dropdownMenu}
      options={[
        { label: `Set ${categoryName} as only category`, onClick: () => handleUpdateCard('set') },
        { label: `Set ${categoryName} as primary`, onClick: () => handleUpdateCard('primary') },
        {
          label: `Add ${categoryName} as secondary`,
          onClick: () => handleUpdateCard('secondary'),
          hidden: categoryName === 'Sideboard' || categoryName === 'Maybeboard',
        },
        { type: 'spacer' },
        { label: `Add new copy to ${categoryName}`, onClick: hanleAddNewCopy },
      ]}>
      <PhatButton disabled={isInCategory} className={styles.triggerButton}>
        <Icon name={icon || (categoryName === 'Sideboard' ? 'arrow alternate circle right' : 'thumb tack')} />
        {categoryName}
      </PhatButton>
    </ArchidektDropdown>
  )
}

type CommanderProps = Omit<Props, 'categoryName'>

export const ToCommanderButton = ({ card, onCardUpdate }: CommanderProps) => {
  const categories = useAppSelector(state => state.deck.categories)

  const isCommander = categories[card.categories[0]]?.isPremier

  const handleToggleCommander = () => {
    // Commander is special, we're gonna remove all other categories for them on this change
    if (!isCommander) return onCardUpdate({ ...card, qty: card.qty || 1, categories: ['Commander'] })

    onCardUpdate({
      ...card,
      categories:
        card.categories.length > 1 ? [...card.categories.filter((_card, index) => index > 0)] : [card.typeCategory],
    })
  }

  return (
    <PhatButton onClick={() => handleToggleCommander()}>
      <Icon name="chess queen" color={isCommander ? 'purple' : undefined} />
      {!isCommander ? 'Set Commander' : 'Unset Commander'}
    </PhatButton>
  )
}
