import React from 'react'
import { Label, Icon } from 'semantic-ui-react'

import { CardType } from 'types/deck'

type Props = {
  card: CardType
}

const CompanionTag = ({ card }: Props) => {
  if (!card.companion) return null

  return (
    <Label
      style={{ borderRadius: '0px 11px 0px 0px', overflow: 'hidden', opacity: 0.8 }}
      color="pink"
      title="Companion"
      corner="right"
      size="mini">
      <div style={{ textAlign: 'right', paddingRight: 12, fontSize: '125%', opacity: '1', color: 'white' }}>
        <Icon name="paw" />
      </div>
    </Label>
  )
}

export default CompanionTag
