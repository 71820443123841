import { CardType } from 'types/deck'

export const creatEdhrecCardName = (card: CardType): string => {
  const cardName = card.front?.name || card.name

  return cardName
    .normalize('NFD')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9-_+\s/]/g, '')
    .replace(/\+/g, 'plus ')
    .replace(/[\s/]+/g, '-')
}
