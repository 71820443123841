import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'

import { initializeTheme, toggleThemeGlobally } from 'utils/theme.utils'
import { THEME } from 'types/active'

import Icon from 'components/elements/Icon'

const DarkModeToggle = () => {
  const [{ theme }, setCookies] = useCookies(['theme'])

  useEffect(() => {
    initializeTheme(setCookies)
  }, [])

  return (
    <button onClick={() => toggleThemeGlobally(setCookies)}>
      <Icon name={`${theme}` === THEME.DARK ? 'moon' : 'moon outline'} />
    </button>
  )
}

export default DarkModeToggle
