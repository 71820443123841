import CookieService from './cookie.service'

const affiliateLoadedKey = 'referred-by'
const collectionV2View = 'collection-v2-view'

export const getAffiliateDeckLoaded = () => CookieService.get(affiliateLoadedKey) || null
export const setAffiliateDeckLoaded = (affiliate: string) => CookieService.set(affiliateLoadedKey, affiliate, 'day')

// prettier-ignore
export const getCollectionV2View = (serverCookies?: string | Record<string, any> | undefined) => CookieService.get(collectionV2View, serverCookies) || 'text'
export const setCollectionV2View = (view: string) => CookieService.set(collectionV2View, view)
