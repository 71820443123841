import { imageDirectory } from 'environment'

export const MTG_CARD_BACK = 'https://storage.googleapis.com/archidekt-card-images/magic_card_back.webp'

export type ImageSize = 'small' | 'normal' | 'large' | 'art_crop' | 'png'

const PREVIEW_CARD_UID_MAP: Record<string, string> = {
  'mocked-ltr-preview-card-uid': 'https://storage.googleapis.com/archidekt-card-images/ltr/mocked-ltr-preview-card-uid',
}

export const getScryfallImageUrl = (
  uid: string,
  back: boolean,
  size: ImageSize,
  scryfallImageHash?: string | null,
  usePngFileType?: boolean,
) => {
  const scryUrl = 'https://cards.scryfall.io'
  const cacheBustParameter = scryfallImageHash || '11' // If the scryfall images are ever cached on something wrong, we can bump this up
  const fileType = usePngFileType ? 'png' : 'jpg'

  if (PREVIEW_CARD_UID_MAP[uid]) return `${PREVIEW_CARD_UID_MAP[uid]}_${size}.${fileType}`

  if (!size) {
    return back
      ? `${scryUrl}/normal/back/${uid.charAt(0)}/${uid.charAt(1)}/${uid}.${fileType}?${cacheBustParameter}`
      : `${scryUrl}/normal/front/${uid.charAt(0)}/${uid.charAt(1)}/${uid}.${fileType}?${cacheBustParameter}`
  } else {
    return back
      ? `${scryUrl}/${size}/back/${uid.charAt(0)}/${uid.charAt(1)}/${uid}.${fileType}?${cacheBustParameter}`
      : `${scryUrl}/${size}/front/${uid.charAt(0)}/${uid.charAt(1)}/${uid}.${fileType}?${cacheBustParameter}`
  }
}

/**
 * Function that builds an images URL of a card given card info.
 *
 * TODO - Just pass in a card here. It doens't make sense to pass in the pieces it just makes everything harder
 * to read.
 */
export const getImageUrl = (setCode: string, uid: string, back: boolean, size?: string): string => {
  if (PREVIEW_CARD_UID_MAP[uid]) return `${PREVIEW_CARD_UID_MAP[uid]}_${size}.jpg`

  if (!size) {
    return back
      ? imageDirectory + setCode + '/' + uid + '_small_back.jpg'
      : imageDirectory + setCode + '/' + uid + '_small.jpg'
  } else {
    return back
      ? imageDirectory + setCode + '/' + uid + '_' + size + '_back.jpg'
      : imageDirectory + setCode + '/' + uid + '_' + size + '.jpg'
  }
}

export const getArtUrl = (setCode: string, uid: string, back?: boolean): string => {
  if (PREVIEW_CARD_UID_MAP[uid]) return `${PREVIEW_CARD_UID_MAP[uid]}_art_crop.jpg`

  return `${imageDirectory}${setCode}/${uid}_art_crop${back ? '_back' : ''}.jpg`
}

/**
 * Parses an image url and extracts the uid from the url and returns it.
 */
export const getId = (url: string): string => {
  let id = ''

  if (url && url.includes(imageDirectory)) {
    const split = url.split('/')
    id = split[split.length - 1].split('_')[0]
  }

  return id
}
