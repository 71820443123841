import React, { useEffect, useState } from 'react'
import { Icon, Modal } from 'semantic-ui-react'

import { TOAST_ICONS, ToastType } from 'types/toasts'

import ToastService from 'services/toast.service'

import styles from './toast.module.scss'

type Props = {
  toast: ToastType
  matchesExistingToast?: boolean
}

const Toast: React.FC<Props> = ({ toast, matchesExistingToast }: Props) => {
  const [offScreen, setOffScreen] = useState(true)

  const removeToast = () => {
    setOffScreen(true)
    setTimeout(() => ToastService.delete(toast.id), 500)
  }

  useEffect(() => {
    setTimeout(() => setOffScreen(false), 25)
  }, [])

  useEffect(() => {
    if (toast.type === 'success') setTimeout(() => removeToast(), toast.duration || 3000)
  }, [offScreen])

  useEffect(() => {
    if (matchesExistingToast) setTimeout(() => removeToast(), 1000)
  }, [matchesExistingToast])

  const Body = toast.body

  let message: string | null = toast.text

  try {
    // @ts-expect-error
    const parsedText = JSON.parse(message)

    if (Array.isArray(parsedText)) message = parsedText.join(' / ')
    if (parsedText.detail) message = parsedText.detail
    if (parsedText.detail && parsedText.statusCode === 429) message = formatThrottleMessage(parsedText.detail)
    if (parsedText.message) message = parsedText.message
    if (parsedText.errors) message = parsedText.errors

    // prettier-ignore
    if (parsedText.non_field_errors && Array.isArray(parsedText.non_field_errors) && parsedText.non_field_errors.length) message = parsedText.non_field_errors.join(' / ')
  } catch (err) {
    message = toast.text
  }

  return (
    <div className={`${styles.outerContainer} ${offScreen ? styles.outerOffscreen : ''}`}>
      <div className={`${styles.toastContainer} ${styles[toast.type]} ${offScreen ? styles.offscreen : ''}`}>
        <Icon name={TOAST_ICONS[toast.type]} />
        {(toast.title || message) && (
          <div className={styles.centerContainer}>
            <h4 className={styles.title}>{toast.title}</h4>
            <div className={styles.text}>{message}</div>
            {toast.type !== 'success' && (
              <Modal
                trigger={<button className={styles.viewButton}>View</button>}
                header={toast.title}
                content={toast.text}
                size="small"
                closeIcon
              />
            )}
          </div>
        )}

        {!!Body && <Body id={toast.id} {...toast.extraProps} />}

        <button className={styles.removeButton} onClick={removeToast}>
          <Icon name="remove" />
        </button>
      </div>
    </div>
  )
}

export default Toast

const formatThrottleMessage = (message: string) => {
  const secondsRemain = message.replace(/\D/g, '')

  return 'Request throttled. Available in ' + secondsRemain + ' seconds.'
}
