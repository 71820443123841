import { PlaytesterCardData, PlaytesterV2State } from 'redux/playtesterV2/types'

import { getCachedState } from 'redux/playtesterV2/helpers/cacheStateChanges'
import { getDeviceSpecificDefaults } from 'redux/playtesterV2/helpers/deviceSpecificDefaults'
import { CardType } from 'types/deck'

export const generateNewGameState = (): Partial<PlaytesterV2State> => {
  const { disableMuliganMenu } = getCachedState()

  return {
    battlefield: [],
    library: [],
    sideboard: [],
    hand: [],
    graveyard: [],
    exile: [],
    commandZone: [],
    attractions: [],
    junkyard: [],
    planes: [],

    undo: [],
    redo: [],

    cardCoordinates: {},
    selected: {},

    searching: null,
    openingHandOverlay: disableMuliganMenu ? false : true,

    turnCounter: 0,
    experiance: 0,
    infectDamage: 0,

    energy: 0,
    whiteMana: 0,
    blueMana: 0,
    blackMana: 0,
    redMana: 0,
    greenMana: 0,
    colorlessMana: 0,
  }
}

export const generateInitialState = (): PlaytesterV2State => ({
  // Used to store the deck without it interfearing with the game state
  allCards: {},
  categories: {},

  cleanGameZones: {
    library: [],
    sideboard: [],
    commandZone: [],
    attractions: [],
    planes: [],
  },

  tokens: [],

  battlefield: [],
  library: [],
  sideboard: [],
  hand: [],
  graveyard: [],
  exile: [],
  commandZone: [],
  attractions: [],
  junkyard: [],
  planes: [],
  miscCards: [],

  undo: [],
  redo: [],

  cardSize: 'normal',
  handSize: 'normal',
  cardLabelShown: false,
  defaultActionOnClick: true,
  handRotation: true,
  upkeepReminderActive: false,
  viewLargeCardOnCursorOver: false,
  countersWidget: false,
  hideFoilOverlay: false,
  showPopoutHand: false,
  disabledFancyZoneHoverEffect: false,
  disableMuliganMenu: false,
  disableReloadStop: false,
  disableOldPlaytesterLink: false,
  hideFancyZoneArrows: false,
  fancyZoneScrollDisabled: false,
  countersOverTextBoxes: false,

  isCtrlHeldDown: false,

  playmatColor: null,
  playmatUrl: null,
  sleevesColor: null,
  sleevesUrl: null,
  playmatColorExtendsToLowerThird: false,

  cardCoordinates: {},
  selected: {},
  multiSelectingWithoutControl: false,

  currentlyDraggedCards: {},

  cursorOverCard: null,
  cursorOverCardZone: null,
  globalCardTransform: null,

  showOverlaysInPopout: false,

  peeking: 0,
  searching: null,
  cardSearch: null,
  allTokensOverlay: false,
  openingHandOverlay: false,
  customCardCreationOverlay: false,

  randomGeneratorOverlay: false,
  randomGeneratedValues: [],
  generatingRandomValue: false,

  multiplayerFormat: false,

  startingLifeTotal: 20,
  lifeTotal: 20,
  lifeTotal2: 20,
  turnCounter: 0,
  infectDamage: 0,
  experiance: 0,

  energy: 0,
  whiteMana: 0,
  blueMana: 0,
  blackMana: 0,
  redMana: 0,
  greenMana: 0,
  colorlessMana: 0,

  xinputField: null,
  xinputMetaIds: null,
  xinputZone: null,

  counterModal: false,
  counterMetaIds: null,
  counterModalFrom: null,

  focusedCardMetaId: null,
  focusedFrom: null,

  maxCardsShownInHand: 9,

  ...getDeviceSpecificDefaults(),
  ...getCachedState(),
})

export default generateInitialState

export const generatePlaytesterCardMeta = (card?: CardType): PlaytesterCardData => ({
  id: '',
  cardId: '',

  flipped: card?.flippedDefault || false,
  tapped: false,
  faceDown: false,
  upsideDown: false,
  revealed: false,
  dimmed: false,

  token: false,
  tokenCopy: false,

  notes: '',
  counters: {},
  commandTax: 0,

  customPowerOffset: 0,
  customToughnessOffset: 0,
})
