import React, { useEffect, useRef, useState } from 'react'
import debounce from 'lodash.debounce'

import { CardType } from 'types/deck'
import { OWNERSHIP_COLORS } from 'types/collection'

import CardService from 'services/card.service'
import ToastService from 'services/toast.service'

import AsyncDropdown, { Option } from 'components/elements/AsyncDropdown'
import { setCodeToElement } from 'components/misc/customIcons/SetSymbols'

import styles from './asyncEditionDropdown.module.scss'

type Props = {
  card: CardType
  onCardSelected: (card: CardType) => void
  containerClassName?: string
  triggerClassName?: string
  menuClassName?: string
  disabled?: boolean
  basic?: boolean
  includeIcon?: boolean
  noIcon?: boolean
}

const AsyncEditionDropdown = ({
  containerClassName = '',
  triggerClassName = '',
  menuClassName = '',
  card,
  onCardSelected,
  includeIcon = false,
  disabled,
  noIcon,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(new Array<Option>())
  const [results, setResults] = useState(new Array<CardType>())

  const fetchCards = (filter = '') => {
    setLoading(true)

    CardService.list({
      name: card.name,
      editionSearch: filter,
      includeDigital: true,
      exact: true,
      allEditions: true,
      orderBy: '-editiondate',
    })
      .then(res => {
        setResults(res.cards)

        // Putting the results into a semantic options format
        // Always include the current edition as the first result
        // Filter to remove the card that's ready set
        // Add it back so it's always there
        const updatedOptions: Option[] = [
          {
            label: <DropdownItem card={card} selectedItem />,
            value: card.id,
          },
          { type: 'spacer', value: '' },
          ...res.cards
            .filter(c => c.id !== card.id)
            .map(c => ({
              label: <DropdownItem card={c} />,
              value: c.id,
            })),
        ]

        if (res.cards.length !== res.total)
          updatedOptions.push({
            value: null,
            type: 'message',
            label: (
              <span className={styles.insertedOption}>
                Showing {res.cards.length} of {res.total} {card.name} printings
              </span>
            ),
          })

        setOptions(updatedOptions)
      })
      .catch(err => {
        console.error(err)

        ToastService.create('Unable to fetch card editions', 'error')
      })
      .finally(() => setLoading(false))
  }

  const debouncedSeach = useRef(debounce(fetchCards, 500)).current

  const handleSearchChange = (searchQuery: string) => {
    setLoading(true)

    debouncedSeach(searchQuery)
  }

  const handleResultSelect = (value: any) => {
    const selectedCard = results.find(c => c.id === value)

    // Shouldn't be possible
    if (!selectedCard) return

    const updatedOptions = options.map((option: any, i: number) => {
      if (i !== 0) return option

      // Replace 0th index with newly selected option
      return {
        label: <DropdownItem card={selectedCard} />,
        value: selectedCard.id,
      }
    })

    setOptions(updatedOptions)

    onCardSelected({
      ...selectedCard,
      createdAt: card.createdAt,
      deckRelationId: card.deckRelationId,
      qty: card.qty,
      categories: card.categories,
      customCmc: card.customCmc,
      id: card.id,
      colorLabel: card.colorLabel,
      modifier: selectedCard.options.includes(card.modifier) ? card.modifier : selectedCard.options[0],
    })
  }

  useEffect(() => {
    setOptions([])
    setResults([])
  }, [card.id])

  return (
    <AsyncDropdown
      noIcon={noIcon}
      loading={loading}
      options={options}
      disabled={disabled}
      containerClassName={containerClassName}
      menuClassName={menuClassName}
      icon={includeIcon ? 'sitemap' : undefined}
      triggerClassName={triggerClassName}
      onChange={handleResultSelect}
      onFocus={() => results.length === 0 && handleSearchChange('')}
      onSearchChange={handleSearchChange}
      placeholder={card.set ? `${card.set} - (${card.setCode}) (${card.collectorNumber})` : 'No card selected'}
    />
  )
}

export default AsyncEditionDropdown

const DropdownItem = ({ card, selectedItem = false }: { card: CardType; selectedItem?: boolean }) => (
  <div className={styles.option}>
    <div className={styles.symbols}>
      {setCodeToElement(card.setCode, card.rarity)}
      <div className={styles.owned} style={{ backgroundColor: card.owned === 1 ? OWNERSHIP_COLORS.EXACT : 'unset' }} />
    </div>
    <div className={styles.words}>
      <div className={styles.setName}>
        {card.set}
        {selectedItem && <span>(Current)</span>}
      </div>
      <div className={styles.setInfo}>
        ({card.setCode.toLocaleUpperCase()}) ({card.collectorNumber})
      </div>
    </div>
  </div>
)
