import React, { useState } from 'react'
import { useAppSelector } from 'redux/hooks'
import Icon from 'components/elements/Icon'

import SearchHistoryService from 'services/searchHistory.service'

import { TabType, Meta, generateTabState, Tab, calculateArchidektSearchDiff, SearchViews } from 'types/searchV2'

import GlobalOverlayStack from 'components/elements/Overlay/GlobalOverlayStack'

import { timeSince } from 'utils/time.utils'

import styles from './searchHistory.module.scss'

type Props = {
  open: boolean
  onClose: () => void
  onTabSelected: (newTab: Tab) => void
}

const SearchHistory = ({ open, onClose, onTabSelected }: Props) => {
  const [history] = useState(SearchHistoryService.getHistory())

  const handleTabSelected = (type: TabType, meta: Meta) => {
    // @ts-ignore
    const newTab: Tab = { ...generateTabState.scrySearch(), type, meta }

    onTabSelected(newTab)
  }

  return (
    <GlobalOverlayStack open={open} onClose={onClose}>
      <h2>Recent Searches</h2>
      <div className={styles.records}>
        {history.map((record, index) => (
          <button
            className={styles.historyEntryContainer}
            onClick={() => handleTabSelected(record.type, record.meta)}
            key={index}>
            <span className={styles.iconContainer}>
              {record.type === 'scrySearch' ? (
                <>
                  <Icon name="code" /> Syntax Search
                </>
              ) : (
                <>
                  <img alt="Archidekt Icon" src="/images/archidekt_basic.svg" height="17" /> Archidekt Search
                </>
              )}
            </span>
            {/* @ts-ignore */}
            {record.type === 'scrySearch' && <span className={styles.queryContainer}>{record.meta.query}</span>}
            {record.type === 'archidektSearch' && (
              /* @ts-ignore */
              <span className={styles.queryContainer}>{calculateArchidektSearchDiff(record.meta)}</span>
            )}
            <span className={styles.timeContainer}>{record.time && timeSince(record.time)}</span>
          </button>
        ))}
      </div>
    </GlobalOverlayStack>
  )
}
export default SearchHistory
