import React, { useState } from 'react'

import { RaritySearchOptions } from 'services/apiTypes/card.types'

import styles from './rarityToggles.module.scss'

type Props = {
  value?: RaritySearchOptions
  onChange: (values: RaritySearchOptions) => void
}

// It's written in this strange way so that this can be either a controlled or an uncontrolled input
const RarityToggles = ({ value, onChange }: Props) => {
  const [common, setCommon] = useState<boolean>(value?.common ?? true)
  const [uncommon, setUncommon] = useState<boolean>(value?.uncommon ?? true)
  const [rare, setRare] = useState<boolean>(value?.rare ?? true)
  const [mythic, setMythic] = useState<boolean>(value?.mythic ?? true)
  const [special, setSpecial] = useState<boolean>(value?.special ?? true)

  const handleToggleCommon = () => {
    setCommon(!common)
    handleChange({ common: !value?.common ?? !common })
  }

  const handleToggleUncommon = () => {
    setUncommon(!uncommon)
    handleChange({ uncommon: !value?.uncommon ?? !uncommon })
  }

  const handleToggleRare = () => {
    setRare(!rare)
    handleChange({ rare: !value?.rare ?? !rare })
  }

  const handleToggleMythic = () => {
    setMythic(!mythic)
    handleChange({ mythic: !value?.mythic ?? !mythic })
  }

  const handleToggleSpecial = () => {
    setSpecial(!special)
    handleChange({ special: !value?.special ?? !special })
  }

  const handleChange = (updates: Partial<RaritySearchOptions>) => {
    onChange({
      common: value?.common ?? common,
      uncommon: value?.uncommon ?? uncommon,
      rare: value?.rare ?? rare,
      mythic: value?.mythic ?? mythic,
      special: value?.special ?? special,
      ...updates,
    })
  }

  return (
    <div className={styles.container}>
      <label>Card Rarity:</label>
      <button type="button" onClick={handleToggleCommon} style={{ opacity: value?.common ?? common ? '1' : '0.2' }}>
        <i className="ss ss-bcore ss-common ss-grad ss-2x" />
      </button>
      <button
        type="button"
        onClick={handleToggleUncommon}
        style={{ opacity: value?.uncommon ?? uncommon ? '1' : '0.2' }}>
        <i className="ss ss-bcore ss-uncommon ss-grad ss-2x" />
      </button>
      <button type="button" onClick={handleToggleRare} style={{ opacity: value?.rare ?? rare ? '1' : '0.2' }}>
        <i className="ss ss-bcore ss-rare ss-grad ss-2x" />
      </button>
      <button type="button" onClick={handleToggleMythic} style={{ opacity: value?.mythic ?? mythic ? '1' : '0.2' }}>
        <i className="ss ss-bcore ss-mythic ss-grad ss-2x" />
      </button>
      <button type="button" onClick={handleToggleSpecial} style={{ opacity: value?.special ?? special ? '1' : '0.2' }}>
        <i className="ss ss-bcore ss-timeshifted ss-grad ss-2x" />
      </button>
    </div>
  )
}

export default RarityToggles
