import styled from 'styled-components'

export const SaltMeterContainer = styled.div`
  width: 100%;
  border-radius: 7px;
  border: 2px solid ${props => props.theme.lightColor};
  margin: 10px 0 45px 0;
  height: 20px;
  position: relative;
  background: rgb(139, 196, 132);
  background: linear-gradient(90deg, rgba(139, 196, 132, 0.7) 0%, rgba(255, 104, 70, 0.7) 100%);
`

export const GaugeContainer = styled.div<{ leftPercent: number }>`
  position: absolute;
  overflow: visible;
  width: 0;
  left: ${props => props.leftPercent}%;
  top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    margin: 0;
  }
`

export const MaskDiv = styled.div<{ widthPercent: number }>`
  background-color: ${props => props.theme.backgroundColor};
  position: absolute;
  right: 0;
  width: ${props => props.widthPercent}%;
  height: 16px;
  border-radius: ${props => (props.widthPercent === 100 ? `5px` : `0 5px 5px 0`)};
`

export const Hash = styled.div<{ height: number; leftPercent: number }>`
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 2px;
  height: ${props => props.height}px;
  left: ${props => props.leftPercent}%;
  background-color: ${props => props.theme.lightColor2};
`
export const ScoreContainer = styled.div`
  padding: 0 4px;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.lightColor2};
  margin-top: 2px;
  font-size: 0.75em;
`
