import React, { useEffect, useState } from 'react'
import { Icon, SemanticICONS } from 'semantic-ui-react'

import { Option as CSSDropdownOption } from 'components/elements/ArchidektDropdown/types'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import SimpleSpinner from 'components/elements/SimpleSpinner'

import styles from './asyncArchidektDropdown.module.scss'

export type Option = CSSDropdownOption & {
  value: any
}

type Props = {
  value?: string
  options: Option[]
  onChange: (selectedOption: any) => void
  onSearchChange: (value: string) => void
  onFocus?: (e: React.FocusEvent) => void
  searchingMessage?: React.ReactNode
  noResultsMessage?: React.ReactNode
  menuClassName?: string
  triggerClassName?: string
  containerClassName?: string
  disabled?: boolean
  loading?: boolean
  placeholder?: string
  noClearOnClick?: boolean
  noShowWithoutOptions?: boolean
  skipCloseOnClick?: boolean
  label?: React.ReactNode
  icon?: SemanticICONS
  noIcon?: boolean
}

const AsyncDropdown = React.forwardRef((props: Props, ref: any) => {
  const {
    loading,
    value,
    options,
    onChange,
    onFocus,
    searchingMessage = 'Fetching results...',
    noResultsMessage = 'No results found',
    containerClassName,
    triggerClassName,
    menuClassName,
    disabled,
    placeholder,
    onSearchChange,
    noClearOnClick,
    noShowWithoutOptions,
    skipCloseOnClick,
    label,
    icon,
    noIcon = false,
  } = props

  const [search, setSearch] = useState(value || '')

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    onSearchChange(e.target.value)
  }

  const handleSelect = (value: any) => {
    if (!noClearOnClick) setSearch('')
    onChange(value)
  }

  useEffect(() => {
    if (value !== undefined) setSearch('')
  }, [value])

  const handledOptions = options.map(o => ({ ...o, onClick: () => handleSelect(o.value) }))
  const loadingOptions: CSSDropdownOption[] = [{ type: 'message', label: searchingMessage }]
  const noResultsOptions: CSSDropdownOption[] = [{ type: 'message', label: noResultsMessage }]

  let usedOptions: CSSDropdownOption[] = handledOptions

  // Only show the no results message with 1) No results and you've searched something
  if (usedOptions.length === 0 && (search.length > 2 || !noShowWithoutOptions)) usedOptions = noResultsOptions
  if (loading) usedOptions = loadingOptions

  return (
    <ArchidektDropdown
      tabIndex={-1}
      skipCloseOnClick={skipCloseOnClick}
      triggerClassName={containerClassName}
      noShowWithoutOptions={noShowWithoutOptions}
      disabled={disabled}
      menuClassName={`${styles.menu} ${menuClassName}`}
      options={usedOptions}>
      <div className={styles.container}>
        {icon && <Icon name={icon} className={styles.prefixIcon} />}
        {label && <label className={styles.label}>{label}</label>}
        <input
          ref={ref}
          type="text"
          autoCorrect="off"
          autoComplete="off"
          className={`${styles.input} ${icon ? styles.prefixIconPadding : ''} ${triggerClassName}`}
          onFocus={(e: React.FocusEvent) => !disabled && onFocus && onFocus(e)}
          disabled={disabled}
          value={value || search}
          placeholder={placeholder}
          onChange={handleTextChange}
        />
        {!loading && !noIcon && (
          <Icon name="caret down" className={`${styles.chevron} ${disabled ? styles.disabled : ''}`} />
        )}
        {loading && <SimpleSpinner className={styles.loader} size="xSmall" />}
      </div>
    </ArchidektDropdown>
  )
})

export default AsyncDropdown
