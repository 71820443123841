import { RequestService } from 'services/request.service'

import { GlobalCategory, GlobalCategoryCard, GlobalCategoryCardList } from 'services/apiTypes/globalCategory.types'
import { responseToCard } from 'utils/ResponseToResults'

export class GlobalCategoryService extends RequestService {
  public list = (page = 1): Promise<GlobalCategoryCard> => {
    return super.get<GlobalCategoryCardList>(`/api/users/globalCategory/?page=${page}`).then(res => {
      return {
        total: res.total,
        next: res.next,
        previous: res.previous,
        categories: res.categories,
        cards: res.cards.map(card => responseToCard(card)),
      }
    })
  }

  public create = (globalCategory: GlobalCategory) => {
    return super.post(`/api/users/globalCategory/`, globalCategory)
  }

  public updateByOracleCardId = (oracleCardId: number | string, categories: string[]) => {
    return super.post(`/api/users/globalCategory/oracleCard/${oracleCardId}/`, { categories })
  }

  public remove = (globalCategoryId: number | string) => {
    return super.delete(`/api/users/globalCategory/${globalCategoryId}/`)
  }
}

const globalCategoryService = new GlobalCategoryService()

export default globalCategoryService
