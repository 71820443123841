import React from 'react'
import { CardType, generateDefaultCategory } from 'types/deck'
import { store } from 'redux/store'

export const getShoppingCards = (
  cards: Array<CardType>,
  listOption: string,
  categories: Record<string, any>,
  tags: Array<string>,
  collectionOptions: Array<number>,
): Array<CardType> => {
  const categoryMap = store.getState().deck.categories

  return cards.filter(card => {
    const primaryCategory = categoryMap[card?.categories[0] || ''] || generateDefaultCategory()

    return (
      listOption === 'all' ||
      (listOption === 'allx' && primaryCategory.includedInDeck) ||
      (listOption === 'cat' && categories.includes(primaryCategory.name)) ||
      (listOption === 'tag' && tags.includes(card.colorLabel.name === '' ? 'Default' : card.colorLabel.name)) ||
      (listOption === 'coll' && collectionOptions.includes(card.owned)) ||
      (listOption === 'collx' && primaryCategory.includedInDeck && collectionOptions.includes(card.owned))
    )
  })
}

export const generateShoppingResults = (cards: Array<CardType>): Record<string, any> => {
  let ckprice = 0.0
  let tcgprice = 0.0
  let chprice = 0.0
  let scgprice = 0.0
  let mpprice = 0.0
  let tcgLandPrice = 0.0

  let ckstring = ''
  let tcgstring = ''
  let scgstring = ''
  let mpstring = ''
  let tcgLandString = ''

  const chInputs: Array<React.ReactNode> = []
  const categoryMap = store.getState().deck.categories

  cards.forEach((card, i) => {
    if (!card) return

    const isFoil = card.modifier !== 'Normal'
    const primaryCategory = categoryMap[card?.categories[0] || ''] || generateDefaultCategory()
    const scgSku = isFoil ? card.ids.scgFoil : card.ids.scg

    const ck = isFoil ? card.prices.ckFoil : card.prices.ck
    const tcg = isFoil ? card.prices.tcgFoil : card.prices.tcg
    const ch = isFoil ? card.prices.mtgoFoil : card.prices.mtgo
    const scg = isFoil ? card.prices.scgFoil : card.prices.scg
    const mp = isFoil ? card.prices.mpFoil : card.prices.mp

    ckprice += card.qty * ck
    tcgprice += card.qty * tcg
    chprice += card.qty * ch
    scgprice += card.qty * scg
    mpprice += card.qty * mp
    tcgLandPrice += card.qty * card.prices.tcgLand

    // This converts diacritics to regular characters
    const cardName = card.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

    chInputs.push(
      <React.Fragment key={i}>
        {card.ids.mtgoNormalId || card.ids.mtgoFoilId ? (
          <input
            type="hidden"
            name={`data[Deck][cards][${i}][id]`}
            value={isFoil ? (card.ids.mtgoFoilId ? card.ids.mtgoFoilId : card.ids.mtgoNormalId) : card.ids.mtgoNormalId}
          />
        ) : (
          <input type="hidden" name={`data[Deck][cards][${i}][name]`} value={cardName.split('//')[0]} />
        )}
        <input type="hidden" name={`data[Deck][cards][${i}][quantity]`} value={card.qty} />
        <input
          type="hidden"
          name={`data[Deck][cards][${i}][is_sideboard]`}
          value={primaryCategory.name === 'Sideboard' ? '1' : '0'}
        />
      </React.Fragment>,
    )

    if (card.layout === 'split') {
      tcgstring += `${card.qty} ${cardName}||`
      ckstring += `${card.qty} ${cardName}||`
    } else {
      ckstring += `${card.qty} ${cardName.split('//')[0].trim()}||`
      tcgstring += `${card.qty} ${cardName.split('//')[0].trim()}||`
    }

    // SCG Broke something, idk what
    // scgstring += `${card.qty} ${cardName}${scgSku ? ` [sku: "${scgSku}"]` : ''} ||`
    scgstring += `${card.qty} ${cardName} ||`
    mpstring += `${card.qty} ${cardName} [${card.setCode}] ${card.collectorNumber}\n`
    tcgLandString += `${card.qty}x ${cardName} (${card.setCode}) ${card.collectorNumber} |`
  })

  tcgstring = tcgstring.substring(0, tcgstring.length - 2)

  return {
    ckprice: ckprice.toFixed(2),
    tcgprice: tcgprice.toFixed(2),
    chprice: chprice.toFixed(2),
    scgprice: scgprice.toFixed(2),
    mpprice: mpprice.toFixed(2),
    tcgLandPrice: tcgLandPrice.toFixed(2),
    tcgstring: tcgstring,
    ckstring: ckstring,
    scgstring: scgstring,
    mpstring: mpstring,
    tcgLandString,
    chelements: <React.Fragment>{chInputs}</React.Fragment>,
  }
}
