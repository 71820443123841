import * as Types from 'redux/collectionV2/actions/types'

import { generateInitialState } from 'redux/collectionV2/initialState'

import { CollectionV2Actions } from 'redux/collectionV2/actions'

import { CollectionV2State } from 'redux/collectionV2/types'

import { writeChangesToCookies } from 'redux/collectionV2/helpers/cachedValues'

const handler: (state: CollectionV2State, action: CollectionV2Actions) => CollectionV2State = (
  state = generateInitialState(),
  action,
) => {
  switch (action.type) {
    case Types.SET_COLLECTION_V2_STATE:
      writeChangesToCookies(action.payload)

      return { ...state, ...action.payload }

    default:
      return state
  }
}

export default handler
