import React from 'react'
import { Dropdown } from 'semantic-ui-react'

import { GameType } from 'types/deck'

const options = [
  { text: 'Paper', value: 1 },
  { text: 'MTGO', value: 2 },
  { text: 'Arena', value: 3 },
]

type Props = {
  value?: number | GameType | null
  onChange: (value: number) => void
}

const SortingDropdown = ({ value = options[0].value, onChange }: Props) => {
  const handleChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: any) => onChange(value)

  return (
    <div>
      <label>Game filter (paper, mtgo, arena):</label>
      {/* @ts-ignore */}
      <Dropdown selection fluid options={options} value={value} onChange={handleChange} />
    </div>
  )
}

export default SortingDropdown
