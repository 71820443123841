import React, { useEffect, useState, useRef } from 'react'
import { debounce } from 'lodash'

import { store } from 'redux/store'
import { useAppSelector } from 'redux/hooks'

import CardService from 'services/card.service'
import ToastService from 'services/toast.service'

import { CardType, GAME_IDS } from 'types/deck'
import { PRICE_CODES } from 'types/active'

import Icon from 'components/elements/Icon'
import CardResultsGrid from 'components/cardSearchPanel/cardLayouts/cardResultsGrid'
import GlobalOverlayStack from 'components/elements/Overlay/GlobalOverlayStack'
import PhatButton from 'components/formElements/PhatButton'
import PhatInput from 'components/formElements/PhatInput'
import Checkbox from 'components/formElements/Checkbox'
import PhatDropdown from 'components/formElements/PhatDropdown'

import { NextPrevOptions } from 'components/elements/Overlay/CardDetailsOverlay'

import styles from './cardEditionsGridOverlay.module.scss'

type Props = {
  open: boolean
  onClose: () => void
  card: CardType
  onCardSelected: (card: CardType) => void

  nextPrevControls?: {
    previous: NextPrevOptions
    next: NextPrevOptions
  }
}

const CardEditionsGridOverlay = ({ open, onClose, card, onCardSelected, nextPrevControls }: Props) => {
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState(new Array<CardType>())
  const [next, setNext] = useState<string | null>(null)
  const [lastFetched, setLastFetched] = useState<string | null>(null)
  const [limitToGameType, setLimitToGameType] = useState(true)
  const [orderBy, setOrderBy] = useState<string>('editiondate')

  const game = useAppSelector(state => state.deck.game)

  useEffect(() => {
    if (!open) return

    // Collaborative editng the card map causes a re-render of the card, and we don't want it to refetch unless it needs to
    if (lastFetched !== card.name) handleSearch(card.name)
  }, [card, open])

  useEffect(() => {
    if (!open) return

    handleSearch(card.name)
  }, [limitToGameType, orderBy])

  const handleSearch = (cardName: string, editionSearch = '') => {
    if (!cardName) return

    setLoading(true)
    setResults([])

    let actualOrderBy = '-editiondate'

    if (orderBy === 'editiondate') actualOrderBy = '-editiondate'
    if (orderBy === 'editionname') actualOrderBy = 'editionname'

    if (orderBy === 'price') {
      const priceSource = store.getState().active.priceSource[0]
      const priceCode = PRICE_CODES[priceSource]

      actualOrderBy = `prices__${priceCode}`
    }

    CardService.list({
      name: cardName,
      editionSearch,
      includeDigital: true,
      includeArtCards: true,
      includeEmblems: true,
      includeTokens: true,
      exact: true,
      allEditions: true,
      orderBy: actualOrderBy,
      game: limitToGameType ? game : null,
    })
      .then(res => {
        setResults(res.cards)
        setNext(res.next)
        setLastFetched(cardName)
      })
      .catch(err => {
        console.error(err)

        ToastService.create('Unable to fetch card editions', 'error')
      })
      .finally(() => setLoading(false))
  }

  const debouncedSeach = useRef(debounce(handleSearch, 500)).current

  const handleSearchChange = (query: string) => {
    setLoading(true)

    debouncedSeach(card.name, query)
  }

  const handleLoadMore = () => {
    setLoading(true)

    CardService.list({}, next)
      .then(res => {
        setResults([...results, ...res.cards])
        setNext(res.next)
      })
      .catch(err => {
        console.error(err)

        ToastService.create('Unable to fetch card editions', 'error')
      })
      .finally(() => setLoading(false))
  }

  const handleSelect = (updatedCard: CardType) => {
    handleOnClose()

    setTimeout(() => {
      onCardSelected({
        ...updatedCard,
        createdAt: card.createdAt,
        deckRelationId: card.deckRelationId,
        qty: card.qty,
        categories: card.categories,
        customCmc: card.customCmc,
        colorLabel: card.colorLabel,
        id: card.id,
        modifier: updatedCard.options.includes(card.modifier) ? card.modifier : updatedCard.options[0],
      })
    }, 500) // Allows for close animation to finish
  }

  const handleOnClose = () => {
    onClose()
  }

  const replacedResults = results.map(c => {
    // If the card is the card that's been selected
    if ([c.uid].includes(card.uid)) return card

    return c
  })

  const nextPrevButtonGroup = nextPrevControls ? (
    <>
      <button
        className={styles.nextPrevButton}
        onClick={nextPrevControls.previous.onClick}
        disabled={nextPrevControls.previous.disabled}>
        <Icon name="arrow left" /> <span>{nextPrevControls.previous.label}</span>
      </button>

      <button
        className={styles.nextPrevButton}
        onClick={nextPrevControls.next.onClick}
        disabled={nextPrevControls.next.disabled}>
        <span>{nextPrevControls.next.label}</span>
        <Icon name="arrow right" />
      </button>
    </>
  ) : null

  const gameTypeName = game ? GAME_IDS[game] : null

  return (
    <GlobalOverlayStack
      open={open}
      onClose={handleOnClose}
      className={styles.container}
      mobileControls={nextPrevButtonGroup}
      mobileControlsClassName={styles.mobileNexPrevContainer}>
      <div className={styles.content}>
        <div className={styles.controls}>
          <div className={styles.formElements}>
            <PhatInput
              header="Search editions..."
              selectOnFocus
              key={card.id}
              className={styles.input}
              placeholder="Filter set name"
              onChange={handleSearchChange}
            />
            <PhatDropdown
              value={orderBy}
              header="Order by"
              options={[
                {
                  label: 'Edition date',
                  id: 'editiondate',
                  onClick: () => setOrderBy('editiondate'),
                },
                {
                  label: 'Set Name',
                  id: 'editionname',
                  onClick: () => setOrderBy('editionname'),
                },
                {
                  label: 'Price',
                  id: 'price',
                  onClick: () => setOrderBy('price'),
                },
              ]}
            />
          </div>

          {!!nextPrevControls && <div className={styles.nextPrevContainer}>{nextPrevButtonGroup}</div>}
        </div>

        {!!game && (
          <div>
            <span className={styles.checkbox}>
              <Checkbox
                label={`Limit to ${gameTypeName} cards`}
                checked={limitToGameType}
                onChange={setLimitToGameType}
              />
            </span>
          </div>
        )}

        <CardResultsGrid loading={loading} cards={replacedResults} onCardClick={handleSelect} editionControls />

        {next && !loading && (
          <div className={styles.center}>
            <PhatButton onClick={handleLoadMore}>Load more</PhatButton>
          </div>
        )}
      </div>
    </GlobalOverlayStack>
  )
}

export default CardEditionsGridOverlay
