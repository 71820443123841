import React, { useEffect, useState } from 'react'

import type { CardType } from 'types/deck'

import CategoryRow from './categoryRow'
import PhatButton from 'components/formElements/PhatButton'
import { ToCategoryButton } from 'components/card/cardForm/toCategoryButtons'

import styles from './categories.module.scss'

type Props = {
  card: CardType
  onCardChange: (updatedCard: CardType) => void
}

// Category Selection list (the one where you can click the blue star)
const Categories = ({ card, onCardChange }: Props) => {
  const handleCategoryChange = (updatedCard: CardType) => {
    setIsAdding(false)
    onCardChange({ ...updatedCard, qty: updatedCard.qty || 1 })
  }

  const [isAdding, setIsAdding] = useState(false)

  return (
    <div className={styles.container} key={card.id}>
      {card.categories.map((category, i) => (
        <CategoryRow
          key={i}
          card={card}
          category={category}
          primary={i === 0}
          onCardChange={handleCategoryChange}
          disableRemoveCategory={card.categories.length === 1}
        />
      ))}
      {isAdding && (
        <CategoryRow
          onClose={() => setIsAdding(false)}
          card={card}
          category=""
          primary={false}
          onCardChange={handleCategoryChange}
          onDismissNewCategory={() => setIsAdding(false)}
        />
      )}

      <div className={styles.controls}>
        <PhatButton onClick={() => setIsAdding(true)} icon="plus" color="blue">
          Add Category
        </PhatButton>
      </div>
    </div>
  )
}

export default Categories
