import React from 'react'

import { GlobalColorTag } from 'services/apiTypes/colorTag.types'
import { CardType } from 'types/deck'

import PhatDropdown from 'components/formElements/PhatDropdown'

import useDeckColorTagOptions from 'hooks/useDeckColorTagOptions'

import styles from './colorLabelDropdown.module.scss'

type Props = {
  card: CardType
  disabled?: boolean
  onCardUpdate: (card: CardType) => void
}

const ColorLabelDropdown = ({ card, disabled = false, onCardUpdate }: Props) => {
  const handleChange = (selectedTag: GlobalColorTag) => onCardUpdate({ ...card, colorLabel: selectedTag })

  const [options, onFocus] = useDeckColorTagOptions(handleChange)

  return (
    <PhatDropdown
      onFocus={onFocus}
      triggerWrapperClassName={styles.triggerWrapper}
      triggerClassName={styles.button}
      disabled={disabled}
      value={`${card.colorLabel.name},${card.colorLabel.color}`}
      options={options}
    />
  )
}

export default ColorLabelDropdown
