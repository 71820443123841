import React from 'react'
import Tooltip from 'components/tooltips/Tooltip'

type Props = Record<string, never>

const SaltScoreInfo: React.FC<Props> = () => {
  return (
    <Tooltip
      content={
        <div>
          <p>
            The <b>EDH Salt Score</b> is a crowd sourced indicator of how salty cards make players in EDH. The data is
            aggregated by EDHREC. &nbsp;
          </p>
          <p>
            The Salt Score is on a scale of 0 to 4. On this scale a 0 indicates no salt, and 4 indicates the most salt
            inducing card possible.
          </p>
          <a href="https://edhrec.com/top/salt" rel="noopener noreferrer" target="_blank">
            Click for more info.
          </a>
        </div>
      }
    />
  )
}

export default SaltScoreInfo
