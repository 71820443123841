import React, { useEffect, useState } from 'react'

import { ManabaseGeneratorMeta } from 'types/searchV2'
import { CardListResponse, Color } from 'services/apiTypes/card.types'

import EDHRECService from 'services/edhrec.service'

import { useAppSelector } from 'redux/hooks'
import { selectCommanderCards, selectMaindeckCards } from 'redux/deck/selectors'

import PhatButton from 'components/formElements/PhatButton'
import PhatDropdown from 'components/formElements/PhatDropdown'

import { getExternalColorCodeFromColors } from 'utils/colorUtils'

import styles from './manabaseGeneratorForm.module.scss'

type Props = {
  onSubmitted: () => void
  onRequestFailed: (message?: string) => void
  // send back the search object to the parent in case anything wants to be done with it
  onResponseRecieved: (res: CardListResponse, meta: ManabaseGeneratorMeta) => void
  className?: string
  alreadyHasResults?: boolean
  noAutoSearch?: boolean
}

const ManabaseGeneratorForm = ({ onSubmitted, onRequestFailed, onResponseRecieved, noAutoSearch }: Props) => {
  const [orderBy, setOrderBy] = useState<'popularity' | 'cycles'>('popularity')

  const commanders = useAppSelector(selectCommanderCards)
  const maindeck = useAppSelector(selectMaindeckCards)

  const handleSearch = (e?: React.SyntheticEvent, edhrecOrderBy = orderBy) => {
    e?.preventDefault()

    const commanderColors = commanders.reduce((acc, c) => [...acc, ...c.colorIdentity], new Array<Color>())
    const maindeckColors = maindeck.reduce((acc, c) => [...acc, ...c.colorIdentity], new Array<Color>())

    const externalColorCode = getExternalColorCodeFromColors([...commanderColors, ...maindeckColors])

    onSubmitted() // let the parent know we're about to submit the search

    EDHRECService.colors(externalColorCode, edhrecOrderBy)
      .then(res => onResponseRecieved(res, {}))
      .catch(err => {
        console.error(err)

        onRequestFailed(err.error)
      })
  }

  useEffect(() => {
    if (noAutoSearch) return

    handleSearch()
  }, [])

  return (
    <form onSubmit={handleSearch} className={styles.form}>
      <PhatDropdown
        className={styles.orderBy}
        value={orderBy}
        options={[
          { label: 'Popularity', onClick: () => setOrderBy('popularity'), id: 'popularity' },
          { label: 'Cycles', onClick: () => setOrderBy('cycles'), id: 'cycles' },
        ]}
      />
      <PhatButton icon="search" color="green">
        Search
      </PhatButton>
    </form>
  )
}

export default ManabaseGeneratorForm
