import React, { useMemo, useState } from 'react'
import { debounce } from 'lodash'

import { CardType, Finishes } from 'types/deck'

import Icon from 'components/elements/Icon'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'

import styles from './toggleFoilButton.module.scss'

type Props = {
  card: CardType
  onCardUpdate: (updatedCard: CardType) => void
  className?: string
  triggerClassName?: string
}

const ToggleFoilButton = ({ card, onCardUpdate, className = '', triggerClassName }: Props) => {
  const [localModifier, setLocalModifier] = useState<Finishes>(card.modifier)

  const handleSetDebouncedModifier = (modifier: Finishes) => onCardUpdate({ ...card, modifier })

  const handleChangeModifier = (modifier: Finishes) => {
    setLocalModifier(modifier)
    debouncedQtyChange(modifier)
  }

  const debouncedQtyChange = useMemo(() => {
    if (card.modifier !== localModifier) setLocalModifier(card.modifier)

    return debounce(handleSetDebouncedModifier, 660)
  }, [card])

  if (card.options.length <= 2)
    return (
      <Trigger
        modifier={localModifier}
        className={triggerClassName}
        disabled={card.options.length < 2}
        onClick={() => handleChangeModifier(localModifier === card.options[0] ? card.options[1] : card.options[0])}
      />
    )

  return (
    <ArchidektDropdown
      triggerClassName={className}
      menuClassName={styles.optionsMenu}
      options={card.options.map(option => ({
        label: option === 'Normal' ? 'Nonfoil' : option,
        onClick: () => handleChangeModifier(option),
      }))}>
      <Trigger modifier={localModifier} className={triggerClassName} disabled={card.options.length < 2} />
    </ArchidektDropdown>
  )
}

export default ToggleFoilButton

const Trigger = ({
  modifier,
  disabled,
  onClick,
  className,
}: {
  modifier: Finishes
  disabled?: boolean
  onClick?: () => void
  className?: string
}) => (
  <button className={`${styles.trigger} ${className}`} onClick={onClick} disabled={disabled}>
    <Icon
      name={modifier !== 'Normal' ? 'star' : 'star outline'}
      color={modifier === 'Foil' ? 'blue' : modifier === 'Etched' ? 'orange' : undefined}
    />
    {/* Replace normal with nonfoil for the user's viewing pleasure */}
    {modifier === 'Normal' ? 'Nonfoil' : modifier}
  </button>
)
