import { Color, ColorAbr } from 'services/apiTypes/card.types'

// Ripped from front of magic card
const white = '#fbf6d3'
const blue = '#96c4d4'
const black = '#b0a8a3'
const red = '#d88f73'
const green = '#93cba4'
const colorless = '#a9a8a8'
const multicolor = '#f2aa3c'

export const COLOR_OBJECT: Record<string, string> = {
  White: white,
  Blue: blue,
  Black: black,
  Red: red,
  Green: green,
  Colorless: colorless,
  Multicolor: multicolor,
}

export const COLORSYMBOL_OBJECT: Record<string, string> = {
  W: white,
  U: blue,
  B: black,
  R: red,
  G: green,
  C: colorless,
  M: multicolor,
}

export const COLOR_MAP: Record<string, Color> = {
  W: 'White',
  U: 'Blue',
  B: 'Black',
  R: 'Red',
  G: 'Green',
  C: 'Colorless',
}

export const REVERSE_COLOR_MAP: Record<Color, ColorAbr> = {
  White: 'W',
  Blue: 'U',
  Black: 'B',
  Red: 'R',
  Green: 'G',
  Colorless: 'C',
}

export const TYPE_OBJECT: Record<string, string> = {
  Artifact: '#919191',
  Conspiracy: '#13BFD6',
  Creature: '#A1ACD4',
  Enchantment: '#92cc89',
  Instant: '#96C4D4',
  Land: '#8CD4BE',
  Phenomenon: '#D69F29',
  Plane: '#C596D4',
  Battle: '#44c767',
  Planeswalker: '#ffb157',
  Scheme: '#D496B9',
  Sorcery: '#cf907e',
  Vanguard: '#D64129',
  Tribal: '#1E60D6',
}

export const SUBTYPE_ALLOWLIST: Record<string, string[]> = {
  Artifact: ['Blood', 'Clue', 'Contraption', 'Equipment', 'Food', 'Fortification', 'Gold', 'Treasure', 'Vehicle'],
  Enchantment: ['Aura', 'Cartouche', 'Class', 'Curse', 'Rune', 'Saga', 'Shrine', 'Shard', 'Background', 'Room'],
  Land: [
    'Plains',
    'Island',
    'Swamp',
    'Mountain',
    'Forest',
    'Desert',
    'Gate',
    'Lair',
    'Locus',
    "Urza's",
    'Urza’s',
    'Mine',
    'Power-Plant',
    'Tower',
  ],
  Instant: ['Adventure', 'Arcane', 'Lesson', 'Trap'],
  Sorcery: ['Adventure', 'Arcane', 'Lesson', 'Trap'],
}

// Returns the calculated height or the provided height if the calculated height is smaller than the provided height
// Also returns whether or not using auto height would do anything
export const calculateChartHeight = (
  providedHeight: number,
  useAutoheight: boolean,
  chartData: any[],
): [number, boolean] => {
  const automaticallyDeterminedHeight = chartData.length * 30
  const isDisabled = automaticallyDeterminedHeight < providedHeight

  if (!useAutoheight) return [providedHeight, isDisabled]
  if (isDisabled) return [providedHeight, isDisabled]

  return [automaticallyDeterminedHeight, false]
}
