import { RequestService } from 'services/request.service'

import { CardType } from 'types/deck'
import { SpellbookCombos, SpellbookResponse } from 'services/apiTypes/spellbook.types'

import { responseToCard } from 'utils/ResponseToResults'

type SpellBookRequestShape = { card: string; quantity: number }[] // card is cardName

export class SpellbookService extends RequestService {
  public combos = (commanders: CardType[], mainboard: CardType[]): Promise<SpellbookCombos> => {
    const commanderDataShape: SpellBookRequestShape = commanders.map(commander => ({
      card: commander.name,
      quantity: commander.qty,
    }))

    const mainboardDataShape: SpellBookRequestShape = mainboard.map(card => ({
      card: card.name,
      quantity: card.qty,
    }))

    return super
      .post<SpellbookResponse>(`/api/cards/spellbook-combos/`, {
        commanders: commanderDataShape,
        main: mainboardDataShape,
      })
      .then(res => ({
        almostIncluded: res.almostIncluded,
        included: res.included,

        // Serilaizing cards from DB to match existing CardType
        cards: Object.keys(res.cards).reduce((acc: Record<string, CardType>, oracleUid: string) => {
          const resCard = res.cards[oracleUid]
          const card = responseToCard(resCard)

          return { ...acc, [oracleUid]: card }
        }, {}),
      }))
  }
}

const spellbookService = new SpellbookService()

export default spellbookService
