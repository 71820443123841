import CookieService from 'services/cookie.service'

import { CachablesState } from 'redux/cacheables/initialState'

const COOKIE_KEY = 'cacheable-state'

export const writeChangesToCookies = (changes: CachablesState) => {
  CookieService.set(COOKIE_KEY, changes, 'year')
}

export function getCachedState(serverCookies?: Record<string, any> | string): Partial<CachablesState> {
  const cachedState = CookieService.get(COOKIE_KEY, serverCookies) as Partial<CachablesState>

  return cachedState
}
