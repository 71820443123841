import CookieService from 'services/cookie.service'

export type AddCardView = 'selecting' | 'reconciling' | 'finalizing'

export type AddCardFormOptions = {
  addCardOnSelect: boolean
  showAdvancedOptions: boolean
  updateIfOneExactExists: boolean
  skipExistingTab: boolean

  keepQuantity: boolean
  keepModifier: boolean
  keepTags: boolean
  keepLanguage: boolean
  keepCondition: boolean
  keepPurchasePrice: boolean

  stayOnCardDetails: boolean
}

export const defaultAddCardFormOptions: AddCardFormOptions = {
  addCardOnSelect: false,
  showAdvancedOptions: false,
  updateIfOneExactExists: false,
  skipExistingTab: false,

  keepQuantity: false,
  keepModifier: false,
  keepTags: false,
  keepLanguage: false,
  keepCondition: false,
  keepPurchasePrice: false,

  stayOnCardDetails: false,
}

const COOKIE_KEY = 'collectionAddCardSettings'

export const cachedOptionFields: Array<keyof AddCardFormOptions> = [
  'addCardOnSelect',
  'showAdvancedOptions',
  'updateIfOneExactExists',
]

export const getDefaultAddCardFormOptions = (): AddCardFormOptions => {
  const cachedOptions = CookieService.get('collectionAddCardSettings', {})

  return {
    ...defaultAddCardFormOptions,
    ...cachedOptions,
  }
}

export const setCachedAddCardFormOptions = (options: AddCardFormOptions): void => {
  const cachedOptions: Partial<AddCardFormOptions> = {}

  for (const field of cachedOptionFields) {
    cachedOptions[field] = options[field]
  }

  CookieService.set(COOKIE_KEY, cachedOptions)
}
