import { RequestService } from 'services/request.service'
import { fixDangjosStupidFuckingInternalProtocolIssue } from 'services/card.service'
import {
  CollectionCardCreateType,
  CollectionCardModificationType,
  CollectionCardResponseType,
  CollectionImportResponseType,
  CollectionListResponseType,
  CollectionStatsType,
  CollectionTagResponseType,
  CollectionTextImportOptionsType,
  UploadResponseV2,
  CollectionResponseV2,
} from 'services/apiTypes/collection.types'
import { CollectionTag, CollectionV2Card } from 'redux/collectionV2/types'

export class CollectionService extends RequestService {
  // prettier-ignore
  public rawList = (userId: number | string, query: string = '', next?: string): Promise<CollectionListResponseType> => {
    if (next) return super.get(fixDangjosStupidFuckingInternalProtocolIssue(next))

    return super.get(`/api/collection/${userId}/${query}`)
  }

  // prettier-ignore
  public create = (body: CollectionCardCreateType | CollectionCardCreateType[]): Promise<CollectionCardResponseType> => {
    return super.post('/api/collection/create/', body)
  }

  public modifyCards = (
    modifications: CollectionCardModificationType,
    ids: number[] | string[],
    tagOption: 'add' | 'replace' = 'replace',
  ): Promise<CollectionCardResponseType[]> => {
    return super.patch('/api/collection/bulk/', {
      modifications,
      ids: ids.map((id: string | number) => Number(id)),
      tagOption,
    })
  }

  public modifyCard = (
    modifications: CollectionCardModificationType,
    id: number,
  ): Promise<CollectionCardResponseType> => {
    return this.modifyCards(modifications, [id]).then(data => data[0])
  }

  public deleteCards = (ids: number[] | string[]): Promise<{ status: 'success' }> => {
    const body = {
      body: JSON.stringify({ ids: ids.map((id: string | number) => Number(id)) }),
    }

    return super.delete('/api/collection/bulk/', body, true)
  }

  public deleteCard = (id: number): Promise<{ status: 'success' }> => {
    return super.delete('/api/collection/bulk/', { body: JSON.stringify({ ids: [id] }) }, true)
  }

  public massDelete = (game: number) => {
    return super.delete('/api/collection/clear/', { body: JSON.stringify({ game }) })
  }

  public getStats = (game: number, userId?: number | string): Promise<CollectionStatsType> => {
    return super.get(`/api/collection/data/${userId ? userId + '/' : ''}?game=${game}`)
  }

  public createTag = (name: string, color: string, collectionCardId?: number): Promise<CollectionTag> => {
    return super
      .post<CollectionTagResponseType>('/api/collection/tags/', {
        tagname: name,
        tagcolor: color,
        card: collectionCardId,
      })
      .then(res => ({
        id: res.id,
        name: res.tagname,
        color: res.tagcolor,
      }))
  }

  public modifyTag = (tagId: number | string, name: string, color: string): Promise<CollectionTag> => {
    return super
      .patch<CollectionTagResponseType>(`/api/collection/modifyTag/${tagId}/`, {
        tagname: name,
        tagcolor: color,
      })
      .then(res => ({
        id: res.id,
        name: res.tagname,
        color: res.tagcolor,
      }))
  }

  public deleteTag = (tagId: number | string) => super.delete(`/api/collection/modifyTag/${tagId}/`, undefined, true)

  public importText = (
    text: string,
    options: CollectionTextImportOptionsType,
  ): Promise<CollectionImportResponseType> => {
    return super.post('/api/collection/textImport/', {
      text,
      ...options,
    })
  }

  public importFile = (
    fileType: 'csv' | 'dek',
    file: File | Blob,
    options: CollectionTextImportOptionsType,
    columns?: string[],
  ): Promise<CollectionImportResponseType> => {
    const body: Record<string, any> = {
      fileType,
      file,
      ...options,
    }
    if (columns) body.columns = columns
    return super.formPost('/api/collection/upload/', body)
  }

  public importCsvV2 = (file: File | Blob, options: CollectionTextImportOptionsType, columns?: string[]) => {
    const body: Record<string, any> = { fileType: 'csv', file, ...options }

    if (columns) body.columns = columns

    return super.formPost<UploadResponseV2>('/api/collection/upload/v2/', body)
  }

  public listV2 = (userId: number | string, query: string = '', next?: string) => {
    if (next) return super.get<CollectionResponseV2>(next)

    return super.get<CollectionResponseV2>(`/api/collection/${userId}/v2/?${query}`)
  }

  public upsertV2 = async (collectionCard: CollectionV2Card, gameId: string | number): Promise<CollectionV2Card> => {
    const route = `/api/collection/v2/` + (!!collectionCard.id ? `${collectionCard.id}/` : '')
    const body = {
      game: gameId,
      id: collectionCard.id,
      quantity: collectionCard.quantity,
      card: collectionCard.card.cardId,
      modifier: collectionCard.modifier,
      language: collectionCard.language,
      condition: collectionCard.condition,
      tags: collectionCard.tags,
      purchasePrice: collectionCard.purchasePrice,
    }

    const response = await (!!collectionCard.id ? super.patch(route, body) : super.post(route, body))

    return { ...collectionCard, id: response.id, modifiedAt: response.modifiedAt, createdAt: response.createdAt }
  }
}

const collectionService = new CollectionService()

export default collectionService
