import { REVERSE_COLOR_MAP } from 'components/deckPage/charts/charts.const'
import { SINGLE_EXTRA_COLOR_COMMANDERS } from 'components/deckPage/header/Legality/legality.const'
import { CardType, CategoryType } from 'types/deck'
import { getCardsColorIdenty } from './cardHelpers'
import { Color, ColorAbr } from 'services/apiTypes/card.types'

type ColorQuantity = Record<string, number>
type ColorData = {
  colors: ColorQuantity
  cardsInColor: ColorQuantity
  totalPips: number
}

export const getCastingCostColors = (cards: CardType[], categories: Record<string, CategoryType>): ColorData => {
  let totalPips = 0

  const colors = cards.reduce(
    (acc, card) => {
      const category = categories[card.categories[0]]

      if (category?.name === 'Sideboard' || !category?.includedInDeck) return acc

      const castingCost: string[] =
        card.front && card.back ? [...card.front.castingCost, ...card.back.castingCost] : card.castingCost

      for (const colorEntry of castingCost) {
        // Normal forced color mana (W, U, B, R, G, C)
        if (acc[colorEntry] !== undefined) {
          acc[colorEntry] += card.qty
          totalPips += card.qty
        }

        // Hybrid Mana
        if (Array.isArray(colorEntry)) {
          for (const subColorEntry of colorEntry) {
            if (acc[subColorEntry] === undefined) continue

            acc[subColorEntry] += card.qty
            totalPips += card.qty
          }
        }
      }

      return acc
    },
    { W: 0, U: 0, B: 0, R: 0, G: 0, C: 0 } as ColorQuantity,
  )

  const cardsInColor = cards.reduce(
    (acc, card) => {
      const category = categories[card.categories[0]]

      if (category?.name === 'Sideboard' || !category?.includedInDeck) return acc

      const castingCost: string[] = (
        card.front && card.back ? [...card.front.castingCost, ...card.back.castingCost] : card.castingCost
      ).flat()

      if (castingCost.includes('W')) acc.W += card.qty
      if (castingCost.includes('U')) acc.U += card.qty
      if (castingCost.includes('B')) acc.B += card.qty
      if (castingCost.includes('R')) acc.R += card.qty
      if (castingCost.includes('G')) acc.G += card.qty
      if (castingCost.includes('C')) acc.C += card.qty

      return acc
    },
    { W: 0, U: 0, B: 0, R: 0, G: 0, C: 0 } as ColorQuantity,
  )

  return { colors, totalPips, cardsInColor }
}

export const getManaProductionData = (
  cards: CardType[],
  categories: Record<string, CategoryType>,
  landsOnly?: boolean,
  allowedColors?: ColorAbr[],
): ColorData => {
  let totalPips = 0

  const colors = cards.reduce(
    (acc, card) => {
      const primaryCategory = categories[card.categories[0]]

      if (primaryCategory?.name === 'Sideboard' || !primaryCategory?.includedInDeck) return acc

      // Note - Ignore the back of a card if it's a land UNLESS it's an MDFC
      const types = [...card.types, ...(card.front?.types || [])]
      if (card.layout === 'modal_dfc') types.concat(card.back?.types || [])

      if (landsOnly && !types.includes('Land')) return acc

      for (const color of Object.keys(card.manaProduction)) {
        if (allowedColors && !allowedColors.includes(color as ColorAbr) && color !== 'C') continue

        acc[color] += card.manaProduction[color] * card.qty
        totalPips += card.manaProduction[color] * card.qty
      }

      return acc
    },
    { W: 0, U: 0, B: 0, R: 0, G: 0, C: 0 } as ColorQuantity,
  )

  const cardsInColor = cards.reduce(
    (acc, card) => {
      const category = categories[card.categories[0]]

      if (category?.name === 'Sideboard' || !category?.includedInDeck) return acc

      for (const color of Object.keys(card.manaProduction)) {
        if (card.manaProduction[color] > 0) acc[color] += card.qty
      }

      return acc
    },
    { W: 0, U: 0, B: 0, R: 0, G: 0, C: 0 } as ColorQuantity,
  )

  return { colors: colors, totalPips, cardsInColor }
}

export const getDeckColorIdent = (
  cards: CardType[],
  categories: Record<string, CategoryType>,
  isEDHFormat?: boolean,
): ColorAbr[] => {
  const commanders = cards.filter(card => {
    const primaryCategory = categories[card.categories[0]]

    return primaryCategory?.isPremier || false
  })

  const allowsExtraColor = commanders.some(card => SINGLE_EXTRA_COLOR_COMMANDERS.includes(card.name))

  const deckColors = cards.reduce(getCardsColorIdenty, new Array<Color>()).map(c => REVERSE_COLOR_MAP[c])
  let premierColors = commanders.reduce(getCardsColorIdenty, new Array<Color>()).map(c => REVERSE_COLOR_MAP[c])

  if (allowsExtraColor && deckColors.length === premierColors.length + 1) premierColors = deckColors

  return isEDHFormat && commanders.length ? premierColors : deckColors
}

export const getTypesForDeckData = (card: CardType) => {
  if (card.flippedDefault && card.back) return [...card.back.types]
  if (card.front) return [...card.front.types]

  return [...card.types]
}

export const getSubtypesForDeckData = (card: CardType) => {
  if (card.flippedDefault && card.back) return [...card.back.subTypes]
  if (card.front) return [...card.front.subTypes]

  return [...card.subTypes]
}
