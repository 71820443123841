import { CardType, CategoryType } from '../types/deck'

export const deckToString = (
  cards: Array<CardType>,
  categories: Record<string, CategoryType>,
  stopAtSetCode?: boolean,
): string => {
  let return_string = ''
  cards.sort((cardA, cardB) => (cardA.name > cardB.name ? 1 : -1))
  cards.forEach((card, i) => {
    if (card.id !== '') {
      let categoryText = ''
      if (card.categories.length > 1 || card.categories[0] !== card.typeCategory) {
        const categoryList = card.categories.map(categoryName => {
          const category = categories[categoryName]
          return `${categoryName}${category?.includedInDeck ? '' : '{noDeck}'}${
            category?.includedInPrice ? '' : '{noPrice}'
          }${category?.isPremier ? '{top}' : ''}`
        })
        categoryText = ' [' + categoryList.join(',') + '] '
      }
      return_string += card.qty + 'x ' + card.name + ' (' + card.setCode + ')'

      if (stopAtSetCode) {
        return_string += i === cards.length - 1 ? '' : '\n'

        return
      }

      return_string +=
        (card.superTypes.includes('Basic') && card.collectorNumber ? ` ${card.collectorNumber}` : '') +
        (card.modifier === 'Foil' ? ' *F* ' : card.modifier === 'Etched' ? ' *E*' : '') +
        categoryText +
        (card.colorLabel.name ? ' ^' + card.colorLabel.name + ',' + card.colorLabel.color + '^' : '') +
        (i === cards.length - 1 ? '' : '\n')
    }
  })
  return return_string
}
