import React, { useEffect, useState, useRef } from 'react'
import Icon from 'components/elements/Icon'
import { useCookies } from 'react-cookie'

import { NotificationType } from 'types/notifications'
import { Option } from 'components/elements/ArchidektDropdown/types'

import RequestService from 'services/request.service'
import CookieService from 'services/cookie.service'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import Link from 'components/elements/Link'
import LineLoader from 'components/elements/LineLoader'

import { timeSince } from 'utils/time.utils'
import buildNotificationRoute from 'utils/Notifications'

import styles from './notifications.module.scss'

const Notifications = () => {
  const [{ tbId: userId }] = useCookies(['tbId'])

  const [loading, setLoading] = useState(true)
  const [notifications, setNotifications] = useState(new Array<NotificationType>())
  const [notificationCount, setNotificationCount] = useState(0)
  const shouldFetchNotifications = useRef(true)

  const handleFetchNotifications = () => {
    if (!shouldFetchNotifications.current) {
      return
    }

    setLoading(true)

    // TODO - change the serialzied resposne here
    RequestService.get(`/api/users/${userId}/notifications/`)
      .then(res => setNotifications(res.notifications))
      .finally(() => {
        shouldFetchNotifications.current = false
        setTimeout(() => (shouldFetchNotifications.current = true), 10000)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!userId) return

    RequestService.get(`/api/users/${userId}/notificationCount/`).then(res => {
      setNotificationCount(res.notificationCount)

      CookieService.set('tbTier', res.patreonAccount?.pledge?.level || null, 'week')
    })
  }, [userId])

  let options: Option[] = []

  if (loading) options = [{ type: 'custom', customChild: <LineLoader /> }]
  // prettier-ignore
  else if (notifications.length > 0) options = notifications.map(n => ({ type: 'custom', customChild: <Notification notification={n} /> }))
  else options = [{ type: 'custom', customChild: <div className={styles.noNotifications}>No notifications</div> }]

  return (
    <div className={styles.container}>
      {notificationCount > 0 && <div className={styles.counter}>{notificationCount}</div>}
      <ArchidektDropdown onFocus={handleFetchNotifications} menuClassName={styles.menu} options={options}>
        <button>
          <Icon name="bell outline" />
        </button>
      </ArchidektDropdown>
    </div>
  )
}

export default Notifications

const Notification = ({ notification }: { notification: NotificationType }) => {
  const route = buildNotificationRoute(notification)

  return (
    <Link href={route} className={styles.notification}>
      <img src={notification.avatar} className={styles.avatar} />
      <div className={styles.info}>
        {!notification.seen && <div className={styles.newNotificationBadge} />}
        <div>{notification.text}</div>
        <div className={styles.meta}>{timeSince(notification.createdAt)}</div>
      </div>
    </Link>
  )
}
