import { DeckTagRelation } from 'services/apiTypes/deckTag.types'
import { CardType, FormatType, HUMAN_READABLE_FORMAT, StackType } from 'types/deck'

import { deltaToString } from 'utils/Deltas'

export const generateDeckDescription = (
  name: string,
  format: FormatType,
  deckDescription: string,
  deckTags: DeckTagRelation[],
  commanders: CardType[],
  stacks: { mainDeck: StackType[]; sideDeck: StackType[] },
): { title: string; description: string } => {
  const humanFormat = HUMAN_READABLE_FORMAT[format]
  const readableDescription = deltaToString(deckDescription)
  const readableTags = getHumanDeckTags(deckTags)
  const commanderNames = commanders.map(card => card.name).join(' & ')
  const stackHeaders = stacks.mainDeck.reduce((acc, stack) => {
    if (!acc) return `(${stack.quantity}) ${stack.name}`

    return `${acc} • (${stack.quantity}) ${stack.name}`
  }, '')

  let title = ''
  let description = ''

  title += name || 'Sandbox'

  if (humanFormat) title += ` • (${humanFormat} deck)`
  else title += ` (Card package)`

  // prettier-ignore
  description += `${commanderNames ? `${commanderNames} - ` : ''}${humanFormat ? `${humanFormat} deck` : 'Card Package'} ${readableTags} \n${stackHeaders} \n${readableDescription}`
  title += ` • Archidekt`

  return { title, description }
}

export const getHumanDeckTags = (deckTags: DeckTagRelation[]) => {
  if (!deckTags.length) return '\n'

  const tagNames = deckTags.map(tag => tag.name)

  return `\n${tagNames.join(' • ')}`
}

const convertName = (name: string) =>
  name
    .replace(/[^A-Za-z0-9\s]/g, '')
    .replace(/  +/g, ' ')
    .replace(/ /g, '_')
    .toLocaleLowerCase()

export const generateDeckUrl = (id: number | string, name?: string) => {
  if (!name) return `/decks/${id}`

  return `/decks/${id}/${name ? convertName(name) : ''}`
}

export const generateSnapshotUrl = (id: number | string, name?: string) => {
  if (!name) return `/snapshots/${id}`

  return `/decks/${id}/${name ? convertName(name) : ''}`
}
