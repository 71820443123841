import React from 'react'
import { useDrop } from 'react-dnd'

import SaveService from 'services/save.service'

import { useActionless, useAppSelector } from 'redux/hooks'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import { DropItemTypes, DeckCardDragType } from 'types/draggables'
import { STACK } from 'types/deck'

import Icon from 'components/elements/Icon'
import SimpleSpinner from 'components/elements/SimpleSpinner'

import { getMultiSelectedUpdates, getNewCategories } from 'utils/CategoryChangeHelpers'

import styles from './stackWrapper.module.scss'

type Props = {
  id?: string
  name: string
  children: React.ReactNode
  className?: string
}

const StackWrapper = ({ id, name, children, className = '' }: Props) => {
  const stackType = useAppSelector(state => state.deck.stack)

  const [saving, setSaving] = React.useState(false)

  const [setActiveState] = useActionless(SET_ACTIVE_STATE)

  const handleDrop = ({ card, isCtrlPressedRef, stackName: sourceStack, multiSelectedIds }: DeckCardDragType) => {
    if (stackType !== STACK.CUSTOM && stackType !== STACK.MULTIPLE) return

    if (multiSelectedIds) {
      const updatedCards = getMultiSelectedUpdates(
        multiSelectedIds,
        sourceStack,
        name,
        stackType,
        !!isCtrlPressedRef?.current,
      )

      setSaving(true)

      return SaveService.save(updatedCards)
        .then(() => setActiveState({ multiSelectedIds: {} }))
        .finally(() => setSaving(false))
    }

    const updatedCategories = getNewCategories(
      card.categories,
      stackType,
      sourceStack,
      name,
      !!isCtrlPressedRef?.current,
    )
    const updatedCard = { ...card, categories: updatedCategories, qty: card.qty || 1 }

    setSaving(true)

    return SaveService.save(updatedCard).finally(() => setSaving(false))
  }

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: DropItemTypes.CARD,
      drop: handleDrop,
      canDrop: ({ card, stackName }) => card && stackName !== name,
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [name],
  )

  const dropZone: React.CSSProperties = {}

  if (!canDrop && !saving) {
    dropZone.pointerEvents = 'none'
    dropZone.opacity = '0'
  }

  if ((isOver && canDrop) || saving) dropZone.opacity = '0.6'

  return drop(
    <div id={id || `stack_${name}`} className={`${styles.container} ${className}`}>
      {children}
      <div className={styles.hoverLayer} style={dropZone}>
        {!saving ? (
          <>
            <Icon size="large" name="add" />
            <h2>{name}</h2>
            <div>(CTRL to add secondary)</div>
          </>
        ) : (
          <SimpleSpinner size="small" />
        )}
      </div>
    </div>,
  )
}

export default StackWrapper
