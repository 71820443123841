import React from 'react'
import { Dropdown } from 'semantic-ui-react'

const options = [
  { text: 'Single Printing', value: false },
  { text: 'All Printings', value: true },
]

type Props = {
  value?: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
}

const AllPrintingsDropdown = ({ value = options[0].value, onChange, disabled }: Props) => {
  const handleChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: any) => onChange(value)

  return (
    <div>
      <label>Printings:</label>
      <Dropdown selection fluid disabled={disabled} options={options} value={value} onChange={handleChange} />
    </div>
  )
}

export default AllPrintingsDropdown
