import { store } from 'redux/store'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import { CardType } from 'types/deck'

export const handleHighlight = (cards: CardType[]) => {
  const alreadyHighlighted = store.getState().active.highlightedCards
  const newHighlights = cards.reduce((acc, card) => ({ ...acc, [card.id]: true }), {} as Record<string, boolean>)

  const allNewAlreadyHighlighted = cards.every(card => alreadyHighlighted[card.id])
  const highlightedCards = { ...alreadyHighlighted, ...newHighlights }

  // If all of the new cards are already highlighted, remove them all
  if (allNewAlreadyHighlighted) cards.forEach(card => delete highlightedCards[card.id])

  store.dispatch({ type: SET_ACTIVE_STATE, payload: { highlightedCards } })
}

export const handleMultiSelect = (cards: CardType[]) => {
  const alreadySelected = store.getState().active.multiSelectedIds
  const newSelections = cards.reduce((acc, card) => ({ ...acc, [card.id]: true }), {} as Record<string, boolean>)

  const allNewAlreadySelected = cards.every(card => alreadySelected[card.id])
  const multiSelectedIds = { ...alreadySelected, ...newSelections }

  // If all of the new cards are already highlighted, remove them all
  if (allNewAlreadySelected) cards.forEach(card => delete multiSelectedIds[card.id])

  store.dispatch({ type: SET_ACTIVE_STATE, payload: { multiSelectedIds } })
}
