import queryString from 'query-string'

import { RequestService } from 'services/request.service'

import {
  DeckListResponse,
  DeckResponse,
  DeckRequest,
  DeckModifyResponse,
  Log,
  Snapshot,
  CardPackageInfo,
} from 'services/apiTypes/deck.types'

import { CardType, FORMAT } from 'types/deck'
import { DeckSearchTypeV3 } from 'types/search'

import { responseToCard, responseToDeckCards } from 'utils/ResponseToResults'

export class DeckService extends RequestService {
  /**
   * Gets full fat response of a deck
   *
   * @param deckId
   */
  public details = (deckId: string | number, isPackageImport = false): Promise<DeckResponse> => {
    let url = `/api/decks/${deckId}/?`

    if (isPackageImport) url += `incrementPackageImport=true`

    return super.get(url)
  }

  /**
   * Returns a list of decks given the provided search options
   *
   * @param queryParams Takes query parameters as an object that gets converted to a query string. @see DeckQueryParams for a more detailed shape of that object
   * @param next
   */
  public listV3 = (queryParams: Partial<DeckSearchTypeV3>, next = ''): Promise<DeckListResponse> => {
    const request = next
      ? super.get<DeckListResponse>(next.replace('http://', 'https://'))
      : super.get<DeckListResponse>(`/api/decks/v3/?${queryString.stringify(queryParams)}`)

    return request
  }

  /**
   * Add a group of cards to the deck (does not account for the cards already in the deck)
   *
   * TODO - Update this function to take into account cards that already exist and merge them together
   *
   * @param deckId
   * @param deck
   */
  public add = (deckId: string | number, deck: CardType[]): Promise<DeckModifyResponse> => {
    const cards = deck.map(card => ({
      action: 'add',
      cardid: card.cardId,
      categories: card.categories,
      patchId: card.id,
      modifications: {
        quantity: card.qty || 1,
        modifier: card.modifier,
        customCmc: card.customCmc,
        companion: card.companion || false,
        flippedDefault: card.flippedDefault || false,
        label: `${card.colorLabel.name},${card.colorLabel.color}`,
      },
    }))

    return super.patch(`/api/decks/${deckId}/modifyCards/v2/`, { cards })
  }

  /**
   * Creates or copies a deck (if a copy id is provided)
   *
   * This function has very simple validations on it that aren't super comprehensive
   *
   * @param deck
   */
  public create = (deck: Partial<DeckRequest>): Promise<DeckResponse> => {
    if (deck.name === '') throw new Error('Deck name may not be empty')
    if (!deck.deckFormat) throw new Error('Deck format is missing')

    const body = {
      name: deck.name || '',
      deckFormat: deck.deckFormat || FORMAT.CUSTOM,
      description: deck.description || '',
      featured: deck.featured || '',
      playmat: deck.playmat || '',
      copyId: deck.copyId || undefined,
      private: deck.private || false,
      unlisted: deck.unlisted || false,
      theorycrafted: deck.theorycrafted || false,
      game: deck.game || null,
      parent_folder: deck.parentFolder || undefined, // seriously, why the heck is this in snake case?
      cardPackage: null,
      extras: deck.extras || null,
    }

    // TODO - why is this two separate endpoints? Why not just copy a deck if a copyId is provided? This seems wasteful
    if (deck.copyId) return super.post(`/api/decks/copy/`, body)

    return super.post(`/api/decks/v2/`, body)
  }

  public createPackage = (deck: Partial<DeckRequest>): Promise<DeckResponse> => {
    if (deck.name === '') throw new Error('Card package name may not be empty')
    if (!deck.cardPackage) throw new Error('Card package is missing')

    const body = {
      name: deck.name || '',
      deckFormat: null,
      description: deck.description || '',
      featured: deck.featured || '',
      playmat: deck.playmat || '',
      copyId: deck.copyId || undefined,
      private: deck.private || false,
      parent_folder: deck.parentFolder || undefined, // seriously, why the heck is this in snake case?
      cardPackage: deck.cardPackage,
    }

    return super.post(`/api/decks/packages/`, body)
  }

  public update = async (deckId: string | number, deck: Partial<DeckRequest>): Promise<DeckResponse> => {
    const response = await super.patch(`/api/decks/${deckId}/update/`, deck)

    // WebsocketDeckService.onDeckPatch(response)

    return response
  }

  public updatePackage = async (packageId: string | number, cardPackage: Partial<CardPackageInfo>) => {
    return super.patch(`/api/decks/packages/${packageId}/`, cardPackage)
  }

  public deleteDeck = async (deckId: string | number, ignoreWebsocketCallback: boolean = false): Promise<any> => {
    const response = await super.delete(`/api/decks/${deckId}/`, undefined, true)

    // if (!ignoreWebsocketCallback) WebsocketDeckService.onDeckDelete()

    return response
  }

  public cardsOnly = async (deckId: string | number, includeDeleted = true): Promise<CardType[]> => {
    const cards = await super.get(`/api/decks/${deckId}/cards/?${includeDeleted ? 'includeDeleted=1' : ''}`)

    return responseToDeckCards(cards)
  }

  public hardDeleteCard = async (deckRelationId: string | number): Promise<void> => {
    return await super.delete(`/api/decks/relations/${deckRelationId}/`)
  }

  public getRecentLogs = async (deckId: string | number): Promise<Log[]> => {
    const response = await super.get(`/api/decks/${deckId}/logs/`)

    return response.map((r: any) => ({
      ...r,
      card: responseToCard(r.card),
    }))
  }

  public getSnapshot = (snapshotId: string | number): Promise<DeckResponse> => {
    return super.get(`/api/decks/snapshots/${snapshotId}/`)
  }

  public getSnapshots = (deckId: string | number): Promise<Snapshot[]> => {
    return super.get(`/api/decks/${deckId}/snapshots/`)
  }

  public createSnapshot = (
    deckId: string | number,
    { name, description }: { name: string; description: string },
  ): Promise<Snapshot> => {
    return super.post(`/api/decks/${deckId}/snapshots/`, { name, description })
  }

  public deleteSnapshot = (snapshotId: string | number): Promise<void> => {
    return super.delete(`/api/decks/snapshots/${snapshotId}/`, undefined, true)
  }

  public getArticleDeck = (articleSource: string, articleSlug: string, listSlug: string): Promise<DeckResponse> => {
    return super.get(`/api/decks/articles/${articleSource}/${articleSlug}/${listSlug}/`)
  }

  public getMyDecks = (): Promise<DeckListResponse> => super.get('/api/decks/curated/self/')
  public getFollowedDecks = (): Promise<DeckListResponse> => super.get('/api/decks/curated/followed/')
  public getMyRecentDecks = (): Promise<DeckListResponse> => super.get('/api/decks/curated/self-recent/')
  public getMyPackages = (): Promise<DeckListResponse> => super.get('/api/decks/curated/self-packages/')
}

const deckService = new DeckService()

export default deckService
