import styled from 'styled-components'
import update from 'immutability-helper'
import style from 'react-treebeard/dist/themes/default'

import { THEME } from 'types/active'

export const getStyle = (theme: string) =>
  `${theme}` === THEME.DARK
    ? update(style, {
        tree: {
          node: {
            activeLink: {
              background: { $set: '#616161' },
            },
          },
        },
      })
    : update(style, {
        tree: {
          base: {
            backgroundColor: { $set: '#f3f3f3' },
            color: { $set: '#000' },
          },
          node: {
            activeLink: {
              background: { $set: '#cfcfcf' },
            },
            header: {
              base: {
                color: { $set: '#5e5e5e' },
              },
            },
          },
        },
      })

export const TreeContainer = styled.div<{ isLoading?: boolean }>`
  position: relative;
  max-height: 600px;
  overflow: ${props => (props.isLoading ? 'hidden' : 'auto')};
`
