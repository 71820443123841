import type {
  CardType,
  CategoryType,
  DeckNameType,
  FormatType,
  GameType,
  SortOptionType,
  StackOptionType,
  ViewType,
} from 'types/deck'

import { DeckActions } from './actions'

import { getSorting, getStack, getView, getHideDefaultColorTags, getSortingDirection } from 'services/accountSettings.service'

import { emptyTextArea } from 'types/editor'
import { defaultCategories, FORMAT } from 'types/deck'
import { VOTE_USER_INPUT } from 'types/comments'
import { ControlledStackOptionsType } from 'types/controlledStacks'
import { Owner } from 'services/apiTypes/comment.types'
import { DeckTagRelation } from 'services/apiTypes/deckTag.types'
import { CardPackageInfo } from 'services/apiTypes/deck.types'

export type UserGroup = Record<string, Owner & { connectedAt: string }>

export type DeckState = {
  // Deck properties
  name: DeckNameType
  description: string
  format: FormatType
  game: null | GameType
  id: number
  private: boolean
  unlisted: boolean
  theorycrafted: boolean
  points: number
  owner: string
  ownerid: number
  editors: number[]
  ownerAvatar: string
  ownerFrame: string
  ownerReferrerEnum: null | string
  ckAffiliate: string
  tcgAffiliate: string
  updatedAt: string
  createdAt: string
  commentid: number
  featured: string
  customFeatured: string
  voteInput: number
  viewCount: number
  parentFolder: number
  playgroupDeckUrl: string | null

  // Settings
  stack: StackOptionType
  sorting: SortOptionType
  sortDirectionDescending: boolean
  view: ViewType

  // Actions
  actionStack: Array<DeckActions>
  undoneActionStack: Array<DeckActions>

  // Cards
  categories: Record<string, CategoryType>
  colorLabels: Array<{
    name: string
    color: string
  }>
  cardMap: Record<string, CardType>
  warningCards: Record<string, boolean>

  menuCardId?: string | null
  openMenuCardToEditions: boolean
  snapshotMeta: {
    description: string
    parentDeckId: number
    parentDeckName: string
  } | null

  controlledStackOptions?: ControlledStackOptionsType | null

  saving: 'saving' | 'saved' | 'error'
  wsConnectionOpen: boolean

  userGroup: UserGroup
  bookmarked: boolean
  deckTags: DeckTagRelation[]
  cardPackage: null | CardPackageInfo
}

export const defaultDeckColorTags = [
  { name: '', color: '#656565' },
  { name: 'Have', color: '#37d67a' },
  { name: 'Getting', color: '#2ccce4' },
  { name: "Don't Have", color: '#f47373' },
]

export const generateInitialDeckState = (context?: any): DeckState => ({
  name: '',
  description: emptyTextArea,
  format: FORMAT.CUSTOM,
  game: null,
  id: 0,
  private: false,
  unlisted: false,
  theorycrafted: false,
  points: 0,
  sorting: getSorting(context),
  sortDirectionDescending: getSortingDirection(context),
  view: getView(context),
  stack: getStack(context),

  actionStack: [],
  undoneActionStack: [],

  categories: { ...defaultCategories },

  owner: '',
  ownerid: 0,
  editors: [],
  ownerAvatar: '',
  ownerFrame: '',
  ownerReferrerEnum: null,
  ckAffiliate: '',
  tcgAffiliate: '',

  saving: 'saved',
  wsConnectionOpen: false,

  updatedAt: new Date().toDateString(),
  createdAt: new Date().toDateString(),

  commentid: 0,
  colorLabels: getHideDefaultColorTags(context) ? [] : defaultDeckColorTags,
  voteInput: VOTE_USER_INPUT.NONE,
  viewCount: 0,
  parentFolder: 0,
  playgroupDeckUrl: null,

  warningCards: {},

  /**
   * Getting the window with minus 10 for some stupid browser reason then multiplying by .97 because of the 97% with
   * margins and then subtracting 20 for the 10px padding on each side. Then dividing by the the width of a card or a
   * name column for text columns.
   */

  cardMap: {},

  featured: '',
  customFeatured: '',

  menuCardId: null,
  openMenuCardToEditions: false,

  controlledStackOptions: null,
  userGroup: {},

  snapshotMeta: null,
  bookmarked: false,
  deckTags: [],
  cardPackage: null,
})
