import generateActiveState from 'redux/active/initialState'
import * as Active from 'redux/active/actions/types'

import { getPriceSources } from 'services/accountSettings.service'

import { ActiveActions } from './actions'

export default (active = generateActiveState(), action: ActiveActions) => {
  const initialState = generateActiveState()

  switch (action.type) {
    case Active.SET_ACTIVE_CARD:
      return { ...active, card: action.payload }

    case Active.TOGGLE_MODAL_ON:
      return { ...active, modalActive: action.payload }

    case Active.TOGGLE_MODAL_OFF:
      return { ...active, modalActive: action.payload }

    case Active.TOGGLE_TEXT_ON:
      return { ...active, textActive: action.payload }

    case Active.TOGGLE_TEXT_OFF:
      return { ...active, textActive: action.payload }

    case Active.SET_DETAILS_TAB:
      return {
        ...active,
        [`${action.payload.position}DetailsTab`]: action.payload.index,
      }

    case Active.SET_MOBILE:
      return {
        ...active,
        [action.payload.target]: action.payload.flag,
      }

    case Active.TOGGLE_NO_FOCUS:
      return { ...active, noFocus: action.payload }

    case Active.TOGGLE_MODAL:
      const newModals = {
        ...active.modals,
        [action.payload.modalName]: action.payload.flag,
      }
      return {
        ...active,
        modals: newModals,
        modalActive: action.payload.flag,
      }

    case Active.PAGE_CHANGE:
      const deckPage = action.payload.includes('/decks/') && !action.payload.includes('/search')
      const cardPage = action.payload.includes('/card') && !action.payload.includes('/search')

      return {
        ...generateActiveState(),
        decksWithCard: cardPage ? active.decksWithCard : initialState.decksWithCard,
        deckResults: action.payload.includes('search/decks/') ? active.deckResults : initialState.deckResults,
        isMobile: active.isMobile,
        isMidsize: active.isMidsize,
        priceSource: active.priceSource,
        isDeckPage: deckPage,
      }

    case Active.SET_HIGHLIGHT_CARDS:
      return { ...active, filteredCards: action.payload }

    case Active.SET_ACTIVE_PREFERENCES:
      // If we're enable dark mode, then turn it on
      // TODO - I don't think this is used anymore
      // action.payload.theme ? setDarkMode() : setLightMode()

      return {
        ...active,
        priceSource: getPriceSources([action.payload.priceSource]),
        keybinds: action.payload.keybinds,
        theme: action.payload.theme,
      }

    case Active.SET_THEME:
      return { ...active, theme: action.payload } // Cookie set by the util

    case Active.SET_MODAL_OVERLAY:
      return { ...active, modalOverlay: action.payload }

    case Active.SET_ACTIVE_STATE:
      return { ...active, ...action.payload }

    default:
      return active
  }
}
