type ImageSize = 1 | 2 | 3

// These numbers aren't exact depending on view being used, but they are used as a reference for things like min-width and max-width
export const STACK_WIDTH: Record<ImageSize, number> = {
  1: 190,
  2: 225,
  3: 275,
}

const MIN_COLUMNS: Record<ImageSize, number> = {
  1: 2,
  2: 1, // Unfortunately size 2 and 3 will have the same size on the smallest displays. There isn't a way to fix this if we want larger and smaller cards than what we currently have while maintaining the existing functionality with size 2
  3: 1,
}

export function getColumnCount(imageSize: ImageSize, containerWidth: number): number {
  const approximateImageWidth = STACK_WIDTH[imageSize]
  const minColumns = MIN_COLUMNS[imageSize]

  let columns = Math.floor(containerWidth / approximateImageWidth)

  if (columns < minColumns) {
    columns = minColumns
  }

  return columns
}

export function getTextColumnCount(columnWidth: number, containerWidth: number): number {
  return Math.floor(containerWidth / columnWidth) || 1
}
