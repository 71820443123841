import LocalStorageService from 'services/localStorage.service'

import { PlaytesterV2State } from 'redux/playtesterV2/types'

const LOCAL_STORAGE_KEY = 'playtesterV2-cache'

const cacheabelFields: (keyof Partial<PlaytesterV2State>)[] = [
  'cardSize',
  'handRotation',
  'handSize',
  'sleevesColor',
  'playmatColor',
  'playmatColorExtendsToLowerThird',
  'cardLabelShown',
  'defaultActionOnClick',
  'viewLargeCardOnCursorOver',
  'countersWidget',
  'hideFoilOverlay',
  'maxCardsShownInHand',
  'disabledFancyZoneHoverEffect',
  'disableMuliganMenu',
  'disableReloadStop',
  'hideFancyZoneArrows',
  'fancyZoneScrollDisabled',
  'disableOldPlaytesterLink',
  'countersOverTextBoxes',
]

export function writeChangesToLocalStorage(changes: Partial<PlaytesterV2State> = {}) {
  // @ts-ignore
  const alterdFields: (keyof PlaytesterV2State)[] = Object.keys(changes)
  const cacheableInfo = alterdFields.reduce((acc, field) => {
    if (!cacheabelFields.includes(field)) return acc

    // @ts-ignore
    acc[`${field}`] = changes[field]

    return acc
  }, {} as Partial<PlaytesterV2State>)

  if (!Object.keys(cacheableInfo).length) return // skip writting to local storage unless there's anything new to write

  const existingCache = getCachedState()
  const updatedCache = { ...existingCache, ...cacheableInfo }

  LocalStorageService.setObject(LOCAL_STORAGE_KEY, updatedCache)
}

export function getCachedState(): Partial<PlaytesterV2State> {
  const cachedState = LocalStorageService.getObject(LOCAL_STORAGE_KEY) || {}

  return cachedState
}
