import { emptyTextArea } from 'types/editor'

export const deltaToString = (textAreaContent: string): string => {
  try {
    const delta = JSON.parse(textAreaContent)

    let output = ''
    if (delta.ops) {
      delta.ops.forEach((op: Record<string, any>) => {
        if (op.insert) {
          if (typeof op.insert === 'string') {
            output += op.insert
          } else if (op.insert['card-link']) {
            output += op.insert['card-link']
          }
        }
      })
    }

    return output
  } catch (e) {
    return '' // emptyTextArea
  }
}

export const isEmpty = (textAreaContent: string): boolean => {
  try {
    const delta = JSON.parse(textAreaContent)

    if (delta && delta.ops && delta.ops.length) {
      return delta.ops.length === 1 && delta.ops[0].insert === '\n'
    }

    return true
  } catch (e) {
    return true
  }
}

export const isEqual = (textAreaContentOne: string, textAreaContentTwo: string): boolean => {
  const delta1 = JSON.parse(textAreaContentOne)
  const delta2 = JSON.parse(textAreaContentTwo)

  if (delta1 && delta2) {
    const diff = delta1.diff(delta2)
    if (diff) {
      return isEmpty(diff)
    }
  }
  return false
}
