import { CardType } from 'types/deck'

export const CategoryWeights: Record<string, any> = {
  Battle: 15, // Not sure this goes here, but we'll see if we ever get multi-types
  Stickers: 14,
  Attraction: 13,
  Creature: 12,
  Land: 11,
  Artifact: 10,
  Enchantement: 9,
  Planeswalker: 8,
  Instant: 7,
  Sorcery: 6,
  Conspiracy: 5,
  Phenomenon: 4,
  Plane: 3,
  Scheme: 2,
  Vanguard: 1,
  Tribal: 0,
}

export const getCardDefaultCategoryFromCard = (card?: CardType): string => {
  if (!card) return 'Default'

  try {
    const types = card.front?.types ? [...card.front.types] : [...card.types]

    if (card.front?.subTypes.includes('Attraction') || card.subTypes.includes('Attraction')) types.push('Attraction')

    return types.sort((a, b) => (CategoryWeights[b] || -1) - (CategoryWeights[a] || -1))[0]
  } catch (e) {
    console.log('Type sort failed')

    return 'Default'
  }
}
