import React, { useEffect } from 'react'
import { useAppSelector } from 'redux/hooks'
import { Dropdown } from 'semantic-ui-react'

import { PRICE_CODES } from 'types/active'

const options = [
  { text: 'Alphabet', value: 'oracleCard__name' },
  { text: 'CMC', value: 'oracleCard__cmc' },
  { text: 'Power', value: 'oracleCard__powerint' },
  { text: 'Toughness', value: 'oracleCard__toughnessint' },
  { text: 'Rarity', value: 'rarity_id' },
  { text: 'Artist', value: 'artistName' },
  { text: 'Price', value: 'prices' },
  { text: 'EDH Salt Score', value: 'oracleCard__salt' },
]

type Props = {
  value?: string
  onChange: (value: string) => void
}

const SortingDropdown = ({ value = options[0].value, onChange }: Props) => {
  const priceSource = useAppSelector(state => state.active.priceSource)
  const priceCode = PRICE_CODES[priceSource[0]] || undefined
  const updatedPriceValue = `prices__${priceCode || 'ck'}`

  const handleChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: any) => onChange(value)

  const personalizedOptions = options.map(option => {
    if (option.value !== 'prices') return option
    return { ...option, value: updatedPriceValue }
  })

  useEffect(() => {
    if (value.includes('prices')) onChange(updatedPriceValue)
  }, [priceSource])

  return (
    <div>
      <label>Sort By:</label>
      <Dropdown selection fluid options={personalizedOptions} value={value} onChange={handleChange} />
    </div>
  )
}

export default SortingDropdown
