import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { DropdownProps } from 'components/elements/ArchidektDropdown/types'

import Menu from 'components/elements/ArchidektDropdown/Menu'

import styles from './contextMenu.module.scss'

const BODY_CLASS = 'context-menu-open'

type Props = {
  id?: string
  checkSkipRightClick?: () => boolean
  className?: string
  style?: React.CSSProperties
  onMouseDown?: (e: React.MouseEvent) => void
  onMouseMove?: (e: React.MouseEvent) => void
  onMouseUp?: (e: React.MouseEvent) => void
  disabled?: boolean
  openOnLeftClick?: boolean
} & DropdownProps

const ContextMenu = React.forwardRef((props: Props, ref: any) => {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const menuWrapperRef = useRef<HTMLDivElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)

  const [openX, setOpenX] = useState<number | null>(null)
  const [openY, setOpenY] = useState<number | null>(null)
  const [visable, setVisable] = useState(false) // used to control whether or not the context menu is visable at all (@see handleRightClick for more info)

  const dropdownProps = { ...props }
  delete dropdownProps.children

  const handleRightClick = (e: React.MouseEvent) => {
    if (props.disabled) return
    if (e.ctrlKey) return // use the default right click

    // @ts-ignore
    const clickedElement: Element = e.target

    if (clickedElement.id === 'contextMenuOverlay') return // If the click hits the overlay, do nothing

    if (
      clickedElement?.tagName === 'INPUT' ||
      clickedElement?.tagName === 'input' ||
      clickedElement?.tagName === 'TEXTAREA' ||
      clickedElement?.tagName === 'textarea' ||
      clickedElement?.className.includes('editor') ||
      clickedElement?.parentElement?.className.includes('editor')
    )
      return

    if (props.checkSkipRightClick && !props.checkSkipRightClick()) return
    if (openX !== null && openY !== null) return handleCloseOverlay()

    e.preventDefault()
    e.stopPropagation()

    setOpenX(e.clientX)
    setOpenY(e.clientY)

    document.body.classList.add(BODY_CLASS)

    setTimeout(() => setVisable(true), 50) // Delay being able to see the context menu by 100ms so we can recalculate whether it needs to be moved for edge detection

    triggerRef.current?.focus() // used to force pull away focus from anything else on the page
  }

  const handleCloseOverlay = (e?: React.MouseEvent) => {
    if (e?.target !== e?.currentTarget) return

    setOpenX(null)
    setOpenY(null)
    setVisable(false)

    document.body.classList.remove(BODY_CLASS)
  }

  const handleMenuItemClick = (_e: React.MouseEvent, optionOnClick?: () => void, noCloseOnClick?: boolean) => {
    if (optionOnClick) optionOnClick()
    if (noCloseOnClick) return

    handleCloseOverlay()
  }

  const handleClick = (e: React.MouseEvent) => {
    // If we don't care about opening on left click, or we're already open, do nothing
    if (!props.openOnLeftClick || !!openX || !!openY) return

    e.preventDefault()
    handleRightClick(e)
  }

  const handlePassthroughRightClick = (e: React.MouseEvent) => {
    if (e.ctrlKey) return
    if (!overlayRef.current) return

    e.preventDefault()

    setVisable(false)
    setOpenX(null)
    setOpenY(null)

    overlayRef.current.style.pointerEvents = 'none'

    const clickPositionX = e.clientX
    const clickPositionY = e.clientY

    const element = window.document.elementFromPoint(clickPositionX, clickPositionY)

    if (!element) return

    const arr = ['mousedown', 'contextmenu', 'mouseup']

    arr.forEach(mouseEventType =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 2,
          clientX: clickPositionX,
          clientY: clickPositionY,
        }),
      ),
    )
  }

  useEffect(() => {
    if (openY === null || openX === null) return
    if (!menuWrapperRef.current) return

    // @ts-expect-error
    const menu: HTMLDivElement = menuWrapperRef.current.childNodes[0]

    if (!menu) return

    const viewportWidth = document.body.clientWidth
    const viewportHeight = document.body.clientHeight

    const menuWidth = menu.clientWidth
    const menuHeight = menu.clientHeight

    if (menuWidth + openX > viewportWidth) setOpenX(viewportWidth - menuWidth - 10)
    if (menuHeight + openY > viewportHeight) setOpenY(viewportHeight - menuHeight - 10)
  }, [openY, openX])

  useEffect(() => {
    return () => document.body.classList.remove(BODY_CLASS)
  }, [])

  const menuLocation: React.CSSProperties = {
    top: openY || 0,
    left: openX || 0,
    display: 'unset',
    opacity: visable ? 1 : 0,
  }

  return (
    <div
      id={props.id}
      ref={ref}
      style={props.style}
      className={`${styles.wrapper} ${props.className || ''}`}
      onContextMenu={handleRightClick}
      onClick={handleClick}
      onMouseDown={props.onMouseDown}
      onMouseMove={props.onMouseMove}
      onMouseUp={props.onMouseUp}>
      {props.children}
      {openY !== null &&
        openX !== null &&
        ReactDOM.createPortal(
          <div
            id="contextMenuOverlay"
            ref={overlayRef}
            className={styles.overlay}
            onClick={handleCloseOverlay}
            onContextMenu={handlePassthroughRightClick}>
            <div ref={menuWrapperRef}>
              <Menu
                {...dropdownProps}
                menuStyle={menuLocation}
                menuClassName={`${styles.menu} ${dropdownProps.menuClassName || ''}`}
                unFocusClick={handleMenuItemClick}
              />
            </div>
          </div>,
          document.body,
        )}

      <button className={styles.trigger} tabIndex={-1} ref={triggerRef} />
    </div>
  )
})

export default ContextMenu
