import React from 'react'
import { Tab, calculateArchidektSearchDiff } from 'types/searchV2'

import styles from './tabPreview.module.scss'

type Props = {
  tab: Tab
  onClick: () => void
}

// The preview of a tab shown on the tab selection screen
const TabPreview = ({ tab, onClick }: Props) => (
  <button className={styles.container} onClick={onClick}>
    {tab.type === 'scrySearch' && (
      <>
        <div className={styles.tabTitle}>Syntax search</div>
        <div>Search Query: {tab.meta.query || 'Empty'}</div>
      </>
    )}
    {tab.type === 'archidektSearch' && (
      <>
        <div className={styles.tabTitle}>Archidekt search</div>
        <div>Search Filters: {calculateArchidektSearchDiff(tab.meta) || 'Empty'}</div>
      </>
    )}

    {tab.type === 'edhRecs' && (
      <>
        <img alt="edhrec logo" src="/images/edhrec.svg" height={50} width={50} />
        <div className={styles.tabTitle}>EDHREC</div>
      </>
    )}

    {tab.type === 'archidektRecs' && (
      <>
        <div className={styles.tabTitle}>Archidekt Recommendations</div>
      </>
    )}

    {tab.type === 'manabaseGenerator' && (
      <>
        <div className={styles.tabTitle}>Landbase suggestions</div>
      </>
    )}

    {tab.type === 'spellbookCombo' && (
      <>
        <div className={styles.tabTitle}>Commander Spellbook combos</div>
      </>
    )}
  </button>
)

export default TabPreview
