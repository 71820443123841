import React, { useEffect, useState } from 'react'

import { selectCommanderCards, selectMaindeckCards, selectSideboardCards } from 'redux/deck/selectors'
import { useAppSelector } from 'redux/hooks'

import { CardType } from 'types/deck'
import { Combo } from 'services/apiTypes/spellbook.types'

import SpellbookService from 'services/spellbook.service'
import ToastService from 'services/toast.service'

import PhatDropdown from 'components/formElements/PhatDropdown'
import PhatButton from 'components/formElements/PhatButton'
import SpellbookCombo from 'components/cardSearchPanel/searchFormElements/spellbookCombo'

import { modifySearchResults } from 'utils/searchModfiyResults'

import styles from './spellbookComboProvider.module.scss'

type Props = {
  noAutoSearch: boolean
  alreadyHasResults: boolean
  onSubmitted: () => void
  onRequestFailed: () => void
  onResponseRecieved: () => void

  handleCardUpdated: (currentId: string, updatedCard: CardType) => void

  onDragStart?: () => void
  onDragStop?: () => void
}

const SpellbookComboProvider = ({
  noAutoSearch,
  alreadyHasResults,
  onSubmitted,
  onRequestFailed,
  onResponseRecieved,
  handleCardUpdated,
  onDragStart,
  onDragStop,
}: Props) => {
  const commanders = useAppSelector(selectCommanderCards)
  const maindeck = useAppSelector(selectMaindeckCards)
  const sideboard = useAppSelector(selectSideboardCards)

  const [showingIncluded, setShowingIncluded] = useState(false)

  const [almostIncluded, setAlmostIncluded] = useState(new Array<Combo>())
  const [included, setIncluded] = useState(new Array<Combo>())
  const [cards, setCards] = useState<Record<string, CardType>>({})

  useEffect(() => {
    if (noAutoSearch || alreadyHasResults) return

    handleSearch()
  }, [])

  const handleSearch = () => {
    setAlmostIncluded([])
    setIncluded([])

    onSubmitted()

    const companions = sideboard.filter(c => c.companion)

    SpellbookService.combos([...commanders, ...companions], maindeck)
      .then(res => {
        setAlmostIncluded(res.almostIncluded)
        setIncluded(res.included)

        setCards(res.cards)
      })
      .catch(err => {
        ToastService.create(err.error || 'Unable to fetch spellbook combos.', 'Combo Fetch Error', 'error')
        onRequestFailed()
      })
      .finally(() => onResponseRecieved())
  }

  const deck = [...commanders, ...maindeck] // Notatebly we're not using the sideboard or maybeboards here.a
  const shownCombos = showingIncluded ? included : almostIncluded

  return (
    <div>
      <div className={styles.controls}>
        <PhatDropdown
          className={styles.dropdown}
          value={showingIncluded ? 'included' : 'almostIncluded'}
          options={[
            { id: 'included', label: 'Included', onClick: () => setShowingIncluded(true) },
            { id: 'almostIncluded', label: 'Almost Included', onClick: () => setShowingIncluded(false) },
          ]}
        />

        <PhatButton color="green" onClick={handleSearch}>
          Fetch combos
        </PhatButton>
      </div>

      <div className={styles.combos}>
        {shownCombos.map(combo => (
          <SpellbookCombo
            combo={combo}
            cards={
              // Modify results changes values on the card in search to use the card from the deck (where applicable)
              // This makes it so that increasing the quantity of a card in the deck will also increase the quantity in the combo
              modifySearchResults(
                deck,
                combo.cards.map(oracleUid => cards[oracleUid]),
              )
            }
            onCardUpdated={handleCardUpdated}
            onCardDragStart={onDragStart}
            onCardDragStop={onDragStop}
          />
        ))}

        {!shownCombos.length && <div className={styles.noCombos}>No combos found</div>}
      </div>
    </div>
  )
}

export default SpellbookComboProvider
