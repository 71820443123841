import { combineReducers } from '@reduxjs/toolkit'

import { DeckState } from 'redux/deck/initialState'
import { ActiveState } from 'redux/active/actions/types'
import { CachablesState } from 'redux/cacheables/initialState'
import { PlaytesterV2State } from 'redux/playtesterV2/types'
import { CollectionState } from './collection/initialState'
import { CollectionV2State } from 'redux/collectionV2/types'
import { FoldersState } from './folders/initialState'

import deck from 'redux/deck/reducer'
import cacheables from 'redux/cacheables/reducer'
import active from 'redux/active/reducer'
import playtesterV2 from 'redux/playtesterV2/reducer'
import collection from 'redux/collection/reducer'
import collectionV2 from 'redux/collectionV2/reducer'
import folders from 'redux/folders/reducer'

// Once we remove all the flow we can use ReturnType<typeof reducers> instead
export type RootState = {
  deck: DeckState
  active: ActiveState
  cacheables: CachablesState
  playtesterV2: PlaytesterV2State
  collection: CollectionState
  collectionV2: CollectionV2State
  folders: FoldersState
}

const reducer = combineReducers({
  deck,
  active,
  cacheables,
  playtesterV2,
  collection,
  collectionV2,
  folders,
})

export default reducer

export const selectDeck = (state: RootState): DeckState => state.deck
export const selectActive = (state: RootState): ActiveState => state.active
export const selectCachables = (state: RootState): CachablesState => state.cacheables
export const selectPlaytesterV2 = (state: RootState): PlaytesterV2State => state.playtesterV2
export const selectCollection = (state: RootState): CollectionState => state.collection
export const selectCollectionV2 = (state: RootState): CollectionV2State => state.collectionV2
export const selectFolders = (state: RootState): FoldersState => state.folders
