import { CollectionTagType } from 'types/collection'
import { CollectionState } from './initialState'
import { initialState } from './initialState'
import { CollectionActions } from './actions'
import * as Collection from './actions/types'
import update from 'utils/ImmutableCommands'
import { getCollectionPrice } from 'services/accountSettings.service'

const reducer = (state: CollectionState = initialState, action: CollectionActions) => {
  let searchIndex = -1
  switch (action.type) {
    case Collection.SET_COLLECTION_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case Collection.FETCH_COLLECTION_SUCCEEDED:
      const collectionPrice = getCollectionPrice()

      let tags: Record<string, CollectionTagType> = {}
      let newCollection = action.payload.results

      action.payload.tags.forEach(tag => (tags[tag.id.toString()] = tag))

      if (action.payload.url) newCollection = state.collection.concat(action.payload.results)

      return {
        ...state,
        nextPage: action.payload.next,
        collection: newCollection,
        loading: false,
        resultCount: action.payload.count,
        tagMap: tags,
        owner: action.payload.owner,
        collectionPrice,
      }

    case Collection.ADD_COLLECTION_CARD_SUCCEEDED:
      return update(state, {
        collection: {
          $removeCardWithId: action.payload.id,
          $unshift: [action.payload],
        },
        addingCard: {
          $set: false,
        },
      })

    case Collection.DELETE_COLLECTION_CARD_SUCCEEDED:
      return update(state, {
        collection: {
          $removeCardWithId: action.payload,
        },
        deletingCard: {
          $set: false,
        },
        resultCount: {
          $set: state.resultCount - 1,
        },
      })

    case Collection.MODIFY_COLLECTION_CARD_SUCCEEDED:
      const modifiedId = action.payload.id
      searchIndex = state.collection.findIndex(card => card.id === modifiedId)
      return update(state, {
        collection: {
          [searchIndex]: {
            $set: action.payload,
          },
        },
        modifyingId: {
          $set: 0,
        },
      })

    case Collection.SET_COLLECTION_PRICE:
      return {
        ...state,
        priceSource: action.payload,
      }
    case Collection.RESET_COLLECTION:
      return {
        ...initialState,
        priceSource: state.priceSource,
        query: {
          ...initialState.query,
          game: state.query.game,
        },
        loading: state.loading,
      }

    case Collection.CLEAR_COLLECTION_SUCCEEDED:
      return {
        ...state,
        collection: action.payload === state.query.game ? [] : state.collection,
        resultCount: action.payload === state.query.game ? 0 : state.resultCount,
        clearingCollection: false,
      }

    case Collection.UPLOAD_COLLECTION_SUCCEEDED:
      return {
        ...state,
        importing: false,
        uploadErrors: {
          ...state.uploadErrors,
          ...action.payload,
        },
        maxChunks: 0,
        currentChunk: 0,
      }

    case Collection.CREATE_COLLECTION_TAG_SUCCEEDED:
      const newTags = {
        ...state.tagMap,
        [action.payload.tag.id.toString()]: action.payload.tag,
      }
      if (action.payload.card) {
        const curId = action.payload.card
        searchIndex = state.collection.findIndex(card => card.id === curId)
        return update(state, {
          collection: {
            [searchIndex]: {
              tags: {
                $push: [action.payload.tag.id],
              },
            },
          },
          tagMap: {
            $set: newTags,
          },
          creatingTag: {
            $set: false,
          },
        })
      }
      return {
        ...state,
        tagMap: newTags,
        creatingTag: false,
      }

    case Collection.DELETE_COLLECTION_TAG_SUCCEEDED:
      const deletedMap: Record<string, CollectionTagType> = { ...state.tagMap }
      delete deletedMap[action.payload.toString()]
      return {
        ...state,
        tagMap: deletedMap,
        deletingTag: false,
      }

    case Collection.SET_QUERY_PARAMETER:
      return {
        ...state,
        query: {
          ...state.query,
          [action.payload.name]: action.payload.value,
        },
      }

    case Collection.TEXT_IMPORT_SUCCEEDED:
      return {
        ...state,
        importing: false,
        textErrors: {
          ...state.textErrors,
          ...action.payload,
        },
      }

    case Collection.SET_COLLECTION_PREFERENCES:
      return {
        ...state,
        priceSource: action.payload.priceSource,
        query: {
          ...state.query,
          game: action.payload.game,
        },
      }
    default:
      return state
  }
}

export default reducer
