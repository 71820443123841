import { useCookies } from 'react-cookie'

import { useState } from 'react'
import { useActionless, useAppSelector } from 'redux/hooks'
import { SET_ACTIVE_STATE, TOGGLE_MODAL } from 'redux/active/actions/types'
import { SET_DECK_STATE } from 'redux/deck/actions/types'

import ColorTagService from 'services/colorTag.service'
import ToastService from 'services/toast.service'

import SimpleSpinner from 'components/elements/SimpleSpinner'

import { Option } from 'components/elements/ArchidektDropdown/types'
import { GlobalColorTag } from 'services/apiTypes/colorTag.types'

import styles from './useDeckColorTagOptions.module.scss'

const useDeckColorTagOptions = (callback: (selectedTag: GlobalColorTag) => void): [Option[], () => void] => {
  const [{ tbId: userId }] = useCookies(['tbId'])

  const colorLabels = useAppSelector(state => state.deck.colorLabels)
  const globalColorTags = useAppSelector(state => state.active.globalColorTags)

  const [toggleModal, setActiveState, setDeckState] = useActionless(TOGGLE_MODAL, SET_ACTIVE_STATE, SET_DECK_STATE)

  const [loadingGlobalColorTags, setLoadingGlobalColorTags] = useState(false)

  const handleFetchGlobalColorTags = () => {
    if (!userId || globalColorTags !== null) return

    setLoadingGlobalColorTags(true)

    ColorTagService.list()
      .then(res => setActiveState({ globalColorTags: res }))
      .catch(() => ToastService.create('Unable to load global color tags', 'Deck page', 'error'))
      .finally(() => setLoadingGlobalColorTags(false))
  }

  const handleCallback = (tag: GlobalColorTag) => {
    callback(tag)

    if (colorLabels.some(deckTag => deckTag.name === tag.name && deckTag.color === tag.color)) return

    setDeckState({ colorLabels: [...colorLabels, tag] })
  }

  const visibleColorLabels = (globalColorTags || []).filter(
    tag => !colorLabels.some(label => label.name === tag.name && label.color === tag.color),
  )

  const options: Option[] = [
    ...colorLabels.map(tag => ({
      label: (
        <div className={styles.inline}>
          <span className={styles.splotch} style={{ backgroundColor: tag.color }} />
          <span className={styles.tagName}>{tag.name || 'Default color tag'}</span>
        </div>
      ),
      id: `${tag.name},${tag.color}`,
      onClick: () => handleCallback(tag),
    })),
    { type: 'spacer' },
    {
      type: 'custom',
      className: styles.headerContainer,
      customChild: (
        <span className={styles.inline}>
          {loadingGlobalColorTags && <SimpleSpinner size="xSmall" />}
          Color tag templates
          {!loadingGlobalColorTags && !visibleColorLabels.length && <span className={styles.none}>(None)</span>}
        </span>
      ),
    },
    ...visibleColorLabels?.map(tag => ({
      label: (
        <div className={styles.inline}>
          <span className={styles.splotch} style={{ backgroundColor: tag.color }} />
          <span className={styles.tagName}>{tag.name || 'Default color tag'}</span>
        </div>
      ),
      id: `${tag.name},${tag.color}`,
      onClick: () => handleCallback(tag),
    })),
    { type: 'spacer' },
    {
      label: 'Edit color tags',
      icon: 'pencil',
      onClick: () => toggleModal({ modalName: 'colorTags', flag: true }),
    },
  ]

  return [options, handleFetchGlobalColorTags]
}

export default useDeckColorTagOptions
