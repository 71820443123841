import React from 'react'
import Icon from 'components/elements/Icon'

import { CardType } from 'types/deck'

import AsyncEditionDropdown from 'components/cardSearchPanel/searchFormElements/asyncEditionDropdown'

import styles from './editionOptions.module.scss'

type Props = {
  card: CardType
  onEditionChanged: (card: CardType) => void
  onAllPrintingsClicked?: () => void
  label?: string
  className?: string
}

const EditionOptions = ({ card, onEditionChanged, onAllPrintingsClicked, label, className = '' }: Props) => (
  <div className={`${styles.container} ${className}`}>
    {label && <label>{label}</label>}

    <AsyncEditionDropdown
      includeIcon
      key={`${card.id}-edition-dropdown`}
      card={card}
      onCardSelected={onEditionChanged}
      containerClassName={styles.dropdownWrapper}
      triggerClassName={styles.dropdown}
    />

    <button className={styles.allEditions} onClick={onAllPrintingsClicked}>
      <Icon name="grid layout" />

      <span className={styles.mobile}>Printings</span>
      <span className={styles.desktop}>All printings</span>
    </button>
  </div>
)

export default EditionOptions
