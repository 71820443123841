export const COMMENT_TYPES = {
  Normal: 0,
  Global: 1,
  Sticky: 2,
  News: 3,
  Features: 6,
}

export const VOTE_USER_INPUT = {
  NONE: 0,
  UP: 1,
  DOWN: 2,
}

export type SectionType = {
  name: string
  description: string
  posts: number
  recentPost: any
  parent: string
  id: number
}
export type CategoryType = {
  name: string
  description: string
  sections: Array<SectionType>
}

export const PAGE_SIZE = {
  THREADS: 20,
  COMMENTS: 15,
  REPLIES: 3,
}
