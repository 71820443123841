import React from 'react'

import { CardType } from 'types/deck'
import { DeckListColors } from 'types/search'

import { orderColorsToOfficialOrder } from 'utils/colorUtils'

import { ManaSymbol, Slash } from 'components/misc/customIcons/ManaSymbol'

type ManaSymbolType = string | string[]

export const flattenManaSymbol = (symbol: ManaSymbolType) => (Array.isArray(symbol) ? symbol.join('') : symbol)

export const colorsToManaString = (colors: DeckListColors) => {
  let manaString = ''

  const orderdColors = orderColorsToOfficialOrder(colors)

  for (const colorCount of orderdColors) {
    if (colorCount.quantity === 0) continue

    if (colorCount.color === 'W') manaString += '{W}'
    if (colorCount.color === 'U') manaString += '{U}'
    if (colorCount.color === 'B') manaString += '{B}'
    if (colorCount.color === 'R') manaString += '{R}'
    if (colorCount.color === 'G') manaString += '{G}'
  }

  if (manaString.length === 0) manaString = '{C}'

  return manaString
}

/**
 * Converst a string of mana such as {2}{W}{U} to a react element of the corrisponding mana symbol
 */
export const manaStringToElement: (manaCost: string) => any = input => {
  const symbols = input.replace(/{/g, '').split('}')

  const manaList = symbols.map((symbol, key) => {
    const element = symbol.replace('/', '').replace('∞', 'I')

    if (!element) return null

    return <ManaSymbol symbol={element} key={key} />
  })

  return <div style={{ display: 'flex', gap: '.15rem', alignItems: 'center' }}>{manaList}</div>
}

/**
 * This function can take a string and give you back a react component with the text and an inline mana symbol
 *
 * Example: Card text with an activated ability. Display the card text as it appears on the card itself
 */
export const fullTextToElement: (input: string) => any = input => {
  const elements = new Array<React.ReactNode>()
  const splitWithSymbols = input.split('}')

  for (let i = 0; i < splitWithSymbols.length; i++) {
    const subString = splitWithSymbols[i]
    const splitOutSymbols = subString.split('{')

    if (splitOutSymbols.length > 1) {
      const newLineSplit = splitOutSymbols[0].split('\n')

      for (let j = 0; j < newLineSplit.length; j++) {
        const line = newLineSplit[j]

        elements.push(<span key={`${i}${j}`}>{line}</span>)

        if (j < newLineSplit.length - 1) elements.push(<br key={`br${i}${j}`} />)
      }

      const manaSymbol = splitOutSymbols[1].replace('/', '').replace('∞', 'I')

      elements.push(<ManaSymbol symbol={manaSymbol} key={`mana-${i}`} />)
    } else {
      const newLineSplit = subString.split('\n')

      for (let j = 0; j < newLineSplit.length; j++) {
        const line = newLineSplit[j]

        elements.push(<span key={`${i}${j}`}>{line}</span>)

        if (j < newLineSplit.length - 1) elements.push(<br key={`br${i}${j}`} />)
      }
    }
  }

  return elements
}

export const manaArrayToElement = (card: CardType, className?: string) => {
  // prettier-ignore
  const manaCost = card.front && card.back ? [...card.front.castingCost, '/', ...card.back?.castingCost] : card.castingCost

  const manaList = manaCost.map((manaValue, key) => {
    if (manaValue === '/') return <Slash key={key}>/</Slash>

    const manaValueString = flattenManaSymbol(manaValue)

    if (!manaValueString) return null

    return <ManaSymbol symbol={manaValueString} key={key} />
  })

  return (
    <div style={{ display: 'flex', gap: '.15rem', alignItems: 'center' }} className={className}>
      {manaList}
    </div>
  )
}
