import React, { useState } from 'react'
import Icon from 'components/elements/Icon'
import { ChromePicker } from 'react-color'

import { useActionless, useAppSelector } from 'redux/hooks'
import { CollectionTag } from 'redux/collectionV2/types'
import { SET_COLLECTION_V2_STATE } from 'redux/collectionV2/actions/types'

import CollectionService from 'services/collection.service'
import ToastService from 'services/toast.service'

import Input from 'components/formElements/Input'
import LabelDisplay from 'components/collectionV2/misc/LabelDisplay'
import ConfirmDeleteModal from 'components/elements/ConfirmDeleteModal'
import GlobalOverlayStack from 'components/elements/Overlay/GlobalOverlayStack'

import styles from './editLabelsModal.module.scss'

type Props = { open: boolean; onClose: () => void }

const EditLabelsModal = ({ open, onClose }: Props) => {
  const tags = useAppSelector(state => state.collectionV2.tags)
  const [newTags, setNewTags] = useState<CollectionTag[]>([])

  const [setCollectionV2State] = useActionless(SET_COLLECTION_V2_STATE)

  const handleRemoveTag = (id?: string | number) => {
    if (!id) return setNewTags([])

    const updatedTags = { ...tags }

    delete updatedTags[id]

    setCollectionV2State({ tags: updatedTags })
  }

  const handleEditOrAddTag = (updatedTag: CollectionTag) => {
    const isNew = !tags[updatedTag.id]
    const updatedTags = { ...tags, [updatedTag.id]: updatedTag }

    if (isNew) setNewTags([])

    setCollectionV2State({ tags: updatedTags })
  }

  // @ts-ignore - Object.keys returns string[] but we know it's number[]
  const tagIds: number[] = Object.keys(tags)
  const existingTags: CollectionTag[] = tagIds
    .map(tagId => ({ ...tags[tagId] }))
    .sort((a, b) => a.name.localeCompare(b.name))

  const newAndExistingTags: CollectionTag[] = [...existingTags, ...newTags]

  return (
    <GlobalOverlayStack open={open} onClose={onClose} className={styles.container}>
      <div className={styles.content}>
        <h2>Edit global collection labels</h2>

        <div className={styles.table}>
          {newAndExistingTags.map(tag => (
            <LabelRecord key={tag.id} tag={tag} onEdited={handleEditOrAddTag} onDeleted={handleRemoveTag} />
          ))}

          {newAndExistingTags.length === 0 && (
            <p className={styles.noTagsMessage}>You have no collection tags. Create some to continue.</p>
          )}
        </div>

        <div className={styles.controls}>
          <button
            disabled={!!newTags.length}
            className={styles.addTag}
            // @ts-ignore - id as undefined because this record has yet to be saved
            onClick={() => setNewTags([...newTags, { id: undefined, name: '', color: '' }])}>
            <Icon name="add circle" />
            New collection label
          </button>
        </div>
      </div>
    </GlobalOverlayStack>
  )
}

export default EditLabelsModal

const LabelRecord = ({
  tag,
  onEdited,
  onDeleted,
}: {
  tag: CollectionTag
  onEdited: (updatedTag: CollectionTag) => void
  onDeleted: (id?: string | number) => void
}) => {
  const [editedName, setEditedName] = useState(tag.name)
  const [editedColor, setEditedColor] = useState(tag.color || '#fff')

  const [editing, setEditing] = useState(!tag.id)
  const [deleting, setDeleting] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSaveEdit = () => {
    if (!editedName) return ToastService.create('Tag name cannot be empty', 'Collection Page')

    const request = !tag.id
      ? CollectionService.createTag(editedName, editedColor)
      : CollectionService.modifyTag(tag.id, editedName, editedColor)

    setLoading(true)

    request
      .then(onEdited)
      .then(() => {
        ToastService.create(`${editedName} label ${tag.id ? 'updated' : 'created'}`, 'Collection Page', 'success')

        setEditing(false)
      })
      .catch(() => ToastService.create('Error updating/ creating collection tag', 'Collection Page'))
      .finally(() => setLoading(false))
  }

  const handleCancel = () => {
    if (!tag.id) return onDeleted()

    setEditing(false)
    setEditedColor(tag.color)
    setEditedName(tag.name)
  }

  const handleDeleteSavedTag = () => {
    setLoading(true)

    return CollectionService.deleteTag(tag.id)
      .then(() => {
        onDeleted(tag.id)

        ToastService.create(`${tag.name} deleted`, 'Collection Page', 'success')
      })
      .catch(err => ToastService.create(err, 'Collection Page'))
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.row}>
      <div className={styles.primaryForm}>
        <div className={styles.tag}>
          <LabelDisplay name={tag.name} color={editedColor} noName={editing} />
          {editing && (
            <Input
              autoFocus
              placeholder="New collection label name"
              className={styles.tagInput}
              value={editedName}
              onChange={setEditedName}
            />
          )}
        </div>

        <div className={styles.tagControls}>
          {!editing && (
            <>
              <button className={styles.editButton} onClick={() => setEditing(true)} disabled={loading}>
                <Icon name="edit" /> Edit
              </button>
              <button
                className={styles.deleteButton}
                onClick={() => {
                  if (tag.id) setDeleting(true)
                  else onDeleted()
                }}
                disabled={loading}>
                <Icon name="trash alternate outline" /> Delete
              </button>
            </>
          )}

          {editing && (
            <>
              <button className={styles.editButton} onClick={handleSaveEdit} disabled={loading}>
                <Icon name="save outline" /> Save
              </button>

              <button className={styles.deleteButton} onClick={handleCancel} disabled={loading}>
                <Icon name="close" /> Cancel
              </button>
            </>
          )}
        </div>
      </div>

      {editing && (
        <ChromePicker
          className={styles.colorPicker}
          disableAlpha
          color={editedColor || '#fff'}
          onChangeComplete={(color: Record<string, any>) => setEditedColor(color.hex)}
        />
      )}

      <ConfirmDeleteModal
        usedInOverlay
        open={deleting}
        onClose={() => setDeleting(false)}
        onDelete={handleDeleteSavedTag}
        header={`Delete "${tag.name}" label`}
        message={`Are you sure you want to delete the "${tag.name}" label? Doing so will delete this label from all records in your collection!`}
      />
    </div>
  )
}
