import { useState } from 'react'

import Modal from 'components/elements/Modal'
import Link from 'components/elements/Link'

import styles from './saveError.module.scss'
import Accordion from 'components/elements/Accordion'

type Props = { id: string; error?: any; body?: any }

const SaveError = ({ id, error, body }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className={styles.toast} id={id}>
        <h4>Failed to save changes</h4>{' '}
        <p>
          Click <button onClick={() => setOpen(true)}>here</button> for more info.
        </p>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} modalClassName={styles.modal} closeButton>
        <h3>Error saving deck changes</h3>

        <p>
          There was an issue saving your deck. All changes have been undone. Editing will attempt to save again. If you
          continue to have issues, please contact support via{' '}
          <a href="mailto:contact@archidekt.com" target="_blank">
            email
          </a>
          ,{' '}
          <a href="https://discord.gg/GyAAANU8uJ" target="_blank">
            discord
          </a>
          , or our <Link href="/forum/General/Development">forums</Link>.
        </p>

        <p>
          If you want to try some troubleshooting steps so you don't need to talk with another human (same), here are
          some things you could try:
        </p>

        <ul>
          <li>Does refreshing the page and trying again fix the issue?</li>
          <li>Does editing decks fail for only this deck, or all of your decks?</li>
          <li>Does the issue happen for every change or only some changes?</li>
          <li>Does the issue persist if you log out and back in?</li>
          <li>Does the issue persist in another browser/ a private or incognito window?</li>
        </ul>

        <Accordion label="Error specifics" containerClass={styles.accordion}>
          <p>
            If / when you report this bug, please include this information, it'll make it easier for us to tell what's
            going on.
          </p>

          <h5>Request</h5>
          <pre>{JSON.stringify(body, null, 2)}</pre>

          <h5>Error</h5>
          <pre>{typeof error === 'string' ? error : JSON.stringify(error, null, 2)}</pre>
        </Accordion>
      </Modal>
    </>
  )
}

export default SaveError
