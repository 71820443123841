import { PlaytesterV2State } from 'redux/playtesterV2/types'

const undoableFields: (keyof Partial<PlaytesterV2State>)[] = [
  'allCards',
  'battlefield',
  'hand',
  'library',
  'sideboard',
  'graveyard',
  'exile',
  'commandZone',
  'attractions',
  'junkyard',
  'planes',
  'miscCards',
  'cardCoordinates',
  'categories',
]

export function generatePreviousState(
  state: PlaytesterV2State,
  changes: Partial<PlaytesterV2State>,
): Partial<PlaytesterV2State> {
  // @ts-ignore
  const alterdFields: (keyof PlaytesterV2State)[] = Object.keys(changes)
  const undoableState = alterdFields.reduce((acc, field) => {
    if (!undoableFields.includes(field)) return acc

    // @ts-ignore
    acc[`${field}`] = state[field]

    return acc
  }, {} as Partial<PlaytesterV2State>)

  return undoableState
}

export function createUndoState(
  state: PlaytesterV2State,
  payload: Partial<PlaytesterV2State>,
): Partial<PlaytesterV2State> {
  const undoableState = generatePreviousState(state, payload)
  const isUndoable = !!Object.keys(undoableState).length

  // If none of the undoable fields were changed, skip this as an undo action
  // If it is NOT an undoable action, don't clear redo (only do that on an undoable action)
  // And limit to the last 10 changes so as to not let redux get out of hand
  const undo = (isUndoable ? [undoableState, ...state.undo] : [...state.undo]).filter((_u, index) => index < 10)
  const redo = (isUndoable ? [] : [...state.redo]).filter((_u, index) => index < 10)

  return { undo, redo }
}

export function getUndoRedo(state: PlaytesterV2State, undo: boolean): Partial<PlaytesterV2State> {
  const undoneRedoneState = undo ? state.undo[0] : state.redo[0]

  if (!undoneRedoneState) return state // does nothing

  // Since this is gotten from an already undone/ redone state, we know that it will have _something_ that makes it an undoable action, so no need to check it here
  const reverseAction = generatePreviousState(state, undoneRedoneState)

  const undoneActions = undo ? state.undo.filter((_s, index) => index > 0) : [reverseAction, ...state.undo]
  const redoneActions = !undo ? state.redo.filter((_s, index) => index > 0) : [reverseAction, ...state.redo]

  return {
    ...undoneRedoneState,
    selected: {},
    undo: undoneActions,
    redo: redoneActions,
  }
}
