import styled, { keyframes } from 'styled-components'

const placeHolderShimmer = keyframes`
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
`

export const CardLoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  animation: ${placeHolderShimmer} 2s linear;
  animation-iteration-count: infinite;
  animation: ${placeHolderShimmer} 2s infinite steps(50);
  background-color: ${props => props.theme.lightBackground2};
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.05) 15%, rgba(0, 0, 0, 0) 30%);
  background-size: 1200px 100%;
`

export const TitleBox = styled.div<{ $typebox?: boolean }>`
  background-color: ${props => props.theme.lightBackground3};
  border-radius: 5px;
  width: 94%;
  left: 3%;
  height: 7%;
  position: absolute;
  top: ${props => (props.$typebox ? '55%' : '3%')};
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.theme.color};
`

export const TextBox = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.color};
  border-radius: 3px;
  width: 90%;
  height: 32%;
  bottom: 5%;
  left: 5%;
  position: absolute;
  text-align: left;
  font-size: 12px;

  padding: 2px;
`

export const Logo = styled.div`
  position: absolute;
  background-image: url('/images/logo_white.svg');
  background-size: cover;
  width: 60%;
  height: 42%;
  left: 20%;
  top: 11%;
`

export const PTBox = styled.div`
  position: absolute;

  bottom: 6px;
  right: 6px;

  background-color: ${props => props.theme.lightBackground2};
  color: ${props => props.theme.color};

  padding: 2px;
  width: 50px;
  text-align: center;

  border-radius: 3px;
`
