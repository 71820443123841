import { Color } from 'services/apiTypes/card.types'

export type TwoColor =
  | 'Azorius'
  | 'Boros'
  | 'Dimir'
  | 'Golgari'
  | 'Gruul'
  | 'Izzet'
  | 'Orzhov'
  | 'Rakdos'
  | 'Selesnya'
  | 'Simic'

export type ThreeColor =
  | 'Abzan'
  | 'Bant'
  | 'Esper'
  | 'Grixis'
  | 'Jeskai'
  | 'Jund'
  | 'Mardu'
  | 'Naya'
  | 'Sultai'
  | 'Temur'

export type FourColor = 'Chaos' | 'Aggression' | 'Altruism' | 'Growth' | 'Artifice'

export type ColorIdentityName = Color | TwoColor | ThreeColor | FourColor | 'WUBRG'

export type ExternalColorCode =
  | 'w'
  | 'u'
  | 'b'
  | 'r'
  | 'g'
  | 'colorless'
  | 'wu'
  | 'ub'
  | 'br'
  | 'rg'
  | 'gw'
  | 'wb'
  | 'ur'
  | 'bg'
  | 'rw'
  | 'gu'
  | 'wub'
  | 'ubr'
  | 'brg'
  | 'rgw'
  | 'gwu'
  | 'wbg'
  | 'urw'
  | 'bgu'
  | 'rwb'
  | 'gur'
  | 'wubr'
  | 'ubrg'
  | 'brgw'
  | 'rgwu'
  | 'gwub'
  | 'wubrg'

// Note that the key colors are alphebettical
const colorsToIdentityName: Record<string, ColorIdentityName> = {
  'White': 'White',
  'Blue': 'Blue',
  'Black': 'Black',
  'Red': 'Red',
  'Green': 'Green',
  'Colorless': 'Colorless',

  'Blue,White': 'Azorius',
  'Red,White': 'Boros',
  'Black,Blue': 'Dimir',
  'Black,Green': 'Golgari',
  'Green,Red': 'Gruul',
  'Blue,Red': 'Izzet',
  'Black,White': 'Orzhov',
  'Black,Red': 'Rakdos',
  'Green,White': 'Selesnya',
  'Blue,Green': 'Simic',

  'Black,Green,White': 'Abzan',
  'Blue,Green,White': 'Bant',
  'Black,Blue,White': 'Esper',
  'Black,Blue,Red': 'Grixis',
  'Blue,Red,White': 'Jeskai',
  'Black,Green,Red': 'Jund',
  'Black,Red,White': 'Mardu',
  'Green,Red,White': 'Naya',
  'Black,Blue,Green': 'Sultai',
  'Blue,Green,Red': 'Temur',

  'Black,Blue,Green,Red': 'Chaos',
  'Black,Green,Red,White': 'Aggression',
  'Blue,Green,Red,White': 'Altruism',
  'Black,Blue,Green,White': 'Growth',
  'Black,Blue,Red,White': 'Artifice',

  'Black,Blue,Green,Red,White': 'WUBRG',

  'W': 'White',
  'U': 'Blue',
  'B': 'Black',
  'R': 'Red',
  'G': 'Green',

  // Again the keys are in alphebetical order so that we can sort the colors we have alpehbetically, then find the color identity that way
  'U,W': 'Azorius',
  'R,W': 'Boros',
  'B,U': 'Dimir',
  'B,G': 'Golgari',
  'G,R': 'Gruul',
  'R,U': 'Izzet',
  'B,W': 'Orzhov',
  'B,R': 'Rakdos',
  'G,W': 'Selesnya',
  'G,U': 'Simic',

  'B,G,W': 'Abzan',
  'G,U,W': 'Bant',
  'B,U,W': 'Esper',
  'B,R,U': 'Grixis',
  'R,U,W': 'Jeskai',
  'B,G,R': 'Jund',
  'B,R,W': 'Mardu',
  'G,R,W': 'Naya',
  'B,G,U': 'Sultai',
  'G,R,U': 'Temur',

  'B,G,U,R': 'Chaos',
  'B,G,R,W': 'Aggression',
  'G,R,U,W': 'Altruism',
  'B,G,U,W': 'Growth',
  'B,R,U,W': 'Artifice',

  'B,G,R,U,W': 'WUBRG',
}

export function colorsToColorIdentityName(colors: Color[]): ColorIdentityName {
  // Sorting colors alphebetically so we can _always_ check the alphebetically
  // Removing colorless and just assume if the array is empty that it's colorless
  const uniuqueColors = [...new Set(colors)].sort((a, b) => (a < b ? -1 : 1)).filter(c => c !== 'Colorless')

  if (uniuqueColors.length === 0) return 'Colorless'
  if (uniuqueColors.length === 5) return 'WUBRG'
  if (uniuqueColors.length === 1) return uniuqueColors[0]

  const mappedColorIdentName = colorsToIdentityName[uniuqueColors.join(',')]

  // If statement in case I screwed up the key for one of the above
  if (mappedColorIdentName) return mappedColorIdentName

  return 'WUBRG'
}

const orderingWeightsByIdentity: Record<ColorIdentityName, Record<string, number>> = {
  White: { W: 1 },
  Blue: { U: 1 },
  Black: { B: 1 },
  Red: { R: 1 },
  Green: { G: 1 },
  Colorless: { Colorless: 1 },

  Azorius: { W: 1, U: 2 },
  Dimir: { U: 1, B: 2 },
  Rakdos: { B: 1, R: 2 },
  Gruul: { R: 1, G: 2 },
  Selesnya: { G: 1, W: 2 },

  Orzhov: { W: 1, B: 2 },
  Izzet: { U: 1, R: 2 },
  Golgari: { B: 1, G: 2 },
  Boros: { R: 1, W: 2 },
  Simic: { G: 1, U: 2 },

  Esper: { W: 1, U: 2, B: 3 },
  Grixis: { U: 1, B: 2, R: 3 },
  Jund: { B: 1, R: 2, G: 3 },
  Naya: { R: 1, G: 2, W: 3 },
  Bant: { G: 1, W: 2, U: 3 },

  Abzan: { W: 1, B: 2, G: 3 },
  Jeskai: { U: 1, R: 2, W: 3 },
  Sultai: { B: 1, G: 2, U: 3 },
  Mardu: { R: 1, W: 2, B: 3 },
  Temur: { G: 1, U: 2, R: 3 },

  Artifice: { W: 1, U: 2, B: 3, R: 4 },
  Chaos: { U: 1, B: 2, R: 3, G: 4 },
  Aggression: { B: 1, R: 2, G: 3, W: 4 },
  Altruism: { R: 1, G: 2, W: 3, U: 4 },
  Growth: { G: 1, W: 2, U: 3, B: 4 },

  WUBRG: { W: 1, U: 2, B: 3, R: 4, G: 5 },
}

export const identityNameOrdering: Record<ColorIdentityName, number> = {
  White: 1,
  Blue: 2,
  Black: 3,
  Red: 4,
  Green: 5,
  Colorless: 6,

  Azorius: 7,
  Dimir: 8,
  Rakdos: 9,
  Gruul: 10,
  Selesnya: 11,

  Orzhov: 12,
  Izzet: 13,
  Golgari: 14,
  Boros: 15,
  Simic: 16,

  Esper: 17,
  Grixis: 18,
  Jund: 19,
  Naya: 20,
  Bant: 21,

  Abzan: 22,
  Jeskai: 23,
  Sultai: 24,
  Mardu: 25,
  Temur: 26,

  Artifice: 27,
  Chaos: 28,
  Aggression: 29,
  Altruism: 30,
  Growth: 31,

  WUBRG: 32,
}

export const externalColorCodes: Record<ColorIdentityName, ExternalColorCode> = {
  White: 'w',
  Blue: 'u',
  Black: 'b',
  Red: 'r',
  Green: 'g',
  Colorless: 'colorless',

  Azorius: 'wu',
  Dimir: 'ub',
  Rakdos: 'br',
  Gruul: 'rg',
  Selesnya: 'gw',

  Orzhov: 'wb',
  Izzet: 'ur',
  Golgari: 'bg',
  Boros: 'rw',
  Simic: 'gu',

  Esper: 'wub',
  Grixis: 'ubr',
  Jund: 'brg',
  Naya: 'rgw',
  Bant: 'gwu',

  Abzan: 'wbg',
  Jeskai: 'urw',
  Sultai: 'bgu',
  Mardu: 'rwb',
  Temur: 'gur',

  Artifice: 'wubr',
  Chaos: 'ubrg',
  Aggression: 'brgw',
  Altruism: 'rgwu',
  Growth: 'gwub',

  WUBRG: 'wubrg',
}

export function getIdentityNameOrdering(identityName: ColorIdentityName | string): number {
  // @ts-ignore
  return identityNameOrdering[identityName] || 1000
}

export function orderColorsToOfficialOrder(colors: Record<string, number>): Array<{ color: string; quantity: number }> {
  const colorsWithQuantities = Object.keys(colors).filter(color => colors[color] > 0) as Color[]
  const colorIdentity = colorsToColorIdentityName(colorsWithQuantities)
  const weightedColors = orderingWeightsByIdentity[colorIdentity] || {}

  const response: Array<{ color: string; quantity: number }> = Object.keys(colors)
    .map(color => {
      const quantity = colors[color] || 0

      return { color, quantity }
    })
    .sort((a, b) => {
      const colorAWeight = weightedColors[a.color] || 0
      const colorBWight = weightedColors[b.color] || 0

      return colorAWeight > colorBWight ? 1 : -1
    })

  return response
}

export function getExternalColorCodeFromColors(colors: Color[]): ExternalColorCode {
  return getExternalColorCodeFromIdentity(colorsToColorIdentityName(colors))
}

export function getExternalColorCodeFromIdentity(identityName: ColorIdentityName): ExternalColorCode {
  return externalColorCodes[identityName]
}
