export const FETCH_COLLECTION_SUCCEEDED = 'collection/FETCH_COLLECTION_SUCCEEDED'
export const ADD_COLLECTION_CARD_SUCCEEDED = 'collection/ADD_COLLECTION_CARD_SUCCEEDED'
export const DELETE_COLLECTION_CARD_SUCCEEDED = 'collection/DELETE_COLLECTION_CARD_SUCCEEDED'
export const MODIFY_COLLECTION_CARD_SUCCEEDED = 'collection/MODIFY_COLLECTION_CARD_SUCCEEDED'
export const SET_COLLECTION_PRICE = 'collection/SET_COLLECTION_PRICE'
export const RESET_COLLECTION = 'collection/RESET_COLLECTION'
export const CLEAR_COLLECTION_SUCCEEDED = 'collection/CLEAR_COLLECTION_SUCCEEDED'
export const UPLOAD_COLLECTION_SUCCEEDED = 'collection/UPLOAD_COLLECTION_SUCCEEDED'
export const CREATE_COLLECTION_TAG_SUCCEEDED = 'collection/CREATE_COLLECTION_TAG_SUCCEEDED'
export const DELETE_COLLECTION_TAG_SUCCEEDED = 'collection/DELETE_COLLECTION_TAG_SUCCEEDED'
export const SET_QUERY_PARAMETER = 'collection/SET_QUERY_PARAMETER'
export const TEXT_IMPORT_SUCCEEDED = 'collection/TEXT_IMPORT_SUCCEEDED'
export const SET_COLLECTION_PREFERENCES = 'collection/SET_COLLECTION_PREFERENCES'
export const SET_COLLECTION_STATE = 'collection/SET_COLLECTION_STATE'
