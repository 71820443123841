import React from 'react'
import { Popup, Icon } from 'semantic-ui-react'

import { LegalityBlock, LegalityLabel } from '../style'

const LegalityKey = () => (
  <Popup trigger={<Icon style={{ color: '#cdcdcd' }} name="question" />} wide>
    <LegalityBlock>
      <LegalityLabel small legality="legal">
        {' '}
        Legal{' '}
      </LegalityLabel>
      <LegalityLabel small legality="restricted">
        {' '}
        Restricted{' '}
      </LegalityLabel>
      <LegalityLabel small legality="banned">
        {' '}
        Banned{' '}
      </LegalityLabel>
      <LegalityLabel small legality="not_legal">
        {' '}
        Not Legal{' '}
      </LegalityLabel>
    </LegalityBlock>
  </Popup>
)

export default LegalityKey
