import React from 'react'

import Icon from 'components/elements/Icon'
import PhatButton from 'components/formElements/PhatButton'

import { CardType } from 'types/deck'

type Props = {
  primary: boolean
  category: string
  card: CardType

  onCardChange: (card: CardType) => void
  disabled?: boolean
}

const PrimaryButton = ({ primary, category, card, onCardChange, disabled }: Props) => {
  const handleMakePrimary = () => {
    if (!category) return

    const categories = card.categories.slice()

    categories.splice(categories.indexOf(category), 1)
    categories.unshift(category)

    onCardChange({ ...card, categories })
  }

  return (
    <PhatButton onClick={handleMakePrimary} disabled={disabled}>
      <Icon name={primary ? 'star' : 'star outline'} color={primary ? 'blue' : 'grey'} />
    </PhatButton>
  )
}

export default PrimaryButton
