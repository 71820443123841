// React
import React from 'react'
import { useCookies } from 'react-cookie'
import { ThemeProvider } from 'styled-components'

import { THEME } from 'types/active'
import { LIGHT, DARK } from 'types/theme'

type Props = {
  children: React.ReactNode
}

/**
 * This component wraps the root level components of the app. It wraps the app in router.js. It uses the redux state
 * to determine what theme object to pass to the styled-components ThemeProvider and then that theme object is
 * available in every styled-component in the app.
 */
const StyledComponentThemeWrapper: React.FC<Props> = ({ children }: Props) => {
  const [{ theme }] = useCookies(['theme'])

  return <ThemeProvider theme={`${theme}` === THEME.DARK ? DARK : LIGHT}>{children}</ThemeProvider>
}

export default StyledComponentThemeWrapper
