import React, { useEffect, useRef } from 'react'

import config from 'environment'

import SaveService from 'services/save.service'
import ToastService from 'services/toast.service'

import { store } from 'redux/store'
import { useActionless, useAppSelector } from 'redux/hooks'
import { ActiveState, SET_ACTIVE_STATE, TOGGLE_MODAL } from 'redux/active/actions/types'
import { useRightPanel } from 'hooks/useRightPanel'

import GlobalOverlayStack from 'components/elements/Overlay/GlobalOverlayStack'

import { ctrlConverter } from 'utils/macosLegendHelper'
import { copyArchidektSyntaxToClipboard, copyCardNamesToClipboard } from 'utils/cardsToClipboard'

import styles from './keybindsOverlay.module.scss'

export const basicKeybindSkips = (e: KeyboardEvent) => {
  if (e.repeat) return true
  if (
    document?.activeElement?.tagName === 'INPUT' ||
    document?.activeElement?.tagName === 'input' ||
    document?.activeElement?.tagName === 'TEXTAREA' ||
    document?.activeElement?.tagName === 'textarea' ||
    document?.activeElement?.className.includes('editor')
  )
    return true

  return false
}

const DeckKeybindsOverlay = () => {
  const keylistener = useRef<(e: KeyboardEvent) => void>(() => null)

  const deckKeybinds = useAppSelector(state => state.active.modals.deckKeybinds)

  const [rightPanelStatus, setRightPanelStatus] = useRightPanel()
  const [setActiveState, toggleModal] = useActionless(SET_ACTIVE_STATE, TOGGLE_MODAL)

  useEffect(() => {
    window.removeEventListener('keydown', keylistener.current)

    keylistener.current = (e: KeyboardEvent) => {
      const controlKey = config.getOnMac() ? e.metaKey : e.ctrlKey

      if (basicKeybindSkips(e)) return

      const state = store.getState()

      const cardMap = state.deck.cardMap
      const multiSelectedIds = Object.keys(state.active.multiSelectedIds)
      const filteredCards = state.active.filteredCards

      const undoStack = state.deck.actionStack
      const redoStack = state.deck.undoneActionStack

      const ids = Object.values(cardMap)
        .filter(c => {
          if (!filteredCards.length) return true

          return filteredCards.includes(c.name)
        })
        .map(c => c.id)

      if (e.key === '?') toggleModal({ modalName: 'deckKeybinds', flag: !deckKeybinds })

      if ((e.key === 'H' || e.key === 'h') && controlKey) {
        e.preventDefault()

        setActiveState({ modals: { addRemovedCards: true }, modalActive: true })
      }

      if ((e.key === 'a' || e.key === 'A') && controlKey) {
        e.preventDefault()

        const payload: Partial<ActiveState> = { multiSelectedIds: ids.reduce((a, id) => ({ ...a, [id]: true }), {}) }

        store.dispatch({ type: SET_ACTIVE_STATE, payload })
      }

      if (multiSelectedIds.length) {
        if ((e.key === 'c' || e.key === 'C') && controlKey) {
          e.preventDefault()

          const selectedCards = multiSelectedIds.map(id => cardMap[id])

          if (!e.shiftKey) copyCardNamesToClipboard(selectedCards, true)
          else copyArchidektSyntaxToClipboard(selectedCards)
        }

        if (e.key === 'Delete') {
          e.preventDefault()

          const selectedCards = multiSelectedIds.map(id => cardMap[id])

          SaveService.save(selectedCards, multiSelectedIds)
            .then(() => store.dispatch({ type: SET_ACTIVE_STATE, payload: { multiSelectedIds: {} } }))
            .catch(() => ToastService.create('Failed to delete cards', 'Deck', 'error'))
        }
      }

      if (controlKey && (e.key === 'M' || e.key === 'm') && !rightPanelStatus.locked) {
        e.preventDefault()

        setRightPanelStatus({ ...rightPanelStatus, open: !rightPanelStatus.open, locked: false })
      }

      if (e.shiftKey && controlKey && (e.key === 'Z' || e.key === 'z') && redoStack.length) SaveService.redo()
      if (!e.shiftKey && controlKey && (e.key === 'Z' || e.key === 'z') && undoStack.length) SaveService.undo()
    }

    window.addEventListener('keydown', keylistener.current)

    return () => window.removeEventListener('keydown', keylistener.current)
  }, [open, rightPanelStatus])

  return (
    <GlobalOverlayStack
      open={deckKeybinds}
      onClose={() => toggleModal({ modalName: 'deckKeybinds', flag: false })}
      className={styles.container}>
      <h2>Deck Page Keybinds</h2>
      <div className={styles.content}>
        <div>
          <h4>Page Actions</h4>
          <Keybind keybind="?" description="Show/ hide keybinds" />
          <Keybind keybind="Ctrl + '" description="Focus Quick add" />
          <Keybind keybind="Ctrl + ." description="Focus Syntax quick add" />
          <Keybind keybind="Ctrl + ;" description="Focus Detailed add" />
          <Keybind keybind="Ctrl + /" description="Focus Deck filter" />
          <Keybind keybind="Ctrl + Z" description="Undo action" />
          <Keybind keybind="Ctrl + Shift + Z" description="Redo action" />
          <Keybind keybind="Ctrl + Enter" description="Opens search" />
          <Keybind keybind="Ctrl + A" description="Select all cards" />
          <Keybind keybind="Ctrl + M" description="Open stats panel" />
          <Keybind keybind="Ctrl + H" description="Open deck history" />
        </div>

        <div>
          <h4>With multi-select active</h4>
          <Keybind keybind="Ctrl + C" description="Copy selected cards" />
          <Keybind keybind="Ctrl + Shift + C" description="Copy selected cards (Archidekt Syntax)" />
          <Keybind keybind="Delete" description="Delete selected cards" />
        </div>

        <div>
          <h4>Card Actions</h4>
          <p>Actions that apply to the card in your deck that your cursor is hovering over</p>
          <Keybind keybind="R" description="Removes card from deck" />
          <Keybind keybind="+" description="Increase card quantity" />
          <Keybind keybind="-" description="Descrease card quantity" />
          <Keybind keybind="A" description="Move to auto category" />
          <Keybind keybind="M" description="Move card to Maybeboard" />
          <Keybind keybind="S" description="Move card to Sideboard" />
          <Keybind keybind="L" description="Flips card (when applicable)" />
          <Keybind keybind="N" description="Set card to nonfoil (when applicable)" />
          <Keybind keybind="F" description="Set card to foil (when applicable)" />
          <Keybind keybind="E" description="Set card to etched (when applicable)" />
          <Keybind keybind="Ctrl + Click" description="Start card multi-select" />
        </div>
      </div>
    </GlobalOverlayStack>
  )
}

export default DeckKeybindsOverlay

export const Keybind = ({ keybind, description }: { keybind: string; description: string }) => {
  const displayKeybind = ctrlConverter(keybind)

  return (
    <div className={styles.keybindContainer}>
      <span className={styles.iconWrapper}>
        <span className={styles.keybind}>{displayKeybind}</span>
      </span>
      <span className={styles.description}>{description}</span>
    </div>
  )
}
