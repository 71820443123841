import { CardType } from 'types/deck'

/**
 *
 * Modify search results to include counts for other printings of the same card
 *
 * eg: In card search we show the little blue circle for other printings of the same card
 *
 * @param deck
 * @param results
 * @returns
 */
export const modifySearchResults = (deck: CardType[], results: CardType[]): CardType[] => {
  return results.map(card => {
    if (!card) return card

    let hasFoundPrinting = false

    const otherPrintingQty = deck.reduce((acc, deckCard) => {
      // If this is the exact card we're looking at, skip it (so it's not included in the other card qty)
      // If we've already found an exact printing then we set a flag to count others separately
      // (This is only applicable if there are more than 1 of the same edition of a card in a deck)
      if (card.uid === deckCard.uid && !hasFoundPrinting) {
        hasFoundPrinting = true
        return acc
      }

      if (deckCard.name === card.name && deckCard.oracleCardId === card.oracleCardId) acc += deckCard.qty

      return acc
    }, 0)

    // prettier-ignore
    const exactCardInDeck = deck.find(deckCard => card.uid === deckCard.uid)

    if (exactCardInDeck) return { ...exactCardInDeck, otherPrintingQty }

    return { ...card, otherPrintingQty, categories: [], qty: 0 }
  })
}
