import React from 'react'

import PrimaryButton from './primaryButton'
import CategoryDropdown from './categoryDropdown'
import RemoveButton from './removeButton'

import { CardType } from 'types/deck'

import styles from './categoryRow.module.scss'

type Props = {
  category: string
  primary: boolean
  card: CardType
  onCardChange: (updatedCard: CardType) => void
  onDismissNewCategory?: () => void
  onClose?: () => void
  disableRemoveCategory?: boolean
}

// Single row in the category list
const categoryRow = ({
  category,
  primary,
  card,
  onCardChange,
  onClose,
  disableRemoveCategory,
  onDismissNewCategory,
}: Props) => (
  <div className={styles.container}>
    <PrimaryButton
      category={category}
      primary={primary}
      card={card}
      onCardChange={onCardChange}
      disabled={!!onDismissNewCategory}
    />
    <CategoryDropdown onClose={onClose} category={category} card={card} onCardChange={onCardChange} />
    <RemoveButton
      disabled={disableRemoveCategory}
      category={category}
      card={card}
      onCardChange={onCardChange}
      onDismissNewCategory={onDismissNewCategory}
    />
  </div>
)

export default categoryRow
