import type { CardType } from '../types/deck'

/**
 * This function simple takes in a card object and returns its cmc. If a custom cmc is provided, it uses that, else
 * it uses the default cmc.
 */
export const getCardCMC = (card: CardType): number => {
  return typeof card.customCmc === 'number' ? card.customCmc : card.cmc
}

export const getCmcForSpellsOnly = (card: CardType): number | string => {
  if (typeof card.customCmc === 'number') return card.customCmc // If it's custom CMC treat it as a spell
  if (card.types.includes('Land') || card.front?.types.includes('Land')) return 'Land'

  return card.cmc
}
