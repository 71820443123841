import React, { memo } from 'react'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import environment from 'environment'

import { useActionless } from 'redux/hooks'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import Link from 'components/elements/Link'
import Notifications from 'components/misc/GlobalToolbar/Notifications'
import AccountDropdown from 'components/misc/GlobalToolbar/AccountDropdown'
import LogoLink from 'components/misc/GlobalToolbar/LogoLink'
import DarkModeToggle from 'components/misc/GlobalToolbar/DarkModeToggle'
import DecksButton from 'components/misc/GlobalToolbar/DecksButton'
import MobileNavbarOptions from 'components/misc/MobileNavbarOptions '
import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import { Option } from 'components/elements/ArchidektDropdown/types'

import { PUBLIC_ROUTES } from 'utils/token.utils'

import styles from './globalToolbar.module.scss'

type Props = {
  noSearch?: boolean
  noLinks?: boolean
  noAccount?: boolean
}

const forumOptions: Option[] = [
  { to: '/forum/General/Development', label: 'Development', icon: 'bug', ariaLabel: 'Development forums' },
  { to: '/forum/General/General', label: 'General MTG', manaFontIcon: 'ability-d20', ariaLabel: 'General forums' },
  { to: '/forum/General/Archidecture', label: 'Deck help', icon: 'wrench', ariaLabel: 'Deck forums' },
  { type: 'spacer' },
  { to: '/features', label: 'Feature voting', icon: 'balance scale', ariaLabel: 'Feature voting forums' },
  { type: 'spacer' },
  {
    to: 'https://discord.gg/GyAAANU8uJ',
    label: 'Archidekt Discord',
    icon: 'discord',
    ariaLabel: 'Archidekt discord',
    rel: 'noopener noreferrer',
    target: '_blank',
  },
]

const GlobalToolbar = memo(({ noSearch = false, noLinks = false, noAccount = false }: Props) => {
  const router = useRouter()
  const [cookies] = useCookies(['tbId'])
  const [setActiveState] = useActionless(SET_ACTIVE_STATE)

  const handleOpen = () => setActiveState({ searchOpen: true })

  const removeLinks = noLinks || (environment.runningOnPrivateServer && PUBLIC_ROUTES.includes(router.pathname))

  return (
    <nav className={`${styles.bar} ${router.route === '/' ? styles.unsticky : ''}`}>
      <div className={styles.content}>
        <div className={styles.section}>
          {!removeLinks && (
            <>
              <LogoLink />
              {!noSearch && (
                <button className={styles.searchDecksNews} onClick={handleOpen} aria-label="Card Search">
                  Search
                </button>
              )}
              <span className={styles.desktopOptions}>
                <DecksButton />
                {/* <Link href="/community">Community</Link> */}
                <Link href="/news">News</Link>

                <Link
                  className={styles.forumsAndCollection}
                  href={cookies.tbId ? `/collection/v2/${cookies.tbId}` : '/collection'}>
                  Collection
                </Link>

                <ArchidektDropdown
                  triggerClassName={styles.forumsAndCollection}
                  menuClassName={styles.dropdown}
                  options={forumOptions}>
                  <button>Forums</button>
                </ArchidektDropdown>
              </span>

              <MobileNavbarOptions
                className={styles.mobileToolbar}
                overflowItemsClassName={styles.partialMobileToolbarOptions}
                noSearch={noSearch}
              />
            </>
          )}
        </div>
        <div className={styles.section}>
          {!noAccount && (
            <>
              {cookies.tbId && <Notifications />}
              {!cookies.tbId ? (
                <>
                  <Link href="/register">Register</Link>
                  <Link
                    href={`/login${router.asPath === '/' ? '' : `?redirectTo=${encodeURIComponent(router.asPath)}`}`}
                    rel={router.pathname}>
                    Login
                  </Link>
                </>
              ) : (
                <AccountDropdown />
              )}
            </>
          )}
          <DarkModeToggle />
        </div>
      </div>
    </nav>
  )
})

export default GlobalToolbar
