import React, { useRef, useEffect } from 'react'
import { useDrop } from 'react-dnd'

import Icon from 'components/elements/Icon'

import { DropItemTypes } from 'types/draggables'

import styles from './scrollZone.module.scss'

type Props = {
  bottom?: boolean
}

const SCROLL_SPEED = 8

const ScrollZone = ({ bottom }: Props) => {
  const scrollingInterval = useRef<any>(null)

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [DropItemTypes.CARD, DropItemTypes.FOLDER],
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [],
  )

  useEffect(() => {
    if (!isOver) clearInterval(scrollingInterval.current)
    else
      scrollingInterval.current = setInterval(
        () => (document.documentElement.scrollTop += bottom ? SCROLL_SPEED : -1 * SCROLL_SPEED),
        1,
      )

    return () => clearInterval(scrollingInterval.current)
  }, [isOver])

  return drop(
    <div
      className={`
      ${styles.container}
      ${bottom ? styles.bottom : ''}
      ${canDrop ? styles.isDragging : ''}
    `}>
      <Icon name={bottom ? 'chevron down' : 'chevron up'} />
    </div>,
  )
}

export default ScrollZone
