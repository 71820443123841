import React from 'react'

import styles from './animatedHamburger.module.scss'

type Props = {
  open: boolean
  className?: string
  barStyles?: string
}

const AnimatedHamburger = ({ open, className = '', barStyles = '' }: Props) => (
  <div className={`${styles.container} ${open ? styles.change : ''} ${className}`}>
    <div className={`${styles.bar1} ${barStyles}`}></div>
    <div className={`${styles.bar2} ${barStyles}`}></div>
    <div className={`${styles.bar3} ${barStyles}`}></div>
  </div>
)

export default AnimatedHamburger
