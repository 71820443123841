import CookieService, { expirationDurations } from 'services/cookie.service'

import { THEME } from 'types/active'

export const COOKIE_THEME_KEY = 'theme' // the key used to look up the value in cookies

export const themes = {
  light: { name: 'light', class: '' },
  dark: { name: 'dark', class: 'dark-mode' },
}

export const isDarkMode = () => {
  // If they've set a theme, use that theme
  const manuallySetTheme = CookieService.get('theme') // NaN , 0, 1
  const accountSetTheme = CookieService.get('settings')?.siteTheme // undefined, 0, 1

  return checkDarkModeManullay(manuallySetTheme, accountSetTheme)
}

export const checkDarkModeManullay = (manuallySetTheme: string, accountSetTheme: string) => {
  const osDarkTheme =
    typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches
      ? THEME.DARK
      : THEME.LIGHT

  // If any of these things say dark mode is enabled, just turn it on
  if (`${manuallySetTheme}` === THEME.DARK) return true
  if (`${manuallySetTheme}` === THEME.LIGHT) return false

  if (`${accountSetTheme}` === THEME.DARK) return true
  if (`${accountSetTheme}` === THEME.LIGHT) return false

  if (osDarkTheme === THEME.DARK) return true

  // If no theme was detected return false (light theme)
  return false
}

export const toggleThemeGlobally = (setCookie?: (name: any, value: any, options?: any) => void) => {
  const currentTheme = CookieService.get(COOKIE_THEME_KEY)
  const isOnLightTheme = `${currentTheme}` === THEME.LIGHT
  const newTheme = isOnLightTheme ? THEME.DARK : THEME.LIGHT

  newTheme === THEME.LIGHT ? setLightModeCss() : setDarkModeCss()

  if (setCookie) setCookie(COOKIE_THEME_KEY, newTheme, { maxAge: expirationDurations.month, path: '/' }) // sets the cookie via react-cookie hook (client)
  CookieService.set(COOKIE_THEME_KEY, newTheme, 'month') // Can set the cookie via universal-cookie (server or client outside of a component)
}

export const setLightModeCss = () => {
  if (typeof document === 'undefined') return

  document.body.classList.remove(themes.dark.class)
}

export const setDarkModeCss = () => {
  if (typeof document === 'undefined') return

  document.body.classList.add(themes.dark.class)
}

export function initializeTheme(setCookies?: (name: any, value: any, options?: any) => void) {
  const isDark = isDarkMode()

  if (isDark) setDarkModeCss()
  else setLightModeCss()

  if (isDark && setCookies) setCookies(COOKIE_THEME_KEY, THEME.DARK, { maxAge: expirationDurations.month, path: '/' })

  return isDark ? THEME.DARK : THEME.LIGHT
}
