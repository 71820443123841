import React, { useEffect, useState } from 'react'
import { Treebeard } from 'react-treebeard'
import { Dimmer, Loader } from 'semantic-ui-react'
import { useCookies } from 'react-cookie'

import { apiUrl } from 'environment'

import RequestService from 'services/request.service'

import { getStyle, TreeContainer } from './style'

type Props = {
  setFolderId: (id: number) => void
}

/**
 * This component houses the folder tree used to select a folder in the NewDeck component. A setFolderId function is
 * passed down from the parent and whenever a folder is selected the function is called with the new id so the parent
 * can keep track of which current folder is selected. The data object is maintained by Treebeard, looking like
 * {
 *   name: string,
 *   id: number,
 *   active: boolean,
 *   toggled: boolean,
 *   children: Array<THIS_OBJECT>
 * }
 * An object of this tree structure is fetched by the fetchFolderTree function which returns the object based on the
 * requesting user. The component itself renders the tree structure with the Treebeard component.
 */
const FolderTree = ({ setFolderId }: Props) => {
  const [{ theme }] = useCookies(['theme'])

  const [cursor, setCursor] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState(false)

  /**
   * This is the standard function provided by Treebeard's documentation to handle a change in state. It oddly
   * assigns values to objects and then reassigns them in the setState function. Cursor keeps track of the currently
   * selected node while data is the entire tree. This also calls the passed in setFolderId function so the parent
   * knows when the selected folder changes.
   * @param node
   * @param toggled
   */
  const handleOnToggle = (node: any, toggled: boolean) => {
    cursor.active = false
    node.active = true

    if (node.children) node.toggled = toggled

    const newData: any = Object.assign({}, data)

    setFolderId(node.id)
    setCursor(node.id === newData.id ? newData : node)
    setData(newData)
  }

  /**
   * This function is authenticated with the user's jwt and then requests the folder tree object for that specific
   * user. It keeps track of a loading state and sets the data and cursor when it receives the response from the api.
   * It sets the home directory as the active directory as well as toggled opened as long as it has less than 4
   * children.
   * @returns {*|Promise<R>|Promise<R|void>}
   */
  const fetchFolderTree = () => {
    setLoading(true)
    setData({})

    return RequestService.get(`${apiUrl}/api/decks/folderTree/`)
      .then(data => {
        const activeRoot = { ...data, active: true, toggled: data.children && data.children.length < 4 }

        setData(activeRoot)
        setCursor(activeRoot)
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchFolderTree()
  }, [])

  return (
    <TreeContainer isLoading={loading}>
      <Dimmer inverted active={loading}>
        <Loader size="mini" />
      </Dimmer>
      <Treebeard data={data} onToggle={handleOnToggle} style={getStyle(theme)} />
    </TreeContainer>
  )
}

export default FolderTree
