import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import Icon from 'components/elements/Icon'

import { AddCardView } from '../types'
import { LANG_ID_TO_CODE, COND_ID_COND_ABR } from 'types/collection'

import { useAppSelector } from 'redux/hooks'
import { CollectionV2Card } from 'redux/collectionV2/types'

import CollectionService from 'services/collection.service'

import SimpleSpinner from 'components/elements/SimpleSpinner'
import LabelDisplay from 'components/collectionV2/misc/LabelDisplay'
import { GenericCursorCardWrapper } from 'components/card/CursorCardWrapper'

import { responseToCard } from 'utils/ResponseToResults'

import styles from './reconcileExistingData.module.scss'

type Props = {
  collectionCard: CollectionV2Card
  onCardChange: (card: CollectionV2Card) => void
  onViewChange: (view: AddCardView) => void
  onCardSave: (card: CollectionV2Card) => void
  updateIfOneExactExists: boolean
}

const ReconcileExistingData = ({
  collectionCard,
  onCardChange,
  onViewChange,
  onCardSave,
  updateIfOneExactExists,
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState(new Array<CollectionV2Card>())

  const selectCardExtraSearchOptions = useAppSelector(state => state.collectionV2.selectCardExtraSearchOptions)

  const [{ tbId: userId }] = useCookies(['tbId'])

  const defaultButton = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    defaultButton.current?.focus() // Helps with keyboard only navigation

    let queryString = `oracleCardIds=${collectionCard.card.oracleCardId}`

    // prettier-ignore
    if (selectCardExtraSearchOptions?.archidekt?.length) queryString += `&cardSearch=true&editions=${selectCardExtraSearchOptions.archidekt.join(',')}`
    if (selectCardExtraSearchOptions?.scryfall) queryString += `&syntaxQuery=${selectCardExtraSearchOptions.scryfall}`

    CollectionService.listV2(`${userId}`, queryString)
      .then(res => {
        const serverCollectionData = res.results.map(collectionCard => ({
          ...collectionCard,
          card: responseToCard(collectionCard.card),
        }))

        if (serverCollectionData.length === 1 && updateIfOneExactExists) {
          const cardToUpdate = serverCollectionData[0]

          // @ts-ignore
          onCardSave({ ...cardToUpdate, quantity: cardToUpdate.quantity + 1 })
          onViewChange('selecting')

          return
        }

        // @ts-ignore - TODO - come back and fix this - Move the serialization logic to the service please
        setResults(serverCollectionData)
      })
      .finally(() => setTimeout(() => setLoading(false), 750))
  }, [])

  const handleSelectCard = (selectedCard: CollectionV2Card) => {
    onCardChange(selectedCard)
    onViewChange('finalizing')
  }

  return (
    <div className={styles.container}>
      <div className={styles.group}>
        <div className={styles.header}>{collectionCard.id ? 'Selected' : 'New'} collection record</div>
        <ExistingCardRecord
          ref={defaultButton}
          collectionCard={collectionCard}
          onSelect={() => handleSelectCard(collectionCard)}
        />
      </div>

      <div className={styles.group}>
        <div className={styles.header}>Existing collection records by name</div>

        <div className={styles.stripes}>
          {!loading &&
            results.map((card, index) => (
              <ExistingCardRecord key={index} collectionCard={card} onSelect={() => handleSelectCard(card)} />
            ))}
        </div>

        {!results.length && !loading && (
          <div className={styles.noMatches}>
            No matching collection records found
            {(!!selectCardExtraSearchOptions.archidekt.length || !!selectCardExtraSearchOptions.scryfall) &&
              ' with filters:'}
            {!!selectCardExtraSearchOptions.archidekt.length &&
              ` set codes ${selectCardExtraSearchOptions.archidekt.join(', ')}`}
            {!!selectCardExtraSearchOptions.scryfall && ` syntax ${selectCardExtraSearchOptions.scryfall}`}
          </div>
        )}

        {loading && (
          <div className={styles.loader}>
            <SimpleSpinner size="xSmall" />
            Fetching matching cards...
          </div>
        )}
      </div>
    </div>
  )
}

export default ReconcileExistingData

type ExistingCardRecordProps = {
  collectionCard: CollectionV2Card
  onSelect: () => void
  selectLabel?: string
  disabled?: boolean
}

export const ExistingCardRecord = forwardRef(
  ({ collectionCard, onSelect, selectLabel = 'Update this card', disabled }: ExistingCardRecordProps, ref?: any) => {
    const [detailsOpen, setDetailsOpen] = useState(false)

    const tags = useAppSelector(state => state.collectionV2.tags)

    const isNewCard = !collectionCard.id

    return (
      <>
        <GenericCursorCardWrapper card={collectionCard.card} className={styles.row} isDragging={false} skipOnTouch>
          <button disabled={isNewCard} className={styles.cardInfo} onClick={() => setDetailsOpen(!detailsOpen)}>
            {!isNewCard && <div>{collectionCard.quantity}</div>}
            <div className={styles.cardName}>{collectionCard.card.name}</div>
            {!isNewCard && <div>-</div>}
            {!isNewCard && collectionCard.modifier !== 'Normal' && <div>{collectionCard.modifier}</div>}
            {!isNewCard && <div>{collectionCard.card.setCode.toLocaleUpperCase()}</div>}
            {!isNewCard && <div>{collectionCard.card.collectorNumber}</div>}
            {!isNewCard && <Icon name={detailsOpen ? 'chevron down' : 'chevron right'} />}
          </button>
          <button
            disabled={disabled}
            ref={ref}
            className={`${styles.select} ${isNewCard ? styles.create : styles.update}`}
            onClick={onSelect}>
            {isNewCard ? 'Edit card details' : selectLabel}
            <Icon name="arrow right" />
          </button>
        </GenericCursorCardWrapper>
        {detailsOpen && (
          <div className={styles.details}>
            <div>Created at: {new Date(collectionCard.createdAt).toLocaleString()}</div>
            <div>Language: {LANG_ID_TO_CODE[collectionCard.language]}</div>
            <div>Condition: {COND_ID_COND_ABR[collectionCard.condition]}</div>
            <div>Collection labels: {!collectionCard.tags.length ? 'None' : ''}</div>
            {collectionCard.tags.map((tag, index) => (
              <LabelDisplay className={styles.tag} key={index} color={tags[tag]?.color} name={tags[tag]?.name} />
            ))}
          </div>
        )}
      </>
    )
  },
)
