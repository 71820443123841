import React from 'react'

import { OWNED } from 'types/deck'
import { OWNERSHIP_COLORS } from 'types/collection'

import styles from './cornerOwned.module.scss'

type Props = {
  owned: number
}

const CornerOwned = ({ owned }: Props) => (
  <div
    className={styles.container}
    title={owned === OWNED.NO ? undefined : owned === OWNED.PRINTING ? 'Owns other printing' : 'Owns exact printing'}>
    <div
      className={styles.ribbon}
      style={{
        backgroundColor:
          owned === OWNED.NO
            ? 'transparent'
            : owned === OWNED.PRINTING
            ? OWNERSHIP_COLORS.NOT_EXACT
            : OWNERSHIP_COLORS.EXACT,
      }}
    />
  </div>
)

export default CornerOwned
