import { SubfolderFolderItemType, DeckFolderItemType, SelectedFolderItemType } from '../../types/folders'

export type Folder = {
  id: number
  parentFolder: {
    id: number
    name: string
  }
  owner: {
    username: string
    id: number
    avatar: string
    frame: string
  }
  name: string
  private: boolean
  subfolders: Array<SubfolderFolderItemType>
  decks: Array<DeckFolderItemType>
}

export type FoldersState = {
  loading: boolean
  selectedItems: SelectedFolderItemType[]
}

export const initialFolderState: FoldersState = {
  loading: false,
  selectedItems: [],
}
