import styled from 'styled-components'

export const StyledAd = styled.div<{ url: string; mobileUrl?: string }>`
  width: 100%;
  height: 90px;
  background-image: url('${props => props.url}');
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 500px) {
    ${props => (props.mobileUrl ? `background-image: url("${props.mobileUrl}");` : ``)}
  }
`

export const StyledAdLink = styled.a`
  width: 100%;
  max-width: 700px;
  height: 90px;
`

export const CustomAdContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`
