import React, { useState, useEffect } from 'react'

import RequestService from 'services/request.service'

import Select, { Options } from 'components/formElements/SemanticSelect'

type EditionResponse = {
  editioncode: string
  editionname: string
  mtgoCode: string | null
}

type Props = {
  disabled?: boolean
  style?: React.CSSProperties
  value?: string | string[]
} & ({ onChange: (values: any) => void; single: true } | { onChange: (values: any[]) => void; single: never | false })

const SetDropdown = ({ value, style, onChange, single, disabled }: Props) => {
  const [selectedOptions, setSelectedOptions] = useState(value ?? new Array<string>())
  const [options, setOptions] = useState(new Array<Options>())

  useEffect(() => {
    RequestService.get<EditionResponse[]>(`/api/cards/editions/`)
      .then(editions => {
        const options = editions.map(res => ({
          text: `${res.editionname} (${res.editioncode.toLocaleUpperCase()})`,
          value: res.editioncode,
        }))

        setOptions(options)
      })
      .catch(err => console.error(err))
  }, [])

  // Used to reset the form
  useEffect(() => {
    if (!value || !value.length) setSelectedOptions([])
  }, [value])

  const handleChange = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions)
    onChange(selectedOptions)
  }

  return (
    <Select
      disabled={disabled}
      fluid
      multiple={!single}
      searchable
      selection
      style={style}
      placeholder="Editions"
      onChange={handleChange}
      options={options}
      value={selectedOptions}
    />
  )
}

export default SetDropdown
