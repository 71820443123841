export function getContrastingColor(hexColor: string) {
  // Remove # if present
  hexColor = hexColor.replace(/^#/, '')

  // Convert hex color to RGB
  const r = parseInt(hexColor.substring(0, 2), 16) || 255
  const g = parseInt(hexColor.substring(2, 4), 16) || 255
  const b = parseInt(hexColor.substring(4, 6), 16) || 255

  // Idk why, but this requires an override
  if (r === 0 && g === 0 && b === 0) return '#FFFFFF'

  // Calculate luminance
  const useBlack = 0.299 * r + 0.587 * g + 0.114 * b > 188

  // Determine which contrast is higher
  return useBlack ? '#000000' : '#FFFFFF'
}

export function calculateShade(hexColor: string, offset = 0.15) {
  // Remove the "#" if it exists
  hexColor = hexColor.replace(/^#/, '')

  // Convert hex to RGB
  let r = parseInt(hexColor.substring(0, 2), 16)
  let g = parseInt(hexColor.substring(2, 4), 16)
  let b = parseInt(hexColor.substring(4, 6), 16)

  const makeDarker = 0.299 * r + 0.587 * g + 0.114 * b > 188
  const relativeOffset = makeDarker ? 1 - offset : 1 + offset

  // Calculate 10% lighter color
  r = Math.min(255, Math.round(r * relativeOffset))
  g = Math.min(255, Math.round(g * relativeOffset))
  b = Math.min(255, Math.round(b * relativeOffset))

  // Convert RGB to hex
  let lighterHex = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)

  return lighterHex
}
