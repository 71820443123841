import React from 'react'

import styles from './cardBanner.module.scss'

type Props = {
  src?: string | null
  backgroundClassName?: string
  height?: string
  className?: string
  artist?: boolean
  title?: any
  children?: any
  allowOverflow?: boolean
  blendBottom?: boolean
  blendTop?: boolean
}

// TODO - on safari the blurred card goes away when you scroll it out of view then come back
// It's very strange
const CardBanner = ({
  src,
  height,
  className = '',
  backgroundClassName = '',
  title,
  children,
  allowOverflow,
  blendBottom,
  blendTop,
}: Props) => (
  <div className={`${styles.container} ${className}`} style={{ height, overflow: allowOverflow ? 'unset' : 'hidden' }}>
    <div className={styles.backgroundContainer}>
      <div
        className={`${styles.background} ${backgroundClassName}`}
        style={{ backgroundImage: `url(${src ? src : '/images/hex_background.jpg'})` }}
      />
    </div>

    {blendTop && <div className={styles.topGradientMask} />}
    {blendBottom && <div className={styles.bottomGradientMask} />}

    {title && <h1 className={styles.header}>{title}</h1>}
    {children}
  </div>
)

export default CardBanner
