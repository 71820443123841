import React from 'react'
import { Label, Icon } from 'semantic-ui-react'

import styles from './cornerFlip.module.scss'

type Props = {
  flipped: boolean
  onClick: () => void
}

const CornerFlip = ({ flipped, onClick }: Props) => (
  <Label
    style={{ zIndex: 'unset' }}
    key="FlipLabel"
    className={styles.container}
    color={flipped ? 'orange' : 'purple'}
    corner="right"
    size="mini">
    <div className={styles.content} onClick={onClick}>
      <Icon name="refresh" style={{ cursor: 'pointer' }} />
    </div>
  </Label>
)

export default CornerFlip
