import React from 'react'
import type { SemanticICONS } from 'semantic-ui-react'

export type ToastComponentType = React.FC<{ id: string } & Record<string, any>>
export type ToastOptionType = 'error' | 'warning' | 'success'

export type ToastType = {
  type: ToastOptionType
  id: string
  duration?: number
} & (
  | {
      title: string
      text: string
      body: null
    }
  | { title: null; text: null; body: ToastComponentType; extraProps?: Record<string, any> }
)

export const TOAST_ICONS: Record<string, SemanticICONS> = {
  error: 'remove circle',
  warning: 'warning sign',
  success: 'check circle',
}
