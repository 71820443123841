import React, { useEffect, useState } from 'react'

type Status = 'unmounted' | 'exited' | 'entering' | 'entered' | 'exiting'
type Props = {
  children: React.ReactNode
  show: boolean
  duration?: number
  unmountOnClosed?: boolean
  className?: string
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const UNMOUNTED: Status = 'unmounted'
const EXITED: Status = 'exited'
const ENTERING: Status = 'entering'
const ENTERED: Status = 'entered'
const EXITING: Status = 'exiting'

const transitionStyles: Record<Status, React.CSSProperties> = {
  unmounted: { opacity: 0 },
  entering: { opacity: 0, transform: 'translateY(-100px)' },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const DropIn = ({ children, show, duration = 300, className, style, onClick, unmountOnClosed }: Props) => {
  const [status, setStatus] = useState<Status>(UNMOUNTED)

  useEffect(() => {
    if (show) performEnter()
    else performExit()
  }, [show])

  const performEnter = () => {
    setStatus(ENTERING)
    setTimeout(() => setStatus(ENTERED), duration)
  }

  const performExit = () => {
    setStatus(EXITING)
    setTimeout(() => setStatus(EXITED), duration)
  }

  if (unmountOnClosed && (status === UNMOUNTED || status === EXITED)) return null

  return (
    <div
      onClick={onClick}
      className={className}
      style={{
        ...style,
        transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
        opacity: 0.1,
        ...transitionStyles[status],
      }}>
      {children}
    </div>
  )
}

export default DropIn
