import React from 'react'
import { LANGUAGE_CODE, CONDITION_CODE } from 'types/collection'
import { CardType, defaultCard, Finishes } from 'types/deck'

export type CollectionV2State = {
  ownsCollection: boolean
  hasFetchedCollectionTags: boolean

  preferredPageSize: number

  recentlyAdded: number[]
  serverCollectionData: number[] // Cards newly added do not touch this array until we refresh and the server sends us the new dat

  collectionCards: Record<string, CollectionV2Card>

  tags: Record<number | string, CollectionTag>

  numberOfResults: number
  game: CollectionGameType
  isPublic: boolean

  // Cached data - pulled from cookies
  view: 'text' | 'image'
  viewingLabels: boolean

  priceSource: number
  priceSource2: number

  activeCardTab: ActiveCollectionTab
  activeCardId: number | null
  openToAllEditionsMenu: boolean

  selectCardExtraSearchOptions: {
    archidekt: string[]
    scryfall: string
  }

  multiSelectedIds: Record<number, boolean>

  filteringModalOpen: boolean

  autoColumns: boolean
  columnData: CollectionColumnOptions[]

  imageSize: ImageSize

  filterType: 'archidekt' | 'scryfall'
}

export type CollectionV2Card = {
  id: number
  card: CardType
  quantity: number
  modifier: Finishes
  language: number
  condition: number
  purchasePrice: number | null
  createdAt: string
  modifiedAt?: string
  tags: number[]
}

export type CollectionTag = {
  id: number | string
  name: string
  color: string
}

export type CollectionGameType = 1 | 2 | 3
export const COLLECTION_GAME_TYPE: Record<CollectionGameType, string> = {
  1: 'Paper',
  2: 'MTGO',
  3: 'Arena',
}

export const defaultCollectionV2Card: CollectionV2Card = {
  id: 0,
  card: defaultCard,
  quantity: 1,
  modifier: 'Normal',
  language: LANGUAGE_CODE.EN,
  condition: CONDITION_CODE.NM,
  purchasePrice: null,
  createdAt: '',
  tags: [],
}

export type CollectionColumn =
  | 'quantity'
  | 'name'
  | 'set'
  | 'editionDate'
  | 'collectorNumber'
  | 'rarity'
  | 'condition'
  | 'language'
  | 'purchasePrice'
  | 'createdAt'
  | 'updatedAt'
  | 'modifier'
  | 'priceOne'
  | 'priceTwo'
  | 'colorLabels'
  | 'manaValue'
  | 'casingCost'
  | 'types'
  | 'power'
  | 'loyalty'
  | 'toughness'
  | 'uid'
  | 'setCode'
  | 'actions'
  | 'artist'

export type CollectionColumnOptions = {
  label: string
  key: CollectionColumn
  enabled: boolean
  width?: number
}

export type SaturatedCollectionColumnOptions =
  | React.ReactNode
  | {
      content?: React.ReactNode
      onClick?: () => void
    }

export type ImageSize = 'small' | 'normal' | 'large'

export type CollectionSpecificFilters = {
  andTags?: string[]
  orTags?: string[]
  notTags?: string[]
  collectionLanguage?: string[]
  quantity?: number | string
  quantityLogic?: '=' | '>=' | '<='
  treatment?: 'foil' | 'nonfoil' | 'etched'
  condition?: string[]
}

export type ActiveCollectionTab = 'details' | 'relatedCards' | 'decks'
