import React, { useEffect, useRef, useState } from 'react'
import Icon from 'components/elements/Icon'
import { isEqual } from 'lodash'

import { AddCardView } from 'components/collectionV2/addCards/formParts/types'

import { CollectionV2Card, CollectionGameType } from 'redux/collectionV2/types'
import { CardDetailsFormOnly } from 'components/collectionV2/cardDetails/Form'

import ToastService from 'services/toast.service'
import PhatDropdown from 'components/formElements/PhatDropdown'
import { basicKeybindSkips } from 'components/deckPage/modals/KeybindsOverlay'

import styles from './finalizeData.module.scss'

type Props = {
  collectionCard: CollectionV2Card
  onCardChange: (card: CollectionV2Card) => void
  onCardSave: (overrideGametype?: number) => Promise<void>
  onChangeView: (view: AddCardView) => void
  ownsCollection?: boolean
  gameTypeSelector?: boolean
}

const FinalizeData = ({
  collectionCard,
  onCardChange,
  onCardSave,
  onChangeView,
  ownsCollection,
  gameTypeSelector = false,
}: Props) => {
  const keylistener = useRef<(e: KeyboardEvent) => void>(() => null)

  const [loading, setLoading] = useState(false)
  const [initialCollectionCard] = useState(collectionCard)
  const [hasChanges, setHasChanges] = useState(false)
  const [overridenGameType, setOverridenGameType] = useState<CollectionGameType>(1)

  useEffect(() => {
    window.removeEventListener('keydown', keylistener.current)

    keylistener.current = (e: KeyboardEvent) => {
      if (basicKeybindSkips(e)) return

      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault()

        handleCardSaveOrUpdated()
      }
    }

    window.addEventListener('keydown', keylistener.current)

    if (!isEqual(collectionCard, initialCollectionCard)) setHasChanges(true)

    return () => window.removeEventListener('keydown', keylistener.current)
  }, [collectionCard, hasChanges])

  const handleGoBack = () => {
    if (!!collectionCard.card.owned) onChangeView('reconciling')
    else onChangeView('selecting')
  }

  const handleCardSaveOrUpdated = () => {
    if (!hasChanges && collectionCard.id)
      return ToastService.create(
        'No changes have been made. Because no changes were made to this collection record, nothing has been saved. This record has been presented exactly as it exists currently in our database, if you want it to update it must be done manually or by using the "+1 quantity" option in the "Speed up" dropdown',
        'Collection update card',
        'warning',
      )

    setLoading(true)

    if (gameTypeSelector) onCardSave(overridenGameType).finally(() => setLoading(false))
    else onCardSave().finally(() => setLoading(false))
  }

  return (
    <div className={styles.container}>
      <CardDetailsFormOnly
        collectionCard={collectionCard}
        onChange={onCardChange}
        ownsCollection={ownsCollection}
        dontAllowZero
      />

      {gameTypeSelector && (
        <>
          <div className={styles.horizontalRule} />

          <div className={styles.gameTypeSelectorContainer}>
            <PhatDropdown
              header="Select collection type"
              value={overridenGameType}
              options={[
                {
                  label: (
                    <span className={styles.customIconOption}>
                      <Icon name="paste" /> Paper
                    </span>
                  ),
                  onClick: () => setOverridenGameType(1),
                  id: '1',
                },
                {
                  label: (
                    <span className={styles.customIconOption}>
                      <Icon className="mtgo" /> MTGO
                    </span>
                  ),
                  onClick: () => setOverridenGameType(2),
                  id: '2',
                },
                {
                  label: (
                    <span className={styles.customIconOption}>
                      <Icon className="arena" /> Arena
                    </span>
                  ),
                  onClick: () => setOverridenGameType(3),
                  id: '3',
                },
              ]}
            />
          </div>
        </>
      )}

      <div className={styles.horizontalRule} />

      <div className={styles.cardDetailControls}>
        <button onClick={handleGoBack} className={styles.backButton}>
          <Icon name="arrow left" /> Go back <span className={styles.hideOnMobile}>a step</span>
        </button>

        <button onClick={handleCardSaveOrUpdated} className={styles.saveButton} disabled={loading}>
          <Icon name={collectionCard.id ? 'save outline' : 'plus'} />
          {collectionCard.id ? 'Update' : 'Add'} <span className={styles.hideOnMobile}>collection</span> record{' '}
          <span className={styles.hideOnMobile}>(Shift + Enter)</span>
        </button>
      </div>
    </div>
  )
}

export default FinalizeData
