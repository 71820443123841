import { RequestService } from 'services/request.service'
import { fixDangjosStupidFuckingInternalProtocolIssue } from 'services/card.service'

import { ColorResponse } from 'services/apiTypes/edhrec.types'
import { CardListResponse } from 'services/apiTypes/card.types'

import { responseToCard } from 'utils/ResponseToResults'
import { ExternalColorCode } from 'utils/colorUtils'

export class EDHRECService extends RequestService {
  public colors = (
    colors: ExternalColorCode = 'wubrg',
    orderBy: 'cycles' | 'popularity' = 'popularity',
  ): Promise<CardListResponse> => {
    return super.get<ColorResponse>(`/api/cards/edhrec-colors/?orderBy=${orderBy}&colors=${colors}`).then(res => ({
      total: res.total,
      next: fixDangjosStupidFuckingInternalProtocolIssue(res.next),
      cards: res.results.map((card: any) => responseToCard(card, {}, true)),
    }))
  }
}

const edhrecService = new EDHRECService()

export default edhrecService
