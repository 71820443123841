import React from 'react'
import Link from 'components/elements/Link'
import { useRouter } from 'next/router'

import { buildId } from 'environment'

import { Spacer } from 'views_DEPRICATED/Pages/style'

import InternalLinks from 'components/misc/Footer/InternalLinks'
import ExternalLinks from 'components/misc/Footer/ExternalLinks'
import SocialMediaInteractions from 'components/misc/Footer/SocialMediaInteractions'

import { SmallGrid, DiagonalGrid } from 'components/misc/StyledGridBackgrounds'

import styles from './footer.module.scss'

/**
 * Footer of the page contains several internal links, social media external links, legal notices and links to the
 * privacy policy and terms of service.
 */
const Footer = () => {
  const router = useRouter()
  const isDeckPage = router.pathname.includes('/decks/') && !router.pathname.includes('/image')

  return (
    <div className={`${styles.container} ${isDeckPage ? styles.deckPageOverride : ''}`}>
      <DiagonalGrid>
        <SmallGrid />
      </DiagonalGrid>
      <InternalLinks />
      <Spacer height={25} />
      <ExternalLinks />
      <SocialMediaInteractions />
      <div className={styles.notices}>
        Magic: The Gathering is a Trademark of Wizards of the Coast, Inc. and Hasbro, Inc. Archidekt is unaffiliated.{' '}
        <br />
        Comments and deck descriptions are user submitted and do not represent the views of Archidekt. <br />
        Current card prices are provided by{' '}
        <a
          href="https://www.tcgplayer.com?partner=Archidekt&utm_campaign=affiliate&utm_medium=footer&utm_source=Archidekt"
          rel="noopener noreferrer"
          target="_blank">
          TCG Player
        </a>
        {', '}
        <a
          href="https://www.cardkingdom.com?partner=archidekt&utm_source=archidekt&utm_medium=affiliate&utm_campaign=archidekt&partner_args=footer"
          rel="noopener noreferrer"
          target="_blank">
          Card Kingdom
        </a>
        {', '}
        <a href="https://www.cardmarket.com/Magic?referrer=archidekt" rel="noopener noreferrer" target="_blank">
          Cardmarket
        </a>
        {' and '}
        <a href="https://www.cardhoarder.com/?affiliate_id=archidekt" rel="noopener noreferrer" target="_blank">
          Cardhoarder
        </a>
        <br />
        Additional data from{' '}
        <a href="https://scryfall.com" rel="noopener noreferrer" target="_blank">
          Scryfall
        </a>
        {' and '}
        <a href="https://edhrec.com" rel="noopener noreferrer" target="_blank">
          EDHREC
        </a>
      </div>

      <div className={styles.notices}>
        <Link href="/privacy">Privacy Policy</Link>
        &nbsp;&nbsp;&nbsp;
        <Link href="/terms">Terms of Service</Link>
      </div>
      <span data-ccpa-link="1"></span>
      <p>Archidekt LLC &copy;{new Date().getFullYear()}</p>
      <p>Build Id - {buildId.substring(0, 7)}</p>
    </div>
  )
}

export default Footer
