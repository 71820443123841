import React from 'react'
import Icon from 'components/elements/Icon'

import { generateTabState, Tab } from 'types/searchV2'

import GlobalOverlayStack from 'components/elements/Overlay/GlobalOverlayStack'
import TabPreview from 'components/cardSearchPanel/tabs/tabPreview'

import styles from './tabSelection.module.scss'

type Props = {
  tabs: Tab[]
  onTabSelected: (updatedTab: Tab) => void
  onKillTab: (tab: Tab) => void
  open: boolean
  onClose: () => void
}

const TabSelection = ({ tabs, onTabSelected, onKillTab, open, onClose }: Props) => {
  return (
    <GlobalOverlayStack
      open={open}
      onClose={onClose}
      mobileControls={
        <button className={styles.newTab} onClick={() => onTabSelected({ ...generateTabState.archidektSearch() })}>
          <Icon name="add" /> <span>New tab</span>
        </button>
      }>
      <div className={styles.header}>
        <h3>Existing tabs</h3>
      </div>

      <div className={styles.tabGrid}>
        {tabs.map((tab, tabIndex) => (
          <span key={tabIndex} className={styles.preview}>
            {tabs.length > 1 && (
              <button onClick={() => onKillTab(tab)} className={styles.killButton}>
                <Icon name="close" />
              </button>
            )}
            <TabPreview tab={tab} onClick={() => onTabSelected(tab)} />
          </span>
        ))}
      </div>
    </GlobalOverlayStack>
  )
}

export default TabSelection
