import React, { useEffect, useState } from 'react'
import shortid from 'shortid'

import styles from './checkbox.module.scss'

type Props = {
  id?: string
  label?: React.ReactNode
  checked?: boolean
  onChange?: (updatedValue: boolean, event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  disabled?: boolean
  disableHoverStyles?: boolean
}

const Checkbox = ({
  id,
  label,
  checked = false,
  onChange,
  className = '',
  disabled = false,
  disableHoverStyles = false,
}: Props) => {
  const [localId] = useState(id || shortid.generate())
  const [localChecked, setLocalChecked] = useState(checked)

  useEffect(() => {
    if (checked !== localChecked) setLocalChecked(checked)
  }, [checked])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalChecked(!localChecked)

    if (onChange) onChange(!localChecked, e)
  }

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''} ${className}`}>
      <input
        id={localId}
        type="checkbox"
        checked={localChecked}
        onChange={handleChange}
        disabled={disabled}
        className={!disableHoverStyles ? styles.hover : undefined}
      />
      {!!label && <label htmlFor={localId}>{label}</label>}
    </div>
  )
}

export default Checkbox
