import shortid from 'shortid'

import type { CardType } from 'types/deck'
import { defaultCard } from 'types/deck'
import { getCardDefaultCategoryFromCard } from './CategoryGenerator'
import type { RecommendationCardType } from 'types/recommendations'
import CookieService from 'services/cookie.service'

const pushToList = (colorString: string, list: Array<any>) => {
  colorString
    .trim()
    .replace(/{/g, '')
    .split('}')
    .forEach(symbol => {
      if (symbol.length > 0) {
        if (symbol.search('/') !== -1) {
          //Maybe split and push array instead
          list.push(symbol.split('/'))
        } else {
          list.push(symbol.replace('∞', 'I'))
        }
      }
    })
}

export const colorStringList = (colorString: string): Array<string> => {
  let results: Array<string> = []
  // console.log(colorString)
  const colorSplit = colorString.replace(/ /g, '').split('//')
  pushToList(colorSplit[0], results)
  if (colorSplit.length > 1) {
    results.push('/')
    pushToList(colorSplit[1], results)
  }
  return results
}

// DEPRICATED - DO NOT USE FOR NEW CODE
// TODO - Refactor this out entirely for old code (eg: Search redux)
export const responseToResults = (response: Array<Record<string, any>>): Array<CardType> => {
  const results: Array<CardType> = []

  response.forEach(card => {
    if (card.layout === 'meld' && card.meldBack === null) return

    results.push(responseToCard(card.card, card))
  })

  return results
}

export const responseToDeckCards = (resposne: Array<Record<string, any>>, unlinkFromDeck = false): Array<CardType> => {
  return resposne.reduce((acc: CardType[], deckCard: any) => {
    const card = deckCard.card

    if (card.layout === 'meld' && card.meldBack === null) return acc

    const serializedCard = responseToCard(card, deckCard, unlinkFromDeck)

    acc.push(serializedCard)

    return acc
  }, new Array<CardType>())
}

export const responseToCard = (
  card: Record<string, any>,
  extraCardMetaData: Record<string, any> = {},
  unlinkFromDeck = false,
): CardType | RecommendationCardType => {
  let colorLabel = { name: '', color: '#656565' }
  const label = extraCardMetaData.label

  if (label) {
    const split = label.split(',')

    if (split.length === 2) colorLabel = { name: split[0], color: split[1] }
  }

  card = {
    ...card.oracleCard,
    ...card,
  }

  const OptionsOrder: Record<string, number> = { 'Normal': 1, 'Foil': 2, 'Etched': 3 }
  const orderedOptions = (card.options || []).sort((a: string, b: string) =>
    (OptionsOrder[a] || 1000) < (OptionsOrder[b] || 1000) ? -1 : 1,
  )

  const deckRelationId = extraCardMetaData.id && !unlinkFromDeck ? extraCardMetaData.id.toString() : ''

  const serializedCard = {
    ...defaultCard,

    // Deck Card Properties
    qty: card.qty || extraCardMetaData.quantity || 0, // card.qty is only used by the ImportCard page
    modifier: extraCardMetaData.modifier || orderedOptions[0],
    typeCategory: 'Default',
    categories: [],
    colorLabel: colorLabel,
    customCmc: typeof extraCardMetaData.customCmc === 'number' ? extraCardMetaData.customCmc : null,
    companion: extraCardMetaData.companion || false,
    flippedDefault: extraCardMetaData.flippedDefault || false,

    // Collection Properites
    owned: card.owned ? card.owned : 0,

    // Card Properties
    prices: {
      ck: card.prices !== null && card.prices.ck ? Number(card.prices.ck) : 0.0,
      ckFoil: card.prices !== null && card.prices.ckfoil ? Number(card.prices.ckfoil) : 0.0,
      tcg: card.prices !== null && card.prices.tcg ? Number(card.prices.tcg) : 0.0,
      tcgFoil: card.prices !== null && card.prices.tcgfoil ? Number(card.prices.tcgfoil) : 0.0,
      mtgo: card.prices !== null && card.prices.mtgo ? Number(card.prices.mtgo) : 0.0,
      mtgoFoil: card.prices !== null && card.prices.mtgofoil ? Number(card.prices.mtgofoil) : 0.0,
      cm: card.prices !== null && card.prices.cm ? Number(card.prices.cm) : 0.0,
      cmFoil: card.prices !== null && card.prices.cmfoil ? Number(card.prices.cmfoil) : 0.0,
      scg: card.prices !== null && card.prices.scg ? Number(card.prices.scg) : 0.0,
      scgFoil: card.prices !== null && card.prices.scgfoil ? Number(card.prices.scgfoil) : 0.0,
      mp: card.prices !== null && card.prices.mp ? Number(card.prices.mp) : 0.0,
      mpFoil: card.prices !== null && card.prices.mpfoil ? Number(card.prices.mpfoil) : 0.0,
      tcgland: card.prices !== null && card.prices.tcgLand ? Number(card.prices.tcgLand) : 0.0,
      tcglandFoil: card.prices !== null && card.prices.tcgLandFoil ? Number(card.prices.tcgLandFoil) : 0.0,
    },
    name: card.name ? card.name : '',
    displayName: card.displayName || null,
    cmc: card.cmc ? card.cmc : 0,
    rarity: card.rarity ? card.rarity : '',
    castingCost: card.manaCost ? colorStringList(card.manaCost) : [],
    colorIdentity: card.colorIdentity ? card.colorIdentity : [],
    colors: card.colors ? card.colors : [],
    manaProduction: card.manaProduction ? card.manaProduction : {},
    text: card.text ? card.text : '',
    flavor: card.flavor ? card.flavor : '',
    setCode: card.edition ? card.edition.editioncode : '',
    mtgoCode: card.edition && card.edition.mtgoCode ? card.edition.mtgoCode : '',
    setDate: (card.edition && card.edition.editiondate) || '',
    setType: (card.edition && card.edition.editiontype) || '',
    set: card.edition ? card.edition.editionname : '',
    uid: card.uid ? card.uid : '',
    id: shortid.generate(),
    deckRelationId: deckRelationId,
    cardId: card.id.toString(),
    oracleCardId: card.oracleCard.id || 0,
    multiverseid: card.multiverseid ? card.multiverseid : 0,
    imgurl: card.imgurl ? card.imgurl : '',
    artist: card.artist ? card.artist : '',
    superTypes: card.superTypes ? card.superTypes : [],
    subTypes: card.subTypes ? card.subTypes : [],
    types: card.types ? card.types : [],
    power: card.power ? card.power : '',
    toughness: card.toughness ? card.toughness : '',
    layout: card.layout ? card.layout : 'normal',
    loyalty: card.loyalty ? card.loyalty : '',
    names: card.names ? card.names : [],
    options: orderedOptions,
    legalities: card.legalities ? card.legalities : {},
    front:
      card.faces && card.faces.length
        ? { ...card.faces[0], castingCost: colorStringList(card.faces[0].manaCost) }
        : null,
    back:
      card.faces && card.faces.length
        ? { ...card.faces[1], castingCost: colorStringList(card.faces[1].manaCost) }
        : null,
    ids: {
      ckNormalId: card.ckNormalId ? card.ckNormalId : 0,
      ckFoilId: card.ckFoilId ? card.ckFoilId : 0,
      mtgoNormalId: card.mtgoNormalId ? card.mtgoNormalId : 0,
      mtgoFoilId: card.mtgoFoilId ? card.mtgoFoilId : 0,
      tcgId: card.tcgProductId ? card.tcgProductId : 0,
      cmEd: card.cmEd ? card.cmEd : '',
      scg: card.scgSku ? card.scgSku : '',
      scgFoil: card.scgFoilSku ? card.scgFoilSku : '',
    },
    collectorNumber: card.collectorNumber ? card.collectorNumber : '',
    salt: card.salt,

    // Recommendation Specific
    percent: card.percent || null,
    tokens: card.tokens || [],
    createdAt: extraCardMetaData.createdAt || null,
    updatedAt: extraCardMetaData.updatedAt || null,
    deletedAt: extraCardMetaData.deletedAt || null,

    defaultCategory: card.oracleCard?.defaultCategory || null,
    globalCategories: card.globalCategories || [],
    scryfallImageHash: card.scryfallImageHash || null,
  }

  const { noUseDefaultCategories = false } = CookieService.get('settings') || {}

  const typeCategory = getCardDefaultCategoryFromCard(serializedCard)
  const defaultCategory = !noUseDefaultCategories ? serializedCard.defaultCategory || typeCategory : typeCategory

  let categories = []

  const hasCategories = extraCardMetaData.categories && extraCardMetaData.categories.length
  const hasGlobalCategories = card.globalCategories && card.globalCategories.length

  if (hasCategories) categories = extraCardMetaData.categories
  else if (extraCardMetaData.removedCategories) categories = extraCardMetaData.removedCategories.split(',')
  else if (hasGlobalCategories) categories = [...card.globalCategories]
  else categories = [defaultCategory]

  return {
    ...serializedCard,
    typeCategory,
    categories,
  }
}
