import CookieService from 'services/cookie.service'

import { CollectionV2State } from 'redux/collectionV2/types'
import { defaultColumnData } from 'redux/collectionV2/initialState'

const COOKIE_KEY = 'collectionV2-cache'
const ENABLED_COLUMNS_KEY = 'enabledColumnKeys'

// Write these to cookies on state change, read them on load
export const cacheabelFields: (keyof CollectionV2State)[] = [
  'view',
  'priceSource',
  'priceSource2',
  'filterType',
  'imageSize',
  'autoColumns',
  'activeCardTab',
  'preferredPageSize',

  // This field is huge with a lot of unncessary data for the cache, let's handle this one manually so we're not pressing against the 4k cookie limit
  // Instead we're saving [ENABLED_COLUMNS_KEY]: Array<string>() of the enabled column keys
  // 'columnData',
]

export const writeChangesToCookies = (changes: Partial<CollectionV2State>) => {
  // @ts-ignore
  const alterdFields: (keyof CollectionV2State)[] = Object.keys(changes)
  const cacheableInfo = alterdFields.reduce((acc, field) => {
    if (!cacheabelFields.includes(field)) return acc

    // @ts-ignore
    acc[`${field}`] = changes[field]

    return acc
  }, {} as Partial<CollectionV2State>)

  if (!Object.keys(cacheableInfo).length && !changes.columnData) return // skip writting to local storage unless there's anything new to write

  // If there are any specific changes to the columnData, let's cache the enabled column keys instead of the whole columnData object
  if (changes.columnData) {
    // @ts-ignore
    cacheableInfo[ENABLED_COLUMNS_KEY] = changes.columnData.filter(column => column.enabled).map(column => column.key)
  }

  const existingCache = getCachedState()

  // Pulling the data from the cache will be in it's saturated state. We don't want to cache that, so we're converting it back to it's unsaturated state
  // I'm doing it this way to keep the getCachgedState function as clean as possible
  if (existingCache.columnData) {
    // @ts-ignore
    existingCache[ENABLED_COLUMNS_KEY] = existingCache.columnData
      .filter(column => column.enabled)
      .map(column => column.key)

    // Remove the satruated data so it doesn't get written back to the cache, and at this point we've already converted it to the unsaturated state
    delete existingCache.columnData
  }

  const updatedCache = { ...existingCache, ...cacheableInfo }

  CookieService.set(COOKIE_KEY, updatedCache, 'year')
}

export function getCachedState(serverCookies?: Record<string, any> | string): Partial<CollectionV2State> {
  const cachedState = CookieService.get(COOKIE_KEY, serverCookies) || {}

  if (cachedState[ENABLED_COLUMNS_KEY]) {
    cachedState.columnData = defaultColumnData.map(column => ({
      ...column,
      enabled: cachedState[ENABLED_COLUMNS_KEY].includes(column.key),
    }))

    delete cachedState[ENABLED_COLUMNS_KEY]
  }

  return cachedState
}
