import React, { useEffect, useState } from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import ReactGA from 'react-ga4'

import { CardType } from 'types/deck'

import CardService from 'services/card.service'
import ToastService from 'services/toast.service'

import { useAppSelector } from 'redux/hooks'
import { selectCommanderCards, selectMaindeckCards } from 'redux/deck/selectors'

import PhatButton from 'components/formElements/PhatButton'

import styles from './edhRecsForm.module.scss'

const options = [
  { text: 'All Card Types', value: 'all' },
  { text: 'Artifact', value: 'Artifact' },
  { text: 'Creature', value: 'Creature' },
  { text: 'Enchantment', value: 'Enchantment' },
  { text: 'Instant', value: 'Instant' },
  { text: 'Land', value: 'Land' },
  { text: 'Planeswalker', value: 'Planeswalker' },
  { text: 'Sorcery', value: 'Sorcery' },
]

type Props = {
  onSubmitted: () => void
  onRequestFailed: (message?: string) => void
  // send back the search object to the parent in case anything wants to be done with it
  onResponseRecieved: (response: CardType[]) => void
  className?: string
  alreadyHasResults?: boolean
  noAutoSearch?: boolean
}

const EdhRecFrom = ({ onSubmitted, onRequestFailed, onResponseRecieved, noAutoSearch }: Props) => {
  const [selectedCardType, setSelectedCardType] = useState('all')
  const [localResults, setLocalResults] = useState(new Array<CardType>())

  const commanders = useAppSelector(selectCommanderCards)
  const maindeck = useAppSelector(selectMaindeckCards)

  const handleSearch = (e?: React.SyntheticEvent, cardType = selectedCardType) => {
    e?.preventDefault()

    // prettier-ignore
    if (!commanders.length) return ToastService.create('Your deck needs a commander before we can get EDHRECs', 'EDHREC Search', 'warning')

    onSubmitted() // let the parent know we're about to submit the search

    let recsSize = '1 - 50'

    if (maindeck.length > 50) recsSize = '51 - 100'
    if (maindeck.length > 100) recsSize = '101 - 150'
    if (maindeck.length > 150) recsSize = '151 - 200'
    if (maindeck.length > 200) recsSize = '200+'

    ReactGA.event({ category: 'Deck', action: `Recs size ${recsSize}` })

    CardService.edhRecs(commanders, maindeck)
      .then(res => {
        setLocalResults(res)
        handleFilterResults(null, { value: cardType }, res)
      })
      .catch(err => {
        console.error(err)

        onRequestFailed(err.error)
      })
  }

  // Passing the local results as an argument here so handleSearch can pass the updated results without rerendering first
  const handleFilterResults = (_e?: any, dropdown = {} as any, localResults?: CardType[]) => {
    setSelectedCardType(dropdown.value)
    if (!localResults || !localResults.length) return handleSearch(undefined, dropdown.value)
    if (dropdown.value === 'all') return onResponseRecieved(localResults)

    const filteredResults = localResults.filter(card => {
      if (card.types.includes(dropdown.value)) return true
      if (card.front?.types.includes(dropdown.value)) return true
      if (card.back?.types.includes(dropdown.value)) return true

      return false
    })

    onResponseRecieved(filteredResults)
  }

  useEffect(() => {
    if (noAutoSearch) return

    handleSearch()
  }, [])

  return (
    <form onSubmit={handleSearch} className={styles.form}>
      <Dropdown
        fluid
        selection
        value={selectedCardType}
        options={options}
        placeholder="Card Type"
        onChange={(e, data) => handleFilterResults(e, data, localResults)}
      />
      <PhatButton color="green" className={styles.submit}>
        <Icon name="search" /> Search
      </PhatButton>
    </form>
  )
}

export default EdhRecFrom
