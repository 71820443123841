import PhatButton from 'components/formElements/PhatButton'
import React from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'

import { CardType } from 'types/deck'
import { getCardCMC } from 'utils/GetCardCMC'
import ManaSymbol from 'components/misc/customIcons/ManaSymbol'

import styles from './customCmcDropdown.module.scss'

const CMCS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]

type Props = {
  card: CardType
  onCardUpdate: (updatedCard: CardType) => void
}

const CustomCmcDropdown = ({ card, onCardUpdate }: Props) => {
  const handleClick = (customCmc: number) => {
    const updatedCard = { ...card, customCmc }

    onCardUpdate(updatedCard)
  }

  const currentCMC = getCardCMC(card)

  const DropdownTrigger = (
    <PhatButton className={styles.trigger}>
      <ManaSymbol symbol={currentCMC} />
      <span style={{ color: currentCMC !== card.cmc ? '#fa890d' : '' }}>Custom CMC</span>
      <Icon name="dropdown" />
    </PhatButton>
  )

  return (
    <Dropdown scrolling trigger={DropdownTrigger} compact icon={false} className={styles.dropdown}>
      <Dropdown.Menu>
        {CMCS.map(cmc => (
          <Dropdown.Item key={cmc} onClick={() => handleClick(cmc)}>
            <ManaSymbol symbol={cmc} />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CustomCmcDropdown
