import React from 'react'
import Link from 'components/elements/Link'

import styles from './logoLink.module.scss'

type Props = {
  forceSmall?: boolean
  forceFullSize?: boolean
  useInternetUrl?: boolean
  className?: string
}

const STATIC_URL = 'https://storage.googleapis.com/topdekt-user/logo.svg'

/**
 * The brand for the global toolbar. It links back to the home page. If mobile it has just an icon. Else it has text as
 * well. In both cases it currenly also includes a beta image.
 */
const LogoLink = ({ forceSmall = false, forceFullSize = false, useInternetUrl, className = '' }: Props) => {
  let forcedDisplayClassName = styles.hideWordOnMobile

  if (forceSmall) forcedDisplayClassName = styles.hide
  if (forceFullSize) forcedDisplayClassName = ''

  return (
    <Link href="/" className={`${styles.logo} ${className}`}>
      <img
        src={useInternetUrl ? STATIC_URL : '/images/logo.svg'}
        alt="Archidekt"
        height="25"
        width="25"
        className={styles.archidektLogo}
      />
      <img
        src="/images/archidekt2.svg"
        alt="Archidekt"
        height="25"
        width="125"
        className={`${styles.archidektWord} ${forcedDisplayClassName}`}
      />
    </Link>
  )
}

export default LogoLink
