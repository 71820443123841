import React from 'react'

// Style
import { Popup, Icon } from 'semantic-ui-react'
import type { StrictPopupProps } from 'semantic-ui-react'

type Props = {
  position?: StrictPopupProps['position']
  content: any
}

/**
 * This is a simple popup component that renders a semantic popup with a greyed out question mark as its trigger for
 * hints on the website. It should always be passed a content prop for what the popup should contain. A position prop
 * is optional for the direction the popups displays.
 * @param position
 * @param content
 * @returns {*}
 * @constructor
 */
const Tooltip = ({ position, content }: Props) => {
  return (
    <Popup
      hoverable
      position={position ? position : 'bottom center'}
      content={content}
      trigger={<Icon color="grey" name="info circle" />}
    />
  )
}

export default Tooltip
