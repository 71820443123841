import React, { useState } from 'react'
import { cloneDeep } from 'lodash'

import CategoryService from 'services/category.service'
import WebsocketService from 'services/websocket.service'
import ToastService from 'services/toast.service'

import { store } from 'redux/store'
import { useActions, useAppSelector } from 'redux/hooks'
import { setDeckStateAction } from 'redux/deck/actions'

import { CardType, CategoryType } from 'types/deck'

import PhatInput from 'components/formElements/PhatInput'
import PhatButton from 'components/formElements/PhatButton'
import CheckboxFancybox from 'components/formElements/CheckboxFancybox'
import SimpleSpinner from 'components/elements/SimpleSpinner'

import styles from './categoryForm.module.scss'

type Props = {
  category?: CategoryType
  onChange?: (category: CategoryType) => void
  isTemplate?: boolean
  personalCategoriesPage?: boolean
  optionsContainerClassName?: string
}

const CategoryForm = ({
  category,
  onChange,
  isTemplate,
  optionsContainerClassName = '',
  personalCategoriesPage = false,
}: Props) => {
  const [setDeckState] = useActions(setDeckStateAction)

  const categories = useAppSelector(state => state.deck.categories)

  const [loading, setLoading] = useState(false)

  const [name, setName] = useState(category?.name ?? '')
  const [includedInDeck, setIncludedInDeck] = useState(category?.includedInDeck ?? true)
  const [includedInPrice, setIncludedInPrice] = useState(category?.includedInPrice ?? true)
  const [isPremier, setIsPremier] = useState(category?.isPremier ?? false)
  const [createTemplate, setCreateTemplate] = useState(false)

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault()

    if (isTemplate) return handleCreateOrUpdateTemplate()

    setLoading(true)

    const cardMap = cloneDeep(store.getState().deck.cardMap)
    const body: CategoryType = { name, includedInDeck, includedInPrice, isPremier, id: category?.id || null }

    try {
      if (createTemplate)
        CategoryService.createTemplate(body)
          .then(res => ToastService.create(`${res.name} created`, 'Category Template', 'success'))
          .catch(err => ToastService.create(err))

      const response = await (category?.id ? CategoryService.update(body) : CategoryService.create(body))
      const updatedCategories = cloneDeep(categories)
      const updatedCardMap = Object.values(cardMap).reduce((acc, card) => {
        // Update any cards to reflect the new category name
        card.categories = card.categories.map(existingCategoryName => {
          if (existingCategoryName === category?.name) return response.name

          return existingCategoryName
        })

        acc[card.id] = card

        return acc
      }, {} as Record<string, CardType>)

      if (category?.id) delete updatedCategories[category?.name]

      updatedCategories[response.name] = response

      setDeckState({ categories: updatedCategories, cardMap: updatedCardMap })
      WebsocketService.send({ categories: updatedCategories, cardMap: updatedCardMap }, 'SET_DECK_STATE')

      if (onChange) onChange(response)
    } catch (err) {
      console.error(err)

      // @ts-ignore
      const errorMessages = err?.name?.join(',') || err?.message

      ToastService.create(errorMessages, 'Unable to save category', 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleCreateOrUpdateTemplate = () => {
    const body: CategoryType = { name, includedInDeck, includedInPrice, isPremier, id: category?.id || null }

    setLoading(true)

    const request = category?.id ? CategoryService.patchTemplate(body) : CategoryService.createTemplate(body)
    const successToast = category?.id ? 'Category template updated' : 'Category template created'

    request
      .then(res => {
        ToastService.create(successToast, 'Category Template', 'success')

        if (onChange) onChange(res)
      })
      .catch(err => ToastService.create(err, 'Category Template', 'error'))
      .finally(() => setLoading(false))
  }

  const isEditing = !!category?.id
  const isExistingName = Object.keys(categories).includes(name) && !category?.id

  return (
    <form onSubmit={handleSubmit}>
      <PhatInput
        focusOnMount
        selectOnFocus
        onChange={setName}
        value={name}
        placeholder="Card draw"
        header="Category name"
        containerClassName={styles.input}
      />

      {/* We hide these options on the personal categories page since they don't apply to personal categories */}
      {!personalCategoriesPage && (
        <>
          <div className={`${styles.categoryOptions} ${optionsContainerClassName}`}>
            <CheckboxFancybox
              checked={includedInDeck}
              onClick={() => setIncludedInDeck(!includedInDeck)}
              header="In Deck"
              description="This category will be included in the deck (ie: Not treated as a maybeboard)"
            />

            <CheckboxFancybox
              checked={includedInPrice}
              onClick={() => setIncludedInPrice(!includedInPrice)}
              header="In Price"
              description="This category will be included in the price calculation"
            />

            <CheckboxFancybox
              checked={isPremier}
              onClick={() => setIsPremier(!isPremier)}
              header="Premier"
              description="This category marks cards as being a Commander (Oathbreaker, Signature Spell, etc)"
            />
          </div>

          {!isEditing && !isTemplate && (
            <div className={`${styles.categoryOptions} ${optionsContainerClassName}`}>
              <CheckboxFancybox
                checked={createTemplate}
                onClick={() => setCreateTemplate(!createTemplate)}
                header="Category Template"
                description="When creating this category, also create a template for it. (Editable via your account settings)"
              />
            </div>
          )}
        </>
      )}

      {personalCategoriesPage && (
        <div className={styles.personalCategoriesBlurb}>
          When editing a Category Template, cards with Personal Categories will update to reflect the changes made here.
          However,
          <b>cards already added to decks will not have their categories changed</b>.
        </div>
      )}

      <div className={styles.controls}>
        <PhatButton
          type="submit"
          onClick={handleSubmit}
          disabled={!name.length || isExistingName || loading}
          color="green">
          {loading && <SimpleSpinner size="xSmall" />}
          {isEditing ? 'Save' : 'Create'} category
        </PhatButton>
      </div>
    </form>
  )
}

export default CategoryForm
