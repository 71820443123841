import React, { useEffect, useState } from 'react'
import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd'
import useResizeObserver from 'use-resize-observer'

import { Option } from 'components/elements/ArchidektDropdown/types'

import ImageCard from 'components/card/deckCards/imageCard'
import StackWrapper from 'components/deckPage/dragAndDrop/StackWrapper'
import StackHeader from 'components/deckPage/DeckContainerV2/StackHeader'

import styles from './imageStack.module.scss'

type Props = {
  cardIds: string[]
  name: string
  price: number
  quantity: number
  isPremier: boolean
  includedInDeck: boolean
  includeInPrice: boolean
  dragHandleProps?: DraggableProvidedDragHandleProps | null
  onDragTriggerMouseDown?: () => void
  onDragTriggerMouseUp?: () => void
  simplifiedDOMStack?: boolean
  hideSecondPrice?: boolean
  className?: string
  cardStackClassName?: string
  extraOptions?: Option[]
}

const Stack = React.memo(
  ({
    cardIds,
    name,
    price,
    quantity,
    includedInDeck,
    includeInPrice,
    isPremier,
    dragHandleProps,
    onDragTriggerMouseDown,
    onDragTriggerMouseUp,
    simplifiedDOMStack,
    className = '',
    cardStackClassName = '',
    extraOptions,
  }: Props) => {
    const { ref, width = 1 } = useResizeObserver<HTMLDivElement>()

    const [mounted, setMounted] = useState(false)

    useEffect(() => {
      // Gives time for the resize observer to get the width and the grid to set the column height for card offsets
      setTimeout(() => setMounted(true), 10)
    }, [])

    // Can this be done without a resize observer?
    const gridTemplateRows = cardIds.reduce((acc, _value, index) => {
      // 5/7 is the aspect ratio of the card - use width * 7/5 to get the height
      // 11% is the height of the name box of a card
      if (index !== cardIds.length - 1) acc += `${width * (7 / 5) * 0.11}px `
      // if (index !== cardIds.length - 1) acc += `2.5rem `
      else acc += 'auto'

      return acc
    }, '')

    return (
      <div className={`${styles.container} ${className}`} style={{ opacity: !mounted ? 0 : 1 }}>
        <StackWrapper name={name} className={styles.center}>
          <StackHeader
            name={name}
            price={price}
            quantity={quantity}
            cardIds={cardIds}
            dragHandleProps={dragHandleProps}
            onDragTriggerMouseDown={onDragTriggerMouseDown}
            onDragTriggerMouseUp={onDragTriggerMouseUp}
            className={`${styles.stackHeader} ${!includedInDeck ? styles.maybeStack : ''}`}
            extraOptions={extraOptions}
            includeInDeck={includedInDeck}
            includeInPrice={includeInPrice}
            isPremier={isPremier}
          />
          <div
            ref={ref}
            className={`${styles.cardStack} ${cardStackClassName}`}
            style={{ gridTemplateRows: gridTemplateRows || 'auto' }}>
            {cardIds.map((cardId, index) => (
              <ImageCard
                skipOpenOnClick
                simplifiedDOMStack={simplifiedDOMStack}
                hideControlsUnlessHovered={index !== cardIds.length - 1}
                cardId={cardId}
                key={`${cardId}-${index}`}
                stackName={name}
                className={styles.basicCard}
                basicCardClass={styles.basicCard}
                imageClassName={styles.cardImage}
              />
            ))}

            {!cardIds.length && <div className={styles.emptyStack}>Empty stack</div>}
          </div>
        </StackWrapper>
      </div>
    )
  },
)

export default Stack
