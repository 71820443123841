import * as Types from 'redux/playtesterV2/actions/types'

import { generateInitialState } from 'redux/playtesterV2/initialState'

import { PlaytesterV2Actions } from 'redux/playtesterV2/actions'

import { PlaytesterV2State } from 'redux/playtesterV2/types'

import { createUndoState, getUndoRedo } from 'redux/playtesterV2/helpers/undoRedo'
import { writeChangesToLocalStorage } from 'redux/playtesterV2/helpers/cacheStateChanges'

const handler: (state: PlaytesterV2State, action: PlaytesterV2Actions) => PlaytesterV2State = (
  state = generateInitialState(),
  action,
) => {
  switch (action.type) {
    case Types.SET_PLAYTESTER_V2_STATE:
      writeChangesToLocalStorage(action.payload)

      const undoRedoState = createUndoState(state, action.payload)

      return { ...state, ...undoRedoState, ...action.payload }

    case Types.UNDO_PLAYTESTER_V2:
    case Types.REDO_PLAYTESTER_V2:
      const undoneRedoneState = getUndoRedo(state, action.type === Types.UNDO_PLAYTESTER_V2)

      return { ...state, ...undoneRedoneState }

    default:
      return state
  }
}

export default handler
