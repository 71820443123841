import { RootState, selectActive } from '../reducers'
import { createSelector } from 'reselect'

// const selectActive = (state: any) => state.active

export const selectPriceSource = createSelector(selectActive, activeState => activeState.priceSource)
export const selectFilteredCards = createSelector(selectActive, activeState => activeState.filteredCards)
export const selectIsMobile = createSelector(selectActive, activeState => activeState.isMobile)

const selectMultiSelectedCollectionIds = createSelector(
  selectActive,
  activeState => activeState.multiSelectedCollectionIds,
)
export const selectIsCollectionCardMultiselected = createSelector(
  selectMultiSelectedCollectionIds,
  (_state: RootState, collectionCardId: number) => collectionCardId,
  (idMap, collectionCardId) => idMap[`${collectionCardId}`],
)
